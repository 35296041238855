//テーマ
import { ThemeProvider, defaultTheme, createTheme } from "@aws-amplify/ui-react";
export const appTheme = createTheme({
  ...defaultTheme,
  name: "app-theme",
  breakpoints: {
    values: {
      base: 0,
      small: 320,
      medium: 768,
      large: 1100,
      xl: 1280,
      xxl: 1536,
    },
  },
  tokens: {
    colors: {
      blue:{
        "10":"#F3F6FE",
        "60":"#1C4EC1",
        "90":"#283960"
      },
      red:{
        "60":"#D14600"
      },
      green:{
        "40":"#00bf8b"
      }
    },
    components: {
      button: {
          borderRadius:"1.5rem",
          color:"white",
          // backgroundColor:"transparent",
          // _hover:{
          //   backgroundColor:"white",
          //   color:"black",
          //   borderColor:"black"
          // },
          // _active:{
          //   backgroundColor:"lightgray",
          //   color:"black",
          //   borderColor:"black"
          // },
          // _focus:{
          //   backgroundColor:"white",
          //   color:"black",
          //   borderColor:"black",
          //   boxShadow:"0 0 0.5rem gray"
          // },
          primary:{
            backgroundColor: "rgb(50,190,130,1)",
            borderColor: "rgb(50,190,130,1)",
            color: "white",
            _hover: {
                backgroundColor: "rgb(46,180,120,1)",
                borderColor: "rgb(46,180,120,1)",
                color: "white",
            },
            _active: {
                backgroundColor: "rgb(40,150,100,1)",
                borderColor: "rgb(40,150,100,1)",
                color: "white",
            },
            _focus: {
                backgroundColor: "rgb(46,180,120,1)",
                borderColor: "rgb(46,180,120,1)",
                boxShadow: "0 0 0.5rem gray"
            }
          },
          menu:{
            _hover:{
            backgroundColor:"white",
            color:"black",
            },
            _active:{
              backgroundColor:"lightgray",
              color:"black",
            },
            _focus:{
              backgroundColor:"white",
              color:"black",
            },
          },
          link:{
            borderWidth:"0",
            _hover:{
              backgroundColor:"none"
            },
            _active:{
              backgroundColor:"none"
            },
            _focus:{
              backgroundColor:"none",
              boxShadow:"none"
            },
            info: {
              borderColor: { value: 'transparent' },
              backgroundColor: { value: 'transparent' },
              color: { value: '{colors.white}' },
              _hover: {
                borderColor: { value: '{colors.white}' },
                backgroundColor: { value: 'transparent' },
                color: { value: '{colors.white}' },
              },
              _focus: {
                borderColor: { value: 'transparent' },
                backgroundColor: { value: 'transparent' },
                color: { value: '{colors.white}' },
                boxShadow: "none"
              },
              _active: {
                borderColor: { value: 'transparent' },
                backgroundColor: { value: 'transparent' },
                color: { value: '{colors.white}' },
              },
            },
          }
      },
      accordion:{
        item:{
          borderColor:"white",
          borderRadius:"0",
          trigger:{
            paddingInline:"1rem",
            color:"white",
            _hover:{
              color:"white"
            },
            _focus:{
              boxShadow:"0 0 0.5rem gray"
            }
          },
          icon:{
            color:"white"
          },
          content:{
            color:"white",
            paddingBlockStart:"0",
            paddingBlockEnd:"0",
            paddingInline:"0"
          }
        }
      },
      pagination:{
        // itemShared:{
        //   borderRadius:"0",
        // },
      },
      checkbox: {
        button: {
          justifyContent: { value: 'center' },
          before: {
            width: { value: '2.2em'},
            height: { value: '2.2em'},
            borderWidth: { value: '0.5px' },
          },
          _focus: {
            borderColor: { value: '{colors.primary.100.value}' },
            boxShadow: { value: '0px 0px 0px 2px hsl(190, 100%, 15%)' },
          },
          _disabled: {
            borderColor: { value: '{colors.neutral.40.value}' },
          },
        },
        icon: {
          backgroundColor: { value: 'transparent' },
          transform: { value: 'scale(0)' },
          _checked: {
            opacity: { value: '{opacities.100.value}' },
            transform: { value: 'scale(2.2)' },
            _disabled: {
              backgroundColor: { value: '{colors.neutral.40}' },
            },
          },
        }
      },
      radio: {
        button: {
            width: { value: '18px' },
            height: { value: '18px' },
            padding: { value: '4px' },
            small: {
              width: { value: '12px' },
              height: { value: '12px' },
            },
            large: {
              width: { value: '25px' },
              height: { value: '25px' },
            },
            _checked: {
              color: {
                value: '{colors.blue.60}',
              },
            },
            _focus: {
              borderColor: { value: '{colors.border.primary.value}' },
              boxShadow: { value: 'none' },
            },
            _disabled: {
              borderColor: { value: '{colors.neutral.40.value}' },
              backgroundColor: { value: '{colors.neutral.10.value}' },
            },
        },
      },
    },
    fonts: {
      default: {
        variable: {
          value: "Noto Sans JP, sans-serif",
        },
        static: {
          value: "Noto Sans JP, sans-serif",
        },
      },
    },
  },
});
