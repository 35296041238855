import React, { useState, useEffect } from 'react';
import { Text, Flex, View, Card, Button, SelectField, Pagination, Table, TableHead, TableBody, TableRow, TableCell, TextField, Message, useTheme, IconsProvider, Alert } from '@aws-amplify/ui-react';
import DownloadLogPagination from './DownloadLogPagination'
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { Helmet } from "react-helmet-async";
import { FaAngleDown } from "react-icons/fa6";
import { ReactComponent as IcoEdit } from '../image/ico_edit.svg'
import { ReactComponent as IcoCsvTable } from '../image/ico_csv_table.svg'
import { ReactComponent as IcoPaginationBack } from '../image/ico_pagination_back.svg'
import { ReactComponent as IcoPaginationNext } from '../image/ico_pagination_next.svg'
import { useTokens } from '../components/AuthPage'; // AuthPageからuseTokensをインポート
import Header from '../commons/Header';
import Footer from '../commons/Footer';
import '../css/DownloadLog.css';

//説明文エリア
const texts = `過去にダウンロードしたファイルの一覧です。\n
               当月中にダウンロードしたファイルに限り、こちらから再度、ダウンロードすることができます。\n
               ※このダウンロードではダウンロード件数は消費しません。`;
const description = texts.split('\n').map((item, index) => {
    return (
    <React.Fragment key={index}>
        {item}<br />
    </React.Fragment>
    );
});

interface DownloadLogType {
    downloadDate:string,
    userName:string,
    industorialClass:string,
    address:string,
    corporateName:string,
    detailParameter:string,
    numberOfCorporate:number,
    comments:string,
    deleteFlag:boolean,
};

enum NumberOfDisplay {
    fifteen=15,
    thirty=30,
    fifty=50,
    oneHundred=100,
};

export default function DownloadLog() {
    const {tokens} = useTheme();
    
    // ■■エラーメッセージの定義
    const ERROR_MESSAGE_PROCESSING_ERROR = "処理中にエラーが発生しました。もう一度お試し下さい。";
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null); // エラーメッセージ用のstate
    
    const { getTokens } = useTokens(); // useTokensフックを使用
    const [tokenInfo, setTokenInfo] = React.useState<any>(null);
    
    // ヘッダ読み込み時処理完了フラグ
    const [isLoadedHeader, setIsLoadedHeader] = React.useState(false);
    
    // ダウンロード履歴一覧
    const [downloadLogList, setDownloadLogList] = React.useState<DownloadLogType[]>([]);
    // 表示件数
    const [numberOfDisplay, setNumberOfDisplay] = React.useState<number>(NumberOfDisplay.fifteen);
    // 現在のページ番号
    const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(1);
    // 総ページ数
    const [totalPages, setTotalPages] = React.useState<number>(1);
    // 総レコード数
    const totalRecords = React.useRef<number>(0);
    // メモHTML要素
    const commentsRef = React.useRef<(HTMLInputElement | null)[]>([]);
    // 処理中フラグ 多重クリック防止
    const [isProcessing, setIsProcessing] = React.useState(false);
    
    // ダウンロード履歴データ取得APIを呼び出す
    const callGetListDownloadLog = async (token:string | null, downloadDate:string) => {
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/postgres/getdownloadlog';
        const query:string = `?downloadDate=${encodeURIComponent(downloadDate)}`
        try {
          const response = await fetch(baseUrl.concat(path).concat(query),{
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${token}`, //アクセストークン
              'Content-Type': 'application/json;'
            },
          });
          if (response.ok) {
            const result = await response.json();
            return result;
          }
          const result = {message: ERROR_MESSAGE_PROCESSING_ERROR};
          return result;
        } catch (error) {
          console.error('csvredlエラー:', error);
          const result = {message: ERROR_MESSAGE_PROCESSING_ERROR};
          return result;
        }
    };
    
    // ダウンロード履歴メモ更新APIを呼び出す
    const callSaveDownloadLogComments = async (token:string | null, record:DownloadLogType) => {
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/event/savedlcomments';
        try {
          const response = await fetch(baseUrl.concat(path),{
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`, //アクセストークン
              'Content-Type': 'application/json;'
            },
            body: JSON.stringify(record)
          });
          if (response.ok) {
            const result = await response.json();
            return result;
          }
          const result = {message: ERROR_MESSAGE_PROCESSING_ERROR};
          return result;
        } catch (error) {
          console.error('savedlcommentsエラー:', error);
          const result = {message: ERROR_MESSAGE_PROCESSING_ERROR};
          return result;
        }
    };
    
    // CSV再ダウンロードAPIを呼び出す
    const callReDownloadCSV = async (token:string | null, record:DownloadLogType) => {
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/event/csvredl';
        try {
          const response = await fetch(baseUrl.concat(path),{
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`, //アクセストークン
              'Content-Type': 'application/json;'
            },
            body: JSON.stringify(record)
          });
          if (response.ok) {
            const result = await response.json();
            return result;
          }
          const result = {message: ERROR_MESSAGE_PROCESSING_ERROR};
          return result;
        } catch (error) {
          console.error('csvredlエラー:', error);
          const result = {message: ERROR_MESSAGE_PROCESSING_ERROR};
          return result;
        }
    };
    
    // 画面読み込み時処理
    React.useEffect(() => {
        const handleLoadDownloadLog = async () => {
            // 最新のアクセストークンを取得する
            const {decodedAccessTokenInfo, idToken} = await getTokens(); // トークンを取得
            setTokenInfo(decodedAccessTokenInfo); // 取得したdecodedAccessTokenInfoをセット
        
            // ダウンロード履歴データの取得を行う
            const now = new Date();
            const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyy/MM');
            const downloadLogData = await callGetListDownloadLog(idToken, formattedNow);

            if (downloadLogData && 'message' in downloadLogData) {
              const message = downloadLogData.message;
              if (message && message != '') {
                setErrorMessage(message);
                return false;
              }
            }
            if (downloadLogData.length!=0) {
                setDownloadLogList(downloadLogData);
                totalRecords.current=downloadLogData.length; // 総レコード数を設定
                const newTotalPages = Math.ceil(totalRecords.current/numberOfDisplay); // 総ページ数を算出
                setTotalPages(newTotalPages); // 総ページ数を設定
            }
        };
        if (isLoadedHeader) {
            handleLoadDownloadLog();
            setIsLoadedHeader(false);
        }
    },[isLoadedHeader]);
    
    // 表示件数ボタン押下時処理
    const handleChangeNumberOfDisplay = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setNumberOfDisplay(Number(event.target.value));
        const newTotalPages = Math.ceil(totalRecords.current/Number(event.target.value));
        setTotalPages(newTotalPages);
        if (currentPageIndex > newTotalPages) {
            setCurrentPageIndex(newTotalPages);
        }
    };
    
    // ページネーション押下時処理
    const handleNextPage = () => {
        setCurrentPageIndex(totalPages);
    };
    const handlePreviousPage = () => {
        setCurrentPageIndex(1);
    };
    const handleChangePage = (newPageIndex?:number, prevPageIndex?:number) => {
        if (newPageIndex) {
            setCurrentPageIndex(newPageIndex);
        }
    };
    
    // 編集ボタン押下時処理
    const handleTextEditable = (textElement:(HTMLInputElement | null)) => {
        if (textElement) {
            textElement.removeAttribute('readonly');
            textElement.removeAttribute('inert');
            textElement.focus();
            textElement.select();
        }
    };
    
    // 入力時処理
    const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>, textElement:(HTMLInputElement | null)) => {
        if (textElement) {
            textElement.value = event.target.value;
        }
    };
    // メモフォーカスアウト時処理
    const handleCommentsFocusOut = async (index:number) => {
        const commentsElement = commentsRef.current[index];
        const targetDownloadLogRecord:DownloadLogType = {...downloadLogList[indexOfFirstRow + index]};
        setErrorMessage(null);
        if (commentsElement) {
            commentsElement.setAttribute('readonly','');
            commentsElement.setAttribute('inert','');
            targetDownloadLogRecord.comments = commentsElement.value;
        }
        if (targetDownloadLogRecord.comments != downloadLogList[indexOfFirstRow + index].comments) { // 内容に変更がある場合のみ処理を行う
            const {idToken} = await getTokens(); // 最新のトークンを取得
            const result = await callSaveDownloadLogComments(idToken, targetDownloadLogRecord); // ダウンロード履歴レコードを更新する
            if (result && 'message' in result) { // エラーメッセージがあれば
                const message = result.message;
                if (message && message != '') {
                    setErrorMessage(message);
                    handleTextEditable(commentsElement);
                    return;
                }
            } else {
                downloadLogList[indexOfFirstRow + index].comments = targetDownloadLogRecord.comments;
            }
        }
    };
    
    // 再ダウンロードボタン押下時処理
    const handleReDownload = async (index:number) => {
        if (isProcessing) {
            return;
        }
        setIsProcessing(true);
        setErrorMessage(null);
        const targetDownloadLogRecord = downloadLogList[indexOfFirstRow + index];
        const {idToken} = await getTokens(); // 最新のトークンを取得
        const csv = await callReDownloadCSV(idToken, targetDownloadLogRecord);
        if (csv) {
            if ('message' in csv) {
              const message = csv.message;
              if (message && message != '') {
                setErrorMessage(message);
                setIsProcessing(false);
                return false;
              }
            }
            if ('url' in csv && typeof csv.url === 'string') {
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = csv.url;
                a.download = 'Companylist_'.concat(format(targetDownloadLogRecord.downloadDate,'yyyyMMddHHmmss')).concat('.csv'); // ダウンロードするファイル名を指定
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }
        setIsProcessing(false);
    };
    
    // 現在のページに表示するデータを切り出す
    const indexOfLastRow = currentPageIndex * numberOfDisplay;
    const indexOfFirstRow = indexOfLastRow - numberOfDisplay;
    const currentRows = downloadLogList.slice(indexOfFirstRow, indexOfLastRow);
    
    const [hoveredRow, setHoveredRow] = React.useState<number|null>(null);
    
    //モーダルサイズ
    const [windowDimensions, setWindowDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });
    
    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
    
      window.addEventListener('resize', handleResize);
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    //テーブル幅設定
    useEffect(() => {
      if (windowDimensions.width < 1100) {
        setMinWidth('1350px');
      } else {
        setMinWidth('950px');
      }
    }, [windowDimensions]);
    
    //テーブルの横幅設定
    const [minWidth, setMinWidth] = useState('1150px');

    return (
        <>
            <Helmet>
                <title>ダウンロード履歴 | 企業INDEXナビ</title>
            </Helmet>
            <View className="appContainer">
            <Header setIsLoadedHeader={setIsLoadedHeader} />
            <Flex className="AddPurchase mainArea" width="100vw" direction="column" alignItems="center" gap="3rem" marginBottom="5rem">
                <Flex width="100%" backgroundColor={tokens.colors.blue[20]} padding="1.5rem 0rem" direction="column" alignItems="center">
                    <Flex width="100%" maxWidth="1455px" backgroundColor="inherit" direction="row" alignItems="flex-start" alignContent="flex-start">
                        <Text color={tokens.colors.blue[90]} fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.xxl} marginLeft="1rem">ダウンロード履歴</Text>
                    </Flex>

                </Flex>
                {/* エラーメッセージの表示 */}
                {errorMessage && (
                <div className="alertDLLog">
                  <Alert
                    isDismissible={false}
                    variation="error"
                    color="red"
                    hasIcon={true}
                    heading=""
                    padding="1rem"
                  >
                    {errorMessage}
                  </Alert>
                </div>
                )}
                <Flex width="100%" maxWidth="1455px" padding="0 0.5rem" direction="column" gap="0">
                    <View padding="10px" borderRadius="1rem" backgroundColor={tokens.colors.white} margin="0 10px 10px 10px" width="100%" alignSelf="center">
                        <Text color="#000" padding="10px 0">
                            {description}
                        </Text>
                    </View>
                </Flex>
                <Flex width="100%" maxWidth="1455px" padding="0rem 1rem" justifyContent="space-between">
                    <Flex fontSize="3rem" fontWeight="bold" display="inline-flex" height="2rem">
                        <Text as="span">{downloadLogList?downloadLogList.length:0}</Text>
                        <Text as="span">件</Text>
                    </Flex>
                    <Flex display="inline-flex" alignItems="center" height="2rem" className="num-display">
                        <Text fontWeight="bold" paddingTop="0.5rem" as="span">表示件数</Text>
                        <SelectField label="" fontWeight="bold" onChange={handleChangeNumberOfDisplay}
                            icon={<FaAngleDown size="2rem" />}>
                            <option value={NumberOfDisplay.fifteen} selected>{NumberOfDisplay.fifteen}件</option>
                            <option value={NumberOfDisplay.thirty}>{NumberOfDisplay.thirty}件</option>
                            <option value={NumberOfDisplay.fifty}>{NumberOfDisplay.fifty}件</option>
                            <option value={NumberOfDisplay.oneHundred}>{NumberOfDisplay.oneHundred}件</option>
                        </SelectField>
                    </Flex>
                </Flex>
                <Flex width="100%" maxWidth="1455px" padding="0 0.5rem" justifyContent="flex-start" style={{ overflowX: "auto" }}>
                    <Table variation="bordered" className="dlhistory-table" style={{tableLayout: "fixed", minWidth: minWidth, width:"100%" }}>
                        <colgroup>
                            <col style={{width:"3%"}} />
                            <col style={{width:"15%"}}/>
                            <col style={{width:"15%"}}/>
                            <col style={{width:"10%"}}/>
                            <col style={{width:"12%"}}/>
                            <col style={{width:"20%"}}/>
                            <col style={{width:"6%"}}/>
                            <col style={{width:"15%"}}/>
                            <col style={{width:"4%"}}/>
                        </colgroup>
                        <TableHead backgroundColor={tokens.colors.neutral[20]}>

                            {/* 一つ目のヘッダ行 */}
                            <TableRow>
                                <th className="left middle" rowSpan={2} style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>No</th>
                                <th className="left middle" rowSpan={2} style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>ダウンロード日時</th>
                                <th className="left" colSpan={4} style={{border:"solid 1px lightgray"}}>検索条件</th>
                                <th className="left middle" rowSpan={2} style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>企業数</th>
                                <th className="left middle" rowSpan={2} style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>メモ</th>
                                <th className="left middle" rowSpan={2} style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>再DL</th>
                            </TableRow>
                            {/* 二つ目のヘッダ行 */}
                            <TableRow>
                                <th className="left" style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>業種</th>
                                <th className="left" style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>地域</th>
                                <th className="left" style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>企業名</th>
                                <th className="left" style={{border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>詳細条件</th>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentRows.map((record, index) => (
                                <TableRow
                                  backgroundColor={tokens.colors.white}
                                  key={index}
                                  onMouseEnter={() => setHoveredRow(index)}
                                  onMouseLeave={() => setHoveredRow(null)}
                                  className={hoveredRow === index ? 'row-hover' : ''}
                                >
                                    {/*各値のデザインはcssに記載*/}
                                    <TableCell className="right" fontSize={tokens.fontSizes.large} style={{
                                        overflow: hoveredRow === index  ? "visible" : "hidden",
                                        textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                        whiteSpace: hoveredRow === index ? "normal" : "nowrap"
                                    }}>{(indexOfFirstRow + index + 1).toLocaleString()}
                                    </TableCell>
                                    <TableCell className="left" fontSize={tokens.fontSizes.large} style={{
                                        overflow: hoveredRow === index  ? "visible" : "hidden",
                                        textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                        whiteSpace: hoveredRow === index ? "normal" : "nowrap"
                                    }}>{format(record.downloadDate, 'yyyy/MM/dd HH:mm:ss')}</TableCell>
                                    <TableCell className="left" fontSize={tokens.fontSizes.large} style={{
                                        overflow: hoveredRow === index  ? "visible" : "hidden",
                                        textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                        whiteSpace: hoveredRow === index ? "normal" : "nowrap"
                                    }}>{record.industorialClass}</TableCell>
                                    <TableCell className="left" fontSize={tokens.fontSizes.large} style={{
                                        overflow: hoveredRow === index  ? "visible" : "hidden",
                                        textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                        whiteSpace: hoveredRow === index ? "normal" : "nowrap"
                                    }}>{record.address}</TableCell>
                                    <TableCell className="left" fontSize={tokens.fontSizes.large} style={{
                                        overflow: hoveredRow === index  ? "visible" : "hidden",
                                        textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                        whiteSpace: hoveredRow === index ? "normal" : "nowrap"
                                    }}>{record.corporateName}</TableCell>
                                    <TableCell className="left" fontSize={tokens.fontSizes.large} style={{
                                        overflow: hoveredRow === index  ? "visible" : "hidden",
                                        textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                        whiteSpace: hoveredRow === index ? "normal" : "nowrap"
                                    }}>{record.detailParameter}</TableCell>
                                    <TableCell className="right" fontSize={tokens.fontSizes.large} style={{
                                        overflow: hoveredRow === index  ? "visible" : "hidden",
                                        textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                        whiteSpace: hoveredRow === index ? "normal" : "nowrap"
                                    }}>{record.numberOfCorporate.toLocaleString()}</TableCell>
                                    <TableCell className="left">
                                        <TextField style={{borderWidth:"0px", fontSize:"1.25rem", 
                                                        textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                                        wordBreak: hoveredRow === index ? "break-word" : "normal",
                                                        overflow: hoveredRow === index  ? "visible" : "hidden",
                                                    }}
                                                   label=""
                                                   key={record.comments}
                                                   defaultValue={record.comments}
                                                   inert={true}
                                                   readOnly={true}
                                                   ref={(element) => (commentsRef.current[index] = element)}
                                                   onChange={(event:React.ChangeEvent<HTMLInputElement>) => {handleTextInput(event,commentsRef.current[index])}}
                                                   onBlur={() => {handleCommentsFocusOut(index)}}
                                                   outerEndComponent={
                                                    <Button className="whiteHoverButton" variation="link" onClick={() => {handleTextEditable(commentsRef.current[index])}}>
                                                        <IcoEdit height={"2rem"} width={"2rem"}/>
                                                    </Button>}
                                        />
                                    </TableCell>
                                    <TableCell className="center">
                                        <Button className="whiteHoverButton" variation="link" onClick={()=>{handleReDownload(index);}} isLoading={isProcessing}>
                                            <IcoCsvTable height={"2rem"} width={"2rem"} />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Flex>
                <Flex width="100%" maxWidth="1455px" justifyContent="flex-end" padding="0 0 1rem 0" direction={{base:"column", large:"row"}}>
                    {downloadLogList&&downloadLogList.length!=0?(
                     <Text as="span" textAlign="center">{numberOfDisplay * (currentPageIndex - 1) + 1}～{downloadLogList.length < numberOfDisplay * currentPageIndex ? downloadLogList.length : numberOfDisplay * currentPageIndex}件</Text>
                     ):(<></>)
                    }
                    <DownloadLogPagination
                            currentPage={currentPageIndex}
                            totalPages={totalPages}
                            onNext={handleNextPage}
                            onPrevious={handlePreviousPage}
                            onChange={handleChangePage}
                    />
                </Flex>
            </Flex>
            <Footer />
            </View>
        </>
  );
};