import React, { useState, useEffect } from 'react';
import { View,Text, Flex, Button, Table, TableHead, TableBody, TableRow, TableCell, TextField, useTheme, Message, Alert } from '@aws-amplify/ui-react';
import { Helmet } from "react-helmet-async";
import { format } from 'date-fns';
import { GoTrash } from "react-icons/go";
import { MdOutlineEdit } from "react-icons/md";
import { ReactComponent as IcoEdit } from '../image/ico_edit.svg'
import { ReactComponent as IcoDelete } from '../image/ico_delete.svg'
import { useTokens } from '../components/AuthPage'; // AuthPageからuseTokensをインポート
import Header from '../commons/Header';
import Footer from '../commons/Footer';
import '../css/SearchFilterList.css';

interface SearchFilterType {
    saveDate:string,
    userName:string,
    industorialClass:string,
    address:string,
    corporateName:string,
    detailParameter:string,
    comments:string,
    deleteFlag:boolean
};

export default function SearchFilterList() {
    
    // useTokensフックを使用
    const {tokens} = useTheme();
    const { getTokens } = useTokens();
    //State
    const [tokenInfo, setTokenInfo] = React.useState<any>(null);//Token
    const [errorMessage1, setErrorMessage1] = React.useState<string | null>(null);// エラーメッセージ
    const [isLoadedHeader, setIsLoadedHeader] = React.useState(false);// ヘッダ読み込み時処理完了フラグ
    const [searchFilterList, setSearchFilterList] = React.useState<SearchFilterType[]>([]);// 保存検索条件一覧
    const commentsRef = React.useRef<(HTMLInputElement | null)[]>([]);// メモHTML要素
    const [isProcessing, setIsProcessing] = React.useState(false);// 処理中フラグ
    //汎用
    const strerrorMessage = "処理中にエラーが発生しました。もう一度お試し下さい。";    
    const errorMessage = {message:strerrorMessage};
    
    // APIイベント定義
    // 取得 保存検索条件データ
    const callGetSearchFilter = async (token:string | null) => {
        
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/postgres/getsearchfilter';
        
        try {
              const response = await fetch(baseUrl.concat(path)
                , {
                    method: 'GET',
                    headers: {
                      'Authorization': `Bearer ${token}` //アクセストークン
                      ,'Content-Type': 'application/json;'
                    }
                  }
              );
              if (response.ok) {
                return await response.json();
              };
              return errorMessage;
              
        } catch (error) {
            throw error;
        }
    };
    
    // 削除 保存検索条件
    const callDeleteSearchFilter = async (token:string | null, record:SearchFilterType) => {
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/event/deletesearchfilter';
        try {
              const response = await fetch(baseUrl.concat(path)
                ,{
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${token}`
                      ,'Content-Type': 'application/json;'
                    }
                    ,body: JSON.stringify(record)
                }
              );
              if (response.ok) {
                return await response.json();
              };
              return errorMessage;
        
          
        } catch (error) {
            throw error;
        }
    };
    
    // 更新 保存検索条件メモ
    const callUpdateSearchFilterComments = async (token:string | null, record:SearchFilterType) => {
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/event/savesearchfiltercomments';
        try {
              const response = await fetch(baseUrl.concat(path)
                ,{
                    method: 'POST'
                    ,headers: {
                      'Authorization': `Bearer ${token}`
                      ,'Content-Type': 'application/json;'
                    }
                    ,body: JSON.stringify(record)
                } 
              );
              if (response.ok) {
                return await response.json();
              };
              return errorMessage;
              
        } catch (error) {
            throw error;
        }
    };
    
    // 画面イベント処理
    // 画面読み込み時
    React.useEffect(() => {
      try{
              const handleLoadSearchFilter = async () => {
                // 最新のアクセストークンを取得する
                const {decodedAccessTokenInfo, idToken} = await getTokens();
                setTokenInfo(decodedAccessTokenInfo);
                // 保存検索条件データの取得を行う
                const searchFilterData = await callGetSearchFilter(idToken);
                if (searchFilterData && 'message' in searchFilterData) 
                {
                  const message = searchFilterData.message;
                  if (message && message != '')
                  {
                    setErrorMessage1(message);
                    return false;
                  }
                }

                if (searchFilterData) {
                  setSearchFilterList(searchFilterData);
                }
                // エラー表示をクリア
                setErrorMessage1("");
              };
            if (isLoadedHeader) {
                handleLoadSearchFilter();
                setIsLoadedHeader(false);
            }
      } catch (error) {
            console.error("エラー:", error);
            setErrorMessage1(strerrorMessage);
        }
    },[isLoadedHeader]);
    
    // 編集ボタン押下時
    const handleTextEditable = (textElement:(HTMLInputElement | null)) => {
        if (textElement)
        {
            textElement.removeAttribute('readonly');
            textElement.removeAttribute('inert');
            textElement.focus();
            textElement.select();
        }
    };
    
    // メモフォーカスアウト時
    const handleCommentsFocusOut = async (index:number) => {
      try{
            setErrorMessage1("");
            const commentsElement = commentsRef.current[index];
            const targetSearchFilterRecord:SearchFilterType = {...searchFilterList[index]};
            if (commentsElement) 
            {
              commentsElement.setAttribute('readonly','');
              commentsElement.setAttribute('inert','');
              targetSearchFilterRecord.comments = commentsElement.value;
            }
            
            // 内容に変更がある場合のみ処理を行う
            if (targetSearchFilterRecord.comments !== searchFilterList[index].comments)
            { 
              const {idToken} = await getTokens();
              const result = await callUpdateSearchFilterComments(idToken, targetSearchFilterRecord);
              
              if (result && 'message' in result ) 
              { 
                const message = result.message;
                // エラーメッセージがある場合
                if (message && message != '') 
                {
                  setErrorMessage1(message);
                  handleTextEditable(commentsElement);
                  return false;
                } 
                else
                {
                    searchFilterList[index].comments = targetSearchFilterRecord.comments;
                }
              }
              setErrorMessage1("");//エラー表示のクリア
            }
        } catch (error) {
            console.error("エラー:", error);
            alert(strerrorMessage);
            return false;
        }
    };
    
    // 削除ボタン押下時
    const handleDeleteSearchFilter = async (index:number) => {
        try{
              //処理中の場合、終了
              if (isProcessing){return;}
              
              setIsProcessing(true);
              const confirm = window.confirm('検索条件を削除します。よろしいですか？')
              if (confirm)
              {
                  const { idToken } = await getTokens();
                  const targetSearchFilterRecord = searchFilterList[index];
                  
                  const newSearchFilterData = await callDeleteSearchFilter(idToken, targetSearchFilterRecord);
                  if (newSearchFilterData && 'message' in newSearchFilterData) 
                  {
                    const message = newSearchFilterData.message;
                    if (message && message != '')
                    {
                      setErrorMessage1(message);
                      setIsProcessing(false);
                      return false;
                    }
                  }
                  setErrorMessage1("");
                  setIsProcessing(false);
                  setSearchFilterList(newSearchFilterData);
                
              }
              setIsProcessing(false);         
          
        } catch (error) {
            console.error("エラー:", error);
            setIsProcessing(false);
            alert(strerrorMessage);
            return false;
        }
    };
    
    
    //ウィンドウサイズ
    const [windowDimensions, setWindowDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight
    });
    
    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight
        });
      };
    
      window.addEventListener('resize', handleResize);
    
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    
    //テーブル幅設定
    useEffect(() => {
      if (windowDimensions.width < 1100) {
        setMinWidth('1455px');
      } else {
        setMinWidth('900px');
      }
    }, [windowDimensions]);

    const [minWidth, setMinWidth] = useState('1150px');
  
    const [hoveredRow, setHoveredRow] = React.useState<number|null>(null);

    return (
      <>
        <Helmet>
          <title>保存検索条件 | 企業INDEXナビ</title>
        </Helmet>
        <View className="appContainer">
        <Header setIsLoadedHeader={setIsLoadedHeader} />
        <Flex className="AddPurchase mainArea" width="100vw" direction="column" alignItems="center">
            <Flex width="100%" backgroundColor={tokens.colors.blue[20]} padding="1.5rem 0.25rem" direction="column" alignItems="center">
                <Flex width="100%" maxWidth="1455px" backgroundColor="inherit" direction="row" alignItems="flex-start" alignContent="flex-start">
                <Text color={tokens.colors.blue[90]} fontWeight={tokens.fontWeights.bold} fontSize={tokens.fontSizes.xxl} marginLeft="1rem">保存検索条件</Text>
                </Flex>
            </Flex>
            {/* エラーメッセージの表示 */}
            {errorMessage1 && (
            <div className="alertSearchFilter">
              <Alert
                isDismissible={false}
                variation="error"
                color="red"
                hasIcon={true}
                heading=""
                padding="1rem"
              >
                {errorMessage1}
              </Alert>
            </div>
            )}
            <Flex width="100%" maxWidth="1455px" padding="2rem 0.5rem 1rem 0.5rem" justifyContent="flex-start" style={{ overflowX: "auto" }}>
                <Table variation="bordered" className="filterlist-table" style={{minWidth: minWidth, tableLayout: "fixed" }}>
                    <TableHead border="solid 1px black" backgroundColor={tokens.colors.neutral[20]}>
                        {/* 一つ目のヘッダ行 */}
                        <TableRow>
                            <th rowSpan={2} style={{width: '3%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", textAlign: "left", fontWeight:"bold", verticalAlign:"middle"}}>No</th>
                            <th rowSpan={2} style={{width: '14%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", textAlign: "left", fontWeight:"bold", verticalAlign:"middle"}}>保存日時</th>
                            <th colSpan={4} style={{width: '64%', border:"solid 1px lightgray", textAlign: 'left', fontWeight:"bold"}}>検索条件</th>
                            <th rowSpan={2} style={{width: '15%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", textAlign: "left", fontWeight:"bold", verticalAlign:"middle"}}>メモ</th>
                            <th rowSpan={2} style={{width: '4%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", textAlign: "left", fontWeight:"bold", verticalAlign:"middle"}}>削除</th>
                        </TableRow>
                        {/* 二つ目のヘッダ行 */}
                        <TableRow>
                            <th style={{width: '17%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", textAlign: "left", fontWeight:"bold"}}>業種</th>
                            <th style={{width: '12%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", textAlign: "left", fontWeight:"bold"}}>地域</th>
                            <th style={{width: '13%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", textAlign: "left", fontWeight:"bold"}}>企業名</th>
                            <th style={{width: '22%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", textAlign: "left", fontWeight:"bold"}}>詳細条件</th>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {searchFilterList.map((record, index) => (
                            <TableRow
                              key={index}
                              onMouseEnter={() => setHoveredRow(index)}
                              onMouseLeave={() => setHoveredRow(null)}
                              className={hoveredRow === index ? 'row-hover' : ''}
                              style={{ backgroundColor: 'white' }}
                            >
                                <TableCell className="right" fontSize={tokens.fontSizes.large}>{(index + 1).toLocaleString()}</TableCell>
                                <TableCell fontSize={tokens.fontSizes.large}
                                           style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                                           overflow: hoveredRow === index ? "visible" : "hidden",
                                           textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                           wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                                  {format(record.saveDate, 'yyyy/MM/dd HH:mm:ss')}
                                </TableCell>
                                <TableCell fontSize={tokens.fontSizes.large}
                                           style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                                           overflow: hoveredRow === index ? "visible" : "hidden",
                                           textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                           wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                                  {record.industorialClass}
                                </TableCell>
                                <TableCell fontSize={tokens.fontSizes.large}
                                           style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                                           overflow: hoveredRow === index ? "visible" : "hidden",
                                           textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                           wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                                  {record.address}
                                </TableCell>
                                <TableCell fontSize={tokens.fontSizes.large}
                                           style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                                           overflow: hoveredRow === index ? "visible" : "hidden",
                                           textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                           wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                                  {record.corporateName}
                                </TableCell>
                                <TableCell fontSize={tokens.fontSizes.large}
                                           style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                                           overflow: hoveredRow === index ? "visible" : "hidden",
                                           textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                                           wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                                  {record.detailParameter}
                                </TableCell>
                                <TableCell fontSize={tokens.fontSizes.large}>
                                    <TextField style={{borderWidth:"0px", fontSize:"1.25rem", textOverflow: hoveredRow === index ? "clip" : "ellipsis"}}
                                               key={`${index}-${record.comments}`}
                                               label=""
                                               defaultValue={record.comments}
                                               inert={true}
                                               readOnly={true}
                                               ref={(element) => (commentsRef.current[index] = element)}
                                               onBlur={() => {handleCommentsFocusOut(index)}}
                                               outerEndComponent={
                                                <Button className="whiteHoverButton" borderRadius="5px" variation="link" onClick={() => {handleTextEditable(commentsRef.current[index])}}>
                                                  <IcoEdit height={"2rem"} width={"2rem"}/>
                                                </Button>}
                                               fontSize={tokens.fontSizes.large}
                                    />
                                </TableCell>
                                <TableCell padding={{large:"0", xl:"0.5rem", xxl:"1.0rem"}}>
                                    <Button className="whiteHoverButton" borderRadius="5px" variation="link" onClick={()=>{handleDeleteSearchFilter(index);}} isLoading={isProcessing}>
                                      <IcoDelete  height={"2rem"} width={"2rem"}/>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Flex>
        </Flex>
        <Footer />
        </View>
      </>
  );
};