// 必要なライブラリのインポート
import { AuthProviderProps } from "react-oidc-context";
import { assert } from './commons/Utils';  // 設定値の検証に使用
import { User } from "oidc-client-ts";

// 環境変数から認証に必要な設定値を取得
const authority = assert(process.env.REACT_APP_OIDC_AUTHORITY, 'OIDC_AUTHORITY is not set');
const clientId = assert(process.env.REACT_APP_OIDC_CLIENT_ID, 'OIDC_CLIENT_ID is not set');
const redirectUri = assert(process.env.REACT_APP_OIDC_REDIRECT_SIGN_IN, 'OIDC_REDIRECT_SIGN_IN is not set');
const postLogoutRedirectUri = assert(process.env.REACT_APP_OIDC_REDIRECT_SIGN_OUT, 'OIDC_REDIRECT_SIGN_OUT is not set');
const audience = process.env.REACT_APP_AUDIENCE; 

// OIDCプロバイダーの設定
export const oidcConfig: AuthProviderProps = {
  authority,               // OIDCサーバーのauthority URL
  client_id: clientId,      // クライアントID
  redirect_uri: redirectUri, // 認証後にリダイレクトされるURI
  post_logout_redirect_uri: postLogoutRedirectUri, // ログアウト後にリダイレクトされるURI
  scope: 'openid', // 必要なスコープ
  onSigninCallback: (_user: User | void): void => {
    const url = new URL(window.location.href);           // 現在のURLを取得
    const params = new URLSearchParams(url.search);      // URLのクエリパラメータを取得
  
    // `code`と`state`を削除
    params.delete('code');
    params.delete('state');
  
    // 残っているクエリパラメータを元に新しいURLを作成
    const newQueryString = params.toString();
    const newUrl = newQueryString ? `${url.pathname}?${newQueryString}` : url.pathname;
  
    // 履歴をクリーンアップし、クエリパラメータを保持
    window.history.replaceState({}, document.title, newUrl);
  }
};

// アプリケーション固有の設定
export const appConfig = {
  audience, //audience
};
