import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { Flex, Text, View, Link, Button, useTheme, TextField, Alert, ThemeProvider, Message, Table, TableHead, TableRow, TableBody, TableCell, } from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import { FaRegSave } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { appTheme } from '../theme/appTheme';
import { useTokens } from './AuthPage'; // AuthPageからuseTokensをインポート
import { useNavigate } from 'react-router-dom';
import { ReactComponent as IcoEdit } from '../image/ico_edit.svg'
import '../css/SaveSearchFilter.css';


interface SaveSearchFilterProps {
  modalIsOpen: boolean;
  onRequestClose: (data: any) => void; 
  modalData: any;
  onSave: (modalData: any) => void;
}

//検索条件保存
//tb_searchfilter_cnaviに合わせて地域=address
//画面からの検索条件
interface SaveSearchData {
  industry: string;
  industryCode: string;
  address: string;
  addressCode: string;
  corporateName: string;
  detailCondition: string;
  comments: string;
}
//関数に渡す型
interface eventArgsType {
  searchConditionRecordInfo: SaveSearchData;
  saveDate: string | null;
  created_at: string | null;
  updated_at: string | null;
}


const validateComments = (comments: string) => {
    if (comments.length > 100) {
        return 'メモは100文字以内で入力してください';
    } else {
        return '';
    }
};

export const SaveSearchFilter: React.FC<SaveSearchFilterProps> = ({ modalIsOpen, onRequestClose, modalData, onSave }) => {
  const { tokens } = useTheme();
  const { getTokens } = useTokens(); // useTokensフックを使用
  const [comments, setComments] = useState(modalData ? modalData.comments : "");
  const [saveError, setSaveError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // 成功メッセージ用のstate
  const commentsRef = useRef<HTMLInputElement | null>(null);
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
    //画面遷移
  const navigate = useNavigate();
  
  // 画面読み込み時
  React.useEffect(() => {
    const handleLoadHeader = async () => {
      // 最新のトークンを取得する
      try {
        // 最新のトークンを取得する
        const { decodedAccessTokenInfo, idToken } = await getTokens(); // トークンを取得
        setTokenInfo(decodedAccessTokenInfo); // 取得したdecodedAccessTokenInfoをセット
        setIdToken(idToken); // 取得したidTokenをセット
      } catch (error) {
        console.error("トークン取得エラー:", error);
      }
    }
    
    handleLoadHeader();
    
  },[]);// 依存配列を空にして初回レンダリング時のみ実行

  //エラーメッセージ処理
  useEffect(() => {
    if (!modalIsOpen) {
      setComments(modalData ? modalData.comments : "");
      setSaveError(''); 
      setSuccessMessage(''); 
    }
  }, [modalIsOpen, modalData]);

  const handleSave = async () => {
    const errorMessage = validateComments(comments);
    setSaveError(errorMessage);
    if (errorMessage !== '') {
        return; // エラーがある場合は保存処理を行わない
    }
  
    const now = new Date();
    const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyy-MM-dd HH:mm:ss').replace(' ', 'T');

    const eventArgs = {
      searchConditionRecordInfo:modalData,
      saveDate:formattedNow,
      created_at:formattedNow,
      updated_at:formattedNow
    }
  
    onSave(eventArgs);
  };

  // 編集ボタン押下時
  const handleTextEditable = (textElement:(HTMLInputElement | null)) => {
      if (textElement)
      {
          textElement.removeAttribute('readonly');
          textElement.removeAttribute('inert');
          textElement.focus();
          textElement.select();
      }
  };

  const handleCommentsFocus = () => {
    if (commentsRef.current) {
        commentsRef.current.select();
    }
  };

  const handleCommentsFocusOut = () => {
    const commentsElement = commentsRef.current;
    if (commentsElement) {
        commentsElement.setAttribute('readonly','');
        commentsElement.setAttribute('inert','');
        const errorMessage = validateComments(commentsElement.value)
        setSaveError(errorMessage);
        if (errorMessage !== '') {
            commentsElement.focus();
        } else {
            modalData.comments = commentsElement.value;
        }
    }
  };


 // ■■「保存上限チェック」の呼び出し
  const callCheckLimitSave = async () => {
    try {
      // idTokenを取得
      const { idToken } = await getTokens();
  
      // API Gatewayを呼び出す
      const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
      const path:string = '/common/checklimitsave';
      const apipath = baseUrl.concat(path);
      console.log("responseの中身:", apipath);
      const response = await fetch(apipath,{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`, // IDトークンをヘッダーに設定
          'Content-Type': 'application/json'
        },
      });
      console.log("responseの中身:", response);
      // ステータスコードの確認
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // レスポンスのJSONデータをパース
      const responseData = await response.json();
      console.log("checklimitsaveAPIのresponseDataの中身:", responseData);
      // レスポンスのbodyから必要なデータを取得
      const { saveFlag, message } = responseData;
      // 保存数の制限が超えた場合の処理
      if (!saveFlag) {
        setSaveError(message); // エラーメッセージを表示
      } else {
        console.log("callCheckLimitSave成功");
        setSaveError(""); // エラーメッセージをクリア
      }
      return saveFlag;
    } catch (error) {
      console.error("callCheckLimitSaveエラー:", error);
      throw Error;
    }
  };
  
    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault(); // デフォルトのリンク動作を防ぐ
    navigate('/searchfilterlist');
  };
  
　const [hoveredRow, setHoveredRow] = React.useState<number|null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  
  
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  //テーブルの横幅設定
  const [minWidth, setMinWidth] = useState('1150px');
  
  //テーブル幅設定
  useEffect(() => {
    if (windowDimensions.width < 1100) {
      setMinWidth('1350px');
    } else {
      setMinWidth('950px');
    }
  }, [windowDimensions]);

const getModalWidth = () => {
  if (windowDimensions.width < 1440) {
    return '95%';
  } else {
    return '75%';
  }
};

  const customStyles = {
    content: {
      width: getModalWidth(),
      height: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0',
      border: 'solid',
      borderRadius: "10px",
      borderColor: "rgb(177,177,177)"
    },
    overlay: {
      backgroundColor: 'rgba(177,177,177,0.75)',
      zIndex: 1000,
    }
  };
  
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => onRequestClose(null)}
      style={customStyles}
    >
      <ThemeProvider theme={appTheme}>
        <Flex direction="column" backgroundColor={tokens.colors.blue[10]} padding="30px" width="100%" height="100%">
          <Button onClick={() => onRequestClose(null)} border="none" alignSelf="end"><IoMdClose size={24} color="black"/></Button>
          <Text>入力した検索条件を保存します。</Text>
          <Text>検索した保存条件は、<Link fontWeight="bold" color={tokens.colors.blue[60]} target="_blank" onClick={handleLinkClick}
                                          onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                                          if (e.key === 'Enter' || e.key === ' ') {
                                            e.preventDefault();
                                            handleLinkClick(e as any);
                                          }}}
                                          tabIndex={0} className="LinkInModal">会員メニュー「保存検索条件」</Link>より、呼び出し・編集・削除ができます。</Text>
          <Text marginBottom="20px">メモ欄に記入したテキストも保存されます。</Text>
          {modalData && (
            <View style={{ overflowX: "auto" }}>
              <div className="table-containerSave">
                <Table style={{ width: '100%', borderCollapse: 'separate', textAlign: 'center', minWidth: minWidth, tableLayout: "fixed" }} className="savemodal-table">
                  <TableHead>
                    <TableRow>
                      <th colSpan={4} className="left" style={{ width: '80%', border:"solid 1px lightgray", ...tableHeaderStyles}}>検索条件</th>
                      <th rowSpan={2} className="left middle" style={{ width: '20%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>メモ</th>
                    </TableRow>
                    <TableRow>
                      <th className="left" style={{ width: '18%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>業種</th>
                      <th className="left" style={{ width: '18%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>地域</th>
                      <th className="left" style={{ width: '18%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>企業名</th>
                      <th className="left" style={{ width: '26%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>詳細条件</th>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow onMouseEnter={() => handleMouseEnter(0)} onMouseLeave={handleMouseLeave}>
                      
                      <TableCell style={{ ...tableCellStyles }} className="table-cell left" fontSize="1.5rem">
                        <div style={{ whiteSpace: hoveredRow === 0 ? "normal" : "nowrap",
                                overflow: hoveredRow === 0 ? "visible" : "hidden",
                                textOverflow: hoveredRow === 0 ? "clip" : "ellipsis",
                                wordBreak: hoveredRow === 0 ? "break-word" : "normal"}}>
                            {modalData.industry}
                        </div>
                      </TableCell>
                      <TableCell style={{ ...tableCellStyles }} className="table-cell left" fontSize="1.5rem">
                        <div style={{ whiteSpace: hoveredRow === 0 ? "normal" : "nowrap",
                                overflow: hoveredRow === 0 ? "visible" : "hidden",
                                textOverflow: hoveredRow === 0 ? "clip" : "ellipsis",
                                wordBreak: hoveredRow === 0 ? "break-word" : "normal"}}>
                            {modalData.address}
                        </div>
                      </TableCell>
                      <TableCell style={{ ...tableCellStyles }} className="table-cell left" fontSize="1.5rem">
                        <div style={{ whiteSpace: hoveredRow === 0 ? "normal" : "nowrap",
                                overflow: hoveredRow === 0 ? "visible" : "hidden",
                                textOverflow: hoveredRow === 0 ? "clip" : "ellipsis",
                                wordBreak: hoveredRow === 0 ? "break-word" : "normal"}}>
                            {modalData.corporateName}
                        </div>
                      </TableCell>
                      <TableCell style={{ ...tableCellStyles }} className="table-cell left" fontSize="1.5rem">
                         <div style={{ whiteSpace: hoveredRow === 0 ? "normal" : "nowrap",
                                overflow: hoveredRow === 0 ? "visible" : "hidden",
                                textOverflow: hoveredRow === 0 ? "clip" : "ellipsis",
                                wordBreak: hoveredRow === 0 ? "break-word" : "normal"}}>
                            {modalData.detailCondition}
                        </div>
                      </TableCell>
                      <TableCell style={{ ...tableCellStyles }} className="table-cell left" fontSize="1.5rem">
                        <div style={{ whiteSpace: hoveredRow === 0 ? "normal" : "nowrap",
                                overflow: "visible",
                                textOverflow: hoveredRow === 0 ? "clip" : "ellipsis",
                                wordBreak: hoveredRow === 0 ? "break-word" : "normal"}}>
                          <TextField 
                              variation="quiet"
                              label=""
                              ref={commentsRef}
                              value={comments}
                              onFocus={handleCommentsFocus}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => setComments(e.target.value)}
                              onBlur={handleCommentsFocusOut}
                              readOnly={true}
                              inert={true}
                              outerEndComponent={<Button className="whiteHoverButton" borderRadius="5px" variation="link" onClick={() => {handleTextEditable(commentsRef.current)}}><IcoEdit height={"2rem"} width={"2rem"}/></Button>}
                              style={{borderWidth:"0px", fontSize:"1.5rem", textOverflow: hoveredRow === 0 ? "clip" : "ellipsis"}}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </View>
          )}
                
          {/* エラーメッセージの表示 */}
          {saveError && (
              <Alert
                isDismissible={false}
                variation="error"
                color="red"
                hasIcon={true}
                heading=""
                padding="1rem"
              >
                {saveError}
              </Alert>
            )}
          <Button 
            width="250px"
            variation="primary"
            backgroundColor={tokens.colors.green[40]}
            color="white"
            alignSelf="center"
            margin="20px 0"
            borderRadius="30px"
            fontSize="20px"
            onClick={handleSave}
            className="greenHoverButton"
          >
            <Flex padding="0 0.5rem" display="inline-flex" alignItems="center">
             <View paddingTop="0.5rem" color="inherit"><FaRegSave size="2rem"/></View>
             <Text paddingRight="1rem" color="inherit">この内容で保存</Text>
            </Flex>
          </Button>
       </Flex> 
     </ThemeProvider>
   </Modal>
 )
}

const tableHeaderStyles = {
  padding: '8px',
  backgroundColor: '#f2f2f2',
  fontWeight: 'bold'
};

const tableCellStyles = {
  border: '1px solid #ddd',
  padding: '8px',
  backgroundColor: '#ffffff',
};
