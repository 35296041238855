import React, { useEffect, useState, useRef } from 'react';
import { Text, Label, View, Flex, Divider, Button, Link, Icon, useTheme } from '@aws-amplify/ui-react';
import { MdSearch } from "react-icons/md";
import { RxExternalLink } from "react-icons/rx";
import { FiMail } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';
import { useTokens } from '../components/AuthPage'; // AuthPageからuseTokensをインポート
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import '../css/Footer.css'

export default function Footer() {
  const {tokens} = useTheme();
  const navigate = useNavigate();
  const { getTokens } = useTokens(); // useTokensフックを使用
  
  // エラーメッセージ
  const ERROR_MESSAGE_PROCESSING_ERROR = "処理中にエラーが発生しました。もう一度お試し下さい。";
  
  // プラン変更画面へ遷移
  const handleNavigatePlanChange = () => {
    navigate("/planchange");
  };
  
  // G-Searchデータベースサービスの呼び出し
  const handleOpenGSearchDBS = async () => {
    const newWindow = window.open('', '_blank');
    try {
      const {decodedAccessTokenInfo, idToken} = await getTokens();
      if (decodedAccessTokenInfo.plan) {
        if (decodedAccessTokenInfo.plan == 'free') {
          newWindow?.close();
          handleNavigatePlanChange();
        }
        else if (decodedAccessTokenInfo.available_features=='disabled') {
          newWindow?.close();
          alert("ただいまお客様のアカウントではG-Searchコンテンツをご利用いただけません。\n詳細については、メールをご確認ください。");
          return;
        } else {
          const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
          const path:string = '/common/gsearch';
          const now = new Date();
          const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyyMMddHHmmss');
          const response = await fetch(baseUrl.concat(path),{
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${idToken}`, //アクセストークン
              'Content-Type': 'application/json;'
            },
            body: JSON.stringify({dbid: 'WTOP', T:formattedNow})
          });
          if (response.ok) {
            const result = await response.json();
            if (newWindow) newWindow.location.href = result.headers.Location
            return;
          }
          newWindow?.close();
          alert(ERROR_MESSAGE_PROCESSING_ERROR);
          return;
        }
      }
      newWindow?.close();
      return;
    } catch (error) {
      console.error('gsearchエラー:', error);
      newWindow?.close();
      alert(ERROR_MESSAGE_PROCESSING_ERROR);
      return;
    }
  };
  
  // お問い合わせフォーム画面を開く
  const handleOpenContactForm = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/inquiry/index.html`, "_blank", "noreferrer");
  };
  
  // 最上部へのスクロール
  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  
  // スクロールボタンのフッターより上かどうか
  const [isAboveFooter, setIsAboveFooter] = useState<boolean>(false);
  // スクロールボタンの高さの位置
  const [buttonBottom, setButtonBottom] = useState<number>(0);
  // スクロールボタンの表示/非表示
  const [isButtonDisplay,setIsButtonDisplay] = useState<boolean>(false);
  // フッターの要素
  const footerElment = useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    scrollWindow()
    window.addEventListener('scroll', scrollWindow)
    return () => {
      window.removeEventListener('scroll', scrollWindow)
    }
  }, [])

  // スクロール時の高さ別のステータス変更
  const scrollWindow = () => {

    const top = 50;  //ボタンを表示させたい位置
    let scroll = 0;
    scroll = window.scrollY; // 現在のスクロール位置
    
    // スクロールが最上部が判定
    if (scroll < top) {
      setButtonBottom(0);
      setIsButtonDisplay(false);
      return
    } else {
      if(footerElment.current === null) return
      setIsButtonDisplay(true);
      const bounding = footerElment.current.getBoundingClientRect()
      
      if (window.innerHeight > bounding.top) {
        setButtonBottom(bounding.height);
        setIsAboveFooter(true)
      } else {
        setButtonBottom(0);
        setIsAboveFooter(false)
      }
    }   
  }

  const styleTop = {
    position: isAboveFooter ? 'absolute' : 'fixed',
    bottom: buttonBottom,
    right: 0,
    zIndex: 3,
    display: isButtonDisplay ? 'block' : 'none',
  } as React.CSSProperties
  
  // 運営会社（株式会社ジー・サーチについて）を別タブで開く
  const handleOpenOperatingCompanyInfo = () => {
    window.open('https://www.g-search.jp/', "_blank", "noreferrer");
  };
  // 個人情報保護ポリシーを別タブで開く
  const handleOpenPrivacyPolicy = () => {
    window.open('https://www.g-search.jp/info/privacy.html', "_blank", "noreferrer");
  };
  // サイトのご利用についてを別タブで開く
  const handleOpenSitePolicy = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/sitepolicy.html`, "_blank", "noreferrer");
  };
  // 情報の修正依頼を別タブで開く
  const handleOpenChangeRequest = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/inquiry/change_request.html`, "_blank", "noreferrer");
  };
  // 会員規約を別タブで開く
  const handleOpenAgreement = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/agreement.html`, "_blank", "noreferrer");
  };


  return (
    <footer ref={footerElment}>
      <div style={styleTop}>
        <Button backgroundColor={tokens.colors.blue[90]} color={tokens.colors.white} fontSize="1.4rem"fontWeight="bold" 
        borderRadius="0" borderStyle="none" padding="0.8rem 1.6rem" onClick={returnTop}>▲</Button>
      </div>
      <Flex backgroundColor={tokens.colors.blue[90]} direction="column" alignItems="center" padding="1.6rem 0" position="relative" style={{ zIndex:"10" }}>
        <Flex width="100%" maxWidth="1455px" direction="column" alignItems="center" gap="0">
          <Flex width="100%" justifyContent="space-between" direction={{base:"column", large:"row"}} alignItems={{base:"center", large:"flex-end"}} paddingBottom="1.6rem" wrap="wrap" height="auto">
            <Flex wrap="wrap" height="auto" width={{base:"90%",large:"400px"}}>
              <Button className="orangeHoverButton orangeHoverButtonDarkblueBackground" width="100%" variation="menu" borderRadius="0" display="inline-flex" padding="0.5rem"
                onClick={handleOpenGSearchDBS} fontSize={tokens.fontSizes.xl} backgroundColor={tokens.colors.red[60]}>
                <Flex margin="auto" display="inline-flex" alignItems="center" gap={{base:"0.5rem", large:"1rem"}}>
                  <View paddingTop="0.5rem" color="inherit"><MdSearch size="2rem" /></View>
                  <Text color="inherit" fontSize={{base:"1.2rem",medium:"1.3rem",large:"1.5rem"}}>G-Searchデータベースサービスで深掘り</Text>
                  <View paddingTop="0.5rem" color="inherit"><RxExternalLink /></View>
                </Flex>
              </Button>
            </Flex>
            <Flex width={{base:"90%",large:"180px"}}>
              <Button className="darkblueHoverButtonDarkblueBackground" width="100%" borderRadius="0" variation="menu" borderColor={tokens.colors.white} borderStyle="solid" borderWidth="1px"  onClick={handleOpenContactForm} fontSize={tokens.fontSizes.xl}>
                <Flex margin="auto" display="inline-flex" alignItems="center">
                  <View paddingTop="0.5rem" color="inherit"><FiMail size="2rem"/></View>
                  <Text paddingRight="1rem" color="inherit">お問い合わせ</Text>
                </Flex>
              </Button>
            </Flex>
          </Flex>
          <Divider borderColor={tokens.colors.white}/>
          <Flex direction="column" width="100%" justifyContent="flex-end" paddingTop="1.6rem" paddingRight="0.5rem">
            <Flex wrap="wrap" justifyContent="flex-end">
              <Link className="footerLink" tabIndex={0} display="inline-flex" 
                onClick={handleOpenOperatingCompanyInfo} 
                onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleOpenOperatingCompanyInfo();
                  }
                }} 
                isExternal={true} fontSize="1.25rem"
              >
                <Text textDecoration="none" color={tokens.colors.white}>運営会社（株式会社ジー・サーチについて）</Text>
                <Flex alignItems="center" color={tokens.colors.white}><RxExternalLink /></Flex>
              </Link>
              <Link className="footerLink" tabIndex={0} display="inline-flex" 
                onClick={handleOpenPrivacyPolicy} 
                onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleOpenPrivacyPolicy();
                  }
                }} 
                isExternal={true} fontSize="1.25rem">
                <Text textDecoration="none" color={tokens.colors.white}>個人情報保護ポリシー</Text>
                <Flex alignItems="center" color={tokens.colors.white}><RxExternalLink /></Flex>
              </Link>
              <Link className="footerLink pointer-cursor" tabIndex={0} display="inline-flex" 
                onClick={handleOpenSitePolicy} 
                onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleOpenSitePolicy();
                  }
                }} 
                isExternal={true} fontSize="1.25rem"
              >
                <Text textDecoration="none" color={tokens.colors.white}>サイトのご利用について</Text>
                <Flex alignItems="center" color={tokens.colors.white}><RxExternalLink /></Flex>
              </Link>
              <Link className="footerLink pointer-cursor" tabIndex={0} display="inline-flex" 
                onClick={handleOpenChangeRequest} 
                onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleOpenChangeRequest();
                  }
                }}   
                isExternal={true} fontSize="1.25rem"
              >
                <Text textDecoration="none" color={tokens.colors.white}>情報の修正依頼</Text>
                <Flex alignItems="center" color={tokens.colors.white}><RxExternalLink /></Flex>
              </Link>
              <Link className="footerLink pointer-cursor" tabIndex={0} display="inline-flex" 
                onClick={handleOpenAgreement} 
                onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleOpenAgreement();
                  }
                }} 
                isExternal={true} fontSize="1.25rem"
              >
                <Text textDecoration="none" color={tokens.colors.white}>会員規約</Text>
                <Flex alignItems="center" color={tokens.colors.white}><RxExternalLink /></Flex>
              </Link>
            </Flex>
            <Flex width="100%" justifyContent="flex-end" color={tokens.colors.white} fontSize="1.25rem">© 2023- 2024 G-Search Limited</Flex>
          </Flex>
        </Flex>
      </Flex>
    </footer>
  );
};
