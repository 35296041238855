import React, { useState, useRef, useEffect } from 'react';
import { Flex, Text, View, Button, useTheme, ThemeProvider, Table, TableHead, TableRow, TableBody, TableCell, } from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import { FaArrowRotateLeft } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import { appTheme } from '../theme/appTheme';
import { useTokens } from './AuthPage'; // AuthPageからuseTokensをインポート
import '../css/CallSearchFilter.css';

interface ModalData {
  savedAt: Date | null;
  userName: string;
  industry: string;
  industryCode: string; 
  address: string;
  addressCode: string; 
  corporateName: string;
  detailCondition: string;
  comments: string;
}

interface CallSearchFilterProps {
  modalIsOpen: boolean;
  onRequestClose: (data: any | null) => void;
  modalData: ModalData[] | null;
  onCall: (calledData: ModalData | null) => void;
}

interface CallSearchFilterType {
  tokenInfo:[];
}

export const CallSearchFilter: React.FC<CallSearchFilterProps> = ({ modalIsOpen, onRequestClose, modalData, onCall }) => {
  const { tokens } = useTheme();
  const { getTokens } = useTokens(); // useTokensフックを使用
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [tableData, setTableData] = useState<ModalData[]>([]);
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  
  
  // 画面読み込み時
  React.useEffect(() => {
    const handleLoadHeader = async () => {
      
      // 最新のトークンを取得する
      try {
        // 最新のトークンを取得する
        const { decodedAccessTokenInfo, idToken } = await getTokens(); // トークンを取得
        setTokenInfo(decodedAccessTokenInfo); // 取得したdecodedAccessTokenInfoをセット
        setIdToken(idToken); // 取得したidTokenをセット
    
        // 画面内容の初期化（トークン取得後に行いたい処理をここに記述）
      } catch (error) {
        console.error("トークン取得エラー:", error);
      }
    
    }
    
    handleLoadHeader();
      
    },[]);// 依存配列を空にして初回レンダリング時のみ実行

useEffect(() => {
  const fetchData = async () => {
    if (modalIsOpen) {
      const data = await callGetlistSearchFilter();
      if (data) {
        const formattedData = data.map((item: any) => ({
          savedAt: new Date(item.saveDate),
          userName: item.userName,
          industry: item.industorialClass,
          industryCode: item.industorialClass_code, // 追加
          address: item.address,
          addressCode: item.address_code, // 追加
          corporateName: item.corporateName,
          detailCondition: item.detailParameter,
          comments: item.comments
        }));
        setTableData(formattedData);
      }
    }
  };
  fetchData();
}, [modalIsOpen]);

useEffect(() => {
  if (modalIsOpen) {
    setSelectedRow(null); // モーダルが開いた時に選択を初期化
  }
}, [modalIsOpen]);

const handleCall = () => {
  if (selectedRow !== null && tableData) {
    const calledData = tableData[selectedRow];
    onCall(calledData); // 親コンポーネントに選択されたデータを渡す
  }
};

// 常に10行表示するためのロジック
const rows = tableData ? [...tableData] : [];
  
// 各種サービステーブルよりデータを取得するAPIを呼び出す
const callGetlistSearchFilter = async () => {
    const baseUrl: string = process.env.REACT_APP_API_BASE_URL || '';
    const path: string = '/postgres/getsearchfilter';
    // idTokenを取得
    const { idToken } = await getTokens();
    try {
      const response = await fetch(baseUrl.concat(path), {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${idToken}`, //アクセストークン
          'Content-Type': 'application/json;'
        },
      });
      if (response.ok) {
        const result = await response.json();
        return result;
      } 
    } catch (error) {
      console.log(error);
    }
};

const [hoveredRow, setHoveredRow] = React.useState<number|null>(null);  

//モーダルサイズ
const [windowDimensions, setWindowDimensions] = useState({
  width: window.innerWidth,
  height: window.innerHeight
});

useEffect(() => {
  const handleResize = () => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

//テーブル幅設定
useEffect(() => {
  if (windowDimensions.width < 1100) {
    setMinWidth('1350px');
  } else {
    setMinWidth('950px');
  }
}, [windowDimensions]);

const getModalWidth = () => {
  if (windowDimensions.width < 1440) {
    return '95%';
  } else {
    return '75%';
  }
};

//テーブルの横幅設定
const [minWidth, setMinWidth] = useState('1150px');

const customStyles = {
  content: {
    width: getModalWidth(),
    height: 'auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    border: 'solid',
    borderRadius: "10px",
    borderColor: "rgb(177,177,177)"
  },
  overlay: {
    backgroundColor: 'rgba(177,177,177,0.75)',
    zIndex: 1000,
  }
};

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        onRequestClose(null); // データを返さずに閉じる
      }}
      style={customStyles}
    >
      <ThemeProvider theme={appTheme}>
        <Flex direction="column" backgroundColor={tokens.colors.blue[10]} padding="20px 30px" width="100%" height="100%">
          <Button 
            onClick={() => {
              onRequestClose(null); // データを返さずに閉じる
            }} 
            border="none" 
            alignSelf="end"
          >
            <IoMdClose size={24} color="black" />
          </Button>
          <Text>保存した検索条件を呼び出します。</Text>
          <Text marginBottom="20px">下記リストから一つ選んで呼び出しボタンを押してください。</Text>
          <View style={{ overflowX: "auto" }}>
            <div className="table-containerCall">
              <Table style={{ width: '100%', borderCollapse: 'separate', textAlign: 'center', minWidth: minWidth, tableLayout: "fixed"}} className="callmodal-table">
                <TableHead>
                  <TableRow>
                    <th rowSpan={2} style={{width: '3%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}></th>
                    <th rowSpan={2} className="left middle" style={{width: '5%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>No</th>
                    <th rowSpan={2} className="left middle" style={{width: '13%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>保存日時</th>
                    <th colSpan={4} className="left" style={{width: '63%', border:"solid 1px lightgray", ...tableHeaderStyles}}>検索条件</th>
                    <th rowSpan={2} className="left middle" style={{width: '16%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>メモ</th>
                  </TableRow>
                  <TableRow>
                    <th className="left" style={{width: '15%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>業種</th>
                    <th className="left" style={{width: '14%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>地域</th>
                    <th className="left" style={{width: '15%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>企業名</th>
                    <th className="left" style={{width: '19%', border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue", ...tableHeaderStyles}}>詳細条件</th>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((data, index) => (
                    <TableRow 
                      key={index}
                      onMouseEnter={() => setHoveredRow(index)}
                      onMouseLeave={() => setHoveredRow(null)}
                      className={hoveredRow === index ? 'row-hover' : ''}
                      style={{ backgroundColor: 'white' }}
                    >
                      <TableCell style={tableCellStyles} fontSize="1.5rem">
                        {data.savedAt !== null && (
                          <input 
                            type="radio" 
                            name="rowSelector" 
                            checked={selectedRow === index}
                            onChange={() => setSelectedRow(index)} 
                            width="10px"
                          />
                        )}
                      </TableCell>
                      <TableCell style={tableCellStyles} className="right" fontSize="1.5rem">{data.savedAt !== null ? index + 1 : ""}</TableCell>
                      <TableCell style={tableCellStyles} className="left" fontSize="1.5rem">
                        {data.savedAt !== null ? (
                          <div style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                              overflow: hoveredRow === index ? "visible" : "hidden",
                              textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                              wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                          {data.savedAt.toLocaleString()}
                          </div>
                        ) : ""}
                      </TableCell>
                      <TableCell style={tableCellStyles} className="left" fontSize="1.5rem">
                        {data.savedAt !== null ? (
                          <div style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                              overflow: hoveredRow === index ? "visible" : "hidden",
                              textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                              wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                          {data.industry}
                          </div>
                        ) : ""}
                      </TableCell>
                      <TableCell style={tableCellStyles} className="left" fontSize="1.5rem">
                        {data.savedAt !== null ? (
                          <div style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                              overflow: hoveredRow === index ? "visible" : "hidden",
                              textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                              wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                          {data.address}
                          </div>
                        ) : ""}
                      </TableCell>
                      <TableCell style={tableCellStyles} className="left" fontSize="1.5rem">
                        {data.savedAt !== null ? (
                          <div style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                              overflow: hoveredRow === index ? "visible" : "hidden",
                              textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                              wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                          {data.corporateName}
                          </div>
                        ) : ""}
                      </TableCell>
                      <TableCell style={tableCellStyles} className="left" fontSize="1.5rem">
                        {data.savedAt !== null ? (
                          <div style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                              overflow: hoveredRow === index ? "visible" : "hidden",
                              textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                              wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                            {data.detailCondition}
                          </div>
                        ) : ""}
                      </TableCell>
                      <TableCell style={tableCellStyles} className="left" fontSize="1.5rem">
                        {data.savedAt !== null ? (
                          <div style={{whiteSpace: hoveredRow === index ? "normal" : "nowrap",
                              overflow: hoveredRow === index ? "visible" : "hidden",
                              textOverflow: hoveredRow === index ? "clip" : "ellipsis",
                              wordBreak: hoveredRow === index ? "break-word" : "normal"}}>
                            {data.comments}
                          </div>
                        ) : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </View>
          <Button
            width="250px"
            backgroundColor={tokens.colors.green[40]}
            variation="primary"
            color={tokens.colors.white}
            alignSelf="center"
            margin="10px 0"
            borderRadius="30px"
            fontSize="20px"
            onClick={handleCall}
            className="greenHoverButton"
          >
            <FaArrowRotateLeft /> この条件を呼び出す
          </Button>
        </Flex>
      </ThemeProvider>
    </Modal>
  );
};

const tableHeaderStyles = {
  padding: '8px',
  backgroundColor: '#f2f2f2',
  fontWeight: 'bold'
};

const tableCellStyles = {
  border: '1px solid #ddd',
  padding: '8px',
  backgroundColor: '#ffffff',
  height: '40px'
};

