import React from 'react';
import { useNavigate, useSearchParams, useLocation  } from "react-router-dom";
import { Text, Label, View, Flex, Divider, Button, Link, Image, Icon, Grid, Card, Badge, Message, useTheme } from '@aws-amplify/ui-react';
import { Helmet } from "react-helmet-async";
import { RxExternalLink } from "react-icons/rx";
import { FaMinus, FaPlus } from "react-icons/fa";
import { RiMapPin2Line } from "react-icons/ri";
import { FaChevronRight } from "react-icons/fa";
import { BiSolidDownArrow } from "react-icons/bi";
import { ReactComponent as IcoSearch } from '../image/ico_search.svg'
import { ReactComponent as IcoKigyou } from '../image/ico_kigyou.svg'
import { ReactComponent as IcoSinbun } from '../image/ico_sinbun.svg'
import { ReactComponent as IcoCircleKigyou } from '../image/ico_circle_kigyou.svg'
import { ReactComponent as IcoCirlceSinbun } from '../image/ico_circle_sinbun.svg'
import { useTokens } from './AuthPage'; // AuthPageからuseTokensをインポート
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { Chart, TooltipItem } from 'chart.js/auto';
import Header from '../commons/Header';
import Footer from '../commons/Footer';
import '../css/detail_template.css';
import '../css/about.css';
import '../css/reset.css';
import '../css/sub_content.css';
import '../css/CorporateDetail.css';


export default function CorporateDetail() {
    const {tokens} = useTheme();
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const location = window.location.origin;
    
    const { getTokens } = useTokens(); // useTokensフックを使用
    const [tokenInfo, setTokenInfo] = React.useState<any>(null);
    
    // ヘッダ読み込み時処理完了フラグ
    const [isLoadedHeader, setIsLoadedHeader] = React.useState(false);
    
    // ヘッダ 詳細表示・ダウンロード件数再読み込みフラグ
    const [reloadHeaderFlag, setReloadHeaderFlag] = React.useState(false);
    
    // 表示用データのstate
    const [detailData, setDetailData] = React.useState<any>(null)
    
    // －
    const FULL_WIDTH_HYPHEN = '－';
    
    // 営業品目
    const HINMOKU_SEIZO:{[key:string]:string} = {'101' : '衣服・その他繊維製品類', '102' : 'ゴム・皮革・プラスチック製品類', '103' : '窯業・土石製品類', '104' : '非鉄金属・金属製品類', '105' : 'フォーム印刷', '106' : 'その他印刷類', '107' : '図書類', '108' : '電子出版物類', '109' : '紙・紙加工品類', '110' : '車両類', '111' : 'その他輸送・搬送機械器具類', '112' : '船舶類', '113' : '燃料類', '114' : '家具・什器類', '115' : '一般・産業用機器類', '116' : '電気・通信用機器類', '117' : '電子計算機類', '118' : '精密機器類', '119' : '医療用機器類', '120' : '事務用機器類', '121' : 'その他機器類', '122' : '医薬品・医療用品類', '123' : '事務用品類', '124' : '土木・建設・建築材料', '127' : '警察用装備品類', '128' : '防衛用装備品類', '129' : 'その他'}
    const HINMOKU_HANBAI:{[key:string]:string} = {'201' : '衣服・その他繊維製品類', '202' : 'ゴム・皮革・プラスチック製品類', '203' : '窯業・土石製品類', '204' : '非鉄金属・金属製品類', '205' : 'フォーム印刷', '206' : 'その他印刷類', '207' : '図書類', '208' : '電子出版物類', '209' : '紙・紙加工品類', '210' : '車両類', '211' : 'その他輸送・搬送機械器具類', '212' : '船舶類', '213' : '燃料類', '214' : '家具・什器類', '215' : '一般・産業用機器類', '216' : '電気・通信用機器類', '217' : '電子計算機類', '218' : '精密機器類', '219' : '医療用機器類', '220' : '事務用機器類', '221' : 'その他機器類', '222' : '医薬品・医療用品類', '223' : '事務用品類', '224' : '土木・建設・建築材料', '227' : '警察用装備品類', '228' : '防衛用装備品類', '229' : 'その他'}
    const HINMOKU_TEIKYO:{[key:string]:string} = {'301' : '広告・宣伝', '302' : '写真・製図', '303' : '調査・研究', '304' : '情報処理', '305' : '翻訳・通訳・速記', '306' : 'ソフトウェア開発', '307' : '会場等の借り上げ', '308' : '賃貸借', '309' : '建物管理等各種保守管理', '310' : '運送', '311' : '車両整備', '312' : '船舶整備', '313' : '電子出版', '314' : '防衛用装備品類の整備', '315' : 'その他'}
    const HINMOKU_KAIUKE:{[key:string]:string} = {'401' : '立木竹', '402' : 'その他'}
    
    // 法人番号データ 変更区分(process)
    const CHANGE_CLASS:{[key:string]:string} = {'01' : '法人番号指定日', '11' : '商号又は名称の変更', '12' : '国内所在地の変更', '13' : '国外所在地の変更', '21' : '登記記録の閉鎖等', '22' : '登記記録の復活等', '71' : '吸収合併', '72' : '吸収合併無効', '81' : '商号の登記の抹消', '99' : '削除'};
    
    // 法人番号データ 変更事由詳細 改行
    // 元号
    const ERA = ['令和', '平成', '昭和', '大正', '明治']
    // 元号年月日 正規表現
    const eraRegExp = new RegExp(`(${ERA.join('|')})((元|[\\d０-９]{1,2})年([\\d０-９]{1,2})月([\\d０-９]{1,2})日)`, 'g');
    
    // グラフ表示用Ref
    // 売上推移
    const canvasSalesRef = React.useRef<HTMLCanvasElement>(null);
    // 年別調達金額
    const canvasYearAmountRef = React.useRef<HTMLCanvasElement>(null);
    // 省庁別調達数
    const canvasMinistryProcurementRef = React.useRef<HTMLCanvasElement>(null);
    
    // ホームポジションRef
    const homePositionRef = React.useRef<HTMLAnchorElement>(null);
    
    // エラーメッセージ
    const ERROR_MESSAGE_PROCESSING_ERROR = "処理中にエラーが発生しました。もう一度お試し下さい。";
    
    // 3桁のカンマ区切りに変換
    const convert_data_comma = (target_data:string | number | bigint | null | undefined, add_str='') => {
      if (target_data == null) {
        return FULL_WIDTH_HYPHEN;
      }
      try {
        if (typeof target_data == 'string') {
          if (target_data) {
            return BigInt(target_data).toLocaleString() + add_str;
          } else {
            return String(target_data) + add_str;
          }
        } else {
          return target_data.toLocaleString() + add_str;
        }
      } catch (error) {
        return String(target_data) + add_str
      }
    }
    
    // 各種表示用データの取得
    const callEventShowCompanyDetail = async (token:string | null, corporateNumber:string | null, fetchType:string) => {
      const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
      const path:string = '/event/showdetail';
      const response = await fetch(baseUrl.concat(path),{
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, //アクセストークン
          'Content-Type': 'application/json;'
        },
        body: JSON.stringify({'corporateNumber': corporateNumber, 'fetchType': fetchType})
      });
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        alert(ERROR_MESSAGE_PROCESSING_ERROR);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    };
    
    // ダミーリンク押下時
    const handleDummyLink = (event:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
    };
    
    // ホーム画面へ遷移
    const handleNavigateHomeScreen = (event:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      navigate('/');
    };
    
    // プラン変更画面へ遷移
    const handleNavigatePlanChange = () => {
      navigate("/planchange");
    };
    
    // G-Searchデータベースサービスの呼び出し バナーリンク押下
    const handleBannerLinkOpenGSearchDBS = async (event:React.MouseEvent<HTMLAnchorElement, MouseEvent>, dbid:string, corporateName:string) => {
      event.preventDefault();
      if (tokenInfo) {
        const newWindow = window.open('', '_blank');
        if (tokenInfo.plan == 'free') {
          newWindow?.close();
          handleNavigatePlanChange();
        }
        else if (tokenInfo.available_features=='disabled') {
          newWindow?.close();
          alert("ただいまお客様のアカウントではG-Searchコンテンツをご利用いただけません。\n詳細については、メールをご確認ください。");
          return;
        } else {
          const {idToken} = await getTokens();
          const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
          const path:string = '/common/gsearch';
          try {
            const now = new Date();
            const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyyMMddHHmmss');
            const response = await fetch(baseUrl.concat(path),{
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${idToken}`, //アクセストークン
                'Content-Type': 'application/json;'
              },
              body: JSON.stringify({dbid: dbid, corporateName: corporateName, T:formattedNow})
            });
            if (response.ok) {
              const result = await response.json();
              if (newWindow) newWindow.location.href = result.headers.Location
              return;
            }
            alert(ERROR_MESSAGE_PROCESSING_ERROR);
            return;
          } catch (error) {
            newWindow?.close();
            console.error('gsearchエラー:', error);
            alert(ERROR_MESSAGE_PROCESSING_ERROR);
            return;
          }
        }
      }
    };
    
    // G-Searchデータベースサービスの呼び出し クイックメニューリンク押下
    const handleQuickMenuLinkOpenGSearchDBS = async (event:React.MouseEvent<HTMLAnchorElement, MouseEvent>, dbid:string, corporateName:string) => {
      event.preventDefault();
      if (tokenInfo) {
        const newWindow = window.open('', '_blank');
        if (tokenInfo.plan == 'free') {
          newWindow?.close();
          handleNavigatePlanChange();
        }
        else if (tokenInfo.available_features=='disabled') {
          newWindow?.close();
          alert("ただいまお客様のアカウントではG-Searchコンテンツをご利用いただけません。\n詳細については、メールをご確認ください。");
          return;
        } else {
          const {idToken} = await getTokens();
          const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
          const path:string = '/common/gsearch';
          try {
            const now = new Date();
            const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyyMMddHHmmss');
            const response = await fetch(baseUrl.concat(path),{
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${idToken}`, //アクセストークン
                'Content-Type': 'application/json;'
              },
              body: JSON.stringify({dbid: dbid, corporateName: corporateName, T:formattedNow})
            });
            if (response.ok) {
              const result = await response.json();
              if (newWindow) newWindow.location.href = result.headers.Location
              return;
            }
            alert(ERROR_MESSAGE_PROCESSING_ERROR);
            return;
          } catch (error) {
            newWindow?.close();
            console.error('gsearchエラー:', error);
            alert(ERROR_MESSAGE_PROCESSING_ERROR);
            return;
          }
        }
      }
    };
    
    React.useEffect(() => {
      const handleLoadCorporateDetail = async () => {
        try{
          // 最新のアクセストークンを取得する
          const {decodedAccessTokenInfo, idToken} = await getTokens();
          setTokenInfo(decodedAccessTokenInfo);
          
          // 最低限の表示用データを取得する
          const corporateNumber = searchParams.get('corporateNumber');
          const firstShowData = await callEventShowCompanyDetail(idToken, corporateNumber, 'first');
          
          // 残数上限チェック
          if (firstShowData.isNotDownloadable) {
            if (decodedAccessTokenInfo && (decodedAccessTokenInfo.plan == 'free' || decodedAccessTokenInfo.plan == 'freePlus')) {
              alert('ダウンロード/表示件数が不足しています。出力数追加には、ベーシックプランへのアップグレードが必要です。\nプラン変更画面へ遷移します。');
              navigate('/planchange');
              return;
            }
            if (decodedAccessTokenInfo && decodedAccessTokenInfo.plan == 'basic') {
              alert('ダウンロード/表示件数が不足しています。\n追加購入画面へ遷移します。');
              navigate('/addpurchase');
              return;
            }
          }
          
          // 法人番号存在チェック
          if (Object.keys(firstShowData.bodyVpromotionsite).length === 0) {
            alert('指定された法人番号に該当する企業情報はありません。\nホーム画面へ遷移します。');
            navigate('/');
            return;
          }
          
          // 最低限の表示用データをセット
          setDetailData({...firstShowData});
          
          // ヘッダ 詳細表示・ダウンロード件数 最新化
          setReloadHeaderFlag(true);
          
          // ホームポジションにカーソルを設定する
          if(homePositionRef.current){
            homePositionRef.current.focus();
          }
          
          // 全表示用データを取得する
          const secondShowData = await callEventShowCompanyDetail(idToken, corporateNumber, 'second');
          // 全表示用データをセット
          setDetailData({...secondShowData});
          
        } catch (error) {
          console.error(error);
        }
        
      };
      if (isLoadedHeader) {
        handleLoadCorporateDetail();
        setIsLoadedHeader(false);
      }
      
    },[isLoadedHeader]);
    
    React.useEffect(() => {
      // グラフ表示
      // 売上推移
      if (detailData && 'graphSettingsVpromotionsite' in detailData && detailData.graphSettingsVpromotionsite.graphDisplayFlag && canvasSalesRef.current) {
        const context = canvasSalesRef.current.getContext('2d');
        const x_value:string[] = detailData.graphSettingsVpromotionsite.x_value;
        const replace_x_value = x_value.map(item => item === null ? '' : item +'年');
        if (context) {
          new Chart(context, {
            type: 'bar', // グラフの種類
            data: {
              labels: replace_x_value,
              datasets: [
                {
                  barPercentage:0.5,
                  categoryPercentage:1,
                  label: '',
                  backgroundColor: ['#4474c4'],
                  data: detailData.graphSettingsVpromotionsite.y_value,
                  borderWidth: 1,
                },
              ],
            },
            options: {
              plugins: {
                title: {
                  display: true,
                  text: "売上"
                },
                legend: {
                  display: false,
                }, 
                tooltip: {
                  callbacks: {
                    label: function (context: TooltipItem<'bar'>) {
                      let label = context.formattedValue;
                      if (label) {
                        label = label + '円';
                      }
                      return label;
                    }
                  }
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  }
                }, 
                y: {
                  beginAtZero: true,
                  title: {
                    display: false,
                  },
                  grid: {
                    display: true,
                    drawTicks:false,
                  },
                  border: {
                    color:"transparent",
                  },
                },
              },
              layout: {
                padding: {
                  top:3,
                  left:5,
                  right:15,
                  bottom:3
                }
              },
              responsive: true,
              maintainAspectRatio: false
            }
          });
        }
      }
      
      // 年別調達金額
      if (detailData && 'graphSettingsChotatsuamount' in detailData && 'y_value' in detailData.graphSettingsChotatsuamount && detailData.graphSettingsChotatsuamount.y_value.length!=0 && canvasYearAmountRef.current) {
        const context = canvasYearAmountRef.current.getContext('2d');
        if (context) {
          new Chart(context, {
            type: 'bar', // グラフの種類
            data: {
              labels: detailData.graphSettingsChotatsuamount.x_value.map((amount:any) => amount?amount+'年':''),
              datasets: [
                {
                  label: '',
                  backgroundColor: ['#4474c4'],
                  data: detailData.graphSettingsChotatsuamount.y_value,
                  borderWidth: 1,
                },
              ],
            },
            options: {
              plugins: {
                title: {
                  display: false,
                  text: detailData.graphSettingsChotatsuamount.graphTitle
                },
                legend: {
                  display: false,
                }, 
                tooltip: {
                  callbacks: {
                    label: function (context: TooltipItem<'bar'>) {
                      let label = context.formattedValue;
                      if (label) {
                        label = label + '円';
                      }
                      return label;
                    }
                  }
                },
              },
              scales: {
                x: {
                  grid: {
                    display: false
                  }
                }, 
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: '金額(円)'
                  },
                  grid: {
                    display: false
                  }
                }
              },
              responsive: true,
              maintainAspectRatio: false
            }
          });
        }
      }
      
      // 省庁別調達数
      if (detailData && 'graphSettingsChotatsunum' in detailData && 'y_value' in detailData.graphSettingsChotatsunum && detailData.graphSettingsChotatsunum.y_value.length!=0 && canvasMinistryProcurementRef.current) {
        const context = canvasMinistryProcurementRef.current.getContext('2d');
        if (context) {
          new Chart(context, {
            type: 'bar', // グラフの種類
            data: {
              labels: detailData.graphSettingsChotatsunum.y_value,
              datasets: [
                {
                  label: '',
                  backgroundColor: ['#acd48c'],
                  data: detailData.graphSettingsChotatsunum.x_value,
                  borderWidth: 1,
                },
              ],
            },
            options: {
              indexAxis: 'y',
              plugins: {
                title: {
                  display: false,
                  text: detailData.graphSettingsChotatsunum.graphTitle
                },
                legend: {
                  display: false,
                }, 
                tooltip: {
                  callbacks: {
                    label: function (context: TooltipItem<'bar'>) {
                      let label = context.formattedValue;
                      if (label) {
                        label = label + '件';
                      }
                      return label;
                    }
                  }
                },
              },
              scales: {
                x: {
                  ticks: {
                    callback: function (label) {
                      if (typeof label === 'number' && Math.floor(label) === label) {
                            return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }
                        return label;
                    }
                  },
                  title: {
                    display: true,
                    text: '調達数(件)'
                  },
                  grid: {
                    display: false
                  }
                }, 
                y: {
                  beginAtZero: true,
                  grid: {
                    display: false
                  },
                  ticks: {
                    autoSkip: false
                  }
                }
              },
              responsive: true,
              maintainAspectRatio: false
            }
          });
        }
      }
    },[detailData]);
    
    // アンカースクロール
    const scrollToAnchor = (event:React.MouseEvent<HTMLAnchorElement, MouseEvent>, anchorId:string) => {
      event.preventDefault();
      const header = document.querySelector('.Header') as HTMLElement | null;
      const headerHeight = header?.offsetHeight || 0;
      const anchorElement = document.getElementById(anchorId);
      if (anchorElement) {
        const anchorPosition = anchorElement.offsetTop;
        window.scrollTo({
          top: anchorPosition - headerHeight,
          behavior: 'smooth'
        });
      }
    };
    
    // もっと見る開閉
    const [isMoreNotificationCertification, setIsMoreNotificationCertification] = React.useState(false);
    const [isMoreSubsidy, setIsMoreSubsidy] = React.useState(false);
    const [isMoreAward, setIsMoreAward] = React.useState(false);
    const [isMoreQualification, setIsMoreQualification] = React.useState(false);
    const [isMoreProcurement, setIsMoreProcurement] = React.useState(false);
    // 府省の届出・認定
    const handleMoreNotificationCertification = () => {
      setIsMoreNotificationCertification(!isMoreNotificationCertification);
    };
    // 補助金
    const handleMoreSubsidy = () => {
      setIsMoreSubsidy(!isMoreSubsidy);
    };
    // 表彰
    const handleMoreAward = () => {
      setIsMoreAward(!isMoreAward);
    };
    // 全省庁統一資格
    const handleMoreQualification = () => {
      setIsMoreQualification(!isMoreQualification);
    };
    // 調達情報
    const handleMoreProcurement = () => {
      setIsMoreProcurement(!isMoreProcurement);
    };
    // 全省庁統一資格 行表示関数
    const handleShowRowsQualification = () => {
      const qualificationGradeArray = detailData.bodyVpromotionsite.qualificationGrade.split('、');
      if (isMoreQualification) {
        return (<>
          {qualificationGradeArray[0]!=''
            ?
            (<tr>
              <td>
                <p>
                    物品の製造
                </p>
              </td>
              <td>
                <p>
                    {qualificationGradeArray[0]}
                </p>
              </td>
              <td>
                <p>
                    {detailData.bodyVpromotionsite.businessLineList.split('、').filter((businessLine:string) => businessLine.startsWith('1')).map((businessLine:string) => HINMOKU_SEIZO[businessLine]).join('、')}
                </p>
              </td>
            </tr>)
            :(<></>)
          }
          {qualificationGradeArray[1]!=''
            ?
            (<tr>
              <td>
                <p>
                    物品の販売
                </p>
              </td>
              <td>
                <p>
                    {qualificationGradeArray[1]}
                </p>
              </td>
              <td>
                <p>
                    {detailData.bodyVpromotionsite.businessLineList.split('、').filter((businessLine:string) => businessLine.startsWith('2')).map((businessLine:string) => HINMOKU_HANBAI[businessLine]).join('、')}
                </p>
              </td>
            </tr>)
            :(<></>)
          }
          {qualificationGradeArray[2]!=''
            ?
            (<tr>
              <td>
                <p>
                    役務の提供等
                </p>
              </td>
              <td>
                <p>
                    {qualificationGradeArray[2]}
                </p>
              </td>
              <td>
                <p>
                    {detailData.bodyVpromotionsite.businessLineList.split('、').filter((businessLine:string) => businessLine.startsWith('3')).map((businessLine:string) => HINMOKU_TEIKYO[businessLine]).join('、')}
                </p>
              </td>
            </tr>)
            :(<></>)
          }
          {qualificationGradeArray[3]!=''
            ?
            (<tr>
              <td>
                <p>
                    物品の買受け
                </p>
              </td>
              <td>
                <p>
                    {qualificationGradeArray[3]}
                </p>
              </td>
              <td>
                <p>
                    {detailData.bodyVpromotionsite.businessLineList.split('、').filter((businessLine:string) => businessLine.startsWith('4')).map((businessLine:string) => HINMOKU_KAIUKE[businessLine]).join('、')}
                </p>
              </td>
            </tr>)
            :(<></>)
          }
        </>);
      } 
      else {
        let rowNumber = 0;
        let rows:any[] = [];
        for (let i=0; i<4; i+=1) {
          if (rowNumber>1) {
            break;
          }
          if (qualificationGradeArray[i]!='') {
            const categoryName = i==0?'物品の製造':i==1?'物品の販売':i==2?'役務の提供等':i==3?'物品の買受け':'';
            const qualificationGrade = qualificationGradeArray[i];
            const HINMOKU = i==0?HINMOKU_SEIZO:i==1?HINMOKU_HANBAI:i==2?HINMOKU_TEIKYO:i==3?HINMOKU_KAIUKE:{};
            const businessLine = detailData.bodyVpromotionsite.businessLineList.split('、').filter((businessLineNumber:string) => businessLineNumber.startsWith((i+1).toString())).map((businessLineNumber:string) => HINMOKU[businessLineNumber]).join('、');
            rows.push({'categoryName':categoryName, 'qualificationGrade':qualificationGrade, 'businessLine':businessLine});
            rowNumber += 1;
          }
        }
        return rows.map((row:any) => (
          <tr>
            <td data-label="資格の種類">
              {row.categoryName}
            </td>
            <td data-label="資格等級">
              {row.qualificationGrade}
            </td>
            <td data-label="営業品目">
              {row.businessLine?row.businessLine:FULL_WIDTH_HYPHEN}
            </td>
          </tr>
        ));
      }
    };
    
  // サイトのご利用についてを別タブで開く
  const handleOpenSitePolicy = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/sitepolicy.html`, "_blank", "noreferrer");
  };
    
    return (
        <>
          {/* Google Tag Manager (noscript) */}
          <noscript>
            &lt;iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQV7WLM"
            height="0" width="0"
            style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;
          </noscript>
          {/* End Google Tag Manager (noscript) */}
          <Helmet>
            <title>企業詳細情報 | 企業INDEXナビ</title>
          </Helmet>
          <View className="appContainer">
          <Header setIsLoadedHeader={setIsLoadedHeader} reloadFlag={reloadHeaderFlag}/>
          <View className="mainArea">
          <nav className="crumb">
            <ol>
              <li>
                <a href="/" tabIndex={-1} onClick={(event) => handleNavigateHomeScreen(event)}>企業検索</a>
              </li>
              <li>
                {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                  ?
                  detailData.bodyVpromotionsite.corporateName
                  :
                  FULL_WIDTH_HYPHEN
                }
              </li>
            </ol>
          </nav>
          <div className="container lower">
            <main>
              {/*ここから概要エリア*/}
              <div className="content company_about flex">
                <div className="txt">
                  <div className="business_type">
                    <p>
                      <span>
                        {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.businessKeyword1
                          ?
                          detailData.bodyVpromotionsite.businessKeyword1
                          :''
                        }
                        {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.businessKeyword2
                          ?
                          `、${detailData.bodyVpromotionsite.businessKeyword2}`
                          :''
                        }
                      </span>
                    </p>
                  </div>
                  <div className="company_name">
                    {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName_kana
                      ?
                      detailData.bodyVpromotionsite.corporateName_kana
                      :FULL_WIDTH_HYPHEN
                    }
                    <h1>
                      {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                        ?
                        detailData.bodyVpromotionsite.corporateName
                        :FULL_WIDTH_HYPHEN
                      }
                    </h1>
                  </div>
                  <div className="company_overview">
                    {detailData&&detailData.bodyVpromotionsite
                      ?
                      (<>
                        {detailData.bodyVpromotionsite.corporateName&&detailData.bodyVpromotionsite.corporateName_kana&&
                         detailData.bodyVpromotionsite.headquarterAddress&&detailData.bodyVpromotionsite.corporateNumber
                          ?
                          (<p>
                            {`${detailData.bodyVpromotionsite.corporateName}（${detailData.bodyVpromotionsite.corporateName_kana}）は、`}
                            {`${detailData.bodyVpromotionsite.headquarterAddress}に所在する法人番号:${detailData.bodyVpromotionsite.corporateNumber}の会社です。`}
                          </p>)
                          :(<></>)
                        }
                        {detailData.bodyVpromotionsite.industorialClass_large
                          ?
                          (<p>
                            {`主な業種は${detailData.bodyVpromotionsite.industorialClass_large}`}
                            {detailData.bodyVpromotionsite.industorialClass_middle
                              ?
                              `、${detailData.bodyVpromotionsite.industorialClass_middle}`
                              :''
                            }
                            {`です。`}
                          </p>)
                          :(<></>)
                        }
                        {detailData.bodyVpromotionsite.houjin_changeDate_origin
                          ?
                          (<p>
                            {`${format(detailData.bodyVpromotionsite.houjin_changeDate_origin, 'yyyy年MM月dd日')}に法人番号が指定されています。`}
                            {detailData.bodyVpromotionsite.houjin_changeDate_latest
                              ?
                              `${format(detailData.bodyVpromotionsite.houjin_changeDate_latest, 'yyyy年MM月dd日')}に国税庁法人番号データの登録情報が変更されています。`
                              :''
                            }
                          </p>)
                          :(<></>)
                        }
                      </>)
                      :(<></>)
                    }
                  </div>
                  <div className="company_industry">
                    <h2>業種</h2>
                    <ul>
                      {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.industorialClass_large
                        ?
                        (<>
                          <li className="tag">{detailData.bodyVpromotionsite.industorialClass_large}</li>
                          {detailData.bodyVpromotionsite.industorialClass_middle
                            ?
                            (<li className="tag">{detailData.bodyVpromotionsite.industorialClass_middle}</li>)
                            :(<></>)
                          }
                        </>)
                        :(<li>－</li>)
                      }
                    </ul>
                  </div>
                  <p className="small">
                    更新日：
                    <span>
                      {detailData&&detailData.bodyVpromotionsite&&
                      (detailData.bodyVpromotionsite.gbizkihon_updated_at||detailData.bodyVpromotionsite.gbiztodokede_updated_at||
                       detailData.bodyVpromotionsite.gbizhyosho_updated_at||detailData.bodyVpromotionsite.gbizchotatsu_updated_at||
                       detailData.bodyVpromotionsite.gbizhojokin_updated_at||detailData.bodyVpromotionsite.gbiztokkyo_updated_at||
                       detailData.bodyVpromotionsite.gbizzaimu_updated_at||detailData.bodyVpromotionsite.gbizshokuba_updated_at||
                       detailData.bodyVpromotionsite.houjin_updated_at||detailData.bodyVpromotionsite.shokuba_updated_at||
                       detailData.bodyVpromotionsite.edinet_updated_at||detailData.bodyVpromotionsite.survey_updated_at)
                        ?
                        format(['gbizkihon_updated_at', 'gbiztodokede_updated_at',
                                'gbizhyosho_updated_at', 'gbizchotatsu_updated_at',
                                'gbizhojokin_updated_at', 'gbiztokkyo_updated_at',
                                'gbizzaimu_updated_at', 'gbizshokuba_updated_at',
                                'houjin_updated_at', 'shokuba_updated_at',
                                'edinet_updated_at', 'survey_updated_at'].map(key => detailData.bodyVpromotionsite[key]).filter(date => date).sort((date_a, date_b) => new Date(date_b).getTime() - new Date(date_a).getTime())[0], 'yyyy年MM月dd日')
                        :''
                      }
                    </span>
                  </p>
                </div>
              </div>
              {/*ここからアンカーエリア*/}
              <div className="anchor_detail">
                <ul>
                  <li>
                    <a href="#Info" ref={homePositionRef} onClick={(event) => scrollToAnchor(event, 'Info')}>基本情報</a>
                  </li>
                  <li>
                    <a href="#Businnes" onClick={(event) => scrollToAnchor(event, 'Businnes')}>業種・事業情報</a>
                  </li>
                  <li>
                    <a href="#Finance" onClick={(event) => scrollToAnchor(event, 'Finance')}>業績情報</a>
                  </li>
                  <li>
                    <a href="#History" onClick={(event) => scrollToAnchor(event, 'History')}>登記関連情報</a>
                  </li>
                  <li>
                    <a href="#Other" onClick={(event) => scrollToAnchor(event, 'Other')}>その他周辺情報</a>
                  </li>
                  <li>
                    <a href="#Database" onClick={(event) => scrollToAnchor(event, 'Database')}>認証・認可・届出情報</a>
                  </li>
                </ul>
              </div>
              {/*ここから基本情報エリア*/}
              <div className="content info gap_24 status_login" id="Info">
                <h2>
                  <span>
                    {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                      ?
                      detailData.bodyVpromotionsite.corporateName
                      :''
                    }
                  </span>
                  の基本情報
                </h2>
                <div className="flex flex_start">
                  <div className="table flex_inner">
                    <table>
                      <tbody>
                        <tr>
                          <th>企業名</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                              ?
                              detailData.bodyVpromotionsite.corporateName
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>企業名カナ</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName_kana
                              ?
                              detailData.bodyVpromotionsite.corporateName_kana
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>法人番号</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateNumber
                              ?
                              detailData.bodyVpromotionsite.corporateNumber
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>本社郵便番号</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.headquarterPostCode
                              ?
                              detailData.bodyVpromotionsite.headquarterPostCode
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>本社住所</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.headquarterAddress
                              ?
                              (<>
                                {detailData.bodyVpromotionsite.headquarterAddress}
                                <p>
                                  <a href={`http://local.google.co.jp/maps?q=${detailData.bodyVpromotionsite.headquarterAddress}`} target="_blank" rel="noopener noreferrer">
                                    地図で見る
                                    <RiMapPin2Line className="icon-underline" size="1.2rem"/>
                                  </a>
                                </p>
                              </>)
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>創業</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.foundationDate&&detailData.bodyVpromotionsite.foundationDate!=""
                              ?
                              detailData.bodyVpromotionsite.foundationDate.substring(0, 4)!='0000'?detailData.bodyVpromotionsite.foundationDate.substring(0, 4)+'年'
                              :FULL_WIDTH_HYPHEN
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>設立</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.establishmentDate&&detailData.bodyVpromotionsite.establishmentDate!=""
                              ?
                              detailData.bodyVpromotionsite.establishmentDate.substring(8, 10)!='00'?detailData.bodyVpromotionsite.establishmentDate.substring(0, 4)+'年'+detailData.bodyVpromotionsite.establishmentDate.substring(5, 7)+'月'+detailData.bodyVpromotionsite.establishmentDate.substring(8, 10)+'日' 
                              :detailData.bodyVpromotionsite.establishmentDate.substring(5, 7)!='00'?detailData.bodyVpromotionsite.establishmentDate.substring(0, 4)+'年'+detailData.bodyVpromotionsite.establishmentDate.substring(5, 7)+'月'
                              :detailData.bodyVpromotionsite.establishmentDate.substring(0, 4)!='0000'?detailData.bodyVpromotionsite.establishmentDate.substring(0, 4)+'年'
                              :FULL_WIDTH_HYPHEN
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>資本金</th>
                          <td>
                            {convert_data_comma(
                              detailData && detailData.bodyVpromotionsite && 'captalStock' in detailData.bodyVpromotionsite
                                ? detailData.bodyVpromotionsite.captalStock : null, '円')
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>従業員数</th>
                          <td>
                            {convert_data_comma(
                              detailData && detailData.bodyVpromotionsite && 'numberOfEmployees' in detailData.bodyVpromotionsite
                                ? detailData.bodyVpromotionsite.numberOfEmployees : null, '人')
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="table flex_inner">
                    <table>
                      <tbody>
                        <tr>
                          <th>上場・非上場</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.isListed
                              ?
                              detailData.bodyVpromotionsite.isListed=='0' ? '非上場' :
                              detailData.bodyVpromotionsite.isListed=='1' ? '上場' : FULL_WIDTH_HYPHEN
                              :
                              FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>ホームページ</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateUrl
                              ?
                              (<a href={detailData.bodyVpromotionsite.corporateUrl} target="_blank" rel="noopener noreferrer">
                                サイトを見る<RxExternalLink />
                              </a>)
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>問い合せページ</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateContactUrl
                              ?
                              (<a href={detailData.bodyVpromotionsite.corporateContactUrl} target="_blank" rel="noopener noreferrer">
                                サイトを見る<RxExternalLink />
                              </a>)
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>電話番号</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.headquarterPhoneNumber
                              ?
                              detailData.bodyVpromotionsite.headquarterPhoneNumber
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>代表者</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.representativeName
                              ?
                              detailData.bodyVpromotionsite.representativeName
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>売上</th>
                          <td>
                            {convert_data_comma(
                              detailData && detailData.bodyVpromotionsite && 'sales' in detailData.bodyVpromotionsite
                                ? detailData.bodyVpromotionsite.sales : null, '円')
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>株主</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.shareholder1
                              ?
                              (<>
                                <p>{detailData.bodyVpromotionsite.shareholder1}</p>
                                {detailData.bodyVpromotionsite.shareholder2
                                  ?
                                  (<p>{detailData.bodyVpromotionsite.shareholder2}</p>)
                                  :(<></>)
                                }
                                {detailData.bodyVpromotionsite.shareholder3
                                  ?
                                  (<p>{detailData.bodyVpromotionsite.shareholder3}</p>)
                                  :(<></>)
                                }
                                {detailData.bodyVpromotionsite.shareholder4
                                  ?
                                  (<p>{detailData.bodyVpromotionsite.shareholder4}</p>)
                                  :(<></>)
                                }
                                {detailData.bodyVpromotionsite.shareholder5
                                  ?
                                  (<p>{detailData.bodyVpromotionsite.shareholder5}</p>)
                                  :(<></>)
                                }
                              </>)
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>取引先</th>
                          <td>
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.client1
                              ?
                              (<>
                                <p>{detailData.bodyVpromotionsite.client1}</p>
                                {detailData.bodyVpromotionsite.client2
                                  ?
                                  (<p>{detailData.bodyVpromotionsite.client2}</p>)
                                  :(<></>)
                                }
                                {detailData.bodyVpromotionsite.client3
                                  ?
                                  (<p>{detailData.bodyVpromotionsite.client3}</p>)
                                  :(<></>)
                                }
                                {detailData.bodyVpromotionsite.client4
                                  ?
                                  (<p>{detailData.bodyVpromotionsite.client4}</p>)
                                  :(<></>)
                                }
                                {detailData.bodyVpromotionsite.client5
                                  ?
                                  (<p>{detailData.bodyVpromotionsite.client5}</p>)
                                  :(<></>)
                                }
                              </>)
                              :FULL_WIDTH_HYPHEN
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/*ここまで基本情報エリア*/}
              
              {/*ここから業種・事業情報エリア*/}
              <div className="content gap_24 status_login" id="Businnes">
                <h2>
                  <span>
                    {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                      ?
                      detailData.bodyVpromotionsite.corporateName
                      :''
                    }
                  </span>
                  の業種・事業情報
                </h2>
                <div>
                  <h3>業種</h3>
                    {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.industorialClass_large
                      ?
                      (<>
                        <ul>
                          <li className="tag">{detailData.bodyVpromotionsite.industorialClass_large}</li>
                          {detailData.bodyVpromotionsite.industorialClass_middle
                            ?
                            (<li className="tag">{detailData.bodyVpromotionsite.industorialClass_middle}</li>)
                            :(<></>)}
                        </ul>
                        {detailData.bodyVpromotionsite.industorialClass_small1||
                         detailData.bodyVpromotionsite.industorialClass_small2||
                         detailData.bodyVpromotionsite.industorialClass_small3||
                         detailData.bodyVpromotionsite.industorialClass_small4||
                         detailData.bodyVpromotionsite.industorialClass_small5
                          ?
                          (<ul>
                            {detailData.bodyVpromotionsite.industorialClass_small1
                              ?
                              (<li className="tag">{detailData.bodyVpromotionsite.industorialClass_small1}</li>)
                              :(<></>)}
                            {detailData.bodyVpromotionsite.industorialClass_small2
                              ?
                              (<li className="tag">{detailData.bodyVpromotionsite.industorialClass_small2}</li>)
                              :(<></>)}
                            {detailData.bodyVpromotionsite.industorialClass_small3
                              ?
                              (<li className="tag">{detailData.bodyVpromotionsite.industorialClass_small3}</li>)
                              :(<></>)}
                            {detailData.bodyVpromotionsite.industorialClass_small4
                              ?
                              (<li className="tag">{detailData.bodyVpromotionsite.industorialClass_small4}</li>)
                              :(<></>)}
                            {detailData.bodyVpromotionsite.industorialClass_small5
                              ?
                              (<li className="tag">{detailData.bodyVpromotionsite.industorialClass_small5}</li>)
                              :(<></>)}
                          </ul>)
                          :(<></>)}
                      </>)
                      :(<ul>{FULL_WIDTH_HYPHEN}</ul>)
                    }
                </div>
                <div>
                  <h3>事業内容</h3>
                  <ul>
                    {detailData&&detailData.bodyVpromotionsite&&(
                     detailData.bodyVpromotionsite.businessKeyword1||detailData.bodyVpromotionsite.businessKeyword2||
                     detailData.bodyVpromotionsite.businessKeyword3||detailData.bodyVpromotionsite.businessKeyword4||
                     detailData.bodyVpromotionsite.businessKeyword5||detailData.bodyVpromotionsite.businessKeyword6||
                     detailData.bodyVpromotionsite.businessKeyword7||detailData.bodyVpromotionsite.businessKeyword8||
                     detailData.bodyVpromotionsite.businessKeyword9||detailData.bodyVpromotionsite.businessKeyword10||
                     detailData.bodyVpromotionsite.businessKeyword11||detailData.bodyVpromotionsite.businessKeyword12||
                     detailData.bodyVpromotionsite.businessKeyword13||detailData.bodyVpromotionsite.businessKeyword14||
                     detailData.bodyVpromotionsite.businessKeyword15||detailData.bodyVpromotionsite.businessKeyword16||
                     detailData.bodyVpromotionsite.businessKeyword17||detailData.bodyVpromotionsite.businessKeyword18||
                     detailData.bodyVpromotionsite.businessKeyword19||detailData.bodyVpromotionsite.businessKeyword20)
                      ?
                      (<>
                        {detailData.bodyVpromotionsite.businessKeyword1
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword1}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword2
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword2}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword3
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword3}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword4
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword4}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword5
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword5}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword6
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword6}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword7
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword7}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword8
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword8}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword9
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword9}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword10
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword10}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword11
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword11}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword12
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword12}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword13
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword13}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword14
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword14}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword15
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword15}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword16
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword16}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword17
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword17}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword18
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword18}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword19
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword19}</li>)
                          :(<></>)}
                        {detailData.bodyVpromotionsite.businessKeyword20
                          ?
                          (<li className="tag">{detailData.bodyVpromotionsite.businessKeyword20}</li>)
                          :(<></>)}
                      </>)
                      :FULL_WIDTH_HYPHEN
                    }
                  </ul>
                </div>
                <div>
                  <p className="bold">
                    ※「事業内容」は独自の調査で収集・付与したワードで、従来の業種分類では見つけられなかった、「ホームページを作ってくれる会社」といった今さがしたい会社を精度高く探すことができます。
                  </p>
                  <p>
                    ※「事業内容」ワードが付与されていない企業もあります。あらかじめご了承ください。
                  </p>
                </div>
              </div>
              {/*ここまで業種・事業情報エリア*/}
              
              {/*ここからバナー*/}
              <div className="relation status_login ">
                <h2>
                  <span>
                    {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                      ?
                      detailData.bodyVpromotionsite.corporateName
                      :''
                    }
                  </span>
                  の周辺情報を調べる
                </h2>
                <div className="relation_inner ">
                  <div className="relation_profile">
                    <a href="#" className="relation_btn"
                       onClick={(event) => handleBannerLinkOpenGSearchDBS(event,
                                                                    'RXCC',
                                                                    detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                                                                    ?detailData.bodyVpromotionsite.corporateName:'')}
                    >
                      <div className="relation_txt">
                        <IcoCircleKigyou height={"5.5rem"} width={"5.5rem"} />
                        <div className="relation_detail">
                          <h3>
                            <span>
                              {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                                ?
                                detailData.bodyVpromotionsite.corporateName
                                :''
                              }
                            </span>
                            の企業プロフィール情報
                          </h3>
                          <p>
                            帝国データバンク、東京商工リサーチ、リスクモンスターなどの企業プロフィール情報を見る
                          </p>
                        </div>
                      </div>
                      <p className="relation_link view_all">
                        全ての情報を見る
                        <FaChevronRight size="1.8rem" />
                      </p>
                    </a>
                  </div>
                  <div className="relation_news">
                    <a href="#" className="relation_btn"
                       onClick={(event) => handleBannerLinkOpenGSearchDBS(event,
                                                                    'RXCN',
                                                                    detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                                                                    ?detailData.bodyVpromotionsite.corporateName:'')}
                    >
                      <div className="relation_txt">
                        <IcoCirlceSinbun height={"5.5rem"} width={"5.5rem"} />
                        <div className="relation_detail">
                          <h3>
                            <span>
                              {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                                ?
                                detailData.bodyVpromotionsite.corporateName
                                :''
                              }
                            </span>
                            に関する新聞記事
                          </h3>
                          <p>
                            {'全国紙、地方紙、雑誌記事のバックナンバーから'}
                            {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                              ?
                              detailData.bodyVpromotionsite.corporateName
                              :''
                            }
                            {'の情報を見る'}
                          </p>
                        </div>
                      </div>
                      <p className="relation_link view_all">
                        全ての情報を見る
                        <FaChevronRight size="1.8rem" />
                      </p>
                    </a>
                  </div>
                </div>
                <p>※こちらは、G-Searchデータベースサービスに接続します。</p>
              </div>
              {/*ここまでバナー*/}
              
              {/*ここから業績情報エリア*/}
              <div className="content gap_24 finance status_login  " id="Finance">
                <h2>
                  <span>
                    {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                      ?
                      detailData.bodyVpromotionsite.corporateName
                      :''
                    }
                  </span>
                  の業績情報
                </h2>
                <div className="data_tltle">
                  <h3>売上（最大直近5期分）</h3>
                </div>
                {detailData&&detailData.bodyVpromotionsite
                  ?(
                    <div className="database">
                      <table>
                        <thead>
                          <tr>
                            <th>決算年</th>
                            <th>
                              {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.sales_prior4
                                ?
                                detailData.bodyVpromotionsite.fiscalYear_prior4
                                  ?`${detailData.bodyVpromotionsite.fiscalYear_prior4}年`
                                  :FULL_WIDTH_HYPHEN
                                :FULL_WIDTH_HYPHEN
                              }
                            </th>
                            <th>
                              {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.sales_prior3
                                ?
                                detailData.bodyVpromotionsite.fiscalYear_prior3
                                  ?`${detailData.bodyVpromotionsite.fiscalYear_prior3}年`
                                  :FULL_WIDTH_HYPHEN
                                :FULL_WIDTH_HYPHEN
                              }
                            </th>
                            <th>
                              {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.sales_prior2
                                ?
                                detailData.bodyVpromotionsite.fiscalYear_prior2
                                  ?`${detailData.bodyVpromotionsite.fiscalYear_prior2}年`
                                  :FULL_WIDTH_HYPHEN
                                :FULL_WIDTH_HYPHEN
                              }
                            </th>
                            <th>
                              {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.sales_prior1
                                ?
                                detailData.bodyVpromotionsite.fiscalYear_prior1
                                  ?`${detailData.bodyVpromotionsite.fiscalYear_prior1}年`
                                  :FULL_WIDTH_HYPHEN
                                :FULL_WIDTH_HYPHEN
                              }
                            </th>
                            <th>
                              {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.sales
                                ?
                                detailData.bodyVpromotionsite.fiscalYear
                                  ?`${detailData.bodyVpromotionsite.fiscalYear}年`
                                  :FULL_WIDTH_HYPHEN
                                :FULL_WIDTH_HYPHEN
                              }
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td data-label="年度">
                              <p>売上</p>
                            </td>
                            <td className='right' data-label={detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.fiscalYear_prior4?detailData.bodyVpromotionsite.fiscalYear_prior4:FULL_WIDTH_HYPHEN}>
                              <p>
                                {convert_data_comma(detailData && detailData.bodyVpromotionsite && 'sales_prior4' in detailData.bodyVpromotionsite
                                  ? detailData.bodyVpromotionsite.sales_prior4 : null, '円')
                                }
                              </p>
                            </td>
                            <td className='right' data-label={detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.fiscalYear_prior3?detailData.bodyVpromotionsite.fiscalYear_prior3:FULL_WIDTH_HYPHEN}>
                              <p>
                                {convert_data_comma(detailData && detailData.bodyVpromotionsite && 'sales_prior3' in detailData.bodyVpromotionsite
                                  ? detailData.bodyVpromotionsite.sales_prior3 : null, '円')
                                }
                              </p>
                            </td>
                            <td className='right' data-label={detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.fiscalYear_prior2?detailData.bodyVpromotionsite.fiscalYear_prior2:FULL_WIDTH_HYPHEN}>
                              <p>
                                {convert_data_comma(detailData && detailData.bodyVpromotionsite && 'sales_prior2' in detailData.bodyVpromotionsite
                                  ? detailData.bodyVpromotionsite.sales_prior2 : null, '円')
                                }
                              </p>
                            </td>
                            <td className='right' data-label={detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.fiscalYear_prior1?detailData.bodyVpromotionsite.fiscalYear_prior1:FULL_WIDTH_HYPHEN}>
                              <p>
                                {convert_data_comma(detailData && detailData.bodyVpromotionsite && 'sales_prior1' in detailData.bodyVpromotionsite
                                  ? detailData.bodyVpromotionsite.sales_prior1 : null, '円')
                                }
                              </p>
                            </td>
                            <td className='right' data-label={detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.fiscalYear?detailData.bodyVpromotionsite.fiscalYear:FULL_WIDTH_HYPHEN}>
                              <p>
                                {convert_data_comma(detailData && detailData.bodyVpromotionsite && 'sales' in detailData.bodyVpromotionsite
                                  ? detailData.bodyVpromotionsite.sales : null, '円')
                                }
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ):(<></>)
                }
                {detailData&&detailData.graphSettingsVpromotionsite&&detailData.graphSettingsVpromotionsite.graphDisplayFlag
                  ?(
                    <div className="chart-container">
                      <div className="graph_area ministry_procurement">
                        <canvas id="canvasSales" ref={canvasSalesRef} width={410} height={200}/>
                      </div>
                    </div>
                  ):(<></>)
                }
              </div>
              {/*ここまで業績情報エリア*/}
              
              {/*ここから登記関連情報エリア*/}
              <div className="content gap_24 history" id="History">
                <h2>
                  <span>
                    {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                      ?
                      detailData.bodyVpromotionsite.corporateName
                      :''
                    }
                  </span>
                  の登記関連情報
                </h2>
                <div>
                  <p>
                    設立年月日：
                    <span>
                      {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.establishmentDate&&detailData.bodyVpromotionsite.establishmentDate!=""
                        ?
                        detailData.bodyVpromotionsite.establishmentDate.substring(8, 10)!='00'?detailData.bodyVpromotionsite.establishmentDate.substring(0, 4)+'年'+detailData.bodyVpromotionsite.establishmentDate.substring(5, 7)+'月'+detailData.bodyVpromotionsite.establishmentDate.substring(8, 10)+'日' 
                        :detailData.bodyVpromotionsite.establishmentDate.substring(5, 7)!='00'?detailData.bodyVpromotionsite.establishmentDate.substring(0, 4)+'年'+detailData.bodyVpromotionsite.establishmentDate.substring(5, 7)+'月'
                        :detailData.bodyVpromotionsite.establishmentDate.substring(0, 4)!='0000'?detailData.bodyVpromotionsite.establishmentDate.substring(0, 4)+'年'                        
                        :FULL_WIDTH_HYPHEN
                        :FULL_WIDTH_HYPHEN
                      }
                    </span>
                  </p>
                </div>
                <div>
                  <h3>変更履歴</h3>
                  <p>
                    ※国税庁法人番号データベースにおける変更履歴（登記履歴ではありません）
                  </p>
                </div>
                <div>
                  <ul className="history_list">
                    {detailData&&detailData.bodyHoujinbangou&&detailData.bodyHoujinbangou.length!=0
                      ?(<>
                        {detailData.bodyHoujinbangou.map((record:any) => (
                          <li>
                            <h4>
                              <span className="history_date">
                                {record.houjin_changeDate
                                  ?
                                  (
                                    <>
                                      {format(record.houjin_changeDate, 'yyyy年MM月dd日')}
                                    </>
                                  )
                                  :(<></>)
                                }
                              </span>
                              <span className="history_event">
                                {record.changeClass&&record.changeClass!=''
                                  ?CHANGE_CLASS[record.changeClass]
                                  :''
                                }
                              </span>
                            </h4>
                            <div className="table">
                              <table>
                                <tbody>
                                  {record.corporateName
                                    ?(
                                      <tr>
                                        <th>商号</th>
                                        <td>{record.corporateName}</td>
                                      </tr>
                                    )
                                    :(<></>)
                                  }
                                  {record.registeredAddress
                                    ?(
                                      <tr>
                                        <th>本店所在地</th>
                                        <td>{record.registeredAddress}</td>
                                      </tr>
                                    )
                                    :(<></>)
                                  }
                                  {record.changeCause
                                    ?(
                                      <tr>
                                        <th>変更事由詳細</th>
                                        <td>
                                          {record.changeCause.replace(eraRegExp, '\n$1$2').split('\n').map((line:string) => (
                                            <>
                                              {line!=''
                                                ?(
                                                  <>
                                                    {line}
                                                    <br />
                                                  </>
                                                )
                                                :(<></>)
                                              }
                                            </>
                                          ))}
                                        </td>
                                      </tr>
                                    )
                                    :(<></>)
                                  }
                                </tbody>
                              </table>
                            </div>
                          </li>
                        ))}
                      </>)
                      :(<></>)
                    }
                  </ul>
                </div>
              </div>
              {/*ここまで業績情報エリア*/}
              
              {/*ここからその他周辺情報エリア*/}
              <div className="content gap_24 other" id="Other">
                <h2>
                  <span>
                    {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                      ?
                      detailData.bodyVpromotionsite.corporateName
                      :''
                    }
                  </span>
                  のその他周辺情報
                </h2>
                <div className="table">
                  <table>
                    <tbody>
                      <tr>
                        <th>平均継続勤務年数（男女別）</th>
                        <td>
                          {detailData&&detailData.bodyGbizinfoShokuba&&detailData.bodyGbizinfoShokuba.length!=0&&(
                           detailData.bodyGbizinfoShokuba[0].avgYearsOfService_male!=null||
                           detailData.bodyGbizinfoShokuba[0].avgYearsOfService_female!=null)
                            ?
                            (<>
                              {'男性：'}
                              {convert_data_comma('avgYearsOfService_male' in detailData.bodyGbizinfoShokuba[0]
                                ? detailData.bodyGbizinfoShokuba[0].avgYearsOfService_male : null, '年')
                              }
                              {'、女性：'}
                              {convert_data_comma('avgYearsOfService_female' in detailData.bodyGbizinfoShokuba[0]
                                ? detailData.bodyGbizinfoShokuba[0].avgYearsOfService_female : null, '年')
                              }
                            </>)
                            :FULL_WIDTH_HYPHEN
                          }
                          
                        </td>
                      </tr>
                      <tr>
                        <th>労働者に占める女性労働者の割合</th>
                        <td>
                          {convert_data_comma(detailData && detailData.bodyGbizinfoShokuba &&
                           detailData.bodyGbizinfoShokuba.length!=0 && 'pptWorkersOfFemale' in detailData.bodyGbizinfoShokuba[0]
                            ? detailData.bodyGbizinfoShokuba[0].pptWorkersOfFemale : null, '%')
                          }
                        </td>
                      </tr>
                      <tr>
                        <th>女性役員人数</th>
                        <td>
                          {convert_data_comma(detailData && detailData.bodyGbizinfoShokuba &&
                           detailData.bodyGbizinfoShokuba.length!=0 && 'officerOfFemale' in detailData.bodyGbizinfoShokuba[0]
                            ? detailData.bodyGbizinfoShokuba[0].officerOfFemale : null, '人')
                          }
                        </td>
                      </tr>
                      <tr>
                        <th>役員全体人数（男女計）</th>
                        <td>
                          {convert_data_comma(detailData && detailData.bodyGbizinfoShokuba &&
                           detailData.bodyGbizinfoShokuba.length!=0 && 'officerOfAll' in detailData.bodyGbizinfoShokuba[0]
                            ? detailData.bodyGbizinfoShokuba[0].officerOfAll : null, '人')
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="small">出典元情報：職場情報総合サイト</p>
                </div>
                <div className="near_company">
                  <h3>
                    <span>
                      {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                        ?
                        detailData.bodyVpromotionsite.corporateName
                        :''
                      }
                    </span>
                    と同じエリアの会社{" "}
                  </h3>
                                    <div className="ci-list-13">
                    <ul>
                    {detailData&&detailData.areaInfo&&detailData.areaInfo.length!=0
                      ?detailData.areaInfo.map((record:any) => (
                        <li>
                          <a href={record.corporateNumber? location + "/corporatedetail?corporateNumber=" + record.corporateNumber:''} className="arw">
                            <div className="near_company_inner near_name">
                              <p>{record.corporateName?record.corporateName:''}</p>
                            </div>
                            <div className="near_company_inner near_info">
                              <dl>
                                <dt>所在地</dt>
                                <dd>{record.headquarterAddress?record.headquarterAddress:''}</dd>
                              </dl>
                              <dl>
                                <dt>法人番号</dt>
                                <dd>{record.corporateNumber?record.corporateNumber:''}</dd>
                              </dl>
                            </div>
                          </a>
                        </li>
                      ))
                      :(<p>該当の企業はありませんでした。</p>)
                    }
                    </ul>
                  </div>
                </div>
              </div>
              {/*ここまでその他周辺情報エリア*/}
              
              {/*ここから認証・認可・届出情報エリア*/}
              <div className="content gap_24 company_data" id="Database">
                <h2>
                  <span>
                    {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                      ?
                      detailData.bodyVpromotionsite.corporateName
                      :''
                    }
                  </span>
                  の認証・認可・届出情報
                </h2>
                <div className="database">
                  <div className="data_tltle">
                    <h3>府省の届出・認定</h3>
                    <p className="result">
                      {'（'}
                      {detailData&&detailData.bodyGbizinfoTodokedenintei
                        ?
                        convert_data_comma(detailData.bodyGbizinfoTodokedenintei.length)
                        :0
                      }
                      {'件）'}
                    </p>
                  </div>
                  {detailData&&detailData.bodyGbizinfoTodokedenintei&&detailData.bodyGbizinfoTodokedenintei.length!=0
                    ?
                    (<>
                      <table>
                        <thead>
                          <tr>
                            <th className="notification_date">認定日</th>
                            <th>届出認定等</th>
                            <th>対象</th>
                            <th>部門</th>
                            <th>企業規模</th>
                            <th className="ministry">府省</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detailData.bodyGbizinfoTodokedenintei.map((record:any, index:number) => (
                            index<2 || ( index>=2 && isMoreNotificationCertification )
                              ?(
                                <tr>
                                  <td data-label="認定日">
                                    <p>
                                    {record.corpCertificationDate
                                      ?format(record.corpCertificationDate, 'yyyy年MM月dd日')
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="届出認定等">
                                    <p>
                                    {record.corpActiveName
                                      ?record.corpActiveName
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="対象">
                                    <p>
                                    {record.corpTarget
                                      ?record.corpTarget
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="部門">
                                    <p>
                                    {record.corpDivision
                                      ?record.corpDivision
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="企業規模">
                                    <p>
                                    {record.corpDivideContents
                                      ?record.corpDivideContents
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="府省">
                                    <p>
                                    {record.publicOrgNameNotation
                                      ?record.publicOrgNameNotation
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                </tr>
                              ):(<></>)
                          ))}
                        </tbody>
                      </table>
                      {detailData.bodyGbizinfoTodokedenintei.length > 2 && (
                        <button className="table_btn" onClick={handleMoreNotificationCertification}>
                          <span className={isMoreNotificationCertification ? "more" : ""}></span>
                          もっと見る
                        </button>
                      )}
                    </>)
                    :(<></>)
                  }
                </div>
                <div className="database">
                  <div className="data_tltle">
                    <h3>補助金</h3>
                    <p className="result">
                      {'（'}
                      {detailData&&detailData.bodyGbizinfoHojokin
                        ?
                        convert_data_comma(detailData.bodyGbizinfoHojokin.length)
                        :0
                      }
                      {'件）'}
                    </p>
                  </div>
                  {detailData&&detailData.bodyGbizinfoHojokin&&detailData.bodyGbizinfoHojokin.length!=0
                    ?
                    (<>
                      <table>
                        <thead>
                          <tr>
                            <th className="notification_date">認定日</th>
                            <th>補助金等</th>
                            <th>金額</th>
                            <th>対象</th>
                            <th>府省</th>
                            <th>備考</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detailData.bodyGbizinfoHojokin.map((record:any, index:number) => (
                            index<2 || ( index>=2 && isMoreSubsidy )
                              ?(
                                <tr>
                                  <td data-label="認定日">
                                    <p>
                                    {record.corpCertificationDate
                                      ?format(record.corpCertificationDate, 'yyyy年MM月dd日')
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="補助金等">
                                    <p>
                                    {record.corpActiveName
                                      ?record.corpActiveName
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td className='right' data-label="金額">
                                    <p>
                                    {convert_data_comma(record.corpAmount
                                      ? record.corpAmount : null, '円')
                                    }
                                    </p>
                                  </td>
                                  <td data-label="対象">
                                    <p>
                                    {record.corpTarget
                                      ?record.corpTarget
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="府省">
                                    <p>
                                    {record.publicOrgNameNotation
                                      ?record.publicOrgNameNotation
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="備考">
                                    <p>
                                    {record.remarksType
                                      ?record.remarksType
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                </tr>
                              ):(<></>)
                          ))}                          
                        </tbody>
                      </table>
                      {detailData.bodyGbizinfoHojokin.length > 2 && (
                        <button className="table_btn" onClick={handleMoreSubsidy}>
                          <span className={isMoreSubsidy ? "more" : ""}></span>
                          もっと見る
                        </button>
                      )}
                    </>)
                    :(<></>)
                  }
                </div>
                <div className="database award">
                  <div className="data_tltle">
                    <h3>表彰</h3>
                    <p className="result">
                      {'（'}
                      {detailData&&detailData.bodyGbizinfoHyosho
                        ?
                        convert_data_comma(detailData.bodyGbizinfoHyosho.length)
                        :0
                      }
                      {'件）'}
                    </p>
                  </div>
                  {detailData&&detailData.bodyGbizinfoHyosho&&detailData.bodyGbizinfoHyosho.length!=0
                    ?
                    (<>
                      <table>
                        <thead>
                          <tr>
                            <th className="notification_date">年月日</th>
                            <th>表彰名</th>
                            <th>受賞対象</th>
                            <th>部門</th>
                            <th className="ministry">府省</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detailData.bodyGbizinfoHyosho.map((record:any, index:number) => (
                            index<2 || ( index>=2 && isMoreAward )
                              ?(
                                <tr>
                                  <td data-label="年月日">
                                    <p>
                                    {record.corpCertificationDate
                                      ?format(record.corpCertificationDate, 'yyyy年MM月dd日')
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="表彰名">
                                    <p>
                                    {record.corpActiveName
                                      ?record.corpActiveName
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="受賞対象">
                                    <p>
                                    {record.corpTarget
                                      ?record.corpTarget
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="部門">
                                    <p>
                                    {record.corpDivision
                                      ?record.corpDivision
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="府省">
                                    <p>
                                    {record.publicOrgNameNotation
                                      ?record.publicOrgNameNotation
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                </tr>
                              ):(<></>)
                          ))}
                        </tbody>
                      </table>
                      {detailData.bodyGbizinfoHyosho.length > 2 && (
                        <button className="table_btn" onClick={handleMoreAward}>
                          <span className={isMoreAward ? "more" : ""}></span>
                          もっと見る
                        </button>
                      )}
                    </>)
                    :(<></>)
                  }
                </div>
                <div className="database patent">
                  <div className="data_tltle">
                    <h3>特許</h3>
                    <p className="result">
                      {'（'}
                      {detailData&&detailData.bodyGbizinfoTokkyo&&detailData.bodyHoujinbangou.length!=0
                        ?
                        detailData.bodyGbizinfoTokkyo.reduce((total:number, patentData:{corpActiveName:string, count:number}) => convert_data_comma(total + patentData.count), 0)
                        :0
                      }
                      {'件）'}
                    </p>
                  </div>
                  {detailData&&detailData.bodyGbizinfoTokkyo&&detailData.bodyGbizinfoTokkyo.length!=0
                    ?(
                      <table>
                        <thead>
                          <tr>
                            <th>特許</th>
                            <th>意匠</th>
                            <th>商標</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span>
                                {detailData.bodyGbizinfoTokkyo
                                  .filter((item:{corpActiveName:string, count:number}) => item.corpActiveName == '特許')
                                  .map((item:{corpActiveName:string, count:number}) => item.count)
                                  .reduce((accumulator:number, currentValue:number) => convert_data_comma(accumulator + currentValue), 0)}
                              </span>
                              件
                            </td>
                            <td>
                              <span>
                                {detailData.bodyGbizinfoTokkyo
                                  .filter((item:{corpActiveName:string, count:number}) => item.corpActiveName == '意匠')
                                  .map((item:{corpActiveName:string, count:number}) => item.count)
                                  .reduce((accumulator:number, currentValue:number) => convert_data_comma(accumulator + currentValue), 0)}
                              </span>
                              件
                            </td>
                            <td>
                              <span>
                                {detailData.bodyGbizinfoTokkyo
                                  .filter((item:{corpActiveName:string, count:number}) => item.corpActiveName == '商標')
                                  .map((item:{corpActiveName:string, count:number}) => item.count)
                                  .reduce((accumulator:number, currentValue:number) => convert_data_comma(accumulator + currentValue), 0)}
                              </span>
                              件
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )
                    :(<></>)
                  }
                  <p>
                    <a
                      href="https://www.j-platpat.inpit.go.jp/"
                      target="_blank"
                      className="underline"
                      rel="noopener noreferrer"
                    >
                      ※特許情報の詳細は「J-PlatPat」で検索できます
                    </a>
                  </p>
                </div>
                <div className="database qualifications">
                  <div className="data_tltle">
                    <h3>全省庁統一資格</h3>
                    <p className="result">
                      {'（'}
                      {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.qualificationGrade&&
                       detailData.bodyVpromotionsite.qualificationGrade!='、、、'
                        ?convert_data_comma(detailData.bodyVpromotionsite.qualificationGrade.split('、').filter((qualificationGrade:string) => qualificationGrade !== '').length)
                        :0
                      }
                      {'件）'}
                    </p>
                  </div>
                  {detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.qualificationGrade&&
                    detailData.bodyVpromotionsite.qualificationGrade!='、、、'
                    ?
                    (<>
                      <table>
                        <thead>
                          <tr>
                            <th className="type">資格の種類</th>
                            <th className="level">資格等級</th>
                            <th className="item">営業品目</th>
                          </tr>
                        </thead>
                        <tbody>
                          {handleShowRowsQualification()}
                        </tbody>
                      </table>
                      {detailData.bodyVpromotionsite.length > 2 && (
                        <button className="table_btn" onClick={handleMoreQualification}>
                          <span className={isMoreQualification ? "more" : ""}></span>
                          もっと見る
                        </button>
                      )}
                    </>)
                    :(<></>)
                  }
                </div>
                <div className="database ">
                  <div className="data_tltle">
                    <h3>調達情報</h3>
                    <p className="result">
                      {'（'}
                      {detailData&&detailData.bodyGbizinfoChotatsu
                        ?
                        convert_data_comma(detailData.bodyGbizinfoChotatsu.length)
                        :0
                      }
                      {'件）'}
                    </p>
                  </div>
                  {detailData&&detailData.bodyGbizinfoChotatsu&&detailData.bodyGbizinfoChotatsu.length!=0
                    ?
                    (<>
                      <table>
                        <thead>
                          <tr>
                            <th className="notification_date">受注日</th>
                            <th>事業名</th>
                            <th>金額</th>
                            <th className="ministry">府省</th>
                          </tr>
                        </thead>
                        <tbody>
                          {detailData.bodyGbizinfoChotatsu.map((record:any, index:number) => (
                            index<2 || ( index>=2 && isMoreProcurement )
                              ?(
                                <tr>
                                  <td data-label="受注日">
                                    <p>
                                    {record.corpPeriodStartDate
                                      ?format(record.corpPeriodStartDate, 'yyyy年MM月dd日')
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="事業名">
                                    <p>
                                    {record.corpTarget
                                      ?record.corpTarget
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td className='right' data-label="金額">
                                    <p>
                                    {record.corpAmount
                                      ?convert_data_comma(record.corpAmount, '円')
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                  <td data-label="府省">
                                    <p>
                                    {record.publicOrgNameNotation
                                      ?record.publicOrgNameNotation
                                      :FULL_WIDTH_HYPHEN
                                    }
                                    </p>
                                  </td>
                                </tr>
                              ):(<></>)
                          ))}
                        </tbody>
                      </table>
                      {detailData.bodyGbizinfoChotatsu.length > 2 && (
                        <button className="table_btn" onClick={handleMoreProcurement}>
                          <span className={isMoreProcurement ? "more" : ""}></span>
                          もっと見る
                        </button>
                      )}
                    </>)
                    :(<></>)
                  }
                </div>
                {detailData&&detailData.bodyGbizinfoChotatsu&&detailData.bodyGbizinfoChotatsu.length!=0
                    ?
                    (
                      <div className="graph">
                        <div className="flex">
                          <div className="flex_inner">
                            <h3>年別調達金額</h3>
                            <div className="chart-container">
                              <div className="graph_area year_amount">
                                <canvas id="canvasYearAmount" ref={canvasYearAmountRef}/>
                              </div>
                            </div>
                          </div>
                          <div className="flex_inner">
                            <h3>省庁別調達数（上位5）</h3>
                            <div className="chart-container">
                              <div className="graph_area ministry_procurement">
                                <canvas id="canvasMinistryProcurement" ref={canvasMinistryProcurementRef}/>
                              </div>
                            </div>
                          </div>
                        </div>
                        <p className="small">出典元情報：法人番号システム、全省庁統一資格</p>
                      </div>
                    ):(<></>)
                  }
              </div>
              {/*ここまで認証・認可・届出情報エリア*/}
              
              <div className="content ">
                <ul>
                  <li className="small">
                    ＊当サイトの掲載情報は、法人番号公表サイトなどの情報をもとに、独自調査の情報を加えて作成しています。出典元や情報修正依頼などについては、
                    <a onClick={handleOpenSitePolicy} className="underline pointer-cursor" target="_blank" rel="noopener noreferrer">
                      サイトのご利用について
                    </a>
                    をご覧ください。
                  </li>
                </ul>
              </div>
            </main>
            
            {/*ここからクイックメニュー*/}
            <aside className="">
              <div className="content aside_content">
                <div className="aside_inner">
                  <h2>クイックメニュー</h2>
                  <div className="anchor_detail_side">
                    <ul>
                      <li>
                        
                        <a href="#Info" onClick={(event) => scrollToAnchor(event, 'Info')}>
                          <div className="anchor_detail_side-item">
                            <BiSolidDownArrow />
                            <span>基本情報</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#Businnes" onClick={(event) => scrollToAnchor(event, 'Businnes')}>
                          <div className="anchor_detail_side-item">
                            <BiSolidDownArrow />
                            <span>業種・事業情報</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#Finance" onClick={(event) => scrollToAnchor(event, 'Finance')}>
                          <div className="anchor_detail_side-item">
                            <BiSolidDownArrow />
                            <span>業績情報</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#History" onClick={(event) => scrollToAnchor(event, 'History')}>
                          <div className="anchor_detail_side-item">
                            <BiSolidDownArrow />
                            <span>登記関連情報</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#Other" onClick={(event) => scrollToAnchor(event, 'Other')}>
                          <div className="anchor_detail_side-item">
                            <BiSolidDownArrow />
                            <span>その他周辺情報</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#Database" onClick={(event) => scrollToAnchor(event, 'Database')}>
                          <div className="anchor_detail_side-item">
                            <BiSolidDownArrow />
                            <span>認証・認可・届出情報</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="aside_inner">
                  <h2>より詳しい情報を見る</h2>
                  <p>
                    <a href="#" onClick={(event) => handleQuickMenuLinkOpenGSearchDBS(event, 'WTOP', '')}>
                      <div className="sidemenu-icon">
                      <IcoSearch height={"2.3rem"} width={"2.3rem"} />
                      <span>G-Searchトップページ</span>
                      </div>
                    </a>
                  </p>
                  <p>
                    <a href="#" onClick={(event) => handleQuickMenuLinkOpenGSearchDBS(event,
                                                                             'RXCC',
                                                                             detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                                                                             ?detailData.bodyVpromotionsite.corporateName:'')}
                    >
                    <div className="sidemenu-icon">
                      <IcoKigyou height={"2.3rem"} width={"2.3rem"} />
                      <span>企業情報横断検索</span>
                    </div>
                    </a>
                  </p>
                  <p>
                    
                    <a href="#" onClick={(event) => handleQuickMenuLinkOpenGSearchDBS(event,
                                                                             'RXCN',
                                                                             detailData&&detailData.bodyVpromotionsite&&detailData.bodyVpromotionsite.corporateName
                                                                             ?detailData.bodyVpromotionsite.corporateName:'')}
                    >
                    <div className="sidemenu-icon">
                        <IcoSinbun height={"2.3rem"} width={"2.3rem"} />
                      <span>新聞記事横断検索</span>
                    </div>
                    </a>
                  </p>
                </div>
              </div>
            </aside>
            {/*ここまでクイックメニュー*/}
            
          </div>
          </View>
          <Footer />
          </View>
        </>
    );
};