import React, { useEffect } from 'react';
import { Pagination, IconsProvider } from '@aws-amplify/ui-react';
import { ReactComponent as IcoPaginationBack } from '../image/ico_pagination_back.svg';
import { ReactComponent as IcoPaginationNext } from '../image/ico_pagination_next.svg';

interface SearchFilterCheckboxInputProps {
    currentPage: number;
    totalPages: number;
    onNext: () => void;
    onPrevious: () => void;
    onChange: () => void;
};

const HomeScreenPagination:React.FC<SearchFilterCheckboxInputProps> = ({ currentPage, totalPages, onNext, onPrevious, onChange }) => {
  useEffect(() => {
    const paginationButtons = document.querySelectorAll('.searchPaginationHome button');
    paginationButtons.forEach((button, index) => {
      button.setAttribute('tabindex', "0");
    });
  }, [currentPage, totalPages]);

  return (
    <IconsProvider icons={{ pagination: { next: <IcoPaginationNext />, previous: <IcoPaginationBack /> } }}>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onNext={onNext}
        onPrevious={onPrevious}
        onChange={onChange}
        className="searchPaginationHome"
      />
    </IconsProvider>
  );
};

export default HomeScreenPagination;