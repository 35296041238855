import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, View, Flex, Table, TableHead, TableRow, TableBody, TableCell, TextField, Alert, Link, Button, useTheme } from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import { ReactComponent as IcoCsvTable } from '../image/ico_csv_table.svg'
import { ReactComponent as IcoCsvTableHover } from '../image/ico_csv_table_hover.svg'
import { MdNavigateNext } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { ReactComponent as IcoEdit } from '../image/ico_edit.svg'
import { useTokens } from '../components/AuthPage'; // AuthPageからuseTokensをインポート
// テーマ
import { ThemeProvider } from '@aws-amplify/ui-react';
import { appTheme } from '../theme/appTheme';
import '../css/CsvDLConfirm.css';

// 検索条件レコード情報
export type SearchFilter = {
    industorialClass:string;
    address:string;
    corporateName:string;
    detailParameter:string;
    numberOfCorporate:number;
    comments:string;
};

type CsvDLConfirmProps = {
    isOpen: boolean;
    seachFilterRecord: SearchFilter;
    corporateNumberList:string[]
    onDownload: (searchFilter:SearchFilter, corporateNumberList:string[]) => void;
    onClose: () => void;
};

export default function CsvDLConfirm(props:CsvDLConfirmProps) {
    const {tokens} = useTheme();
    const navigate = useNavigate();
    
    const { getTokens } = useTokens(); // useTokensフックを使用
    const [tokenInfo, setTokenInfo] = React.useState<any>(null);
    
    const isOpen = props.isOpen;
    const searchFilterRecord:SearchFilter = {...props.seachFilterRecord};
    const corporateNumberList = props.corporateNumberList;
    const onDownload = props.onDownload;
    const onClose = props.onClose;
    
    // HTML要素 メモ
    const commentsRef = React.useRef<HTMLInputElement>(null);
    
    // APIエラー
    const [isCheckError, setIsCheckError] = React.useState(false);
    
    // 残数エラー
    const [isNotDownloadable, setIsNotDownloadable] = React.useState(false);
    
    // エラーメッセージ メモ
    const [commentsErrorMessage, setCommentsErrorMessage] = React.useState('');
    
    // 処理中フラグ 多重クリック防止
    const [isProcessing, setIsProcessing] = React.useState(false);
    
    // ローディング中か判定
    const [isApiLoading, setIsApiLoading] = React.useState(true);
    
    // 閉じる関数の追加処理
    function onCloseAndSet() {
        setIsApiLoading(true);
        onClose()
    }
    
    // 残数チェックAPIを呼び出す。
    const callCheckLimitoutput = async (token:string | null, parameters:{corporateNumber:string[], numberOfCorporate:number, targetDate:string, outputDate:string}) => {
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/common/checklimitoutput';
        try {
          const response = await fetch(baseUrl.concat(path),{
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + token, //アクセストークン
              'Content-Type': 'application/json;'
            },
            body: JSON.stringify(parameters)
          });
          if (response.ok) {
            const result = await response.json();
            return result;
          }
          else {
              throw Error('残数確認エラー');
          }
        } catch (error) {
          console.log(error);
          throw error;
        }
    };
    
    // 文字数チェック メモ
    const validateComments = (comments:string) => {
        if (comments.length > 100) {
            return '100文字以内で入力してください。';
        }
        else {
            return '';
        }
    };
    
    // 画面読み込み時処理
    React.useEffect(() => {
        const handleLoadCsvDLConfirm = async () => {
            // 読込中・エラーの初期化
            setIsApiLoading(true)
            setCommentsErrorMessage('');
            setIsCheckError(false);
            setIsNotDownloadable(false);
            setIsProcessing(false);
            // 最新のアクセストークンを取得する。
            const {decodedAccessTokenInfo, idToken} = await getTokens(); // トークンを取得
            setTokenInfo(decodedAccessTokenInfo); // アクセストークンをセット
            // ダウンロード残数チェックを行う。
            const now = new Date();
            const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyy/MM');
            const parametersCheckLimitoutput = {
                corporateNumber:corporateNumberList,
                numberOfCorporate:searchFilterRecord.numberOfCorporate,
                targetDate:formattedNow,
                outputDate:formattedNow
            };
            try {
                const resultCheckLimitoutput = await callCheckLimitoutput(idToken, parametersCheckLimitoutput);
                setIsApiLoading(false)
                setIsCheckError(false);
                if (resultCheckLimitoutput && 'isNotDownloadable' in resultCheckLimitoutput) {
                    setIsNotDownloadable(resultCheckLimitoutput.isNotDownloadable);
                }
            }
            catch (error){
                setIsApiLoading(false)
                // 残数確認でエラーがあればエラーメッセージ表示
                setIsCheckError(true);
            }
        };
        
        if(isOpen){
            handleLoadCsvDLConfirm();
        }
        
    }, [isOpen]);
    
    // ダウンロード履歴一覧画面へ遷移
    const handleNavigateDownloadLog = () => {
        navigate("/downloadlog");
    };
      
    // プラン変更画面へ遷移
    const handleNavigatePlanChange = () => {
        navigate("/planchange");
    };
    
    // 追加購入画面へ遷移
    const handleNavigateAddPurchase = () => {
        navigate("/addpurchase");
    };
    
    // 編集ボタン押下時
    const handleTextEditable = (textElement:(HTMLInputElement | null)) => {
      if (textElement)
      {
          textElement.removeAttribute('readonly');
          textElement.removeAttribute('inert');
          textElement.focus();
          textElement.select();
      }
    };
    
    // メモフォーカス時処理
    const handleCommentsFocus = () => {
        if (commentsRef.current) {
            commentsRef.current.select();
        }
    }
    // 入力時処理
    const handleTextInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (commentsRef.current) {
            commentsRef.current.value = event.target.value;
        }
    };
    // メモフォーカスアウト時処理
    const handleCommentsFocusOut = () => {
        const commentsElement = commentsRef.current;
        if (commentsElement) {
            commentsElement.setAttribute('readonly','');
            commentsElement.setAttribute('inert','');
            const errorMessage = validateComments(commentsElement.value)
            setCommentsErrorMessage(errorMessage);
            if (errorMessage != '') {
                commentsElement.focus();
            }
            else {
                searchFilterRecord.comments = commentsElement.value;
            }
        }
    };
    
    // ダウンロードボタン押下時処理
    const handleDLConfirm = async () => {
        if (isProcessing) {
            return;
        }
        setIsProcessing(true);
        
        // メモの文字数チェックを行う。
        const errorMessage = validateComments(searchFilterRecord.comments)
        if (errorMessage != '') {
            setCommentsErrorMessage(errorMessage);
            commentsRef.current?.focus();
            setIsProcessing(false);
            return false;
        }
        
        // ダウンロード残数チェックを行う。
        const {idToken} = await getTokens(); // 最新のトークンを取得
        const now = new Date();
        const formattedNow = format(toZonedTime(now, '+09:00'), 'yyyy/MM');
        const parametersCheckLimitoutput = {
            corporateNumber:corporateNumberList,
            numberOfCorporate:searchFilterRecord.numberOfCorporate,
            targetDate:formattedNow,
            outputDate:formattedNow
        };
        try {
            const resultCheckLimitoutput = await callCheckLimitoutput(idToken, parametersCheckLimitoutput);
            setIsCheckError(false);
            // 残数があればダウンロード
            if (resultCheckLimitoutput && 'isNotDownloadable' in resultCheckLimitoutput) {
                if (resultCheckLimitoutput.isNotDownloadable) {
                    setIsNotDownloadable(resultCheckLimitoutput.isNotDownloadable);
                    setIsProcessing(false);
                    return;
                }
                // ダウンロード関数の実行
                onDownload(searchFilterRecord, corporateNumberList);
                setIsProcessing(false);
                onCloseAndSet();
            }
        }
        catch (error) {
            // エラーがあればエラーメッセージ表示
            setIsCheckError(true);
        }

        setIsProcessing(false);
    };
    
    const [hoveredRow, setHoveredRow] = React.useState<boolean>(false);
    
    //モーダル幅
    const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
    //テーブルの横幅設定
  const [minWidth, setMinWidth] = useState('1150px');
  
  //テーブル幅設定
  useEffect(() => {
    if (windowDimensions.width < 1100) {
      setMinWidth('1350px');
    } else {
      setMinWidth('950px');
    }
  }, [windowDimensions]);

    const getModalWidth = () => {
      if (windowDimensions.width < 1440) {
        return '95%';
      } else {
        return '75%';
      }
    };

    const customStyles = {
    content: {
      width: getModalWidth(),
      height: 'auto',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0',
      border: 'solid',
      borderRadius: "10px",
      borderColor: "rgb(177,177,177)"
    },
    overlay: {
      backgroundColor: 'rgba(177,177,177,0.75)',
      zIndex: 1000,
    }
  };


    return (
        <Modal isOpen={isOpen} onRequestClose={onCloseAndSet}style={customStyles}>
            <ThemeProvider theme={appTheme}>
                <Flex backgroundColor={tokens.colors.blue[10]} padding="20px" borderRadius="5px" direction="column" justifyContent="center" alignItems="center">
                    <View width="100%" textAlign="right">
                        <Button borderStyle="none"  onClick={onCloseAndSet}><IoMdClose size={24} color="black"/></Button>
                    </View>
                    <View width="100%">
                        <Text>チェックした企業のリストを、CSVファイルでダウンロードします。</Text>
                        <Text>ダウンロードしたCSVファイルは、
                            <Link tabIndex={0} fontWeight="bold" color={tokens.colors.blue[60]} onClick={handleNavigateDownloadLog} 
                                onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        e.preventDefault();
                                        handleNavigateDownloadLog();
                                    }
                                }}>
                                会員メニュー「CSVダウンロード履歴」
                            </Link>より、再ダウンロードができます。</Text>
                        <Text>メモ欄に記入したテキストも保存されます。</Text>
                        <Text>再ダウンロードの際は、残りダウンロード件数はカウントされません。</Text>
                    </View>
                    <div className="dlmodal-table-wrapper">
                      <div className="table-containerCsvDL">
                        <Table variation="bordered" className="dlmodal-table"  style={{tableLayout: "fixed", minWidth: minWidth ,borderCollapse: 'separate'}} >
                            <colgroup>
                                <col style={{width:"17%"}} />
                                <col style={{width:"17%"}}/>
                                <col style={{width:"17%"}}/>
                                <col style={{width:"22%"}}/>
                                <col style={{width:"7%"}}/>
                                <col style={{width:"20%"}}/>
                            </colgroup>
                            <TableHead border="solid 1px black" backgroundColor={tokens.colors.neutral[20]}>
                                {/* 一つ目のヘッダ行 */}
                                <TableRow>
                                    <th colSpan={4} className="left" style={{padding:"0.5rem 1rem", border:"solid 1px lightgray"}}>検索条件</th>
                                    <th rowSpan={2} className="left middle" style={{padding:"0.5rem 1rem", border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>企業数</th>
                                    <th rowSpan={2} className="left middle" style={{padding:"0.5rem 1rem", border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>メモ</th>
                                </TableRow>
                                {/* 二つ目のヘッダ行 */}
                                <TableRow>
                                    <th className="left" style={{padding:"0.5rem 1rem", border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>業種</th>
                                    <th className="left" style={{padding:"0.5rem 1rem", border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>地域</th>
                                    <th className="left" style={{padding:"0.5rem 1rem", border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>企業名</th>
                                    <th className="left" style={{padding:"0.5rem 1rem", border:"solid 1px lightgray", borderBottomWidth:"0.25rem", borderBottomColor:"blue"}}>詳細条件</th>
                                </TableRow>
                            </TableHead>
                            <TableBody backgroundColor={tokens.colors.white}>
                                    <TableRow onMouseEnter={() => setHoveredRow(true)} onMouseLeave={() => setHoveredRow(false)} >
                                        <TableCell fontSize="1.5rem" className="left" style={{
                                            overflow: hoveredRow ? "visible" : "hidden",
                                            textOverflow: hoveredRow ? "clip" : "ellipsis",
                                            whiteSpace: hoveredRow ? "normal" : "nowrap"
                                        }}>{searchFilterRecord.industorialClass}
                                        </TableCell>
                                        <TableCell fontSize="1.5rem" className="left" style={{
                                            overflow: hoveredRow ? "visible" : "hidden",
                                            textOverflow: hoveredRow ? "clip" : "ellipsis",
                                            whiteSpace: hoveredRow ? "normal" : "nowrap"
                                        }}>{searchFilterRecord.address}
                                        </TableCell>
                                        <TableCell fontSize="1.5rem" className="left" style={{
                                            overflow: hoveredRow ? "visible" : "hidden",
                                            textOverflow: hoveredRow ? "clip" : "ellipsis",
                                            whiteSpace: hoveredRow ? "normal" : "nowrap"
                                        }}>{searchFilterRecord.corporateName}
                                        </TableCell>
                                        <TableCell fontSize="1.5rem" className="left" style={{
                                            overflow: hoveredRow ? "visible" : "hidden",
                                            textOverflow: hoveredRow ? "clip" : "ellipsis",
                                            whiteSpace: hoveredRow ? "normal" : "nowrap"
                                        }}>{searchFilterRecord.detailParameter}
                                        </TableCell>
                                        <TableCell fontSize="1.5rem" className="right" style={{
                                            overflow: hoveredRow ? "visible" : "hidden",
                                            textOverflow: hoveredRow ? "clip" : "ellipsis",
                                            whiteSpace: hoveredRow ? "normal" : "nowrap"
                                        }}>{searchFilterRecord.numberOfCorporate.toLocaleString()}
                                        </TableCell>
                                        <TableCell fontSize="1.5rem">
                                            <TextField 
                                                style={{borderWidth:"0px", fontSize:"1.5rem", 
                                                        textOverflow: hoveredRow ? "clip" : "ellipsis",
                                                        wordBreak: hoveredRow ? "break-word" : "normal",
                                                        overflow: hoveredRow ? "visible" : "hidden",
                                                }}
                                                label=""
                                                ref={commentsRef}
                                                defaultValue={searchFilterRecord.comments}
                                                onFocus={handleCommentsFocus}
                                                onChange={handleTextInput}
                                                onBlur={handleCommentsFocusOut}
                                                readOnly={true}
                                                inert={true}
                                                outerEndComponent={<Button className="whiteHoverButton" borderRadius="5px" variation="link" onClick={() => {handleTextEditable(commentsRef.current)}}><IcoEdit height={"2rem"} width={"2rem"}/></Button>}
                                            />
                                            {commentsErrorMessage != '' ? (<Alert>{commentsErrorMessage}</Alert>) : <></>}
                                        </TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                      </div>
                    </div>
                    {isCheckError?(
                        <Alert variation="error" color="red" fontSize="1.25rem" fontWeight="bold" hasIcon={true} width="100%" marginTop="2rem" padding="1.5rem">処理中にエラーが発生しました。もう一度お試し下さい。</Alert>
                    ):(<></>)}
                    {isNotDownloadable?(
                        <Alert variation="error" color="red" fontSize="1.25rem" fontWeight="bold" hasIcon={true} width="100%" marginTop="2rem" padding="1.5rem">
                            ダウンロード/表示件数が不足しています。{(tokenInfo.plan == 'free' || tokenInfo.plan == 'freePlus') ?  "出力数追加には、ベーシックプランへのアップグレードが必要です。" : "" }
                        </Alert>
                    ):(<></>)}
                    {tokenInfo && (tokenInfo.plan == 'free' || tokenInfo.plan == 'freePlus') && isNotDownloadable ? (
                        <Button className="whiteHoverButton" onClick={handleNavigatePlanChange} marginTop="2rem" display="inline-flex" borderColor={tokens.colors.neutral[60]} backgroundColor={tokens.colors.white} color={tokens.colors.black} padding="1rem 3rem 0.5rem" borderRadius="0px">
                            <Flex gap="1rem" justifyContent="center">
                                    <Text fontSize="1.5rem" fontWeight="500">プラン変更の案内</Text>
                                    <View><MdNavigateNext fontSize="2rem" /></View>
                            </Flex>
                        </Button>
                    ):(<></>)}
                    {tokenInfo && tokenInfo.plan == 'basic' && isNotDownloadable ? (
                        <Button className="whiteHoverButton" onClick={handleNavigateAddPurchase} marginTop="2rem" display="inline-flex" borderColor={tokens.colors.neutral[60]} backgroundColor={tokens.colors.white} color={tokens.colors.black} padding="1rem 3rem 0.5rem" borderRadius="0px" >
                            <Flex gap="1rem" justifyContent="center">
                                    <Text fontSize="1.5rem" fontWeight="500">出力数追加の案内</Text>
                                    <View><MdNavigateNext fontSize="2rem" /></View>
                            </Flex>
                        </Button>
                    ):(<></>)}
                    <Button className="greenHoverButton" variation="primary" borderRadius="3rem" margin="2rem 0rem" isLoading={isProcessing} isDisabled={isApiLoading || isNotDownloadable || isCheckError || commentsErrorMessage != '' } onClick={handleDLConfirm} display="inline-flex">
                        <Flex padding="0 0.5rem" display="inline-flex" alignItems="center">
                            <View paddingTop="0.25rem" className="dlmodal-icon"><IcoCsvTableHover height={"2rem"} width={"2rem"} /></View>
                            <Text paddingRight="1rem" color="inherit" fontSize={{base:"1.5rem", medium:"2rem"}}>この内容でダウンロード</Text>
                        </Flex>
                    </Button>
                </Flex>
            </ThemeProvider>
        </Modal>
        );
};