import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const LogoutComponent: React.FC = () => {
    const auth = useAuth();

    useEffect(() => {
        const signout = async () => {
            try {
                // TactnaのOIDCサーバーに対してsignoutリクエストを送信
                await auth.signoutRedirect();

                // サインアウトが成功したら、セッションストレージをクリア
                sessionStorage.clear();

            } catch (error) {
                console.error('Logout failed:', error);
            }
        };

        signout();
    }, []);  // 依存配列を空にして一度だけ実行

    return (
        <div>
            <p>Logging out...</p>
        </div>
    );
};

export default LogoutComponent;
