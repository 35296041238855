import React,{ useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Flex, View, Text } from '@aws-amplify/ui-react';
import { useAuth } from "react-oidc-context";
import { useTheme } from '@aws-amplify/ui-react';
import { Helmet } from "react-helmet-async";
import Header from '../commons/HeaderOpenVersion';
import Footer from '../commons/FooterOpenVersion';

const AuthErrorScreen = () => {
  const { tokens } = useTheme();
  const [isLoadedHeader, setIsLoadedHeader] = React.useState(false);
  
  const auth = useAuth();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  
  let searchParams = location.search;
  if (searchParams.startsWith('?')) {
  searchParams = searchParams.slice(1);
}

  const error = params.get("error") || auth.error?.name;
  const errorDescription =
    params.get("error_description") ||
    auth.error?.message ||
    "";

    useEffect(() => {
        if (auth.error) {
            auth.removeUser();
        }
    }, [auth.error]);
    
  const now = new Date();
  const formattedTime = now.toUTCString();
  
  return (
    <>
      <Helmet>
        <title>タイムアウトまたはエラーが発生しました。</title>
      </Helmet>
      <View className="appContainer">
      <Header />
      <Flex direction="column" textAlign="center" className="mainArea">
        <Text fontSize={tokens.fontSizes.xxxxl} backgroundColor={tokens.colors.blue[20]} fontWeight="bold" padding="40px 20px">タイムアウトまたはエラーが発生しました。</Text>
        <View backgroundColor={tokens.colors.white} width={{base:"90%", large:"50%"}} margin="20px auto" textAlign="left" padding="20px">
          <Text>
            タイムアウトまたはエラーが発生しました。<br />
            ログインボタンより、再度ログインをお試しください。<br />
            <br />
            ジー・サーチ・アカウントの管理画面で登録状況や利用明細の確認を行う場合は、<br />以下のURLからログインをお願いいたします。<br />
            ・ジー・サーチ アカウント（ログイン）<br />
            https://myaccount.gh.g-search.or.jp/settings
            <br /><br />
            再ログインをしてもエラーが解消しない場合は、以下日時とメッセージの内容を添えてお問い合わせフォームよりお問い合わせください。
          </Text>
          <Text>
            <br />
            {formattedTime}
          </Text>
          <Text style={{wordBreak:"break-all"}} whiteSpace="pre-wrap">{searchParams}</Text>
        </View>
      </Flex>
      <Footer />
      </View>
    </>  
  );
};

export default AuthErrorScreen;