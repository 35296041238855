export const regionData = {
    "010006": {
        "name": "北海道",
        "childDataDict": {
            "011002": {
                "name": "札幌市",
                "childDataDict": {
                    "011011": {
                        "name": "中央区",
                        "childDataDict": {}
                    },
                    "011029": {
                        "name": "北区",
                        "childDataDict": {}
                    },
                    "011037": {
                        "name": "東区",
                        "childDataDict": {}
                    },
                    "011045": {
                        "name": "白石区",
                        "childDataDict": {}
                    },
                    "011053": {
                        "name": "豊平区",
                        "childDataDict": {}
                    },
                    "011061": {
                        "name": "南区",
                        "childDataDict": {}
                    },
                    "011070": {
                        "name": "西区",
                        "childDataDict": {}
                    },
                    "011088": {
                        "name": "厚別区",
                        "childDataDict": {}
                    },
                    "011096": {
                        "name": "手稲区",
                        "childDataDict": {}
                    },
                    "011100": {
                        "name": "清田区",
                        "childDataDict": {}
                    }
                }
            },
            "012025": {
                "name": "函館市",
                "childDataDict": {}
            },
            "012033": {
                "name": "小樽市",
                "childDataDict": {}
            },
            "012041": {
                "name": "旭川市",
                "childDataDict": {}
            },
            "012050": {
                "name": "室蘭市",
                "childDataDict": {}
            },
            "012068": {
                "name": "釧路市",
                "childDataDict": {}
            },
            "012076": {
                "name": "帯広市",
                "childDataDict": {}
            },
            "012084": {
                "name": "北見市",
                "childDataDict": {}
            },
            "012092": {
                "name": "夕張市",
                "childDataDict": {}
            },
            "012106": {
                "name": "岩見沢市",
                "childDataDict": {}
            },
            "012114": {
                "name": "網走市",
                "childDataDict": {}
            },
            "012122": {
                "name": "留萌市",
                "childDataDict": {}
            },
            "012131": {
                "name": "苫小牧市",
                "childDataDict": {}
            },
            "012149": {
                "name": "稚内市",
                "childDataDict": {}
            },
            "012157": {
                "name": "美唄市",
                "childDataDict": {}
            },
            "012165": {
                "name": "芦別市",
                "childDataDict": {}
            },
            "012173": {
                "name": "江別市",
                "childDataDict": {}
            },
            "012181": {
                "name": "赤平市",
                "childDataDict": {}
            },
            "012190": {
                "name": "紋別市",
                "childDataDict": {}
            },
            "012203": {
                "name": "士別市",
                "childDataDict": {}
            },
            "012211": {
                "name": "名寄市",
                "childDataDict": {}
            },
            "012220": {
                "name": "三笠市",
                "childDataDict": {}
            },
            "012238": {
                "name": "根室市",
                "childDataDict": {}
            },
            "012246": {
                "name": "千歳市",
                "childDataDict": {}
            },
            "012254": {
                "name": "滝川市",
                "childDataDict": {}
            },
            "012262": {
                "name": "砂川市",
                "childDataDict": {}
            },
            "012271": {
                "name": "歌志内市",
                "childDataDict": {}
            },
            "012289": {
                "name": "深川市",
                "childDataDict": {}
            },
            "012297": {
                "name": "富良野市",
                "childDataDict": {}
            },
            "012301": {
                "name": "登別市",
                "childDataDict": {}
            },
            "012319": {
                "name": "恵庭市",
                "childDataDict": {}
            },
            "012335": {
                "name": "伊達市",
                "childDataDict": {}
            },
            "012343": {
                "name": "北広島市",
                "childDataDict": {}
            },
            "012351": {
                "name": "石狩市",
                "childDataDict": {}
            },
            "012360": {
                "name": "北斗市",
                "childDataDict": {}
            },
            "013030": {
                "name": "当別町",
                "childDataDict": {}
            },
            "013048": {
                "name": "新篠津村",
                "childDataDict": {}
            },
            "013315": {
                "name": "松前町",
                "childDataDict": {}
            },
            "013323": {
                "name": "福島町",
                "childDataDict": {}
            },
            "013331": {
                "name": "知内町",
                "childDataDict": {}
            },
            "013340": {
                "name": "木古内町",
                "childDataDict": {}
            },
            "013374": {
                "name": "七飯町",
                "childDataDict": {}
            },
            "013439": {
                "name": "鹿部町",
                "childDataDict": {}
            },
            "013455": {
                "name": "森町",
                "childDataDict": {}
            },
            "013463": {
                "name": "八雲町",
                "childDataDict": {}
            },
            "013471": {
                "name": "長万部町",
                "childDataDict": {}
            },
            "013617": {
                "name": "江差町",
                "childDataDict": {}
            },
            "013625": {
                "name": "上ノ国町",
                "childDataDict": {}
            },
            "013633": {
                "name": "厚沢部町",
                "childDataDict": {}
            },
            "013641": {
                "name": "乙部町",
                "childDataDict": {}
            },
            "013676": {
                "name": "奥尻町",
                "childDataDict": {}
            },
            "013706": {
                "name": "今金町",
                "childDataDict": {}
            },
            "013714": {
                "name": "せたな町",
                "childDataDict": {}
            },
            "013919": {
                "name": "島牧村",
                "childDataDict": {}
            },
            "013927": {
                "name": "寿都町",
                "childDataDict": {}
            },
            "013935": {
                "name": "黒松内町",
                "childDataDict": {}
            },
            "013943": {
                "name": "蘭越町",
                "childDataDict": {}
            },
            "013951": {
                "name": "ニセコ町",
                "childDataDict": {}
            },
            "013960": {
                "name": "真狩村",
                "childDataDict": {}
            },
            "013978": {
                "name": "留寿都村",
                "childDataDict": {}
            },
            "013986": {
                "name": "喜茂別町",
                "childDataDict": {}
            },
            "013994": {
                "name": "京極町",
                "childDataDict": {}
            },
            "014001": {
                "name": "倶知安町",
                "childDataDict": {}
            },
            "014010": {
                "name": "共和町",
                "childDataDict": {}
            },
            "014028": {
                "name": "岩内町",
                "childDataDict": {}
            },
            "014036": {
                "name": "泊村",
                "childDataDict": {}
            },
            "014044": {
                "name": "神恵内村",
                "childDataDict": {}
            },
            "014052": {
                "name": "積丹町",
                "childDataDict": {}
            },
            "014061": {
                "name": "古平町",
                "childDataDict": {}
            },
            "014079": {
                "name": "仁木町",
                "childDataDict": {}
            },
            "014087": {
                "name": "余市町",
                "childDataDict": {}
            },
            "014095": {
                "name": "赤井川村",
                "childDataDict": {}
            },
            "014231": {
                "name": "南幌町",
                "childDataDict": {}
            },
            "014249": {
                "name": "奈井江町",
                "childDataDict": {}
            },
            "014257": {
                "name": "上砂川町",
                "childDataDict": {}
            },
            "014273": {
                "name": "由仁町",
                "childDataDict": {}
            },
            "014281": {
                "name": "長沼町",
                "childDataDict": {}
            },
            "014290": {
                "name": "栗山町",
                "childDataDict": {}
            },
            "014303": {
                "name": "月形町",
                "childDataDict": {}
            },
            "014311": {
                "name": "浦臼町",
                "childDataDict": {}
            },
            "014320": {
                "name": "新十津川町",
                "childDataDict": {}
            },
            "014338": {
                "name": "妹背牛町",
                "childDataDict": {}
            },
            "014346": {
                "name": "秩父別町",
                "childDataDict": {}
            },
            "014362": {
                "name": "雨竜町",
                "childDataDict": {}
            },
            "014371": {
                "name": "北竜町",
                "childDataDict": {}
            },
            "014389": {
                "name": "沼田町",
                "childDataDict": {}
            },
            "014524": {
                "name": "鷹栖町",
                "childDataDict": {}
            },
            "014532": {
                "name": "東神楽町",
                "childDataDict": {}
            },
            "014541": {
                "name": "当麻町",
                "childDataDict": {}
            },
            "014559": {
                "name": "比布町",
                "childDataDict": {}
            },
            "014567": {
                "name": "愛別町",
                "childDataDict": {}
            },
            "014575": {
                "name": "上川町",
                "childDataDict": {}
            },
            "014583": {
                "name": "東川町",
                "childDataDict": {}
            },
            "014591": {
                "name": "美瑛町",
                "childDataDict": {}
            },
            "014605": {
                "name": "上富良野町",
                "childDataDict": {}
            },
            "014613": {
                "name": "中富良野町",
                "childDataDict": {}
            },
            "014621": {
                "name": "南富良野町",
                "childDataDict": {}
            },
            "014630": {
                "name": "占冠村",
                "childDataDict": {}
            },
            "014648": {
                "name": "和寒町",
                "childDataDict": {}
            },
            "014656": {
                "name": "剣淵町",
                "childDataDict": {}
            },
            "014681": {
                "name": "下川町",
                "childDataDict": {}
            },
            "014699": {
                "name": "美深町",
                "childDataDict": {}
            },
            "014702": {
                "name": "音威子府村",
                "childDataDict": {}
            },
            "014711": {
                "name": "中川町",
                "childDataDict": {}
            },
            "014729": {
                "name": "幌加内町",
                "childDataDict": {}
            },
            "014818": {
                "name": "増毛町",
                "childDataDict": {}
            },
            "014826": {
                "name": "小平町",
                "childDataDict": {}
            },
            "014834": {
                "name": "苫前町",
                "childDataDict": {}
            },
            "014842": {
                "name": "羽幌町",
                "childDataDict": {}
            },
            "014851": {
                "name": "初山別村",
                "childDataDict": {}
            },
            "014869": {
                "name": "遠別町",
                "childDataDict": {}
            },
            "014877": {
                "name": "天塩町",
                "childDataDict": {}
            },
            "015113": {
                "name": "猿払村",
                "childDataDict": {}
            },
            "015121": {
                "name": "浜頓別町",
                "childDataDict": {}
            },
            "015130": {
                "name": "中頓別町",
                "childDataDict": {}
            },
            "015148": {
                "name": "枝幸町",
                "childDataDict": {}
            },
            "015164": {
                "name": "豊富町",
                "childDataDict": {}
            },
            "015172": {
                "name": "礼文町",
                "childDataDict": {}
            },
            "015181": {
                "name": "利尻町",
                "childDataDict": {}
            },
            "015199": {
                "name": "利尻富士町",
                "childDataDict": {}
            },
            "015202": {
                "name": "幌延町",
                "childDataDict": {}
            },
            "015431": {
                "name": "美幌町",
                "childDataDict": {}
            },
            "015440": {
                "name": "津別町",
                "childDataDict": {}
            },
            "015458": {
                "name": "斜里町",
                "childDataDict": {}
            },
            "015466": {
                "name": "清里町",
                "childDataDict": {}
            },
            "015474": {
                "name": "小清水町",
                "childDataDict": {}
            },
            "015491": {
                "name": "訓子府町",
                "childDataDict": {}
            },
            "015504": {
                "name": "置戸町",
                "childDataDict": {}
            },
            "015521": {
                "name": "佐呂間町",
                "childDataDict": {}
            },
            "015555": {
                "name": "遠軽町",
                "childDataDict": {}
            },
            "015598": {
                "name": "湧別町",
                "childDataDict": {}
            },
            "015601": {
                "name": "滝上町",
                "childDataDict": {}
            },
            "015610": {
                "name": "興部町",
                "childDataDict": {}
            },
            "015628": {
                "name": "西興部村",
                "childDataDict": {}
            },
            "015636": {
                "name": "雄武町",
                "childDataDict": {}
            },
            "015644": {
                "name": "大空町",
                "childDataDict": {}
            },
            "015717": {
                "name": "豊浦町",
                "childDataDict": {}
            },
            "015750": {
                "name": "壮瞥町",
                "childDataDict": {}
            },
            "015784": {
                "name": "白老町",
                "childDataDict": {}
            },
            "015814": {
                "name": "厚真町",
                "childDataDict": {}
            },
            "015849": {
                "name": "洞爺湖町",
                "childDataDict": {}
            },
            "015857": {
                "name": "安平町",
                "childDataDict": {}
            },
            "015865": {
                "name": "むかわ町",
                "childDataDict": {}
            },
            "016012": {
                "name": "日高町",
                "childDataDict": {}
            },
            "016021": {
                "name": "平取町",
                "childDataDict": {}
            },
            "016047": {
                "name": "新冠町",
                "childDataDict": {}
            },
            "016071": {
                "name": "浦河町",
                "childDataDict": {}
            },
            "016080": {
                "name": "様似町",
                "childDataDict": {}
            },
            "016098": {
                "name": "えりも町",
                "childDataDict": {}
            },
            "016101": {
                "name": "新ひだか町",
                "childDataDict": {}
            },
            "016314": {
                "name": "音更町",
                "childDataDict": {}
            },
            "016322": {
                "name": "士幌町",
                "childDataDict": {}
            },
            "016331": {
                "name": "上士幌町",
                "childDataDict": {}
            },
            "016349": {
                "name": "鹿追町",
                "childDataDict": {}
            },
            "016357": {
                "name": "新得町",
                "childDataDict": {}
            },
            "016365": {
                "name": "清水町",
                "childDataDict": {}
            },
            "016373": {
                "name": "芽室町",
                "childDataDict": {}
            },
            "016381": {
                "name": "中札内村",
                "childDataDict": {}
            },
            "016390": {
                "name": "更別村",
                "childDataDict": {}
            },
            "016411": {
                "name": "大樹町",
                "childDataDict": {}
            },
            "016420": {
                "name": "広尾町",
                "childDataDict": {}
            },
            "016438": {
                "name": "幕別町",
                "childDataDict": {}
            },
            "016446": {
                "name": "池田町",
                "childDataDict": {}
            },
            "016454": {
                "name": "豊頃町",
                "childDataDict": {}
            },
            "016462": {
                "name": "本別町",
                "childDataDict": {}
            },
            "016471": {
                "name": "足寄町",
                "childDataDict": {}
            },
            "016489": {
                "name": "陸別町",
                "childDataDict": {}
            },
            "016497": {
                "name": "浦幌町",
                "childDataDict": {}
            },
            "016616": {
                "name": "釧路町",
                "childDataDict": {}
            },
            "016624": {
                "name": "厚岸町",
                "childDataDict": {}
            },
            "016632": {
                "name": "浜中町",
                "childDataDict": {}
            },
            "016641": {
                "name": "標茶町",
                "childDataDict": {}
            },
            "016659": {
                "name": "弟子屈町",
                "childDataDict": {}
            },
            "016675": {
                "name": "鶴居村",
                "childDataDict": {}
            },
            "016683": {
                "name": "白糠町",
                "childDataDict": {}
            },
            "016918": {
                "name": "別海町",
                "childDataDict": {}
            },
            "016926": {
                "name": "中標津町",
                "childDataDict": {}
            },
            "016934": {
                "name": "標津町",
                "childDataDict": {}
            },
            "016942": {
                "name": "羅臼町",
                "childDataDict": {}
            },
            "016951": {
                "name": "色丹村",
                "childDataDict": {}
            },
            "016969": {
                "name": "泊村",
                "childDataDict": {}
            },
            "016977": {
                "name": "留夜別村",
                "childDataDict": {}
            },
            "016985": {
                "name": "留別村",
                "childDataDict": {}
            },
            "016993": {
                "name": "紗那村",
                "childDataDict": {}
            },
            "017001": {
                "name": "蘂取村",
                "childDataDict": {}
            }
        }
    },
    "020001": {
        "name": "青森県",
        "childDataDict": {
            "022012": {
                "name": "青森市",
                "childDataDict": {}
            },
            "022021": {
                "name": "弘前市",
                "childDataDict": {}
            },
            "022039": {
                "name": "八戸市",
                "childDataDict": {}
            },
            "022047": {
                "name": "黒石市",
                "childDataDict": {}
            },
            "022055": {
                "name": "五所川原市",
                "childDataDict": {}
            },
            "022063": {
                "name": "十和田市",
                "childDataDict": {}
            },
            "022071": {
                "name": "三沢市",
                "childDataDict": {}
            },
            "022080": {
                "name": "むつ市",
                "childDataDict": {}
            },
            "022098": {
                "name": "つがる市",
                "childDataDict": {}
            },
            "022101": {
                "name": "平川市",
                "childDataDict": {}
            },
            "023019": {
                "name": "平内町",
                "childDataDict": {}
            },
            "023035": {
                "name": "今別町",
                "childDataDict": {}
            },
            "023043": {
                "name": "蓬田村",
                "childDataDict": {}
            },
            "023078": {
                "name": "外ヶ浜町",
                "childDataDict": {}
            },
            "023213": {
                "name": "鰺ヶ沢町",
                "childDataDict": {}
            },
            "023230": {
                "name": "深浦町",
                "childDataDict": {}
            },
            "023434": {
                "name": "西目屋村",
                "childDataDict": {}
            },
            "023612": {
                "name": "藤崎町",
                "childDataDict": {}
            },
            "023621": {
                "name": "大鰐町",
                "childDataDict": {}
            },
            "023671": {
                "name": "田舎館村",
                "childDataDict": {}
            },
            "023817": {
                "name": "板柳町",
                "childDataDict": {}
            },
            "023841": {
                "name": "鶴田町",
                "childDataDict": {}
            },
            "023876": {
                "name": "中泊町",
                "childDataDict": {}
            },
            "024015": {
                "name": "野辺地町",
                "childDataDict": {}
            },
            "024023": {
                "name": "七戸町",
                "childDataDict": {}
            },
            "024058": {
                "name": "六戸町",
                "childDataDict": {}
            },
            "024066": {
                "name": "横浜町",
                "childDataDict": {}
            },
            "024082": {
                "name": "東北町",
                "childDataDict": {}
            },
            "024112": {
                "name": "六ヶ所村",
                "childDataDict": {}
            },
            "024121": {
                "name": "おいらせ町",
                "childDataDict": {}
            },
            "024236": {
                "name": "大間町",
                "childDataDict": {}
            },
            "024244": {
                "name": "東通村",
                "childDataDict": {}
            },
            "024252": {
                "name": "風間浦村",
                "childDataDict": {}
            },
            "024261": {
                "name": "佐井村",
                "childDataDict": {}
            },
            "024414": {
                "name": "三戸町",
                "childDataDict": {}
            },
            "024422": {
                "name": "五戸町",
                "childDataDict": {}
            },
            "024431": {
                "name": "田子町",
                "childDataDict": {}
            },
            "024457": {
                "name": "南部町",
                "childDataDict": {}
            },
            "024465": {
                "name": "階上町",
                "childDataDict": {}
            },
            "024503": {
                "name": "新郷村",
                "childDataDict": {}
            }
        }
    },
    "030007": {
        "name": "岩手県",
        "childDataDict": {
            "032018": {
                "name": "盛岡市",
                "childDataDict": {}
            },
            "032026": {
                "name": "宮古市",
                "childDataDict": {}
            },
            "032034": {
                "name": "大船渡市",
                "childDataDict": {}
            },
            "032051": {
                "name": "花巻市",
                "childDataDict": {}
            },
            "032069": {
                "name": "北上市",
                "childDataDict": {}
            },
            "032077": {
                "name": "久慈市",
                "childDataDict": {}
            },
            "032085": {
                "name": "遠野市",
                "childDataDict": {}
            },
            "032093": {
                "name": "一関市",
                "childDataDict": {}
            },
            "032107": {
                "name": "陸前高田市",
                "childDataDict": {}
            },
            "032115": {
                "name": "釜石市",
                "childDataDict": {}
            },
            "032131": {
                "name": "二戸市",
                "childDataDict": {}
            },
            "032140": {
                "name": "八幡平市",
                "childDataDict": {}
            },
            "032158": {
                "name": "奥州市",
                "childDataDict": {}
            },
            "032166": {
                "name": "滝沢市",
                "childDataDict": {}
            },
            "033014": {
                "name": "雫石町",
                "childDataDict": {}
            },
            "033022": {
                "name": "葛巻町",
                "childDataDict": {}
            },
            "033031": {
                "name": "岩手町",
                "childDataDict": {}
            },
            "033219": {
                "name": "紫波町",
                "childDataDict": {}
            },
            "033227": {
                "name": "矢巾町",
                "childDataDict": {}
            },
            "033669": {
                "name": "西和賀町",
                "childDataDict": {}
            },
            "033812": {
                "name": "金ケ崎町",
                "childDataDict": {}
            },
            "034029": {
                "name": "平泉町",
                "childDataDict": {}
            },
            "034410": {
                "name": "住田町",
                "childDataDict": {}
            },
            "034614": {
                "name": "大槌町",
                "childDataDict": {}
            },
            "034827": {
                "name": "山田町",
                "childDataDict": {}
            },
            "034835": {
                "name": "岩泉町",
                "childDataDict": {}
            },
            "034843": {
                "name": "田野畑村",
                "childDataDict": {}
            },
            "034851": {
                "name": "普代村",
                "childDataDict": {}
            },
            "035017": {
                "name": "軽米町",
                "childDataDict": {}
            },
            "035033": {
                "name": "野田村",
                "childDataDict": {}
            },
            "035068": {
                "name": "九戸村",
                "childDataDict": {}
            },
            "035076": {
                "name": "洋野町",
                "childDataDict": {}
            },
            "035246": {
                "name": "一戸町",
                "childDataDict": {}
            }
        }
    },
    "040002": {
        "name": "宮城県",
        "childDataDict": {
            "041009": {
                "name": "仙台市",
                "childDataDict": {
                    "041017": {
                        "name": "青葉区",
                        "childDataDict": {}
                    },"041025": {
                        "name": "宮城野区",
                        "childDataDict": {}
                    },"041033": {
                        "name": "若林区",
                        "childDataDict": {}
                    },"041041": {
                        "name": "太白区",
                        "childDataDict": {}
                    },"041050": {
                        "name": "泉区",
                        "childDataDict": {}
                    }
                }
            },
            "042021": {
                "name": "石巻市",
                "childDataDict": {}
            },
            "042030": {
                "name": "塩竈市",
                "childDataDict": {}
            },
            "042056": {
                "name": "気仙沼市",
                "childDataDict": {}
            },
            "042064": {
                "name": "白石市",
                "childDataDict": {}
            },
            "042072": {
                "name": "名取市",
                "childDataDict": {}
            },
            "042081": {
                "name": "角田市",
                "childDataDict": {}
            },
            "042099": {
                "name": "多賀城市",
                "childDataDict": {}
            },
            "042111": {
                "name": "岩沼市",
                "childDataDict": {}
            },
            "042129": {
                "name": "登米市",
                "childDataDict": {}
            },
            "042137": {
                "name": "栗原市",
                "childDataDict": {}
            },
            "042145": {
                "name": "東松島市",
                "childDataDict": {}
            },
            "042153": {
                "name": "大崎市",
                "childDataDict": {}
            },
            "042161": {
                "name": "富谷市",
                "childDataDict": {}
            },
            "043010": {
                "name": "蔵王町",
                "childDataDict": {}
            },
            "043028": {
                "name": "七ヶ宿町",
                "childDataDict": {}
            },
            "043214": {
                "name": "大河原町",
                "childDataDict": {}
            },
            "043222": {
                "name": "村田町",
                "childDataDict": {}
            },
            "043231": {
                "name": "柴田町",
                "childDataDict": {}
            },
            "043249": {
                "name": "川崎町",
                "childDataDict": {}
            },
            "043419": {
                "name": "丸森町",
                "childDataDict": {}
            },
            "043613": {
                "name": "亘理町",
                "childDataDict": {}
            },
            "043621": {
                "name": "山元町",
                "childDataDict": {}
            },
            "044016": {
                "name": "松島町",
                "childDataDict": {}
            },
            "044041": {
                "name": "七ヶ浜町",
                "childDataDict": {}
            },
            "044067": {
                "name": "利府町",
                "childDataDict": {}
            },
            "044211": {
                "name": "大和町",
                "childDataDict": {}
            },
            "044229": {
                "name": "大郷町",
                "childDataDict": {}
            },
            "044245": {
                "name": "大衡村",
                "childDataDict": {}
            },
            "044440": {
                "name": "色麻町",
                "childDataDict": {}
            },
            "044458": {
                "name": "加美町",
                "childDataDict": {}
            },
            "045012": {
                "name": "涌谷町",
                "childDataDict": {}
            },
            "045055": {
                "name": "美里町",
                "childDataDict": {}
            },
            "045811": {
                "name": "女川町",
                "childDataDict": {}
            },
            "046060": {
                "name": "南三陸町",
                "childDataDict": {}
            }
        }
    },
    "050008": {
        "name": "秋田県",
        "childDataDict": {
            "052019": {
                "name": "秋田市",
                "childDataDict": {}
            },
            "052027": {
                "name": "能代市",
                "childDataDict": {}
            },
            "052035": {
                "name": "横手市",
                "childDataDict": {}
            },
            "052043": {
                "name": "大館市",
                "childDataDict": {}
            },
            "052060": {
                "name": "男鹿市",
                "childDataDict": {}
            },
            "052078": {
                "name": "湯沢市",
                "childDataDict": {}
            },
            "052094": {
                "name": "鹿角市",
                "childDataDict": {}
            },
            "052108": {
                "name": "由利本荘市",
                "childDataDict": {}
            },
            "052116": {
                "name": "潟上市",
                "childDataDict": {}
            },
            "052124": {
                "name": "大仙市",
                "childDataDict": {}
            },
            "052132": {
                "name": "北秋田市",
                "childDataDict": {}
            },
            "052141": {
                "name": "にかほ市",
                "childDataDict": {}
            },
            "052159": {
                "name": "仙北市",
                "childDataDict": {}
            },
            "053031": {
                "name": "小坂町",
                "childDataDict": {}
            },
            "053279": {
                "name": "上小阿仁村",
                "childDataDict": {}
            },
            "053465": {
                "name": "藤里町",
                "childDataDict": {}
            },
            "053481": {
                "name": "三種町",
                "childDataDict": {}
            },
            "053490": {
                "name": "八峰町",
                "childDataDict": {}
            },
            "053619": {
                "name": "五城目町",
                "childDataDict": {}
            },
            "053635": {
                "name": "八郎潟町",
                "childDataDict": {}
            },
            "053660": {
                "name": "井川町",
                "childDataDict": {}
            },
            "053686": {
                "name": "大潟村",
                "childDataDict": {}
            },
            "054348": {
                "name": "美郷町",
                "childDataDict": {}
            },
            "054631": {
                "name": "羽後町",
                "childDataDict": {}
            },
            "054640": {
                "name": "東成瀬村",
                "childDataDict": {}
            }
        }
    },
    "060003":{
        "name": "山形県",
        "childDataDict": {
            "062014": {
                "name": "山形市",
                "childDataDict": {}
            },
            "062022": {
                "name": "米沢市",
                "childDataDict": {}
            },
            "062031": {
                "name": "鶴岡市",
                "childDataDict": {}
            },
            "062049": {
                "name": "酒田市",
                "childDataDict": {}
            },
            "062057": {
                "name": "新庄市",
                "childDataDict": {}
            },
            "062065": {
                "name": "寒河江市",
                "childDataDict": {}
            },
            "062073": {
                "name": "上山市",
                "childDataDict": {}
            },
            "062081": {
                "name": "村山市",
                "childDataDict": {}
            },
            "062090": {
                "name": "長井市",
                "childDataDict": {}
            },
            "062103": {
                "name": "天童市",
                "childDataDict": {}
            },
            "062111": {
                "name": "東根市",
                "childDataDict": {}
            },
            "062120": {
                "name": "尾花沢市",
                "childDataDict": {}
            },
            "062138": {
                "name": "南陽市",
                "childDataDict": {}
            },
            "063011": {
                "name": "山辺町",
                "childDataDict": {}
            },
            "063029": {
                "name": "中山町",
                "childDataDict": {}
            },
            "063215": {
                "name": "河北町",
                "childDataDict": {}
            },
            "063223": {
                "name": "西川町",
                "childDataDict": {}
            },
            "063231": {
                "name": "朝日町",
                "childDataDict": {}
            },
            "063240": {
                "name": "大江町",
                "childDataDict": {}
            },
            "063410": {
                "name": "大石田町",
                "childDataDict": {}
            },
            "063614": {
                "name": "金山町",
                "childDataDict": {}
            },
            "063622": {
                "name": "最上町",
                "childDataDict": {}
            },
            "063631": {
                "name": "舟形町",
                "childDataDict": {}
            },
            "063649": {
                "name": "真室川町",
                "childDataDict": {}
            },
            "063657": {
                "name": "大蔵村",
                "childDataDict": {}
            },
            "063665": {
                "name": "鮭川村",
                "childDataDict": {}
            },
            "063673": {
                "name": "戸沢村",
                "childDataDict": {}
            },
            "063819": {
                "name": "高畠町",
                "childDataDict": {}
            },
            "063827": {
                "name": "川西町",
                "childDataDict": {}
            },
            "064017": {
                "name": "小国町",
                "childDataDict": {}
            },
            "064025": {
                "name": "白鷹町",
                "childDataDict": {}
            },
            "064033": {
                "name": "飯豊町",
                "childDataDict": {}
            },
            "064262": {
                "name": "三川町",
                "childDataDict": {}
            },
            "064289": {
                "name": "庄内町",
                "childDataDict": {}
            },
            "064611": {
                "name": "遊佐町",
                "childDataDict": {}
            }
        }
    },
    "070009": {
        "name": "福島県",
        "childDataDict": {
            "072010": {
                "name": "福島市",
                "childDataDict": {}
            },
            "072028": {
                "name": "会津若松市",
                "childDataDict": {}
            },
            "072036": {
                "name": "郡山市",
                "childDataDict": {}
            },
            "072044": {
                "name": "いわき市",
                "childDataDict": {}
            },
            "072052": {
                "name": "白河市",
                "childDataDict": {}
            },
            "072079": {
                "name": "須賀川市",
                "childDataDict": {}
            },
            "072087": {
                "name": "喜多方市",
                "childDataDict": {}
            },
            "072095": {
                "name": "相馬市",
                "childDataDict": {}
            },
            "072109": {
                "name": "二本松市",
                "childDataDict": {}
            },
            "072117": {
                "name": "田村市",
                "childDataDict": {}
            },
            "072125": {
                "name": "南相馬市",
                "childDataDict": {}
            },
            "072133": {
                "name": "伊達市",
                "childDataDict": {}
            },
            "072141": {
                "name": "本宮市",
                "childDataDict": {}
            },
            "073016": {
                "name": "桑折町",
                "childDataDict": {}
            },
            "073032": {
                "name": "国見町",
                "childDataDict": {}
            },
            "073083": {
                "name": "川俣町",
                "childDataDict": {}
            },
            "073229": {
                "name": "大玉村",
                "childDataDict": {}
            },
            "073423": {
                "name": "鏡石町",
                "childDataDict": {}
            },
            "073440": {
                "name": "天栄村",
                "childDataDict": {}
            },
            "073628": {
                "name": "下郷町",
                "childDataDict": {}
            },
            "073644": {
                "name": "檜枝岐村",
                "childDataDict": {}
            },
            "073679": {
                "name": "只見町",
                "childDataDict": {}
            },
            "073687": {
                "name": "南会津町",
                "childDataDict": {}
            },
            "074021": {
                "name": "北塩原村",
                "childDataDict": {}
            },
            "074055": {
                "name": "西会津町",
                "childDataDict": {}
            },
            "074071": {
                "name": "磐梯町",
                "childDataDict": {}
            },
            "074080": {
                "name": "猪苗代町",
                "childDataDict": {}
            },
            "074217": {
                "name": "会津坂下町",
                "childDataDict": {}
            },
            "074225": {
                "name": "湯川村",
                "childDataDict": {}
            },
            "074233": {
                "name": "柳津町",
                "childDataDict": {}
            },
            "074446": {
                "name": "三島町",
                "childDataDict": {}
            },
            "074454": {
                "name": "金山町",
                "childDataDict": {}
            },
            "074462": {
                "name": "昭和村",
                "childDataDict": {}
            },
            "074471": {
                "name": "会津美里町",
                "childDataDict": {}
            },
            "074616": {
                "name": "西郷村",
                "childDataDict": {}
            },
            "074641": {
                "name": "泉崎村",
                "childDataDict": {}
            },
            "074659": {
                "name": "中島村",
                "childDataDict": {}
            },
            "074667": {
                "name": "矢吹町",
                "childDataDict": {}
            },
            "074811": {
                "name": "棚倉町",
                "childDataDict": {}
            },
            "074829": {
                "name": "矢祭町",
                "childDataDict": {}
            },
            "074837": {
                "name": "塙町",
                "childDataDict": {}
            },
            "074845": {
                "name": "鮫川村",
                "childDataDict": {}
            },
            "075019": {
                "name": "石川町",
                "childDataDict": {}
            },
            "075027": {
                "name": "玉川村",
                "childDataDict": {}
            },
            "075035": {
                "name": "平田村",
                "childDataDict": {}
            },
            "075043": {
                "name": "浅川町",
                "childDataDict": {}
            },
            "075051": {
                "name": "古殿町",
                "childDataDict": {}
            },
            "075213": {
                "name": "三春町",
                "childDataDict": {}
            },
            "075221": {
                "name": "小野町",
                "childDataDict": {}
            },
            "075418": {
                "name": "広野町",
                "childDataDict": {}
            },
            "075426": {
                "name": "楢葉町",
                "childDataDict": {}
            },
            "075434": {
                "name": "富岡町",
                "childDataDict": {}
            },
            "075442": {
                "name": "川内村",
                "childDataDict": {}
            },
            "075451": {
                "name": "大熊町",
                "childDataDict": {}
            },
            "075469": {
                "name": "双葉町",
                "childDataDict": {}
            },
            "075477": {
                "name": "浪江町",
                "childDataDict": {}
            },
            "075485": {
                "name": "葛尾村",
                "childDataDict": {}
            },
            "075612": {
                "name": "新地町",
                "childDataDict": {}
            },
            "075647": {
                "name": "飯舘村",
                "childDataDict": {}
            }
        }
    },
    "080004": {
        "name": "茨城県",
        "childDataDict": {
            "082015": {
                "name": "水戸市",
                "childDataDict": {}
            },
            "082023": {
                "name": "日立市",
                "childDataDict": {}
            },
            "082031": {
                "name": "土浦市",
                "childDataDict": {}
            },
            "082040": {
                "name": "古河市",
                "childDataDict": {}
            },
            "082058": {
                "name": "石岡市",
                "childDataDict": {}
            },
            "082074": {
                "name": "結城市",
                "childDataDict": {}
            },
            "082082": {
                "name": "龍ケ崎市",
                "childDataDict": {}
            },
            "082104": {
                "name": "下妻市",
                "childDataDict": {}
            },
            "082112": {
                "name": "常総市",
                "childDataDict": {}
            },
            "082121": {
                "name": "常陸太田市",
                "childDataDict": {}
            },
            "082147": {
                "name": "高萩市",
                "childDataDict": {}
            },
            "082155": {
                "name": "北茨城市",
                "childDataDict": {}
            },
            "082163": {
                "name": "笠間市",
                "childDataDict": {}
            },
            "082171": {
                "name": "取手市",
                "childDataDict": {}
            },
            "082198": {
                "name": "牛久市",
                "childDataDict": {}
            },
            "082201": {
                "name": "つくば市",
                "childDataDict": {}
            },
            "082210": {
                "name": "ひたちなか市",
                "childDataDict": {}
            },
            "082228": {
                "name": "鹿嶋市",
                "childDataDict": {}
            },
            "082236": {
                "name": "潮来市",
                "childDataDict": {}
            },
            "082244": {
                "name": "守谷市",
                "childDataDict": {}
            },
            "082252": {
                "name": "常陸大宮市",
                "childDataDict": {}
            },
            "082261": {
                "name": "那珂市",
                "childDataDict": {}
            },
            "082279": {
                "name": "筑西市",
                "childDataDict": {}
            },
            "082287": {
                "name": "坂東市",
                "childDataDict": {}
            },
            "082295": {
                "name": "稲敷市",
                "childDataDict": {}
            },
            "082309": {
                "name": "かすみがうら市",
                "childDataDict": {}
            },
            "082317": {
                "name": "桜川市",
                "childDataDict": {}
            },
            "082325": {
                "name": "神栖市",
                "childDataDict": {}
            },
            "082333": {
                "name": "行方市",
                "childDataDict": {}
            },
            "082341": {
                "name": "鉾田市",
                "childDataDict": {}
            },
            "082350": {
                "name": "つくばみらい市",
                "childDataDict": {}
            },
            "082368": {
                "name": "小美玉市",
                "childDataDict": {}
            },
            "083020": {
                "name": "茨城町",
                "childDataDict": {}
            },
            "083097": {
                "name": "大洗町",
                "childDataDict": {}
            },
            "083101": {
                "name": "城里町",
                "childDataDict": {}
            },
            "083411": {
                "name": "東海村",
                "childDataDict": {}
            },
            "083640": {
                "name": "大子町",
                "childDataDict": {}
            },
            "084425": {
                "name": "美浦村",
                "childDataDict": {}
            },
            "084433": {
                "name": "阿見町",
                "childDataDict": {}
            },
            "084476": {
                "name": "河内町",
                "childDataDict": {}
            },
            "085219": {
                "name": "八千代町",
                "childDataDict": {}
            },
            "085421": {
                "name": "五霞町",
                "childDataDict": {}
            },
            "085464": {
                "name": "境町",
                "childDataDict": {}
            },
            "085642": {
                "name": "利根町",
                "childDataDict": {}
            }
        }
    },
    "090000": {
        "name": "栃木県",
        "childDataDict": {
            "092011": {
                "name": "宇都宮市",
                "childDataDict": {}
            },
            "092029": {
                "name": "足利市",
                "childDataDict": {}
            },
            "092037": {
                "name": "栃木市",
                "childDataDict": {}
            },
            "092045": {
                "name": "佐野市",
                "childDataDict": {}
            },
            "092053": {
                "name": "鹿沼市",
                "childDataDict": {}
            },
            "092061": {
                "name": "日光市",
                "childDataDict": {}
            },
            "092088": {
                "name": "小山市",
                "childDataDict": {}
            },
            "092096": {
                "name": "真岡市",
                "childDataDict": {}
            },
            "092100": {
                "name": "大田原市",
                "childDataDict": {}
            },
            "092118": {
                "name": "矢板市",
                "childDataDict": {}
            },
            "092134": {
                "name": "那須塩原市",
                "childDataDict": {}
            },
            "092142": {
                "name": "さくら市",
                "childDataDict": {}
            },
            "092151": {
                "name": "那須烏山市",
                "childDataDict": {}
            },
            "092169": {
                "name": "下野市",
                "childDataDict": {}
            },
            "093017": {
                "name": "上三川町",
                "childDataDict": {}
            },
            "093424": {
                "name": "益子町",
                "childDataDict": {}
            },
            "093432": {
                "name": "茂木町",
                "childDataDict": {}
            },
            "093441": {
                "name": "市貝町",
                "childDataDict": {}
            },
            "093459": {
                "name": "芳賀町",
                "childDataDict": {}
            },
            "093611": {
                "name": "壬生町",
                "childDataDict": {}
            },
            "093645": {
                "name": "野木町",
                "childDataDict": {}
            },
            "093840": {
                "name": "塩谷町",
                "childDataDict": {}
            },
            "093866": {
                "name": "高根沢町",
                "childDataDict": {}
            },
            "094072": {
                "name": "那須町",
                "childDataDict": {}
            },
            "094111": {
                "name": "那珂川町",
                "childDataDict": {}
            }
        }
    },
    "100005": {
        "name": "群馬県",
        "childDataDict": {
            "102016": {
                "name": "前橋市",
                "childDataDict": {}
            },
            "102024": {
                "name": "高崎市",
                "childDataDict": {}
            },
            "102032": {
                "name": "桐生市",
                "childDataDict": {}
            },
            "102041": {
                "name": "伊勢崎市",
                "childDataDict": {}
            },
            "102059": {
                "name": "太田市",
                "childDataDict": {}
            },
            "102067": {
                "name": "沼田市",
                "childDataDict": {}
            },
            "102075": {
                "name": "館林市",
                "childDataDict": {}
            },
            "102083": {
                "name": "渋川市",
                "childDataDict": {}
            },
            "102091": {
                "name": "藤岡市",
                "childDataDict": {}
            },
            "102105": {
                "name": "富岡市",
                "childDataDict": {}
            },
            "102113": {
                "name": "安中市",
                "childDataDict": {}
            },
            "102121": {
                "name": "みどり市",
                "childDataDict": {}
            },
            "103446": {
                "name": "榛東村",
                "childDataDict": {}
            },
            "103454": {
                "name": "吉岡町",
                "childDataDict": {}
            },
            "103667": {
                "name": "上野村",
                "childDataDict": {}
            },
            "103675": {
                "name": "神流町",
                "childDataDict": {}
            },
            "103829": {
                "name": "下仁田町",
                "childDataDict": {}
            },
            "103837": {
                "name": "南牧村",
                "childDataDict": {}
            },
            "103845": {
                "name": "甘楽町",
                "childDataDict": {}
            },
            "104213": {
                "name": "中之条町",
                "childDataDict": {}
            },
            "104248": {
                "name": "長野原町",
                "childDataDict": {}
            },
            "104256": {
                "name": "嬬恋村",
                "childDataDict": {}
            },
            "104264": {
                "name": "草津町",
                "childDataDict": {}
            },
            "104281": {
                "name": "高山村",
                "childDataDict": {}
            },
            "104299": {
                "name": "東吾妻町",
                "childDataDict": {}
            },
            "104434": {
                "name": "片品村",
                "childDataDict": {}
            },
            "104442": {
                "name": "川場村",
                "childDataDict": {}
            },
            "104485": {
                "name": "昭和村",
                "childDataDict": {}
            },
            "104493": {
                "name": "みなかみ町",
                "childDataDict": {}
            },
            "104647": {
                "name": "玉村町",
                "childDataDict": {}
            },
            "105210": {
                "name": "板倉町",
                "childDataDict": {}
            },
            "105228": {
                "name": "明和町",
                "childDataDict": {}
            },
            "105236": {
                "name": "千代田町",
                "childDataDict": {}
            },
            "105244": {
                "name": "大泉町",
                "childDataDict": {}
            },
            "105252": {
                "name": "邑楽町",
                "childDataDict": {}
            }
        }
    },
    "110001":{
        "name": "埼玉県",
        "childDataDict": {
            "111007": {
                "name": "さいたま市",
                "childDataDict": {
                    "111015": {
                        "name": "西区",
                        "childDataDict": {}
                    },"111023": {
                        "name": "北区",
                        "childDataDict": {}
                    },"111031": {
                        "name": "大宮区",
                        "childDataDict": {}
                    },"111040": {
                        "name": "見沼区",
                        "childDataDict": {}
                    },"111058": {
                        "name": "中央区",
                        "childDataDict": {}
                    },"111066": {
                        "name": "桜区",
                        "childDataDict": {}
                    },"111074": {
                        "name": "浦和区",
                        "childDataDict": {}
                    },"111082": {
                        "name": "南区",
                        "childDataDict": {}
                    },"111091": {
                        "name": "緑区",
                        "childDataDict": {}
                    },"111104": {
                        "name": "岩槻区",
                        "childDataDict": {}
                    }
                }
            },
            "112011": {
                "name": "川越市",
                "childDataDict": {}
            },
            "112020": {
                "name": "熊谷市",
                "childDataDict": {}
            },
            "112038": {
                "name": "川口市",
                "childDataDict": {}
            },
            "112062": {
                "name": "行田市",
                "childDataDict": {}
            },
            "112071": {
                "name": "秩父市",
                "childDataDict": {}
            },
            "112089": {
                "name": "所沢市",
                "childDataDict": {}
            },
            "112097": {
                "name": "飯能市",
                "childDataDict": {}
            },
            "112101": {
                "name": "加須市",
                "childDataDict": {}
            },
            "112119": {
                "name": "本庄市",
                "childDataDict": {}
            },
            "112127": {
                "name": "東松山市",
                "childDataDict": {}
            },
            "112143": {
                "name": "春日部市",
                "childDataDict": {}
            },
            "112151": {
                "name": "狭山市",
                "childDataDict": {}
            },
            "112160": {
                "name": "羽生市",
                "childDataDict": {}
            },
            "112178": {
                "name": "鴻巣市",
                "childDataDict": {}
            },
            "112186": {
                "name": "深谷市",
                "childDataDict": {}
            },
            "112194": {
                "name": "上尾市",
                "childDataDict": {}
            },
            "112216": {
                "name": "草加市",
                "childDataDict": {}
            },
            "112224": {
                "name": "越谷市",
                "childDataDict": {}
            },
            "112232": {
                "name": "蕨市",
                "childDataDict": {}
            },
            "112241": {
                "name": "戸田市",
                "childDataDict": {}
            },
            "112259": {
                "name": "入間市",
                "childDataDict": {}
            },
            "112275": {
                "name": "朝霞市",
                "childDataDict": {}
            },
            "112283": {
                "name": "志木市",
                "childDataDict": {}
            },
            "112291": {
                "name": "和光市",
                "childDataDict": {}
            },
            "112305": {
                "name": "新座市",
                "childDataDict": {}
            },
            "112313": {
                "name": "桶川市",
                "childDataDict": {}
            },
            "112321": {
                "name": "久喜市",
                "childDataDict": {}
            },
            "112330": {
                "name": "北本市",
                "childDataDict": {}
            },
            "112348": {
                "name": "八潮市",
                "childDataDict": {}
            },
            "112356": {
                "name": "富士見市",
                "childDataDict": {}
            },
            "112372": {
                "name": "三郷市",
                "childDataDict": {}
            },
            "112381": {
                "name": "蓮田市",
                "childDataDict": {}
            },
            "112399": {
                "name": "坂戸市",
                "childDataDict": {}
            },
            "112402": {
                "name": "幸手市",
                "childDataDict": {}
            },
            "112411": {
                "name": "鶴ヶ島市",
                "childDataDict": {}
            },
            "112429": {
                "name": "日高市",
                "childDataDict": {}
            },
            "112437": {
                "name": "吉川市",
                "childDataDict": {}
            },
            "112453": {
                "name": "ふじみ野市",
                "childDataDict": {}
            },
            "112461": {
                "name": "白岡市",
                "childDataDict": {}
            },
            "113018": {
                "name": "伊奈町",
                "childDataDict": {}
            },
            "113247": {
                "name": "三芳町",
                "childDataDict": {}
            },
            "113263": {
                "name": "毛呂山町",
                "childDataDict": {}
            },
            "113271": {
                "name": "越生町",
                "childDataDict": {}
            },
            "113417": {
                "name": "滑川町",
                "childDataDict": {}
            },
            "113425": {
                "name": "嵐山町",
                "childDataDict": {}
            },
            "113433": {
                "name": "小川町",
                "childDataDict": {}
            },
            "113468": {
                "name": "川島町",
                "childDataDict": {}
            },
            "113476": {
                "name": "吉見町",
                "childDataDict": {}
            },
            "113484": {
                "name": "鳩山町",
                "childDataDict": {}
            },
            "113492": {
                "name": "ときがわ町",
                "childDataDict": {}
            },
            "113611": {
                "name": "横瀬町",
                "childDataDict": {}
            },
            "113620": {
                "name": "皆野町",
                "childDataDict": {}
            },
            "113638": {
                "name": "長瀞町",
                "childDataDict": {}
            },
            "113654": {
                "name": "小鹿野町",
                "childDataDict": {}
            },
            "113697": {
                "name": "東秩父村",
                "childDataDict": {}
            },
            "113816": {
                "name": "美里町",
                "childDataDict": {}
            },
            "113832": {
                "name": "神川町",
                "childDataDict": {}
            },
            "113859": {
                "name": "上里町",
                "childDataDict": {}
            },
            "114081": {
                "name": "寄居町",
                "childDataDict": {}
            },
            "114421": {
                "name": "宮代町",
                "childDataDict": {}
            },
            "114642": {
                "name": "杉戸町",
                "childDataDict": {}
            },
            "114651": {
                "name": "松伏町",
                "childDataDict": {}
            }
        }
    },
    "120006":{
        "name": "千葉県",
        "childDataDict": {
            "121002": {
                "name": "千葉市",
                "childDataDict": {
                    "121011": {
                        "name": "中央区",
                        "childDataDict": {}
                    },"121029": {
                        "name": "花見川区",
                        "childDataDict": {}
                    },"121037": {
                        "name": "稲毛区",
                        "childDataDict": {}
                    },"121045": {
                        "name": "若葉区",
                        "childDataDict": {}
                    },"121053": {
                        "name": "緑区",
                        "childDataDict": {}
                    },"121061": {
                        "name": "美浜区",
                        "childDataDict": {}
                    }
                }
            },
            "122025": {
                "name": "銚子市",
                "childDataDict": {}
            },
            "122033": {
                "name": "市川市",
                "childDataDict": {}
            },
            "122041": {
                "name": "船橋市",
                "childDataDict": {}
            },
            "122050": {
                "name": "館山市",
                "childDataDict": {}
            },
            "122068": {
                "name": "木更津市",
                "childDataDict": {}
            },
            "122076": {
                "name": "松戸市",
                "childDataDict": {}
            },
            "122084": {
                "name": "野田市",
                "childDataDict": {}
            },
            "122106": {
                "name": "茂原市",
                "childDataDict": {}
            },
            "122114": {
                "name": "成田市",
                "childDataDict": {}
            },
            "122122": {
                "name": "佐倉市",
                "childDataDict": {}
            },
            "122131": {
                "name": "東金市",
                "childDataDict": {}
            },
            "122157": {
                "name": "旭市",
                "childDataDict": {}
            },
            "122165": {
                "name": "習志野市",
                "childDataDict": {}
            },
            "122173": {
                "name": "柏市",
                "childDataDict": {}
            },
            "122181": {
                "name": "勝浦市",
                "childDataDict": {}
            },
            "122190": {
                "name": "市原市",
                "childDataDict": {}
            },
            "122203": {
                "name": "流山市",
                "childDataDict": {}
            },
            "122211": {
                "name": "八千代市",
                "childDataDict": {}
            },
            "122220": {
                "name": "我孫子市",
                "childDataDict": {}
            },
            "122238": {
                "name": "鴨川市",
                "childDataDict": {}
            },
            "122246": {
                "name": "鎌ケ谷市",
                "childDataDict": {}
            },
            "122254": {
                "name": "君津市",
                "childDataDict": {}
            },
            "122262": {
                "name": "富津市",
                "childDataDict": {}
            },
            "122271": {
                "name": "浦安市",
                "childDataDict": {}
            },
            "122289": {
                "name": "四街道市",
                "childDataDict": {}
            },
            "122297": {
                "name": "袖ケ浦市",
                "childDataDict": {}
            },
            "122301": {
                "name": "八街市",
                "childDataDict": {}
            },
            "122319": {
                "name": "印西市",
                "childDataDict": {}
            },
            "122327": {
                "name": "白井市",
                "childDataDict": {}
            },
            "122335": {
                "name": "富里市",
                "childDataDict": {}
            },
            "122343": {
                "name": "南房総市",
                "childDataDict": {}
            },
            "122351": {
                "name": "匝瑳市",
                "childDataDict": {}
            },
            "122360": {
                "name": "香取市",
                "childDataDict": {}
            },
            "122378": {
                "name": "山武市",
                "childDataDict": {}
            },
            "122386": {
                "name": "いすみ市",
                "childDataDict": {}
            },
            "122394": {
                "name": "大網白里市",
                "childDataDict": {}
            },
            "123226": {
                "name": "酒々井町",
                "childDataDict": {}
            },
            "123293": {
                "name": "栄町",
                "childDataDict": {}
            },
            "123421": {
                "name": "神崎町",
                "childDataDict": {}
            },
            "123471": {
                "name": "多古町",
                "childDataDict": {}
            },
            "123498": {
                "name": "東庄町",
                "childDataDict": {}
            },
            "124036": {
                "name": "九十九里町",
                "childDataDict": {}
            },
            "124095": {
                "name": "芝山町",
                "childDataDict": {}
            },
            "124109": {
                "name": "横芝光町",
                "childDataDict": {}
            },
            "124214": {
                "name": "一宮町",
                "childDataDict": {}
            },
            "124222": {
                "name": "睦沢町",
                "childDataDict": {}
            },
            "124231": {
                "name": "長生村",
                "childDataDict": {}
            },
            "124249": {
                "name": "白子町",
                "childDataDict": {}
            },
            "124265": {
                "name": "長柄町",
                "childDataDict": {}
            },
            "124273": {
                "name": "長南町",
                "childDataDict": {}
            },
            "124419": {
                "name": "大多喜町",
                "childDataDict": {}
            },
            "124435": {
                "name": "御宿町",
                "childDataDict": {}
            },
            "124630": {
                "name": "鋸南町",
                "childDataDict": {}
            }
        }
    },
    "130001": {
        "name": "東京都",
        "childDataDict": {
            "131016": {
                "name": "千代田区",
                "childDataDict": {}
            },
            "131024": {
                "name": "中央区",
                "childDataDict": {}
            },
            "131032": {
                "name": "港区",
                "childDataDict": {}
            },
            "131041": {
                "name": "新宿区",
                "childDataDict": {}
            },
            "131059": {
                "name": "文京区",
                "childDataDict": {}
            },
            "131067": {
                "name": "台東区",
                "childDataDict": {}
            },
            "131075": {
                "name": "墨田区",
                "childDataDict": {}
            },
            "131083": {
                "name": "江東区",
                "childDataDict": {}
            },
            "131091": {
                "name": "品川区",
                "childDataDict": {}
            },
            "131105": {
                "name": "目黒区",
                "childDataDict": {}
            },
            "131113": {
                "name": "大田区",
                "childDataDict": {}
            },
            "131121": {
                "name": "世田谷区",
                "childDataDict": {}
            },
            "131130": {
                "name": "渋谷区",
                "childDataDict": {}
            },
            "131148": {
                "name": "中野区",
                "childDataDict": {}
            },
            "131156": {
                "name": "杉並区",
                "childDataDict": {}
            },
            "131164": {
                "name": "豊島区",
                "childDataDict": {}
            },
            "131172": {
                "name": "北区",
                "childDataDict": {}
            },
            "131181": {
                "name": "荒川区",
                "childDataDict": {}
            },
            "131199": {
                "name": "板橋区",
                "childDataDict": {}
            },
            "131202": {
                "name": "練馬区",
                "childDataDict": {}
            },
            "131211": {
                "name": "足立区",
                "childDataDict": {}
            },
            "131229": {
                "name": "葛飾区",
                "childDataDict": {}
            },
            "131237": {
                "name": "江戸川区",
                "childDataDict": {}
            },
            "132012": {
                "name": "八王子市",
                "childDataDict": {}
            },
            "132021": {
                "name": "立川市",
                "childDataDict": {}
            },
            "132039": {
                "name": "武蔵野市",
                "childDataDict": {}
            },
            "132047": {
                "name": "三鷹市",
                "childDataDict": {}
            },
            "132055": {
                "name": "青梅市",
                "childDataDict": {}
            },
            "132063": {
                "name": "府中市",
                "childDataDict": {}
            },
            "132071": {
                "name": "昭島市",
                "childDataDict": {}
            },
            "132080": {
                "name": "調布市",
                "childDataDict": {}
            },
            "132098": {
                "name": "町田市",
                "childDataDict": {}
            },
            "132101": {
                "name": "小金井市",
                "childDataDict": {}
            },
            "132110": {
                "name": "小平市",
                "childDataDict": {}
            },
            "132128": {
                "name": "日野市",
                "childDataDict": {}
            },
            "132136": {
                "name": "東村山市",
                "childDataDict": {}
            },
            "132144": {
                "name": "国分寺市",
                "childDataDict": {}
            },
            "132152": {
                "name": "国立市",
                "childDataDict": {}
            },
            "132187": {
                "name": "福生市",
                "childDataDict": {}
            },
            "132195": {
                "name": "狛江市",
                "childDataDict": {}
            },
            "132209": {
                "name": "東大和市",
                "childDataDict": {}
            },
            "132217": {
                "name": "清瀬市",
                "childDataDict": {}
            },
            "132225": {
                "name": "東久留米市",
                "childDataDict": {}
            },
            "132233": {
                "name": "武蔵村山市",
                "childDataDict": {}
            },
            "132241": {
                "name": "多摩市",
                "childDataDict": {}
            },
            "132250": {
                "name": "稲城市",
                "childDataDict": {}
            },
            "132276": {
                "name": "羽村市",
                "childDataDict": {}
            },
            "132284": {
                "name": "あきる野市",
                "childDataDict": {}
            },
            "132292": {
                "name": "西東京市",
                "childDataDict": {}
            },
            "133035": {
                "name": "瑞穂町",
                "childDataDict": {}
            },
            "133051": {
                "name": "日の出町",
                "childDataDict": {}
            },
            "133078": {
                "name": "檜原村",
                "childDataDict": {}
            },
            "133086": {
                "name": "奥多摩町",
                "childDataDict": {}
            },
            "133612": {
                "name": "大島町",
                "childDataDict": {}
            },
            "133621": {
                "name": "利島村",
                "childDataDict": {}
            },
            "133639": {
                "name": "新島村",
                "childDataDict": {}
            },
            "133647": {
                "name": "神津島村",
                "childDataDict": {}
            },
            "133817": {
                "name": "三宅村",
                "childDataDict": {}
            },
            "133825": {
                "name": "御蔵島村",
                "childDataDict": {}
            },
            "134015": {
                "name": "八丈町",
                "childDataDict": {}
            },
            "134023": {
                "name": "青ヶ島村",
                "childDataDict": {}
            },
            "134210": {
                "name": "小笠原村",
                "childDataDict": {}
            }
        }
    },
    "140007": {
        "name": "神奈川県",
        "childDataDict": {
            "141003": {
                "name": "横浜市",
                "childDataDict": {
                    "141011": {
                        "name": "鶴見区",
                        "childDataDict": {}
                    },"141020": {
                        "name": "神奈川区",
                        "childDataDict": {}
                    },"141038": {
                        "name": "西区",
                        "childDataDict": {}
                    },"141046": {
                        "name": "中区",
                        "childDataDict": {}
                    },"141054": {
                        "name": "南区",
                        "childDataDict": {}
                    },"141062": {
                        "name": "保土ケ谷区",
                        "childDataDict": {}
                    },"141071": {
                        "name": "磯子区",
                        "childDataDict": {}
                    },"141089": {
                        "name": "金沢区",
                        "childDataDict": {}
                    },"141097": {
                        "name": "港北区",
                        "childDataDict": {}
                    },"141101": {
                        "name": "戸塚区",
                        "childDataDict": {}
                    },"141119": {
                        "name": "港南区",
                        "childDataDict": {}
                    },"141127": {
                        "name": "旭区",
                        "childDataDict": {}
                    },"141135": {
                        "name": "緑区",
                        "childDataDict": {}
                    },"141143": {
                        "name": "瀬谷区",
                        "childDataDict": {}
                    },"141151": {
                        "name": "栄区",
                        "childDataDict": {}
                    },"141160": {
                        "name": "泉区",
                        "childDataDict": {}
                    },"141178": {
                        "name": "青葉区",
                        "childDataDict": {}
                    },"141186": {
                        "name": "都筑区",
                        "childDataDict": {}
                    }
                }
            },
            "141305": {
                "name": "川崎市",
                "childDataDict": {
                    "141313": {
                        "name": "川崎区",
                        "childDataDict": {}
                    },"141321": {
                        "name": "幸区",
                        "childDataDict": {}
                    },"141330": {
                        "name": "中原区",
                        "childDataDict": {}
                    },"141348": {
                        "name": "高津区",
                        "childDataDict": {}
                    },"141356": {
                        "name": "多摩区",
                        "childDataDict": {}
                    },"141364": {
                        "name": "宮前区",
                        "childDataDict": {}
                    },"141372": {
                        "name": "麻生区",
                        "childDataDict": {}
                    }
                }
            },
            "141500": {
                "name": "相模原市",
                "childDataDict": {
                    "141518": {
                        "name": "緑区",
                        "childDataDict": {}
                    },"141526": {
                        "name": "中央区",
                        "childDataDict": {}
                    },"141534": {
                        "name": "南区",
                        "childDataDict": {}
                    }
                }
            },
            "142018": {
                "name": "横須賀市",
                "childDataDict": {}
            },
            "142034": {
                "name": "平塚市",
                "childDataDict": {}
            },
            "142042": {
                "name": "鎌倉市",
                "childDataDict": {}
            },
            "142051": {
                "name": "藤沢市",
                "childDataDict": {}
            },
            "142069": {
                "name": "小田原市",
                "childDataDict": {}
            },
            "142077": {
                "name": "茅ヶ崎市",
                "childDataDict": {}
            },
            "142085": {
                "name": "逗子市",
                "childDataDict": {}
            },
            "142107": {
                "name": "三浦市",
                "childDataDict": {}
            },
            "142115": {
                "name": "秦野市",
                "childDataDict": {}
            },
            "142123": {
                "name": "厚木市",
                "childDataDict": {}
            },
            "142131": {
                "name": "大和市",
                "childDataDict": {}
            },
            "142140": {
                "name": "伊勢原市",
                "childDataDict": {}
            },
            "142158": {
                "name": "海老名市",
                "childDataDict": {}
            },
            "142166": {
                "name": "座間市",
                "childDataDict": {}
            },
            "142174": {
                "name": "南足柄市",
                "childDataDict": {}
            },
            "142182": {
                "name": "綾瀬市",
                "childDataDict": {}
            },
            "143014": {
                "name": "葉山町",
                "childDataDict": {}
            },
            "143219": {
                "name": "寒川町",
                "childDataDict": {}
            },
            "143413": {
                "name": "大磯町",
                "childDataDict": {}
            },
            "143421": {
                "name": "二宮町",
                "childDataDict": {}
            },
            "143618": {
                "name": "中井町",
                "childDataDict": {}
            },
            "143626": {
                "name": "大井町",
                "childDataDict": {}
            },
            "143634": {
                "name": "松田町",
                "childDataDict": {}
            },
            "143642": {
                "name": "山北町",
                "childDataDict": {}
            },
            "143669": {
                "name": "開成町",
                "childDataDict": {}
            },
            "143821": {
                "name": "箱根町",
                "childDataDict": {}
            },
            "143839": {
                "name": "真鶴町",
                "childDataDict": {}
            },
            "143847": {
                "name": "湯河原町",
                "childDataDict": {}
            },
            "144011": {
                "name": "愛川町",
                "childDataDict": {}
            },
            "144029": {
                "name": "清川村",
                "childDataDict": {}
            }
        }
    },
    "150002": {
        "name": "新潟県",
        "childDataDict": {
            "151009": {
                "name": "新潟市",
                "childDataDict": {
                    "151017": {
                        "name": "北区",
                        "childDataDict": {}
                    },"151025": {
                        "name": "東区",
                        "childDataDict": {}
                    },"151033": {
                        "name": "中央区",
                        "childDataDict": {}
                    },"151041": {
                        "name": "江南区",
                        "childDataDict": {}
                    },"151050": {
                        "name": "秋葉区",
                        "childDataDict": {}
                    },"151068": {
                        "name": "南区",
                        "childDataDict": {}
                    },"151076": {
                        "name": "西区",
                        "childDataDict": {}
                    },"151084": {
                        "name": "西蒲区",
                        "childDataDict": {}
                    }
                }
            },
            "152021": {
                "name": "長岡市",
                "childDataDict": {}
            },
            "152048": {
                "name": "三条市",
                "childDataDict": {}
            },
            "152056": {
                "name": "柏崎市",
                "childDataDict": {}
            },
            "152064": {
                "name": "新発田市",
                "childDataDict": {}
            },
            "152081": {
                "name": "小千谷市",
                "childDataDict": {}
            },
            "152099": {
                "name": "加茂市",
                "childDataDict": {}
            },
            "152102": {
                "name": "十日町市",
                "childDataDict": {}
            },
            "152111": {
                "name": "見附市",
                "childDataDict": {}
            },
            "152129": {
                "name": "村上市",
                "childDataDict": {}
            },
            "152137": {
                "name": "燕市",
                "childDataDict": {}
            },
            "152161": {
                "name": "糸魚川市",
                "childDataDict": {}
            },
            "152170": {
                "name": "妙高市",
                "childDataDict": {}
            },
            "152188": {
                "name": "五泉市",
                "childDataDict": {}
            },
            "152226": {
                "name": "上越市",
                "childDataDict": {}
            },
            "152234": {
                "name": "阿賀野市",
                "childDataDict": {}
            },
            "152242": {
                "name": "佐渡市",
                "childDataDict": {}
            },
            "152251": {
                "name": "魚沼市",
                "childDataDict": {}
            },
            "152269": {
                "name": "南魚沼市",
                "childDataDict": {}
            },
            "152277": {
                "name": "胎内市",
                "childDataDict": {}
            },
            "153079": {
                "name": "聖籠町",
                "childDataDict": {}
            },
            "153427": {
                "name": "弥彦村",
                "childDataDict": {}
            },
            "153613": {
                "name": "田上町",
                "childDataDict": {}
            },
            "153851": {
                "name": "阿賀町",
                "childDataDict": {}
            },
            "154059": {
                "name": "出雲崎町",
                "childDataDict": {}
            },
            "154610": {
                "name": "湯沢町",
                "childDataDict": {}
            },
            "154822": {
                "name": "津南町",
                "childDataDict": {}
            },
            "155047": {
                "name": "刈羽村",
                "childDataDict": {}
            },
            "155811": {
                "name": "関川村",
                "childDataDict": {}
            },
            "155861": {
                "name": "粟島浦村",
                "childDataDict": {}
            }
        }
    },
    "160008": {
        "name": "富山県",
        "childDataDict": {
            "162019": {
                "name": "富山市",
                "childDataDict": {}
            },
            "162027": {
                "name": "高岡市",
                "childDataDict": {}
            },
            "162043": {
                "name": "魚津市",
                "childDataDict": {}
            },
            "162051": {
                "name": "氷見市",
                "childDataDict": {}
            },
            "162060": {
                "name": "滑川市",
                "childDataDict": {}
            },
            "162078": {
                "name": "黒部市",
                "childDataDict": {}
            },
            "162086": {
                "name": "砺波市",
                "childDataDict": {}
            },
            "162094": {
                "name": "小矢部市",
                "childDataDict": {}
            },
            "162108": {
                "name": "南砺市",
                "childDataDict": {}
            },
            "162116": {
                "name": "射水市",
                "childDataDict": {}
            },
            "163210": {
                "name": "舟橋村",
                "childDataDict": {}
            },
            "163228": {
                "name": "上市町",
                "childDataDict": {}
            },
            "163236": {
                "name": "立山町",
                "childDataDict": {}
            },
            "163422": {
                "name": "入善町",
                "childDataDict": {}
            },
            "163431": {
                "name": "朝日町",
                "childDataDict": {}
            }
        }
    },
    "170003": {
        "name": "石川県",
        "childDataDict": {
            "172014": {
                "name": "金沢市",
                "childDataDict": {}
            },
            "172022": {
                "name": "七尾市",
                "childDataDict": {}
            },
            "172031": {
                "name": "小松市",
                "childDataDict": {}
            },
            "172049": {
                "name": "輪島市",
                "childDataDict": {}
            },
            "172057": {
                "name": "珠洲市",
                "childDataDict": {}
            },
            "172065": {
                "name": "加賀市",
                "childDataDict": {}
            },
            "172073": {
                "name": "羽咋市",
                "childDataDict": {}
            },
            "172090": {
                "name": "かほく市",
                "childDataDict": {}
            },
            "172103": {
                "name": "白山市",
                "childDataDict": {}
            },
            "172111": {
                "name": "能美市",
                "childDataDict": {}
            },
            "172120": {
                "name": "野々市市",
                "childDataDict": {}
            },
            "173240": {
                "name": "川北町",
                "childDataDict": {}
            },
            "173614": {
                "name": "津幡町",
                "childDataDict": {}
            },
            "173657": {
                "name": "内灘町",
                "childDataDict": {}
            },
            "173843": {
                "name": "志賀町",
                "childDataDict": {}
            },
            "173860": {
                "name": "宝達志水町",
                "childDataDict": {}
            },
            "174076": {
                "name": "中能登町",
                "childDataDict": {}
            },
            "174611": {
                "name": "穴水町",
                "childDataDict": {}
            },
            "174637": {
                "name": "能登町",
                "childDataDict": {}
            }
        }
    },
    "180009": {
        "name": "福井県",
        "childDataDict": {
            "182010": {
                "name": "福井市",
                "childDataDict": {}
            },
            "182028": {
                "name": "敦賀市",
                "childDataDict": {}
            },
            "182044": {
                "name": "小浜市",
                "childDataDict": {}
            },
            "182052": {
                "name": "大野市",
                "childDataDict": {}
            },
            "182061": {
                "name": "勝山市",
                "childDataDict": {}
            },
            "182079": {
                "name": "鯖江市",
                "childDataDict": {}
            },
            "182087": {
                "name": "あわら市",
                "childDataDict": {}
            },
            "182095": {
                "name": "越前市",
                "childDataDict": {}
            },
            "182109": {
                "name": "坂井市",
                "childDataDict": {}
            },
            "183229": {
                "name": "永平寺町",
                "childDataDict": {}
            },
            "183822": {
                "name": "池田町",
                "childDataDict": {}
            },
            "184047": {
                "name": "南越前町",
                "childDataDict": {}
            },
            "184233": {
                "name": "越前町",
                "childDataDict": {}
            },
            "184420": {
                "name": "美浜町",
                "childDataDict": {}
            },
            "184811": {
                "name": "高浜町",
                "childDataDict": {}
            },
            "184837": {
                "name": "おおい町",
                "childDataDict": {}
            },
            "185019": {
                "name": "若狭町",
                "childDataDict": {}
            }
        }
    },
    "190004": {
        "name": "山梨県",
        "childDataDict": {
            "192015": {
                "name": "甲府市",
                "childDataDict": {}
            },
            "192023": {
                "name": "富士吉田市",
                "childDataDict": {}
            },
            "192040": {
                "name": "都留市",
                "childDataDict": {}
            },
            "192058": {
                "name": "山梨市",
                "childDataDict": {}
            },
            "192066": {
                "name": "大月市",
                "childDataDict": {}
            },
            "192074": {
                "name": "韮崎市",
                "childDataDict": {}
            },
            "192082": {
                "name": "南アルプス市",
                "childDataDict": {}
            },
            "192091": {
                "name": "北杜市",
                "childDataDict": {}
            },
            "192104": {
                "name": "甲斐市",
                "childDataDict": {}
            },
            "192112": {
                "name": "笛吹市",
                "childDataDict": {}
            },
            "192121": {
                "name": "上野原市",
                "childDataDict": {}
            },
            "192139": {
                "name": "甲州市",
                "childDataDict": {}
            },
            "192147": {
                "name": "中央市",
                "childDataDict": {}
            },
            "193461": {
                "name": "市川三郷町",
                "childDataDict": {}
            },
            "193640": {
                "name": "早川町",
                "childDataDict": {}
            },
            "193658": {
                "name": "身延町",
                "childDataDict": {}
            },
            "193666": {
                "name": "南部町",
                "childDataDict": {}
            },
            "193682": {
                "name": "富士川町",
                "childDataDict": {}
            },
            "193844": {
                "name": "昭和町",
                "childDataDict": {}
            },
            "194221": {
                "name": "道志村",
                "childDataDict": {}
            },
            "194239": {
                "name": "西桂町",
                "childDataDict": {}
            },
            "194247": {
                "name": "忍野村",
                "childDataDict": {}
            },
            "194255": {
                "name": "山中湖村",
                "childDataDict": {}
            },
            "194298": {
                "name": "鳴沢村",
                "childDataDict": {}
            },
            "194301": {
                "name": "富士河口湖町",
                "childDataDict": {}
            },
            "194425": {
                "name": "小菅村",
                "childDataDict": {}
            },
            "194433": {
                "name": "丹波山村",
                "childDataDict": {}
            }
        }
    },
    "200000": {
        "name": "長野県",
        "childDataDict": {
            "202011": {
                "name": "長野市",
                "childDataDict": {}
            },
            "202029": {
                "name": "松本市",
                "childDataDict": {}
            },
            "202037": {
                "name": "上田市",
                "childDataDict": {}
            },
            "202045": {
                "name": "岡谷市",
                "childDataDict": {}
            },
            "202053": {
                "name": "飯田市",
                "childDataDict": {}
            },
            "202061": {
                "name": "諏訪市",
                "childDataDict": {}
            },
            "202070": {
                "name": "須坂市",
                "childDataDict": {}
            },
            "202088": {
                "name": "小諸市",
                "childDataDict": {}
            },
            "202096": {
                "name": "伊那市",
                "childDataDict": {}
            },
            "202100": {
                "name": "駒ヶ根市",
                "childDataDict": {}
            },
            "202118": {
                "name": "中野市",
                "childDataDict": {}
            },
            "202126": {
                "name": "大町市",
                "childDataDict": {}
            },
            "202134": {
                "name": "飯山市",
                "childDataDict": {}
            },
            "202142": {
                "name": "茅野市",
                "childDataDict": {}
            },
            "202151": {
                "name": "塩尻市",
                "childDataDict": {}
            },
            "202177": {
                "name": "佐久市",
                "childDataDict": {}
            },
            "202185": {
                "name": "千曲市",
                "childDataDict": {}
            },
            "202193": {
                "name": "東御市",
                "childDataDict": {}
            },
            "202207": {
                "name": "安曇野市",
                "childDataDict": {}
            },
            "203033": {
                "name": "小海町",
                "childDataDict": {}
            },
            "203041": {
                "name": "川上村",
                "childDataDict": {}
            },
            "203050": {
                "name": "南牧村",
                "childDataDict": {}
            },
            "203068": {
                "name": "南相木村",
                "childDataDict": {}
            },
            "203076": {
                "name": "北相木村",
                "childDataDict": {}
            },
            "203092": {
                "name": "佐久穂町",
                "childDataDict": {}
            },
            "203211": {
                "name": "軽井沢町",
                "childDataDict": {}
            },
            "203238": {
                "name": "御代田町",
                "childDataDict": {}
            },
            "203246": {
                "name": "立科町",
                "childDataDict": {}
            },
            "203491": {
                "name": "青木村",
                "childDataDict": {}
            },
            "203505": {
                "name": "長和町",
                "childDataDict": {}
            },
            "203611": {
                "name": "下諏訪町",
                "childDataDict": {}
            },
            "203629": {
                "name": "富士見町",
                "childDataDict": {}
            },
            "203637": {
                "name": "原村",
                "childDataDict": {}
            },
            "203823": {
                "name": "辰野町",
                "childDataDict": {}
            },
            "203831": {
                "name": "箕輪町",
                "childDataDict": {}
            },
            "203840": {
                "name": "飯島町",
                "childDataDict": {}
            },
            "203858": {
                "name": "南箕輪村",
                "childDataDict": {}
            },
            "203866": {
                "name": "中川村",
                "childDataDict": {}
            },
            "203882": {
                "name": "宮田村",
                "childDataDict": {}
            },
            "204021": {
                "name": "松川町",
                "childDataDict": {}
            },
            "204030": {
                "name": "高森町",
                "childDataDict": {}
            },
            "204048": {
                "name": "阿南町",
                "childDataDict": {}
            },
            "204072": {
                "name": "阿智村",
                "childDataDict": {}
            },
            "204099": {
                "name": "平谷村",
                "childDataDict": {}
            },
            "204102": {
                "name": "根羽村",
                "childDataDict": {}
            },
            "204111": {
                "name": "下條村",
                "childDataDict": {}
            },
            "204129": {
                "name": "売木村",
                "childDataDict": {}
            },
            "204137": {
                "name": "天龍村",
                "childDataDict": {}
            },
            "204145": {
                "name": "泰阜村",
                "childDataDict": {}
            },
            "204153": {
                "name": "喬木村",
                "childDataDict": {}
            },
            "204161": {
                "name": "豊丘村",
                "childDataDict": {}
            },
            "204170": {
                "name": "大鹿村",
                "childDataDict": {}
            },
            "204226": {
                "name": "上松町",
                "childDataDict": {}
            },
            "204234": {
                "name": "南木曽町",
                "childDataDict": {}
            },
            "204251": {
                "name": "木祖村",
                "childDataDict": {}
            },
            "204293": {
                "name": "王滝村",
                "childDataDict": {}
            },
            "204307": {
                "name": "大桑村",
                "childDataDict": {}
            },
            "204323": {
                "name": "木曽町",
                "childDataDict": {}
            },
            "204463": {
                "name": "麻績村",
                "childDataDict": {}
            },
            "204480": {
                "name": "生坂村",
                "childDataDict": {}
            },
            "204501": {
                "name": "山形村",
                "childDataDict": {}
            },
            "204510": {
                "name": "朝日村",
                "childDataDict": {}
            },
            "204528": {
                "name": "筑北村",
                "childDataDict": {}
            },
            "204811": {
                "name": "池田町",
                "childDataDict": {}
            },
            "204820": {
                "name": "松川村",
                "childDataDict": {}
            },
            "204854": {
                "name": "白馬村",
                "childDataDict": {}
            },
            "204862": {
                "name": "小谷村",
                "childDataDict": {}
            },
            "205214": {
                "name": "坂城町",
                "childDataDict": {}
            },
            "205419": {
                "name": "小布施町",
                "childDataDict": {}
            },
            "205435": {
                "name": "高山村",
                "childDataDict": {}
            },
            "205613": {
                "name": "山ノ内町",
                "childDataDict": {}
            },
            "205621": {
                "name": "木島平村",
                "childDataDict": {}
            },
            "205630": {
                "name": "野沢温泉村",
                "childDataDict": {}
            },
            "205834": {
                "name": "信濃町",
                "childDataDict": {}
            },
            "205885": {
                "name": "小川村",
                "childDataDict": {}
            },
            "205907": {
                "name": "飯綱町",
                "childDataDict": {}
            },
            "206024": {
                "name": "栄村",
                "childDataDict": {}
            }
        }
    },
    "210005": {
        "name": "岐阜県",
        "childDataDict": {
            "212016": {
                "name": "岐阜市",
                "childDataDict": {}
            },
            "212024": {
                "name": "大垣市",
                "childDataDict": {}
            },
            "212032": {
                "name": "高山市",
                "childDataDict": {}
            },
            "212041": {
                "name": "多治見市",
                "childDataDict": {}
            },
            "212059": {
                "name": "関市",
                "childDataDict": {}
            },
            "212067": {
                "name": "中津川市",
                "childDataDict": {}
            },
            "212075": {
                "name": "美濃市",
                "childDataDict": {}
            },
            "212083": {
                "name": "瑞浪市",
                "childDataDict": {}
            },
            "212091": {
                "name": "羽島市",
                "childDataDict": {}
            },
            "212105": {
                "name": "恵那市",
                "childDataDict": {}
            },
            "212113": {
                "name": "美濃加茂市",
                "childDataDict": {}
            },
            "212121": {
                "name": "土岐市",
                "childDataDict": {}
            },
            "212130": {
                "name": "各務原市",
                "childDataDict": {}
            },
            "212148": {
                "name": "可児市",
                "childDataDict": {}
            },
            "212156": {
                "name": "山県市",
                "childDataDict": {}
            },
            "212164": {
                "name": "瑞穂市",
                "childDataDict": {}
            },
            "212172": {
                "name": "飛騨市",
                "childDataDict": {}
            },
            "212181": {
                "name": "本巣市",
                "childDataDict": {}
            },
            "212199": {
                "name": "郡上市",
                "childDataDict": {}
            },
            "212202": {
                "name": "下呂市",
                "childDataDict": {}
            },
            "212211": {
                "name": "海津市",
                "childDataDict": {}
            },
            "213021": {
                "name": "岐南町",
                "childDataDict": {}
            },
            "213039": {
                "name": "笠松町",
                "childDataDict": {}
            },
            "213411": {
                "name": "養老町",
                "childDataDict": {}
            },
            "213616": {
                "name": "垂井町",
                "childDataDict": {}
            },
            "213624": {
                "name": "関ケ原町",
                "childDataDict": {}
            },
            "213811": {
                "name": "神戸町",
                "childDataDict": {}
            },
            "213829": {
                "name": "輪之内町",
                "childDataDict": {}
            },
            "213837": {
                "name": "安八町",
                "childDataDict": {}
            },
            "214019": {
                "name": "揖斐川町",
                "childDataDict": {}
            },
            "214035": {
                "name": "大野町",
                "childDataDict": {}
            },
            "214043": {
                "name": "池田町",
                "childDataDict": {}
            },
            "214213": {
                "name": "北方町",
                "childDataDict": {}
            },
            "215015": {
                "name": "坂祝町",
                "childDataDict": {}
            },
            "215023": {
                "name": "富加町",
                "childDataDict": {}
            },
            "215031": {
                "name": "川辺町",
                "childDataDict": {}
            },
            "215040": {
                "name": "七宗町",
                "childDataDict": {}
            },
            "215058": {
                "name": "八百津町",
                "childDataDict": {}
            },
            "215066": {
                "name": "白川町",
                "childDataDict": {}
            },
            "215074": {
                "name": "東白川村",
                "childDataDict": {}
            },
            "215210": {
                "name": "御嵩町",
                "childDataDict": {}
            },
            "216046": {
                "name": "白川村",
                "childDataDict": {}
            }
        }
    },
    "220001":{
        "name": "静岡県",
        "childDataDict": {
            "221007": {
                "name": "静岡市",
                "childDataDict": {
                    "221015": {
                        "name": "葵区",
                        "childDataDict": {}
                    },
                    "221023": {
                        "name": "駿河区",
                        "childDataDict": {}
                    },
                    "221031": {
                        "name": "清水区",
                        "childDataDict": {}
                    },
                }
            },
            "221309": {
                "name": "浜松市",
                "childDataDict": {
                    "221384": {
                        "name": "中央区",
                        "childDataDict": {}
                    },
                    "221392": {
                        "name": "浜名区",
                        "childDataDict": {}
                    },
                    "221406": {
                        "name": "天竜区",
                        "childDataDict": {}
                    },
                }
            },
            "222038": {
                "name": "沼津市",
                "childDataDict": {}
            },
            "222054": {
                "name": "熱海市",
                "childDataDict": {}
            },
            "222062": {
                "name": "三島市",
                "childDataDict": {}
            },
            "222071": {
                "name": "富士宮市",
                "childDataDict": {}
            },
            "222089": {
                "name": "伊東市",
                "childDataDict": {}
            },
            "222097": {
                "name": "島田市",
                "childDataDict": {}
            },
            "222101": {
                "name": "富士市",
                "childDataDict": {}
            },
            "222119": {
                "name": "磐田市",
                "childDataDict": {}
            },
            "222127": {
                "name": "焼津市",
                "childDataDict": {}
            },
            "222135": {
                "name": "掛川市",
                "childDataDict": {}
            },
            "222143": {
                "name": "藤枝市",
                "childDataDict": {}
            },
            "222151": {
                "name": "御殿場市",
                "childDataDict": {}
            },
            "222160": {
                "name": "袋井市",
                "childDataDict": {}
            },
            "222194": {
                "name": "下田市",
                "childDataDict": {}
            },
            "222208": {
                "name": "裾野市",
                "childDataDict": {}
            },
            "222216": {
                "name": "湖西市",
                "childDataDict": {}
            },
            "222224": {
                "name": "伊豆市",
                "childDataDict": {}
            },
            "222232": {
                "name": "御前崎市",
                "childDataDict": {}
            },
            "222241": {
                "name": "菊川市",
                "childDataDict": {}
            },
            "222259": {
                "name": "伊豆の国市",
                "childDataDict": {}
            },
            "222267": {
                "name": "牧之原市",
                "childDataDict": {}
            },
            "223018": {
                "name": "東伊豆町",
                "childDataDict": {}
            },
            "223026": {
                "name": "河津町",
                "childDataDict": {}
            },
            "223042": {
                "name": "南伊豆町",
                "childDataDict": {}
            },
            "223051": {
                "name": "松崎町",
                "childDataDict": {}
            },
            "223069": {
                "name": "西伊豆町",
                "childDataDict": {}
            },
            "223255": {
                "name": "函南町",
                "childDataDict": {}
            },
            "223417": {
                "name": "清水町",
                "childDataDict": {}
            },
            "223425": {
                "name": "長泉町",
                "childDataDict": {}
            },
            "223441": {
                "name": "小山町",
                "childDataDict": {}
            },
            "224243": {
                "name": "吉田町",
                "childDataDict": {}
            },
            "224294": {
                "name": "川根本町",
                "childDataDict": {}
            },
            "224618": {
                "name": "森町",
                "childDataDict": {}
            }
        }
    },
    "230006":{
        "name": "愛知県",
        "childDataDict": {
            "231002": {
                "name": "名古屋市",
                "childDataDict": {
                    "231011": {
                        "name": "千種区",
                        "childDataDict": {}
                    },
                    "231029": {
                        "name": "東区",
                        "childDataDict": {}
                    },
                    "231037": {
                        "name": "北区",
                        "childDataDict": {}
                    },
                    "231045": {
                        "name": "西区",
                        "childDataDict": {}
                    },
                    "231053": {
                        "name": "中村区",
                        "childDataDict": {}
                    },
                    "231061": {
                        "name": "中区",
                        "childDataDict": {}
                    },
                    "231070": {
                        "name": "昭和区",
                        "childDataDict": {}
                    },
                    "231088": {
                        "name": "瑞穂区",
                        "childDataDict": {}
                    },
                    "231096": {
                        "name": "熱田区",
                        "childDataDict": {}
                    },
                    "231100": {
                        "name": "中川区",
                        "childDataDict": {}
                    },
                    "231118": {
                        "name": "港区",
                        "childDataDict": {}
                    },
                    "231126": {
                        "name": "南区",
                        "childDataDict": {}
                    },
                    "231134": {
                        "name": "守山区",
                        "childDataDict": {}
                    },
                    "231142": {
                        "name": "緑区",
                        "childDataDict": {}
                    },
                    "231151": {
                        "name": "名東区",
                        "childDataDict": {}
                    },
                    "231169": {
                        "name": "天白区",
                        "childDataDict": {}
                    },
                }
            },
            "232017": {
                "name": "豊橋市",
                "childDataDict": {}
            },
            "232025": {
                "name": "岡崎市",
                "childDataDict": {}
            },
            "232033": {
                "name": "一宮市",
                "childDataDict": {}
            },
            "232041": {
                "name": "瀬戸市",
                "childDataDict": {}
            },
            "232050": {
                "name": "半田市",
                "childDataDict": {}
            },
            "232068": {
                "name": "春日井市",
                "childDataDict": {}
            },
            "232076": {
                "name": "豊川市",
                "childDataDict": {}
            },
            "232084": {
                "name": "津島市",
                "childDataDict": {}
            },
            "232092": {
                "name": "碧南市",
                "childDataDict": {}
            },
            "232106": {
                "name": "刈谷市",
                "childDataDict": {}
            },
            "232114": {
                "name": "豊田市",
                "childDataDict": {}
            },
            "232122": {
                "name": "安城市",
                "childDataDict": {}
            },
            "232131": {
                "name": "西尾市",
                "childDataDict": {}
            },
            "232149": {
                "name": "蒲郡市",
                "childDataDict": {}
            },
            "232157": {
                "name": "犬山市",
                "childDataDict": {}
            },
            "232165": {
                "name": "常滑市",
                "childDataDict": {}
            },
            "232173": {
                "name": "江南市",
                "childDataDict": {}
            },
            "232190": {
                "name": "小牧市",
                "childDataDict": {}
            },
            "232203": {
                "name": "稲沢市",
                "childDataDict": {}
            },
            "232211": {
                "name": "新城市",
                "childDataDict": {}
            },
            "232220": {
                "name": "東海市",
                "childDataDict": {}
            },
            "232238": {
                "name": "大府市",
                "childDataDict": {}
            },
            "232246": {
                "name": "知多市",
                "childDataDict": {}
            },
            "232254": {
                "name": "知立市",
                "childDataDict": {}
            },
            "232262": {
                "name": "尾張旭市",
                "childDataDict": {}
            },
            "232271": {
                "name": "高浜市",
                "childDataDict": {}
            },
            "232289": {
                "name": "岩倉市",
                "childDataDict": {}
            },
            "232297": {
                "name": "豊明市",
                "childDataDict": {}
            },
            "232301": {
                "name": "日進市",
                "childDataDict": {}
            },
            "232319": {
                "name": "田原市",
                "childDataDict": {}
            },
            "232327": {
                "name": "愛西市",
                "childDataDict": {}
            },
            "232335": {
                "name": "清須市",
                "childDataDict": {}
            },
            "232343": {
                "name": "北名古屋市",
                "childDataDict": {}
            },
            "232351": {
                "name": "弥富市",
                "childDataDict": {}
            },
            "232360": {
                "name": "みよし市",
                "childDataDict": {}
            },
            "232378": {
                "name": "あま市",
                "childDataDict": {}
            },
            "232386": {
                "name": "長久手市",
                "childDataDict": {}
            },
            "233021": {
                "name": "東郷町",
                "childDataDict": {}
            },
            "233421": {
                "name": "豊山町",
                "childDataDict": {}
            },
            "233617": {
                "name": "大口町",
                "childDataDict": {}
            },
            "233625": {
                "name": "扶桑町",
                "childDataDict": {}
            },
            "234249": {
                "name": "大治町",
                "childDataDict": {}
            },
            "234257": {
                "name": "蟹江町",
                "childDataDict": {}
            },
            "234273": {
                "name": "飛島村",
                "childDataDict": {}
            },
            "234419": {
                "name": "阿久比町",
                "childDataDict": {}
            },
            "234427": {
                "name": "東浦町",
                "childDataDict": {}
            },
            "234451": {
                "name": "南知多町",
                "childDataDict": {}
            },
            "234460": {
                "name": "美浜町",
                "childDataDict": {}
            },
            "234478": {
                "name": "武豊町",
                "childDataDict": {}
            },
            "235016": {
                "name": "幸田町",
                "childDataDict": {}
            },
            "235610": {
                "name": "設楽町",
                "childDataDict": {}
            },
            "235628": {
                "name": "東栄町",
                "childDataDict": {}
            },
            "235636": {
                "name": "豊根村",
                "childDataDict": {}
            }
        }
    },
    "240001": {
        "name": "三重県",
        "childDataDict": {
            "242012": {
                "name": "津市",
                "childDataDict": {}
            },
            "242021": {
                "name": "四日市市",
                "childDataDict": {}
            },
            "242039": {
                "name": "伊勢市",
                "childDataDict": {}
            },
            "242047": {
                "name": "松阪市",
                "childDataDict": {}
            },
            "242055": {
                "name": "桑名市",
                "childDataDict": {}
            },
            "242071": {
                "name": "鈴鹿市",
                "childDataDict": {}
            },
            "242080": {
                "name": "名張市",
                "childDataDict": {}
            },
            "242098": {
                "name": "尾鷲市",
                "childDataDict": {}
            },
            "242101": {
                "name": "亀山市",
                "childDataDict": {}
            },
            "242110": {
                "name": "鳥羽市",
                "childDataDict": {}
            },
            "242128": {
                "name": "熊野市",
                "childDataDict": {}
            },
            "242144": {
                "name": "いなべ市",
                "childDataDict": {}
            },
            "242152": {
                "name": "志摩市",
                "childDataDict": {}
            },
            "242161": {
                "name": "伊賀市",
                "childDataDict": {}
            },
            "243035": {
                "name": "木曽岬町",
                "childDataDict": {}
            },
            "243248": {
                "name": "東員町",
                "childDataDict": {}
            },
            "243418": {
                "name": "菰野町",
                "childDataDict": {}
            },
            "243434": {
                "name": "朝日町",
                "childDataDict": {}
            },
            "243442": {
                "name": "川越町",
                "childDataDict": {}
            },
            "244414": {
                "name": "多気町",
                "childDataDict": {}
            },
            "244422": {
                "name": "明和町",
                "childDataDict": {}
            },
            "244431": {
                "name": "大台町",
                "childDataDict": {}
            },
            "244619": {
                "name": "玉城町",
                "childDataDict": {}
            },
            "244708": {
                "name": "度会町",
                "childDataDict": {}
            },
            "244716": {
                "name": "大紀町",
                "childDataDict": {}
            },
            "244724": {
                "name": "南伊勢町",
                "childDataDict": {}
            },
            "245437": {
                "name": "紀北町",
                "childDataDict": {}
            },
            "245615": {
                "name": "御浜町",
                "childDataDict": {}
            },
            "245623": {
                "name": "紀宝町",
                "childDataDict": {}
            }
        }
    },
    "250007": {
        "name": "滋賀県",
        "childDataDict": {
            "252018": {
                "name": "大津市",
                "childDataDict": {}
            },
            "252026": {
                "name": "彦根市",
                "childDataDict": {}
            },
            "252034": {
                "name": "長浜市",
                "childDataDict": {}
            },
            "252042": {
                "name": "近江八幡市",
                "childDataDict": {}
            },
            "252069": {
                "name": "草津市",
                "childDataDict": {}
            },
            "252077": {
                "name": "守山市",
                "childDataDict": {}
            },
            "252085": {
                "name": "栗東市",
                "childDataDict": {}
            },
            "252093": {
                "name": "甲賀市",
                "childDataDict": {}
            },
            "252107": {
                "name": "野洲市",
                "childDataDict": {}
            },
            "252115": {
                "name": "湖南市",
                "childDataDict": {}
            },
            "252123": {
                "name": "高島市",
                "childDataDict": {}
            },
            "252131": {
                "name": "東近江市",
                "childDataDict": {}
            },
            "252140": {
                "name": "米原市",
                "childDataDict": {}
            },
            "253839": {
                "name": "日野町",
                "childDataDict": {}
            },
            "253847": {
                "name": "竜王町",
                "childDataDict": {}
            },
            "254258": {
                "name": "愛荘町",
                "childDataDict": {}
            },
            "254410": {
                "name": "豊郷町",
                "childDataDict": {}
            },
            "254428": {
                "name": "甲良町",
                "childDataDict": {}
            },
            "254436": {
                "name": "多賀町",
                "childDataDict": {}
            }
        }
    },
    "260002": {
        "name": "京都府",
        "childDataDict": {
            "261009": {
                "name": "京都市",
                "childDataDict": {
                    "261017": {
                        "name": "北区",
                        "childDataDict": {}
                    },                                  
                    "261025": {
                        "name": "上京区",
                        "childDataDict": {}
                    },                                  
                    "261033": {
                        "name": "左京区",
                        "childDataDict": {}
                    },                
                    "261041": {
                        "name": "中京区",
                        "childDataDict": {}
                    },  
                    "261050": {
                        "name": "東山区",
                        "childDataDict": {}
                    },                                  
                    "261068": {
                        "name": "下京区",
                        "childDataDict": {}
                    },                                  
                    "261076": {
                        "name": "南区",
                        "childDataDict": {}
                    },                                  
                    "261084": {
                        "name": "右京区",
                        "childDataDict": {}
                    },                                  
                    "261092": {
                        "name": "伏見区",
                        "childDataDict": {}
                    },                                  
                    "261106": {
                        "name": "山科区",
                        "childDataDict": {}
                    },                                  
                    "261114": {
                        "name": "西京区",
                        "childDataDict": {}
                    },                                  
                                
                }
            },
            "262013": {
                "name": "福知山市",
                "childDataDict": {}
            },
            "262021": {
                "name": "舞鶴市",
                "childDataDict": {}
            },
            "262030": {
                "name": "綾部市",
                "childDataDict": {}
            },
            "262048": {
                "name": "宇治市",
                "childDataDict": {}
            },
            "262056": {
                "name": "宮津市",
                "childDataDict": {}
            },
            "262064": {
                "name": "亀岡市",
                "childDataDict": {}
            },
            "262072": {
                "name": "城陽市",
                "childDataDict": {}
            },
            "262081": {
                "name": "向日市",
                "childDataDict": {}
            },
            "262099": {
                "name": "長岡京市",
                "childDataDict": {}
            },
            "262102": {
                "name": "八幡市",
                "childDataDict": {}
            },
            "262111": {
                "name": "京田辺市",
                "childDataDict": {}
            },
            "262129": {
                "name": "京丹後市",
                "childDataDict": {}
            },
            "262137": {
                "name": "南丹市",
                "childDataDict": {}
            },
            "262145": {
                "name": "木津川市",
                "childDataDict": {}
            },
            "263036": {
                "name": "大山崎町",
                "childDataDict": {}
            },
            "263222": {
                "name": "久御山町",
                "childDataDict": {}
            },
            "263435": {
                "name": "井手町",
                "childDataDict": {}
            },
            "263443": {
                "name": "宇治田原町",
                "childDataDict": {}
            },
            "263648": {
                "name": "笠置町",
                "childDataDict": {}
            },
            "263656": {
                "name": "和束町",
                "childDataDict": {}
            },
            "263664": {
                "name": "精華町",
                "childDataDict": {}
            },
            "263672": {
                "name": "南山城村",
                "childDataDict": {}
            },
            "264075": {
                "name": "京丹波町",
                "childDataDict": {}
            },
            "264636": {
                "name": "伊根町",
                "childDataDict": {}
            },
            "264652": {
                "name": "与謝野町",
                "childDataDict": {}
            }
        }
    },
    "270008": {
        "name": "大阪府",
        "childDataDict": {
            "271004": {
                "name": "大阪市",
                "childDataDict": {
                    "271021": {
                        "name": "都島区",
                        "childDataDict": {}
                    },
                    "271039": {
                        "name": "福島区",
                        "childDataDict": {}
                    },
                    "271047": {
                        "name": "此花区",
                        "childDataDict": {}
                    },
                    "271063": {
                        "name": "西区",
                        "childDataDict": {}
                    },
                    "271071": {
                        "name": "港区",
                        "childDataDict": {}
                    },
                    "271080": {
                        "name": "大正区",
                        "childDataDict": {}
                    },
                    "271098": {
                        "name": "天王寺区",
                        "childDataDict": {}
                    },
                    "271110": {
                        "name": "浪速区",
                        "childDataDict": {}
                    },
                    "271136": {
                        "name": "西淀川区",
                        "childDataDict": {}
                    },
                    "271144": {
                        "name": "東淀川区",
                        "childDataDict": {}
                    },
                    "271152": {
                        "name": "東成区",
                        "childDataDict": {}
                    },
                    "271161": {
                        "name": "生野区",
                        "childDataDict": {}
                    },
                    "271179": {
                        "name": "旭区",
                        "childDataDict": {}
                    },
                    "271187": {
                        "name": "城東区",
                        "childDataDict": {}
                    },
                    "271195": {
                        "name": "阿倍野区",
                        "childDataDict": {}
                    },
                    "271209": {
                        "name": "住吉区",
                        "childDataDict": {}
                    },
                    "271217": {
                        "name": "東住吉区",
                        "childDataDict": {}
                    },
                    "271225": {
                        "name": "西成区",
                        "childDataDict": {}
                    },
                    "271233": {
                        "name": "淀川区",
                        "childDataDict": {}
                    },
                    "271241": {
                        "name": "鶴見区",
                        "childDataDict": {}
                    },
                    "271250": {
                        "name": "住之江区",
                        "childDataDict": {}
                    },
                    "271268": {
                        "name": "平野区",
                        "childDataDict": {}
                    },
                    "271276": {
                        "name": "北区",
                        "childDataDict": {}
                    },
                    "271284": {
                        "name": "中央区",
                        "childDataDict": {}
                    },
                }
            },
            "271403": {
                "name": "堺市",
                "childDataDict": {
                    "271411": {
                        "name": "堺区",
                        "childDataDict": {}
                    },
                    "271420": {
                        "name": "中区",
                        "childDataDict": {}
                    },
                    "271438": {
                        "name": "東区",
                        "childDataDict": {}
                    },
                    "271446": {
                        "name": "西区",
                        "childDataDict": {}
                    },
                    "271454": {
                        "name": "南区",
                        "childDataDict": {}
                    },
                    "271462": {
                        "name": "北区",
                        "childDataDict": {}
                    },
                    "271471": {
                        "name": "美原区",
                        "childDataDict": {}
                    },
                }
            },
            "272027": {
                "name": "岸和田市",
                "childDataDict": {}
            },
            "272035": {
                "name": "豊中市",
                "childDataDict": {}
            },
            "272043": {
                "name": "池田市",
                "childDataDict": {}
            },
            "272051": {
                "name": "吹田市",
                "childDataDict": {}
            },
            "272060": {
                "name": "泉大津市",
                "childDataDict": {}
            },
            "272078": {
                "name": "高槻市",
                "childDataDict": {}
            },
            "272086": {
                "name": "貝塚市",
                "childDataDict": {}
            },
            "272094": {
                "name": "守口市",
                "childDataDict": {}
            },
            "272108": {
                "name": "枚方市",
                "childDataDict": {}
            },
            "272116": {
                "name": "茨木市",
                "childDataDict": {}
            },
            "272124": {
                "name": "八尾市",
                "childDataDict": {}
            },
            "272132": {
                "name": "泉佐野市",
                "childDataDict": {}
            },
            "272141": {
                "name": "富田林市",
                "childDataDict": {}
            },
            "272159": {
                "name": "寝屋川市",
                "childDataDict": {}
            },
            "272167": {
                "name": "河内長野市",
                "childDataDict": {}
            },
            "272175": {
                "name": "松原市",
                "childDataDict": {}
            },
            "272183": {
                "name": "大東市",
                "childDataDict": {}
            },
            "272191": {
                "name": "和泉市",
                "childDataDict": {}
            },
            "272205": {
                "name": "箕面市",
                "childDataDict": {}
            },
            "272213": {
                "name": "柏原市",
                "childDataDict": {}
            },
            "272221": {
                "name": "羽曳野市",
                "childDataDict": {}
            },
            "272230": {
                "name": "門真市",
                "childDataDict": {}
            },
            "272248": {
                "name": "摂津市",
                "childDataDict": {}
            },
            "272256": {
                "name": "高石市",
                "childDataDict": {}
            },
            "272264": {
                "name": "藤井寺市",
                "childDataDict": {}
            },
            "272272": {
                "name": "東大阪市",
                "childDataDict": {}
            },
            "272281": {
                "name": "泉南市",
                "childDataDict": {}
            },
            "272299": {
                "name": "四條畷市",
                "childDataDict": {}
            },
            "272302": {
                "name": "交野市",
                "childDataDict": {}
            },
            "272311": {
                "name": "大阪狭山市",
                "childDataDict": {}
            },
            "272329": {
                "name": "阪南市",
                "childDataDict": {}
            },
            "273015": {
                "name": "島本町",
                "childDataDict": {}
            },
            "273210": {
                "name": "豊能町",
                "childDataDict": {}
            },
            "273228": {
                "name": "能勢町",
                "childDataDict": {}
            },
            "273414": {
                "name": "忠岡町",
                "childDataDict": {}
            },
            "273619": {
                "name": "熊取町",
                "childDataDict": {}
            },
            "273627": {
                "name": "田尻町",
                "childDataDict": {}
            },
            "273660": {
                "name": "岬町",
                "childDataDict": {}
            },
            "273813": {
                "name": "太子町",
                "childDataDict": {}
            },
            "273821": {
                "name": "河南町",
                "childDataDict": {}
            },
            "273830": {
                "name": "千早赤阪村",
                "childDataDict": {}
            }
        }
    },
    "280003": {
        "name": "兵庫県",
        "childDataDict": {
            "281000": {
                "name": "神戸市",
                "childDataDict": {
                    "281018": {
                        "name": "東灘区",
                        "childDataDict": {}
                    },
                    "281026": {
                        "name": "灘区",
                        "childDataDict": {}
                    },
                    "281051": {
                        "name": "兵庫区",
                        "childDataDict": {}
                    },
                    "281069": {
                        "name": "長田区",
                        "childDataDict": {}
                    },
                    "281077": {
                        "name": "須磨区",
                        "childDataDict": {}
                    },
                    "281085": {
                        "name": "垂水区",
                        "childDataDict": {}
                    },
                    "281093": {
                        "name": "北区",
                        "childDataDict": {}
                    },
                    "281107": {
                        "name": "中央区",
                        "childDataDict": {}
                    },
                    "281115": {
                        "name": "西区",
                        "childDataDict": {}
                    },
                }
            },
            "282014": {
                "name": "姫路市",
                "childDataDict": {}
            },
            "282022": {
                "name": "尼崎市",
                "childDataDict": {}
            },
            "282031": {
                "name": "明石市",
                "childDataDict": {}
            },
            "282049": {
                "name": "西宮市",
                "childDataDict": {}
            },
            "282057": {
                "name": "洲本市",
                "childDataDict": {}
            },
            "282065": {
                "name": "芦屋市",
                "childDataDict": {}
            },
            "282073": {
                "name": "伊丹市",
                "childDataDict": {}
            },
            "282081": {
                "name": "相生市",
                "childDataDict": {}
            },
            "282090": {
                "name": "豊岡市",
                "childDataDict": {}
            },
            "282103": {
                "name": "加古川市",
                "childDataDict": {}
            },
            "282120": {
                "name": "赤穂市",
                "childDataDict": {}
            },
            "282138": {
                "name": "西脇市",
                "childDataDict": {}
            },
            "282146": {
                "name": "宝塚市",
                "childDataDict": {}
            },
            "282154": {
                "name": "三木市",
                "childDataDict": {}
            },
            "282162": {
                "name": "高砂市",
                "childDataDict": {}
            },
            "282171": {
                "name": "川西市",
                "childDataDict": {}
            },
            "282189": {
                "name": "小野市",
                "childDataDict": {}
            },
            "282197": {
                "name": "三田市",
                "childDataDict": {}
            },
            "282201": {
                "name": "加西市",
                "childDataDict": {}
            },
            "282219": {
                "name": "丹波篠山市",
                "childDataDict": {}
            },
            "282227": {
                "name": "養父市",
                "childDataDict": {}
            },
            "282235": {
                "name": "丹波市",
                "childDataDict": {}
            },
            "282243": {
                "name": "南あわじ市",
                "childDataDict": {}
            },
            "282251": {
                "name": "朝来市",
                "childDataDict": {}
            },
            "282260": {
                "name": "淡路市",
                "childDataDict": {}
            },
            "282278": {
                "name": "宍粟市",
                "childDataDict": {}
            },
            "282286": {
                "name": "加東市",
                "childDataDict": {}
            },
            "282294": {
                "name": "たつの市",
                "childDataDict": {}
            },
            "283011": {
                "name": "猪名川町",
                "childDataDict": {}
            },
            "283657": {
                "name": "多可町",
                "childDataDict": {}
            },
            "283819": {
                "name": "稲美町",
                "childDataDict": {}
            },
            "283827": {
                "name": "播磨町",
                "childDataDict": {}
            },
            "284424": {
                "name": "市川町",
                "childDataDict": {}
            },
            "284432": {
                "name": "福崎町",
                "childDataDict": {}
            },
            "284467": {
                "name": "神河町",
                "childDataDict": {}
            },
            "284645": {
                "name": "太子町",
                "childDataDict": {}
            },
            "284815": {
                "name": "上郡町",
                "childDataDict": {}
            },
            "285013": {
                "name": "佐用町",
                "childDataDict": {}
            },
            "285854": {
                "name": "香美町",
                "childDataDict": {}
            },
            "285862": {
                "name": "新温泉町",
                "childDataDict": {}
            }
        }
    },
    "290009": {
        "name": "奈良県",
        "childDataDict": {
            "292010": {
                "name": "奈良市",
                "childDataDict": {}
            },
            "292028": {
                "name": "大和高田市",
                "childDataDict": {}
            },
            "292036": {
                "name": "大和郡山市",
                "childDataDict": {}
            },
            "292044": {
                "name": "天理市",
                "childDataDict": {}
            },
            "292052": {
                "name": "橿原市",
                "childDataDict": {}
            },
            "292061": {
                "name": "桜井市",
                "childDataDict": {}
            },
            "292079": {
                "name": "五條市",
                "childDataDict": {}
            },
            "292087": {
                "name": "御所市",
                "childDataDict": {}
            },
            "292095": {
                "name": "生駒市",
                "childDataDict": {}
            },
            "292109": {
                "name": "香芝市",
                "childDataDict": {}
            },
            "292117": {
                "name": "葛城市",
                "childDataDict": {}
            },
            "292125": {
                "name": "宇陀市",
                "childDataDict": {}
            },
            "293229": {
                "name": "山添村",
                "childDataDict": {}
            },
            "293423": {
                "name": "平群町",
                "childDataDict": {}
            },
            "293431": {
                "name": "三郷町",
                "childDataDict": {}
            },
            "293440": {
                "name": "斑鳩町",
                "childDataDict": {}
            },
            "293458": {
                "name": "安堵町",
                "childDataDict": {}
            },
            "293610": {
                "name": "川西町",
                "childDataDict": {}
            },
            "293628": {
                "name": "三宅町",
                "childDataDict": {}
            },
            "293636": {
                "name": "田原本町",
                "childDataDict": {}
            },
            "293857": {
                "name": "曽爾村",
                "childDataDict": {}
            },
            "293865": {
                "name": "御杖村",
                "childDataDict": {}
            },
            "294012": {
                "name": "高取町",
                "childDataDict": {}
            },
            "294021": {
                "name": "明日香村",
                "childDataDict": {}
            },
            "294241": {
                "name": "上牧町",
                "childDataDict": {}
            },
            "294250": {
                "name": "王寺町",
                "childDataDict": {}
            },
            "294268": {
                "name": "広陵町",
                "childDataDict": {}
            },
            "294276": {
                "name": "河合町",
                "childDataDict": {}
            },
            "294411": {
                "name": "吉野町",
                "childDataDict": {}
            },
            "294420": {
                "name": "大淀町",
                "childDataDict": {}
            },
            "294438": {
                "name": "下市町",
                "childDataDict": {}
            },
            "294446": {
                "name": "黒滝村",
                "childDataDict": {}
            },
            "294462": {
                "name": "天川村",
                "childDataDict": {}
            },
            "294471": {
                "name": "野迫川村",
                "childDataDict": {}
            },
            "294497": {
                "name": "十津川村",
                "childDataDict": {}
            },
            "294501": {
                "name": "下北山村",
                "childDataDict": {}
            },
            "294519": {
                "name": "上北山村",
                "childDataDict": {}
            },
            "294527": {
                "name": "川上村",
                "childDataDict": {}
            },
            "294535": {
                "name": "東吉野村",
                "childDataDict": {}
            }
        }
    },
    "300004": {
        "name": "和歌山県",
        "childDataDict": {
            "302015": {
                "name": "和歌山市",
                "childDataDict": {}
            },
            "302023": {
                "name": "海南市",
                "childDataDict": {}
            },
            "302031": {
                "name": "橋本市",
                "childDataDict": {}
            },
            "302040": {
                "name": "有田市",
                "childDataDict": {}
            },
            "302058": {
                "name": "御坊市",
                "childDataDict": {}
            },
            "302066": {
                "name": "田辺市",
                "childDataDict": {}
            },
            "302074": {
                "name": "新宮市",
                "childDataDict": {}
            },
            "302082": {
                "name": "紀の川市",
                "childDataDict": {}
            },
            "302091": {
                "name": "岩出市",
                "childDataDict": {}
            },
            "303046": {
                "name": "紀美野町",
                "childDataDict": {}
            },
            "303411": {
                "name": "かつらぎ町",
                "childDataDict": {}
            },
            "303437": {
                "name": "九度山町",
                "childDataDict": {}
            },
            "303445": {
                "name": "高野町",
                "childDataDict": {}
            },
            "303615": {
                "name": "湯浅町",
                "childDataDict": {}
            },
            "303623": {
                "name": "広川町",
                "childDataDict": {}
            },
            "303666": {
                "name": "有田川町",
                "childDataDict": {}
            },
            "303810": {
                "name": "美浜町",
                "childDataDict": {}
            },
            "303828": {
                "name": "日高町",
                "childDataDict": {}
            },
            "303836": {
                "name": "由良町",
                "childDataDict": {}
            },
            "303909": {
                "name": "印南町",
                "childDataDict": {}
            },
            "303917": {
                "name": "みなべ町",
                "childDataDict": {}
            },
            "303925": {
                "name": "日高川町",
                "childDataDict": {}
            },
            "304018": {
                "name": "白浜町",
                "childDataDict": {}
            },
            "304042": {
                "name": "上富田町",
                "childDataDict": {}
            },
            "304069": {
                "name": "すさみ町",
                "childDataDict": {}
            },
            "304212": {
                "name": "那智勝浦町",
                "childDataDict": {}
            },
            "304221": {
                "name": "太地町",
                "childDataDict": {}
            },
            "304247": {
                "name": "古座川町",
                "childDataDict": {}
            },
            "304271": {
                "name": "北山村",
                "childDataDict": {}
            },
            "304280": {
                "name": "串本町",
                "childDataDict": {}
            }
        }
    },
    "310000":{
        "name": "鳥取県",
        "childDataDict": {
            "312011": {
                "name": "鳥取市",
                "childDataDict": {}
            },
            "312029": {
                "name": "米子市",
                "childDataDict": {}
            },
            "312037": {
                "name": "倉吉市",
                "childDataDict": {}
            },
            "312045": {
                "name": "境港市",
                "childDataDict": {}
            },
            "313025": {
                "name": "岩美町",
                "childDataDict": {}
            },
            "313254": {
                "name": "若桜町",
                "childDataDict": {}
            },
            "313289": {
                "name": "智頭町",
                "childDataDict": {}
            },
            "313297": {
                "name": "八頭町",
                "childDataDict": {}
            },
            "313645": {
                "name": "三朝町",
                "childDataDict": {}
            },
            "313700": {
                "name": "湯梨浜町",
                "childDataDict": {}
            },
            "313718": {
                "name": "琴浦町",
                "childDataDict": {}
            },
            "313726": {
                "name": "北栄町",
                "childDataDict": {}
            },
            "313840": {
                "name": "日吉津村",
                "childDataDict": {}
            },
            "313866": {
                "name": "大山町",
                "childDataDict": {}
            },
            "313891": {
                "name": "南部町",
                "childDataDict": {}
            },
            "313904": {
                "name": "伯耆町",
                "childDataDict": {}
            },
            "314013": {
                "name": "日南町",
                "childDataDict": {}
            },
            "314021": {
                "name": "日野町",
                "childDataDict": {}
            },
            "314030": {
                "name": "江府町",
                "childDataDict": {}
            }
        }
    },
    "320005": {
        "name": "島根県",
        "childDataDict": {
            "322016": {
                "name": "松江市",
                "childDataDict": {}
            },
            "322024": {
                "name": "浜田市",
                "childDataDict": {}
            },
            "322032": {
                "name": "出雲市",
                "childDataDict": {}
            },
            "322041": {
                "name": "益田市",
                "childDataDict": {}
            },
            "322059": {
                "name": "大田市",
                "childDataDict": {}
            },
            "322067": {
                "name": "安来市",
                "childDataDict": {}
            },
            "322075": {
                "name": "江津市",
                "childDataDict": {}
            },
            "322091": {
                "name": "雲南市",
                "childDataDict": {}
            },
            "323438": {
                "name": "奥出雲町",
                "childDataDict": {}
            },
            "323861": {
                "name": "飯南町",
                "childDataDict": {}
            },
            "324418": {
                "name": "川本町",
                "childDataDict": {}
            },
            "324485": {
                "name": "美郷町",
                "childDataDict": {}
            },
            "324493": {
                "name": "邑南町",
                "childDataDict": {}
            },
            "325015": {
                "name": "津和野町",
                "childDataDict": {}
            },
            "325058": {
                "name": "吉賀町",
                "childDataDict": {}
            },
            "325252": {
                "name": "海士町",
                "childDataDict": {}
            },
            "325261": {
                "name": "西ノ島町",
                "childDataDict": {}
            },
            "325279": {
                "name": "知夫村",
                "childDataDict": {}
            },
            "325287": {
                "name": "隠岐の島町",
                "childDataDict": {}
            }
        }
    },
    "330001": {
        "name": "岡山県",
        "childDataDict": {
            "331007": {
                "name": "岡山市",
                "childDataDict": {
                    "331015": {
                        "name": "北区",
                        "childDataDict": {}
                    },
                    "331023": {
                        "name": "中区",
                        "childDataDict": {}
                    },
                    "331031": {
                        "name": "東区",
                        "childDataDict": {}
                    },
                    "331040": {
                        "name": "南区",
                        "childDataDict": {}
                    },
                }
            },
            "332020": {
                "name": "倉敷市",
                "childDataDict": {}
            },
            "332038": {
                "name": "津山市",
                "childDataDict": {}
            },
            "332046": {
                "name": "玉野市",
                "childDataDict": {}
            },
            "332054": {
                "name": "笠岡市",
                "childDataDict": {}
            },
            "332071": {
                "name": "井原市",
                "childDataDict": {}
            },
            "332089": {
                "name": "総社市",
                "childDataDict": {}
            },
            "332097": {
                "name": "高梁市",
                "childDataDict": {}
            },
            "332101": {
                "name": "新見市",
                "childDataDict": {}
            },
            "332119": {
                "name": "備前市",
                "childDataDict": {}
            },
            "332127": {
                "name": "瀬戸内市",
                "childDataDict": {}
            },
            "332135": {
                "name": "赤磐市",
                "childDataDict": {}
            },
            "332143": {
                "name": "真庭市",
                "childDataDict": {}
            },
            "332151": {
                "name": "美作市",
                "childDataDict": {}
            },
            "332160": {
                "name": "浅口市",
                "childDataDict": {}
            },
            "333468": {
                "name": "和気町",
                "childDataDict": {}
            },
            "334235": {
                "name": "早島町",
                "childDataDict": {}
            },
            "334456": {
                "name": "里庄町",
                "childDataDict": {}
            },
            "334618": {
                "name": "矢掛町",
                "childDataDict": {}
            },
            "335860": {
                "name": "新庄村",
                "childDataDict": {}
            },
            "336068": {
                "name": "鏡野町",
                "childDataDict": {}
            },
            "336220": {
                "name": "勝央町",
                "childDataDict": {}
            },
            "336238": {
                "name": "奈義町",
                "childDataDict": {}
            },
            "336432": {
                "name": "西粟倉村",
                "childDataDict": {}
            },
            "336637": {
                "name": "久米南町",
                "childDataDict": {}
            },
            "336661": {
                "name": "美咲町",
                "childDataDict": {}
            },
            "336815": {
                "name": "吉備中央町",
                "childDataDict": {}
            }
        }
    },
    "340006": {
        "name": "広島県",
        "childDataDict": {
            "341002": {
                "name": "広島市",
                "childDataDict": {
                    "341011": {
                        "name": "中区",
                        "childDataDict": {}
                    },
                    "341029": {
                        "name": "東区",
                        "childDataDict": {}
                    },
                    "341037": {
                        "name": "南区",
                        "childDataDict": {}
                    },
                    "341045": {
                        "name": "西区",
                        "childDataDict": {}
                    },
                    "341053": {
                        "name": "安佐南区",
                        "childDataDict": {}
                    },
                    "341061": {
                        "name": "安佐北区",
                        "childDataDict": {}
                    },
                    "341070": {
                        "name": "安芸区",
                        "childDataDict": {}
                    },
                    "341088": {
                        "name": "佐伯区",
                        "childDataDict": {}
                    },
                }
            },
            "342025": {
                "name": "呉市",
                "childDataDict": {}
            },
            "342033": {
                "name": "竹原市",
                "childDataDict": {}
            },
            "342041": {
                "name": "三原市",
                "childDataDict": {}
            },
            "342050": {
                "name": "尾道市",
                "childDataDict": {}
            },
            "342076": {
                "name": "福山市",
                "childDataDict": {}
            },
            "342084": {
                "name": "府中市",
                "childDataDict": {}
            },
            "342092": {
                "name": "三次市",
                "childDataDict": {}
            },
            "342106": {
                "name": "庄原市",
                "childDataDict": {}
            },
            "342114": {
                "name": "大竹市",
                "childDataDict": {}
            },
            "342122": {
                "name": "東広島市",
                "childDataDict": {}
            },
            "342131": {
                "name": "廿日市市",
                "childDataDict": {}
            },
            "342149": {
                "name": "安芸高田市",
                "childDataDict": {}
            },
            "342157": {
                "name": "江田島市",
                "childDataDict": {}
            },
            "343021": {
                "name": "府中町",
                "childDataDict": {}
            },
            "343048": {
                "name": "海田町",
                "childDataDict": {}
            },
            "343072": {
                "name": "熊野町",
                "childDataDict": {}
            },
            "343099": {
                "name": "坂町",
                "childDataDict": {}
            },
            "343684": {
                "name": "安芸太田町",
                "childDataDict": {}
            },
            "343692": {
                "name": "北広島町",
                "childDataDict": {}
            },
            "344311": {
                "name": "大崎上島町",
                "childDataDict": {}
            },
            "344621": {
                "name": "世羅町",
                "childDataDict": {}
            },
            "345458": {
                "name": "神石高原町",
                "childDataDict": {}
            }
        }
    },
    "350001": {
        "name": "山口県",
        "childDataDict": {
            "352012": {
                "name": "下関市",
                "childDataDict": {}
            },
            "352021": {
                "name": "宇部市",
                "childDataDict": {}
            },
            "352039": {
                "name": "山口市",
                "childDataDict": {}
            },
            "352047": {
                "name": "萩市",
                "childDataDict": {}
            },
            "352063": {
                "name": "防府市",
                "childDataDict": {}
            },
            "352071": {
                "name": "下松市",
                "childDataDict": {}
            },
            "352080": {
                "name": "岩国市",
                "childDataDict": {}
            },
            "352101": {
                "name": "光市",
                "childDataDict": {}
            },
            "352110": {
                "name": "長門市",
                "childDataDict": {}
            },
            "352128": {
                "name": "柳井市",
                "childDataDict": {}
            },
            "352136": {
                "name": "美祢市",
                "childDataDict": {}
            },
            "352152": {
                "name": "周南市",
                "childDataDict": {}
            },
            "352161": {
                "name": "山陽小野田市",
                "childDataDict": {}
            },
            "353051": {
                "name": "周防大島町",
                "childDataDict": {}
            },
            "353213": {
                "name": "和木町",
                "childDataDict": {}
            },
            "353418": {
                "name": "上関町",
                "childDataDict": {}
            },
            "353434": {
                "name": "田布施町",
                "childDataDict": {}
            },
            "353442": {
                "name": "平生町",
                "childDataDict": {}
            },
            "355020": {
                "name": "阿武町",
                "childDataDict": {}
            }
        }
    },
    "360007": {
        "name": "徳島県",
        "childDataDict": {
            "362018": {
                "name": "徳島市",
                "childDataDict": {}
            },
            "362026": {
                "name": "鳴門市",
                "childDataDict": {}
            },
            "362034": {
                "name": "小松島市",
                "childDataDict": {}
            },
            "362042": {
                "name": "阿南市",
                "childDataDict": {}
            },
            "362051": {
                "name": "吉野川市",
                "childDataDict": {}
            },
            "362069": {
                "name": "阿波市",
                "childDataDict": {}
            },
            "362077": {
                "name": "美馬市",
                "childDataDict": {}
            },
            "362085": {
                "name": "三好市",
                "childDataDict": {}
            },
            "363014": {
                "name": "勝浦町",
                "childDataDict": {}
            },
            "363022": {
                "name": "上勝町",
                "childDataDict": {}
            },
            "363219": {
                "name": "佐那河内村",
                "childDataDict": {}
            },
            "363413": {
                "name": "石井町",
                "childDataDict": {}
            },
            "363421": {
                "name": "神山町",
                "childDataDict": {}
            },
            "363685": {
                "name": "那賀町",
                "childDataDict": {}
            },
            "363839": {
                "name": "牟岐町",
                "childDataDict": {}
            },
            "363871": {
                "name": "美波町",
                "childDataDict": {}
            },
            "363880": {
                "name": "海陽町",
                "childDataDict": {}
            },
            "364011": {
                "name": "松茂町",
                "childDataDict": {}
            },
            "364029": {
                "name": "北島町",
                "childDataDict": {}
            },
            "364037": {
                "name": "藍住町",
                "childDataDict": {}
            },
            "364045": {
                "name": "板野町",
                "childDataDict": {}
            },
            "364053": {
                "name": "上板町",
                "childDataDict": {}
            },
            "364681": {
                "name": "つるぎ町",
                "childDataDict": {}
            },
            "364894": {
                "name": "東みよし町",
                "childDataDict": {}
            }
        }
    },
    "370002": {
        "name": "香川県",
        "childDataDict": {
            "372013": {
                "name": "高松市",
                "childDataDict": {}
            },
            "372021": {
                "name": "丸亀市",
                "childDataDict": {}
            },
            "372030": {
                "name": "坂出市",
                "childDataDict": {}
            },
            "372048": {
                "name": "善通寺市",
                "childDataDict": {}
            },
            "372056": {
                "name": "観音寺市",
                "childDataDict": {}
            },
            "372064": {
                "name": "さぬき市",
                "childDataDict": {}
            },
            "372072": {
                "name": "東かがわ市",
                "childDataDict": {}
            },
            "372081": {
                "name": "三豊市",
                "childDataDict": {}
            },
            "373222": {
                "name": "土庄町",
                "childDataDict": {}
            },
            "373249": {
                "name": "小豆島町",
                "childDataDict": {}
            },
            "373419": {
                "name": "三木町",
                "childDataDict": {}
            },
            "373648": {
                "name": "直島町",
                "childDataDict": {}
            },
            "373869": {
                "name": "宇多津町",
                "childDataDict": {}
            },
            "373877": {
                "name": "綾川町",
                "childDataDict": {}
            },
            "374032": {
                "name": "琴平町",
                "childDataDict": {}
            },
            "374041": {
                "name": "多度津町",
                "childDataDict": {}
            },
            "374067": {
                "name": "まんのう町",
                "childDataDict": {}
            }
        }
    },
    "380008": {
        "name": "愛媛県",
        "childDataDict": {
            "382019": {
                "name": "松山市",
                "childDataDict": {}
            },
            "382027": {
                "name": "今治市",
                "childDataDict": {}
            },
            "382035": {
                "name": "宇和島市",
                "childDataDict": {}
            },
            "382043": {
                "name": "八幡浜市",
                "childDataDict": {}
            },
            "382051": {
                "name": "新居浜市",
                "childDataDict": {}
            },
            "382060": {
                "name": "西条市",
                "childDataDict": {}
            },
            "382078": {
                "name": "大洲市",
                "childDataDict": {}
            },
            "382108": {
                "name": "伊予市",
                "childDataDict": {}
            },
            "382132": {
                "name": "四国中央市",
                "childDataDict": {}
            },
            "382141": {
                "name": "西予市",
                "childDataDict": {}
            },
            "382159": {
                "name": "東温市",
                "childDataDict": {}
            },
            "383562": {
                "name": "上島町",
                "childDataDict": {}
            },
            "383864": {
                "name": "久万高原町",
                "childDataDict": {}
            },
            "384011": {
                "name": "松前町",
                "childDataDict": {}
            },
            "384020": {
                "name": "砥部町",
                "childDataDict": {}
            },
            "384224": {
                "name": "内子町",
                "childDataDict": {}
            },
            "384429": {
                "name": "伊方町",
                "childDataDict": {}
            },
            "384844": {
                "name": "松野町",
                "childDataDict": {}
            },
            "384887": {
                "name": "鬼北町",
                "childDataDict": {}
            },
            "385069": {
                "name": "愛南町",
                "childDataDict": {}
            }
        }
    },
    "390003": {
        "name": "高知県",
        "childDataDict": {
            "392014": {
                "name": "高知市",
                "childDataDict": {}
            },
            "392022": {
                "name": "室戸市",
                "childDataDict": {}
            },
            "392031": {
                "name": "安芸市",
                "childDataDict": {}
            },
            "392049": {
                "name": "南国市",
                "childDataDict": {}
            },
            "392057": {
                "name": "土佐市",
                "childDataDict": {}
            },
            "392065": {
                "name": "須崎市",
                "childDataDict": {}
            },
            "392081": {
                "name": "宿毛市",
                "childDataDict": {}
            },
            "392090": {
                "name": "土佐清水市",
                "childDataDict": {}
            },
            "392103": {
                "name": "四万十市",
                "childDataDict": {}
            },
            "392111": {
                "name": "香南市",
                "childDataDict": {}
            },
            "392120": {
                "name": "香美市",
                "childDataDict": {}
            },
            "393011": {
                "name": "東洋町",
                "childDataDict": {}
            },
            "393029": {
                "name": "奈半利町",
                "childDataDict": {}
            },
            "393037": {
                "name": "田野町",
                "childDataDict": {}
            },
            "393045": {
                "name": "安田町",
                "childDataDict": {}
            },
            "393053": {
                "name": "北川村",
                "childDataDict": {}
            },
            "393061": {
                "name": "馬路村",
                "childDataDict": {}
            },
            "393070": {
                "name": "芸西村",
                "childDataDict": {}
            },
            "393410": {
                "name": "本山町",
                "childDataDict": {}
            },
            "393444": {
                "name": "大豊町",
                "childDataDict": {}
            },
            "393631": {
                "name": "土佐町",
                "childDataDict": {}
            },
            "393649": {
                "name": "大川村",
                "childDataDict": {}
            },
            "393860": {
                "name": "いの町",
                "childDataDict": {}
            },
            "393878": {
                "name": "仁淀川町",
                "childDataDict": {}
            },
            "394017": {
                "name": "中土佐町",
                "childDataDict": {}
            },
            "394025": {
                "name": "佐川町",
                "childDataDict": {}
            },
            "394033": {
                "name": "越知町",
                "childDataDict": {}
            },
            "394050": {
                "name": "梼原町",
                "childDataDict": {}
            },
            "394106": {
                "name": "日高村",
                "childDataDict": {}
            },
            "394114": {
                "name": "津野町",
                "childDataDict": {}
            },
            "394122": {
                "name": "四万十町",
                "childDataDict": {}
            },
            "394246": {
                "name": "大月町",
                "childDataDict": {}
            },
            "394271": {
                "name": "三原村",
                "childDataDict": {}
            },
            "394289": {
                "name": "黒潮町",
                "childDataDict": {}
            }
        }
    },
    "400009": {
        "name": "福岡県",
        "childDataDict": {
            "401005": {
                "name": "北九州市",
                "childDataDict": {
                    "401013": {
                        "name": "門司区",
                        "childDataDict": {}
                    },
                    "401030": {
                        "name": "若松区",
                        "childDataDict": {}
                    },
                    "401056": {
                        "name": "戸畑区",
                        "childDataDict": {}
                    },
                    "401064": {
                        "name": "小倉北区",
                        "childDataDict": {}
                    },
                    "401072": {
                        "name": "小倉南区",
                        "childDataDict": {}
                    },
                    "401081": {
                        "name": "八幡東区",
                        "childDataDict": {}
                    },
                    "401099": {
                        "name": "八幡西区",
                        "childDataDict": {}
                    },
                }
            },
            "401307": {
                "name": "福岡市",
                "childDataDict": {
                    "401315": {
                        "name": "東区",
                        "childDataDict": {}
                    },
                    "401323": {
                        "name": "博多区",
                        "childDataDict": {}
                    },
                    "401331": {
                        "name": "中央区",
                        "childDataDict": {}
                    },
                    "401340": {
                        "name": "南区",
                        "childDataDict": {}
                    },
                    "401358": {
                        "name": "西区",
                        "childDataDict": {}
                    },
                    "401366": {
                        "name": "城南区",
                        "childDataDict": {}
                    },
                    "401374": {
                        "name": "早良区",
                        "childDataDict": {}
                    },
                }
            },
            "402028": {
                "name": "大牟田市",
                "childDataDict": {}
            },
            "402036": {
                "name": "久留米市",
                "childDataDict": {}
            },
            "402044": {
                "name": "直方市",
                "childDataDict": {}
            },
            "402052": {
                "name": "飯塚市",
                "childDataDict": {}
            },
            "402061": {
                "name": "田川市",
                "childDataDict": {}
            },
            "402079": {
                "name": "柳川市",
                "childDataDict": {}
            },
            "402109": {
                "name": "八女市",
                "childDataDict": {}
            },
            "402117": {
                "name": "筑後市",
                "childDataDict": {}
            },
            "402125": {
                "name": "大川市",
                "childDataDict": {}
            },
            "402133": {
                "name": "行橋市",
                "childDataDict": {}
            },
            "402141": {
                "name": "豊前市",
                "childDataDict": {}
            },
            "402150": {
                "name": "中間市",
                "childDataDict": {}
            },
            "402168": {
                "name": "小郡市",
                "childDataDict": {}
            },
            "402176": {
                "name": "筑紫野市",
                "childDataDict": {}
            },
            "402184": {
                "name": "春日市",
                "childDataDict": {}
            },
            "402192": {
                "name": "大野城市",
                "childDataDict": {}
            },
            "402206": {
                "name": "宗像市",
                "childDataDict": {}
            },
            "402214": {
                "name": "太宰府市",
                "childDataDict": {}
            },
            "402231": {
                "name": "古賀市",
                "childDataDict": {}
            },
            "402249": {
                "name": "福津市",
                "childDataDict": {}
            },
            "402257": {
                "name": "うきは市",
                "childDataDict": {}
            },
            "402265": {
                "name": "宮若市",
                "childDataDict": {}
            },
            "402273": {
                "name": "嘉麻市",
                "childDataDict": {}
            },
            "402281": {
                "name": "朝倉市",
                "childDataDict": {}
            },
            "402290": {
                "name": "みやま市",
                "childDataDict": {}
            },
            "402303": {
                "name": "糸島市",
                "childDataDict": {}
            },
            "402311": {
                "name": "那珂川市",
                "childDataDict": {}
            },
            "403415": {
                "name": "宇美町",
                "childDataDict": {}
            },
            "403423": {
                "name": "篠栗町",
                "childDataDict": {}
            },
            "403431": {
                "name": "志免町",
                "childDataDict": {}
            },
            "403440": {
                "name": "須恵町",
                "childDataDict": {}
            },
            "403458": {
                "name": "新宮町",
                "childDataDict": {}
            },
            "403482": {
                "name": "久山町",
                "childDataDict": {}
            },
            "403491": {
                "name": "粕屋町",
                "childDataDict": {}
            },
            "403814": {
                "name": "芦屋町",
                "childDataDict": {}
            },
            "403822": {
                "name": "水巻町",
                "childDataDict": {}
            },
            "403831": {
                "name": "岡垣町",
                "childDataDict": {}
            },
            "403849": {
                "name": "遠賀町",
                "childDataDict": {}
            },
            "404012": {
                "name": "小竹町",
                "childDataDict": {}
            },
            "404021": {
                "name": "鞍手町",
                "childDataDict": {}
            },
            "404217": {
                "name": "桂川町",
                "childDataDict": {}
            },
            "404471": {
                "name": "筑前町",
                "childDataDict": {}
            },
            "404489": {
                "name": "東峰村",
                "childDataDict": {}
            },
            "405035": {
                "name": "大刀洗町",
                "childDataDict": {}
            },
            "405221": {
                "name": "大木町",
                "childDataDict": {}
            },
            "405442": {
                "name": "広川町",
                "childDataDict": {}
            },
            "406015": {
                "name": "香春町",
                "childDataDict": {}
            },
            "406023": {
                "name": "添田町",
                "childDataDict": {}
            },
            "406040": {
                "name": "糸田町",
                "childDataDict": {}
            },
            "406058": {
                "name": "川崎町",
                "childDataDict": {}
            },
            "406082": {
                "name": "大任町",
                "childDataDict": {}
            },
            "406091": {
                "name": "赤村",
                "childDataDict": {}
            },
            "406104": {
                "name": "福智町",
                "childDataDict": {}
            },
            "406210": {
                "name": "苅田町",
                "childDataDict": {}
            },
            "406252": {
                "name": "みやこ町",
                "childDataDict": {}
            },
            "406422": {
                "name": "吉富町",
                "childDataDict": {}
            },
            "406465": {
                "name": "上毛町",
                "childDataDict": {}
            },
            "406473": {
                "name": "築上町",
                "childDataDict": {}
            }
        }
    },
    "410004": {
        "name": "佐賀県",
        "childDataDict": {
            "412015": {
                "name": "佐賀市",
                "childDataDict": {}
            },
            "412023": {
                "name": "唐津市",
                "childDataDict": {}
            },
            "412031": {
                "name": "鳥栖市",
                "childDataDict": {}
            },
            "412040": {
                "name": "多久市",
                "childDataDict": {}
            },
            "412058": {
                "name": "伊万里市",
                "childDataDict": {}
            },
            "412066": {
                "name": "武雄市",
                "childDataDict": {}
            },
            "412074": {
                "name": "鹿島市",
                "childDataDict": {}
            },
            "412082": {
                "name": "小城市",
                "childDataDict": {}
            },
            "412091": {
                "name": "嬉野市",
                "childDataDict": {}
            },
            "412104": {
                "name": "神埼市",
                "childDataDict": {}
            },
            "413275": {
                "name": "吉野ヶ里町",
                "childDataDict": {}
            },
            "413411": {
                "name": "基山町",
                "childDataDict": {}
            },
            "413453": {
                "name": "上峰町",
                "childDataDict": {}
            },
            "413461": {
                "name": "みやき町",
                "childDataDict": {}
            },
            "413879": {
                "name": "玄海町",
                "childDataDict": {}
            },
            "414018": {
                "name": "有田町",
                "childDataDict": {}
            },
            "414239": {
                "name": "大町町",
                "childDataDict": {}
            },
            "414247": {
                "name": "江北町",
                "childDataDict": {}
            },
            "414255": {
                "name": "白石町",
                "childDataDict": {}
            },
            "414417": {
                "name": "太良町",
                "childDataDict": {}
            }
        }
    },
    "420000": {
        "name": "長崎県",
        "childDataDict": {
            "422011": {
                "name": "長崎市",
                "childDataDict": {}
            },
            "422029": {
                "name": "佐世保市",
                "childDataDict": {}
            },
            "422037": {
                "name": "島原市",
                "childDataDict": {}
            },
            "422045": {
                "name": "諫早市",
                "childDataDict": {}
            },
            "422053": {
                "name": "大村市",
                "childDataDict": {}
            },
            "422070": {
                "name": "平戸市",
                "childDataDict": {}
            },
            "422088": {
                "name": "松浦市",
                "childDataDict": {}
            },
            "422096": {
                "name": "対馬市",
                "childDataDict": {}
            },
            "422100": {
                "name": "壱岐市",
                "childDataDict": {}
            },
            "422118": {
                "name": "五島市",
                "childDataDict": {}
            },
            "422126": {
                "name": "西海市",
                "childDataDict": {}
            },
            "422134": {
                "name": "雲仙市",
                "childDataDict": {}
            },
            "422142": {
                "name": "南島原市",
                "childDataDict": {}
            },
            "423076": {
                "name": "長与町",
                "childDataDict": {}
            },
            "423084": {
                "name": "時津町",
                "childDataDict": {}
            },
            "423211": {
                "name": "東彼杵町",
                "childDataDict": {}
            },
            "423220": {
                "name": "川棚町",
                "childDataDict": {}
            },
            "423238": {
                "name": "波佐見町",
                "childDataDict": {}
            },
            "423831": {
                "name": "小値賀町",
                "childDataDict": {}
            },
            "423912": {
                "name": "佐々町",
                "childDataDict": {}
            },
            "424111": {
                "name": "新上五島町",
                "childDataDict": {}
            }
        }
    },
    "430005":{
        "name": "熊本県",
        "childDataDict": {
            "431001": {
                "name": "熊本市",
                "childDataDict": {
                    "431010": {
                        "name": "中央区",
                        "childDataDict": {}
                    },  
                    "431028": {
                        "name": "東区",
                        "childDataDict": {}
                    },  
                    "431036": {
                        "name": "西区",
                        "childDataDict": {}
                    },  
                    "431044": {
                        "name": "南区",
                        "childDataDict": {}
                    },  
                    "431052": {
                        "name": "北区",
                        "childDataDict": {}
                    },  
                }
            },
            "432024": {
                "name": "八代市",
                "childDataDict": {}
            },
            "432032": {
                "name": "人吉市",
                "childDataDict": {}
            },
            "432041": {
                "name": "荒尾市",
                "childDataDict": {}
            },
            "432059": {
                "name": "水俣市",
                "childDataDict": {}
            },
            "432067": {
                "name": "玉名市",
                "childDataDict": {}
            },
            "432083": {
                "name": "山鹿市",
                "childDataDict": {}
            },
            "432105": {
                "name": "菊池市",
                "childDataDict": {}
            },
            "432113": {
                "name": "宇土市",
                "childDataDict": {}
            },
            "432121": {
                "name": "上天草市",
                "childDataDict": {}
            },
            "432130": {
                "name": "宇城市",
                "childDataDict": {}
            },
            "432148": {
                "name": "阿蘇市",
                "childDataDict": {}
            },
            "432156": {
                "name": "天草市",
                "childDataDict": {}
            },
            "432164": {
                "name": "合志市",
                "childDataDict": {}
            },
            "433489": {
                "name": "美里町",
                "childDataDict": {}
            },
            "433641": {
                "name": "玉東町",
                "childDataDict": {}
            },
            "433675": {
                "name": "南関町",
                "childDataDict": {}
            },
            "433683": {
                "name": "長洲町",
                "childDataDict": {}
            },
            "433691": {
                "name": "和水町",
                "childDataDict": {}
            },
            "434035": {
                "name": "大津町",
                "childDataDict": {}
            },
            "434043": {
                "name": "菊陽町",
                "childDataDict": {}
            },
            "434230": {
                "name": "南小国町",
                "childDataDict": {}
            },
            "434248": {
                "name": "小国町",
                "childDataDict": {}
            },
            "434256": {
                "name": "産山村",
                "childDataDict": {}
            },
            "434281": {
                "name": "高森町",
                "childDataDict": {}
            },
            "434329": {
                "name": "西原村",
                "childDataDict": {}
            },
            "434337": {
                "name": "南阿蘇村",
                "childDataDict": {}
            },
            "434418": {
                "name": "御船町",
                "childDataDict": {}
            },
            "434426": {
                "name": "嘉島町",
                "childDataDict": {}
            },
            "434434": {
                "name": "益城町",
                "childDataDict": {}
            },
            "434442": {
                "name": "甲佐町",
                "childDataDict": {}
            },
            "434477": {
                "name": "山都町",
                "childDataDict": {}
            },
            "434680": {
                "name": "氷川町",
                "childDataDict": {}
            },
            "434825": {
                "name": "芦北町",
                "childDataDict": {}
            },
            "434841": {
                "name": "津奈木町",
                "childDataDict": {}
            },
            "435015": {
                "name": "錦町",
                "childDataDict": {}
            },
            "435058": {
                "name": "多良木町",
                "childDataDict": {}
            },
            "435066": {
                "name": "湯前町",
                "childDataDict": {}
            },
            "435074": {
                "name": "水上村",
                "childDataDict": {}
            },
            "435104": {
                "name": "相良村",
                "childDataDict": {}
            },
            "435112": {
                "name": "五木村",
                "childDataDict": {}
            },
            "435121": {
                "name": "山江村",
                "childDataDict": {}
            },
            "435139": {
                "name": "球磨村",
                "childDataDict": {}
            },
            "435147": {
                "name": "あさぎり町",
                "childDataDict": {}
            },
            "435317": {
                "name": "苓北町",
                "childDataDict": {}
            }
        }
    },
    "440001":{
        "name": "大分県",
        "childDataDict": {
            "442011": {
                "name": "大分市",
                "childDataDict": {}
            },
            "442020": {
                "name": "別府市",
                "childDataDict": {}
            },
            "442038": {
                "name": "中津市",
                "childDataDict": {}
            },
            "442046": {
                "name": "日田市",
                "childDataDict": {}
            },
            "442054": {
                "name": "佐伯市",
                "childDataDict": {}
            },
            "442062": {
                "name": "臼杵市",
                "childDataDict": {}
            },
            "442071": {
                "name": "津久見市",
                "childDataDict": {}
            },
            "442089": {
                "name": "竹田市",
                "childDataDict": {}
            },
            "442097": {
                "name": "豊後高田市",
                "childDataDict": {}
            },
            "442101": {
                "name": "杵築市",
                "childDataDict": {}
            },
            "442119": {
                "name": "宇佐市",
                "childDataDict": {}
            },
            "442127": {
                "name": "豊後大野市",
                "childDataDict": {}
            },
            "442135": {
                "name": "由布市",
                "childDataDict": {}
            },
            "442143": {
                "name": "国東市",
                "childDataDict": {}
            },
            "443221": {
                "name": "姫島村",
                "childDataDict": {}
            },
            "443417": {
                "name": "日出町",
                "childDataDict": {}
            },
            "444618": {
                "name": "九重町",
                "childDataDict": {}
            },
            "444626": {
                "name": "玖珠町",
                "childDataDict": {}
            }
        }
    },
    "450006": {
        "name": "宮崎県",
        "childDataDict": {
            "452017": {
                "name": "宮崎市",
                "childDataDict": {}
            },
            "452025": {
                "name": "都城市",
                "childDataDict": {}
            },
            "452033": {
                "name": "延岡市",
                "childDataDict": {}
            },
            "452041": {
                "name": "日南市",
                "childDataDict": {}
            },
            "452050": {
                "name": "小林市",
                "childDataDict": {}
            },
            "452068": {
                "name": "日向市",
                "childDataDict": {}
            },
            "452076": {
                "name": "串間市",
                "childDataDict": {}
            },
            "452084": {
                "name": "西都市",
                "childDataDict": {}
            },
            "452092": {
                "name": "えびの市",
                "childDataDict": {}
            },
            "453412": {
                "name": "三股町",
                "childDataDict": {}
            },
            "453617": {
                "name": "高原町",
                "childDataDict": {}
            },
            "453820": {
                "name": "国富町",
                "childDataDict": {}
            },
            "453838": {
                "name": "綾町",
                "childDataDict": {}
            },
            "454010": {
                "name": "高鍋町",
                "childDataDict": {}
            },
            "454028": {
                "name": "新富町",
                "childDataDict": {}
            },
            "454036": {
                "name": "西米良村",
                "childDataDict": {}
            },
            "454044": {
                "name": "木城町",
                "childDataDict": {}
            },
            "454052": {
                "name": "川南町",
                "childDataDict": {}
            },
            "454061": {
                "name": "都農町",
                "childDataDict": {}
            },
            "454214": {
                "name": "門川町",
                "childDataDict": {}
            },
            "454290": {
                "name": "諸塚村",
                "childDataDict": {}
            },
            "454303": {
                "name": "椎葉村",
                "childDataDict": {}
            },
            "454311": {
                "name": "美郷町",
                "childDataDict": {}
            },
            "454419": {
                "name": "高千穂町",
                "childDataDict": {}
            },
            "454427": {
                "name": "日之影町",
                "childDataDict": {}
            },
            "454435": {
                "name": "五ヶ瀬町",
                "childDataDict": {}
            }
        }
    },
    "460001":{
        "name": "鹿児島県",
        "childDataDict": {
            "462012": {
                "name": "鹿児島市",
                "childDataDict": {}
            },
            "462039": {
                "name": "鹿屋市",
                "childDataDict": {}
            },
            "462047": {
                "name": "枕崎市",
                "childDataDict": {}
            },
            "462063": {
                "name": "阿久根市",
                "childDataDict": {}
            },
            "462080": {
                "name": "出水市",
                "childDataDict": {}
            },
            "462101": {
                "name": "指宿市",
                "childDataDict": {}
            },
            "462136": {
                "name": "西之表市",
                "childDataDict": {}
            },
            "462144": {
                "name": "垂水市",
                "childDataDict": {}
            },
            "462152": {
                "name": "薩摩川内市",
                "childDataDict": {}
            },
            "462161": {
                "name": "日置市",
                "childDataDict": {}
            },
            "462179": {
                "name": "曽於市",
                "childDataDict": {}
            },
            "462187": {
                "name": "霧島市",
                "childDataDict": {}
            },
            "462195": {
                "name": "いちき串木野市",
                "childDataDict": {}
            },
            "462209": {
                "name": "南さつま市",
                "childDataDict": {}
            },
            "462217": {
                "name": "志布志市",
                "childDataDict": {}
            },
            "462225": {
                "name": "奄美市",
                "childDataDict": {}
            },
            "462233": {
                "name": "南九州市",
                "childDataDict": {}
            },
            "462241": {
                "name": "伊佐市",
                "childDataDict": {}
            },
            "462250": {
                "name": "姶良市",
                "childDataDict": {}
            },
            "463035": {
                "name": "三島村",
                "childDataDict": {}
            },
            "463043": {
                "name": "十島村",
                "childDataDict": {}
            },
            "463922": {
                "name": "さつま町",
                "childDataDict": {}
            },
            "464040": {
                "name": "長島町",
                "childDataDict": {}
            },
            "464520": {
                "name": "湧水町",
                "childDataDict": {}
            },
            "464686": {
                "name": "大崎町",
                "childDataDict": {}
            },
            "464821": {
                "name": "東串良町",
                "childDataDict": {}
            },
            "464902": {
                "name": "錦江町",
                "childDataDict": {}
            },
            "464911": {
                "name": "南大隅町",
                "childDataDict": {}
            },
            "464929": {
                "name": "肝付町",
                "childDataDict": {}
            },
            "465011": {
                "name": "中種子町",
                "childDataDict": {}
            },
            "465020": {
                "name": "南種子町",
                "childDataDict": {}
            },
            "465054": {
                "name": "屋久島町",
                "childDataDict": {}
            },
            "465232": {
                "name": "大和村",
                "childDataDict": {}
            },
            "465241": {
                "name": "宇検村",
                "childDataDict": {}
            },
            "465259": {
                "name": "瀬戸内町",
                "childDataDict": {}
            },
            "465275": {
                "name": "龍郷町",
                "childDataDict": {}
            },
            "465291": {
                "name": "喜界町",
                "childDataDict": {}
            },
            "465305": {
                "name": "徳之島町",
                "childDataDict": {}
            },
            "465313": {
                "name": "天城町",
                "childDataDict": {}
            },
            "465321": {
                "name": "伊仙町",
                "childDataDict": {}
            },
            "465330": {
                "name": "和泊町",
                "childDataDict": {}
            },
            "465348": {
                "name": "知名町",
                "childDataDict": {}
            },
            "465356": {
                "name": "与論町",
                "childDataDict": {}
            }
        }
    },
    "470007": {
        "name": "沖縄県",
        "childDataDict": {
            "472018": {
                "name": "那覇市",
                "childDataDict": {}
            },
            "472051": {
                "name": "宜野湾市",
                "childDataDict": {}
            },
            "472077": {
                "name": "石垣市",
                "childDataDict": {}
            },
            "472085": {
                "name": "浦添市",
                "childDataDict": {}
            },
            "472093": {
                "name": "名護市",
                "childDataDict": {}
            },
            "472107": {
                "name": "糸満市",
                "childDataDict": {}
            },
            "472115": {
                "name": "沖縄市",
                "childDataDict": {}
            },
            "472123": {
                "name": "豊見城市",
                "childDataDict": {}
            },
            "472131": {
                "name": "うるま市",
                "childDataDict": {}
            },
            "472140": {
                "name": "宮古島市",
                "childDataDict": {}
            },
            "472158": {
                "name": "南城市",
                "childDataDict": {}
            },
            "473014": {
                "name": "国頭村",
                "childDataDict": {}
            },
            "473022": {
                "name": "大宜味村",
                "childDataDict": {}
            },
            "473031": {
                "name": "東村",
                "childDataDict": {}
            },
            "473065": {
                "name": "今帰仁村",
                "childDataDict": {}
            },
            "473081": {
                "name": "本部町",
                "childDataDict": {}
            },
            "473111": {
                "name": "恩納村",
                "childDataDict": {}
            },
            "473138": {
                "name": "宜野座村",
                "childDataDict": {}
            },
            "473146": {
                "name": "金武町",
                "childDataDict": {}
            },
            "473154": {
                "name": "伊江村",
                "childDataDict": {}
            },
            "473243": {
                "name": "読谷村",
                "childDataDict": {}
            },
            "473251": {
                "name": "嘉手納町",
                "childDataDict": {}
            },
            "473260": {
                "name": "北谷町",
                "childDataDict": {}
            },
            "473278": {
                "name": "北中城村",
                "childDataDict": {}
            },
            "473286": {
                "name": "中城村",
                "childDataDict": {}
            },
            "473294": {
                "name": "西原町",
                "childDataDict": {}
            },
            "473481": {
                "name": "与那原町",
                "childDataDict": {}
            },
            "473502": {
                "name": "南風原町",
                "childDataDict": {}
            },
            "473537": {
                "name": "渡嘉敷村",
                "childDataDict": {}
            },
            "473545": {
                "name": "座間味村",
                "childDataDict": {}
            },
            "473553": {
                "name": "粟国村",
                "childDataDict": {}
            },
            "473561": {
                "name": "渡名喜村",
                "childDataDict": {}
            },
            "473570": {
                "name": "南大東村",
                "childDataDict": {}
            },
            "473588": {
                "name": "北大東村",
                "childDataDict": {}
            },
            "473596": {
                "name": "伊平屋村",
                "childDataDict": {}
            },
            "473600": {
                "name": "伊是名村",
                "childDataDict": {}
            },
            "473618": {
                "name": "久米島町",
                "childDataDict": {}
            },
            "473626": {
                "name": "八重瀬町",
                "childDataDict": {}
            },
            "473758": {
                "name": "多良間村",
                "childDataDict": {}
            },
            "473812": {
                "name": "竹富町",
                "childDataDict": {}
            },
            "473821": {
                "name": "与那国町",
                "childDataDict": {}
            }
        }
    }
}

