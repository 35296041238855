import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { isExpired, decodeToken } from "react-jwt";
import { appConfig } from '../oidcConfig'; // oidcConfigからappConfigをインポート

interface DecodedAccessToken {
  "https://tactna.net/team_id"?: string;
  "https://tactna.net/role"?: string;
  "https://tactna.net/app/team_status"?: string;
  "https://tactna.net/display_name"?: string;
  "sub"?: string;
  "https://tactna.net/custom/app-team/plan"?: string;
  "https://tactna.net/custom/app-team/cnavi_gsh_status"?: string;
  "exp"?: number; // トークンの有効期限を保持
}

// カスタムフックとして getTokens を定義
export const useTokens = () => {
  const auth = useAuth();

  // トークンのリフレッシュ時間を .env から取得
  const tokenRefreshThreshold = parseInt(process.env.REACT_APP_TOKEN_REFRESH_THRESHOLD as string, 10);


  // サイレントリフレッシュしてトークンを取得
  const getTokens = async (): Promise<{
    decodedAccessTokenInfo: {
      team_id: string | null;
      role: string | null;
      team_status: string | null;
      user_name: string | null;
      user_id: string | null;
      plan: string | null;
      available_features: string | null;
    },
    idToken: string | null
  }> => {
    try {

      let newAccessToken = auth.user?.access_token || null;

      // ここでフックを使ってトークンをデコードする
      const decodedAccessToken = decodeToken(newAccessToken || '') as DecodedAccessToken ;
      const currentTime = Math.floor(Date.now() / 1000); // 現在の時刻（UNIXタイムスタンプ）

      // トークンの有効期限をログに出力
      if (decodedAccessToken?.exp) {
        const expirationDate = new Date(decodedAccessToken.exp * 1000); // UNIXタイムスタンプをミリ秒に変換してDateオブジェクトを作成
        console.log('getTokens呼び出し時の現在の時間 (日付形式):', new Date(currentTime * 1000).toString());
        console.log('getTokens呼び出し時の最新のトークンの有効期限 (日付形式):', expirationDate.toString());
      }

      const decodedAccessTokenInfo = {
        team_id: decodedAccessToken?.["https://tactna.net/team_id"] || null,
        role: decodedAccessToken?.["https://tactna.net/role"] || null,
        team_status: decodedAccessToken?.["https://tactna.net/app/team_status"] || null,
        user_name: decodedAccessToken?.["https://tactna.net/display_name"] || null,
        user_id: decodedAccessToken?.["sub"] || null,
        plan: decodedAccessToken?.["https://tactna.net/custom/app-team/plan"] || null,
        available_features: decodedAccessToken?.["https://tactna.net/custom/app-team/cnavi_gsh_status"] || null,
      };

      return {
        decodedAccessTokenInfo,
        idToken: newAccessToken,
      };
    } catch (error) {
      // エラー時の処理を直接ここに記述
      if (error.error) {
        console.error("getTokens失敗 - Error:", error.error);
      }
      if (error.error_description) {
        console.error("getTokens失敗 - Error Description:", error.error_description);
      }
      console.error("getTokens失敗 - Full Error Object:", error);
      
      return {
        decodedAccessTokenInfo: {
          team_id: null,
          role: null,
          team_status: null,
          user_name: null,
          user_id: null,
          plan: null,
          available_features: null,
        },
        idToken: null,
      };
    }
  };

  return { getTokens };
};

const AuthPage: React.FC = () => {
  
  const auth = useAuth();

  // ① OIDC Authorization Code Flow の開始
  useEffect(() => {
    
    const isAuthenticated = auth.isAuthenticated;
    
    console.log("isAuthenticatedの値:", isAuthenticated);

  }, []);

  return null;  // 認証処理が完了するまで何も表示しない
};

export default AuthPage;
