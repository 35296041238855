import $ from 'jquery';
$(function () {
  const headerHeight = $("header").height();
  const speed = 500;
  $('a[href^="#"]').click(function () {
    const href = $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const position = target.offset().top - headerHeight;
    $("html, body").animate({ scrollTop: position }, speed, "swing");
    return false;
  });
});

// SPメニュー
$(function () {
  $('.sp_menu_btn').click(function () {
    $(".sp_menu_btn_line").toggleClass('open')
    $(".sp_wrap").toggleClass('open').slideToggle();
  });
  $('.sp_wrap a').click(function () {
    $(".sp_wrap").removeClass('open');
  });
  $(window).on('load resize', function () {
    const windowWith = $(window).width();
    if (windowWith >= 990) {
      $('.sp_wrap').show();
      $(".sp_menu_btn_line").removeClass('open')
    $(".sp_wrap").removeClass('open');
    } else if (windowWith <= 991) {
      $('.sp_wrap').hide();
    }
  });
});

// FAQアコーディオン
$(function () {
  $(".answer_wrap").hide();
  $('.fap_wrap button').click(function () {
    $(this).toggleClass('active');
    $(this).next(".answer_wrap").toggleClass('open').slideToggle();
  });

});

// 「サービスについて」ページタブ切り替え
$(function () {
  let tabs = $(".howto_tab button");
  $(".howto_tab button").on("click", function () {
    $(".active").removeClass("active");
    $(this).addClass("active");
    const index = tabs.index(this);
    $(".tab_content").removeClass("show").eq(index).addClass("show");
  });
});

// // ページトップ
// $(function () {
//   var pagetop = $('.pagetop');
//   pagetop.hide();
//   $(window).scroll(function () {
//     if ($(this).scrollTop() > 100) {
//       pagetop.slideDown(300);
//       $(".pagetop").css({
//         "position": "fixed",
//         "z-index": "3",
//         "bottom": "0"
//       });
//     } else {
//       pagetop.slideUp(300);
//     };
//     const scrollHeight = $(document).height();
//     const scrollPosition = $(window).height() + $(window).scrollTop();
//     const footHeight = $("footer").innerHeight();
//     if (scrollHeight - scrollPosition <= footHeight) {
//       $(".pagetop").css({
//         "position": "absolute",
//         "bottom": footHeight - 5,
//       });
//     } else {
//       $(".pagetop").css({
//         "position": "fixed",
//         "bottom": "0"
//       });
//     };
//   });
// });

// ボタンクリックでn番目以降のセルを表示
$(function () {
  $('.database').children('.table_btn').click(function () {
    $(this).parent('.database').toggleClass('more');
    $(this).children('span').toggleClass('more');
  });
});

$(function () {
  $(".result:contains('（0件）')").each(function () {
    $(this).closest('.database').addClass("nodatabase");
  });
});

// 共通パーツ読み込み
function header(rootDir) {
  $.ajax({
    url: rootDir + "header.html",
    cache: false,
    async: false,
    dataType: 'html',
    success: function (html) {
      html = html.replace(/\{\$root\}/g, rootDir);
      document.write(html);
    }
  });
}
function header_top(rootDir) {
  $.ajax({
    url: rootDir + "header_top.html",
    cache: false,
    async: false,
    dataType: 'html',
    success: function (html) {
      html = html.replace(/\{\$root\}/g, rootDir);
      document.write(html);
    }
  });
}
function aside(rootDir) {
  $.ajax({
    url: rootDir + "aside.html",
    cache: false,
    async: false,
    dataType: 'html',
    success: function (html) {
      html = html.replace(/\{\$root\}/g, rootDir);
      document.write(html);
    }
  });
}
function footer(rootDir) {
  $.ajax({
    url: rootDir + "footer.html",
    cache: false,
    async: false,
    dataType: 'html',
    success: function (html) {
      html = html.replace(/\{\$root\}/g, rootDir);
      document.write(html);
    }
  });
}
function conversion(rootDir) {
  $.ajax({
    url: rootDir + "conversion.html",
    cache: false,
    async: false,
    dataType: 'html',
    success: function (html) {
      html = html.replace(/\{\$root\}/g, rootDir);
      document.write(html);
    }
  });
}
function conversion_relation(rootDir) {
  $.ajax({
    url: rootDir + "conversion_relation.html",
    cache: false,
    async: false,
    dataType: 'html',
    success: function (html) {
      html = html.replace(/\{\$root\}/g, rootDir);
      document.write(html);
    }
  });
}