import React from 'react';
import logo from './logo.svg';
import './App.css';
import './css/style.css';
import { BrowserRouter, Routes, Route }  from 'react-router-dom';
// 共通コンポーネント
import ScrollToTop from './commons/ScrollToTop';

// 画面コンポーネント
import HomeScreen from './components/HomeScreen';
import AddPurchase from './components/AddPurchase';
import DownloadLog from './components/DownloadLog';
import SearchFilterList from './components/SearchFilterList';
import ChangePlan from './components/ChangePlan';
import CorporateDetail from './components/CorporateDetail';
import AuthPage from './components/AuthPage'; // AuthPageをインポート
import LogoutComponent from './components/LogoutComponent';
import AuthErrorScreen from './components/AuthErrorScreen';
import AuthErrorChecker from './components/AuthErrorChecker'; 

// テーマ
import { ThemeProvider } from '@aws-amplify/ui-react';
import { appTheme } from './theme/appTheme';
// スタイル
import '@aws-amplify/ui-react/styles.css';
import { withAuthenticationRequired, WithAuthenticationRequiredProps } from 'react-oidc-context';
import { appConfig } from './oidcConfig'; // oidcConfigからappConfigをインポート
import LoadingModal from './components/LoadingModal';


export default function App() {
  
  // 認証が必要なページにリダイレクトする際に使用する設定
  const authProps: WithAuthenticationRequiredProps = {
    OnRedirecting: () => <LoadingModal open={true} />
    ,signinRedirectArgs: {
      redirect_uri: window.location.href
      ,extraQueryParams: { audience: appConfig.audience }
    },    
  };
  
  
  return (
    <ThemeProvider theme={appTheme}>
      <BrowserRouter>
        <ScrollToTop />
        <AuthErrorChecker>
          <Routes>
            <Route path="/" Component={withAuthenticationRequired(HomeScreen, authProps)} /> 
            <Route path="/homescreen" Component={withAuthenticationRequired(HomeScreen, authProps)} /> 
            <Route path="/addpurchase" Component={withAuthenticationRequired(AddPurchase, authProps)} /> 
            <Route path="/downloadlog" Component={withAuthenticationRequired(DownloadLog, authProps)} /> 
            <Route path="/searchfilterlist" Component={withAuthenticationRequired(SearchFilterList, authProps)} /> 
            <Route path="/planchange" Component={withAuthenticationRequired(ChangePlan, authProps)} />
            <Route path="/corporatedetail" Component={withAuthenticationRequired(CorporateDetail, authProps)} />
            <Route path="/logout" Component={LogoutComponent} />
            <Route path="/autherrorscreen" Component={AuthErrorScreen} />
          </Routes>
        </AuthErrorChecker>
      </BrowserRouter>
    </ThemeProvider>
  );
};
