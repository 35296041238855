import React from 'react';
import { Text, View, Flex, Divider, Card, Button, useTheme } from '@aws-amplify/ui-react';
import { Helmet } from "react-helmet-async";
import { useTokens } from '../components/AuthPage'; // AuthPageからuseTokensをインポート
import Header from '../commons/Header';
import Footer from '../commons/Footer';

//出来ること・メリット・料金形態の説明等
const texts = `詳細表示・ダウンロード件数を追加購入いただけます。
              ・ご利用料金：1,000件追加　5,000円/月
                ※追加購入された件数は、当月のみ有効です。
                ※企業INDEXナビの当月ご利用料金に加算してご請求いたします。`;
const description = texts.split('\n').map((item, index) => {
    return (
    <React.Fragment key={index}>
        {item}<br />
    </React.Fragment>
    );
});

// iovation
// config.jsを読み込む
const loadIovationConfig = () => {
  return new Promise<void>((resolve, reject) => {
    const oldConfig = document.getElementById('config');
    if (oldConfig){
        oldConfig.remove();
    }
    const config = document.createElement('script');
    config.id = 'config';
    config.src = './config.js';
    config.onload = () => resolve();
    config.onerror = () => reject(new Error(`Failed to load script config.js`));
    document.body.appendChild(config);
  });
};
// iovation.jsを読み込む
const loadIovation = () => {
  return new Promise<void>((resolve, reject) => {
    const oldIovation = document.getElementById('iovation');
    if (oldIovation){
        oldIovation.remove();
    }
    const iovation = document.createElement('script');
    iovation.id = 'iovation';
    iovation.src = './iovation.js';
    iovation.onload = () => resolve();
    iovation.onerror = () => reject(new Error(`Failed to load script iovation.js`));
    document.body.appendChild(iovation);
  });
};

export default function AddPurchase() {
    const {tokens} = useTheme();
    
    const { getTokens } = useTokens(); // useTokensフックを使用
    const [tokenInfo, setTokenInfo] = React.useState<any>(null);
    
    // ヘッダ読み込み時処理完了フラグ
    const [isLoadedHeader, setIsLoadedHeader] = React.useState(false);
    
    // HTML要素 ホームポジション
    const homePositionRef = React.useRef<HTMLButtonElement>(null);
    
    // 処理中フラグ 多重クリック防止
    const [isProcessing, setIsProcessing] = React.useState(false);
    
    // 追加購入APIを呼び出す
    const callAppendPurchase = async (token:string | null, parameters:{'deviceId':any, 'clientIPAdress':any}) => {
        const baseUrl:string = process.env.REACT_APP_API_BASE_URL || '';
        const path:string = '/event/appendpurchase';
        try {
          const response = await fetch(baseUrl.concat(path),{
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`, //アクセストークン
              'Content-Type': 'application/json;'
            },
            body: JSON.stringify(parameters)
          });
          if (response.ok) {
            const result = await response.json();
            return result;
          } 
        } catch (error) {
          console.log(error);
        }
    };
    
    React.useEffect(() => {
        const handleLoadAddPurchase = async () => {
            // iovation.jsをロードする
            await loadIovationConfig();
            await loadIovation();
            // 最新のアクセストークンの取得
            const {decodedAccessTokenInfo} = await getTokens(); // トークンを取得
            setTokenInfo(decodedAccessTokenInfo); // 取得したdecodedAccessTokenInfoをセット
            
            // ホームポジションにカーソルを設定する
            if (homePositionRef.current) {
                homePositionRef.current.setAttribute('tabIndex', '1');
                homePositionRef.current.focus();
            }
        }
        if (isLoadedHeader) {
            handleLoadAddPurchase();
            setIsLoadedHeader(false);
        }
        
    }, [isLoadedHeader]);
    
    //追加購入ボタン押下時処理
    const handleAddPurchase = async () => {
        if (isProcessing) {return;}
        setIsProcessing(true);
        
        const userConfirmed = window.confirm('追加購入を確定します。よろしいですか？');
        if (userConfirmed) {
            let deviceId;
            const iovationBlackBox = document.getElementById('ioBlackBox') as HTMLInputElement;
            if (iovationBlackBox) {deviceId = iovationBlackBox.value;}
            
            // クライアントIPアドレスの取得
            const API_URL = 'https://api.ipify.org/?format=json'
            const res = await fetch(API_URL)
            const data = await res.json()
            const clientIPAddress = data.ip;
            
            // 追加購入手続きAPIの呼び出し
            const {idToken} = await getTokens(); // 最新のトークンを取得
            const parameters = {
                'deviceId': deviceId,
                'clientIPAdress': clientIPAddress,
            };
            const resultAppendPurchase = await callAppendPurchase(idToken, parameters);
            if (resultAppendPurchase && 'result' in resultAppendPurchase) {
                if (resultAppendPurchase.result == '購入成功') {
                    
                    alert('購入成功');
                    window.location.reload(); //画面再読込
                }
                else{alert('購入失敗');}
            }
            else{alert('購入失敗');}
        }
        else {}    
        setIsProcessing(false);
    };

    return (
        <>
            <Helmet>
              <title>追加購入 | 企業INDEXナビ</title>
            </Helmet>
            <View className="appContainer">
            <Header setIsLoadedHeader={setIsLoadedHeader} />
            <Flex className="mainArea" backgroundColor={tokens.colors.blue[10]} direction="column" alignItems="center" gap="0">
                <Flex padding="1.5rem" backgroundColor={tokens.colors.blue[20]} width="100%" justifyContent="center" alignItems="center" >
                  <Text color={tokens.colors.blue[90]} height="30px" fontSize={tokens.fontSizes.xxl} fontWeight="bold" width={{base:"100%", large:"calc(100% - 32px)"}} maxWidth="1200px" padding={{base: "0 clamp(2rem, 1.92rem + 0.4vw, 2.4rem)",large:"0"}} >
                    追加購入
                  </Text>
                </Flex>
                <View as="div" margin="4.8rem auto" width={{base: "100%", large:"calc(100% - 32px)"}} maxWidth="1200px" padding={{base: "0 clamp(2rem, 1.92rem + 0.4vw, 2.4rem)",large:"0"}} >
                  <Text backgroundColor={tokens.colors.blue[60]} fontSize="2xl" fontWeight="bold" color={tokens.colors.white} alignSelf="center" padding="10px 20px 10px 20px">
                    企業INDEXナビ 詳細表示・ダウンロード件数の追加購入
                  </Text>
                  <View padding="20px 25px 20px 25px" backgroundColor={tokens.colors.white}  alignSelf="center">
                    <Text color={tokens.colors.black}>
                      {description}
                    </Text>
                  </View>
                </View>
                <Flex width="100%" maxWidth="1455px" justifyContent="center" padding="0 0 1rem 0" margin="20px 0 50px 0">
                    <Button className="orangeHoverButton"　variation="menu" display="inline-flex" justifyContent="center" width="15rem" backgroundColor={tokens.colors.red[60]} borderColor={tokens.colors.red[60]} borderRadius="30px"
                        ref={homePositionRef} isLoading={isProcessing} isDisabled={!(tokenInfo&&tokenInfo.plan=='basic'&&tokenInfo.role=='ADMIN')} onClick={handleAddPurchase} fontSize={tokens.fontSizes.xxl}>
                        追加購入
                    </Button>
                    <input id="ioBlackBox" type="hidden" />
                </Flex>
            </Flex>
            <Footer />
            </View>
        </>
  );
};
