export const industryData = {
    "A,00,000,0000": {
        "name": "農業，林業",
        "childDataDict": {
            "A,01,000,0000": {
                "name": "農業",
                "childDataDict": {
                    "A,01,010,0000": {
                        "name": "管理，補助的経済活動を行う事業所（01農業）",
                        "childDataDict": {}
                    },
                    "A,01,011,0000": {
                        "name": "耕種農業",
                        "childDataDict": {}
                    },
                    "A,01,012,0000": {
                        "name": "畜産農業",
                        "childDataDict": {}
                    },
                    "A,01,013,0000": {
                        "name": "農業サービス業（園芸サービス業を除く）",
                        "childDataDict": {}
                    },
                    "A,01,014,0000": {
                        "name": "園芸サービス業",
                        "childDataDict": {}
                    }
                }
            },
            "A,02,000,0000": {
                "name": "林業",
                "childDataDict": {
                    "A,02,020,0000": {
                        "name": "管理，補助的経済活動を行う事業所（02林業）",
                        "childDataDict": {}
                    },
                    "A,02,021,0000": {
                        "name": "育林業",
                        "childDataDict": {}
                    },
                    "A,02,022,0000": {
                        "name": "素材生産業",
                        "childDataDict": {}
                    },
                    "A,02,023,0000": {
                        "name": "特用林産物生産業（きのこ類の栽培を除く）",
                        "childDataDict": {}
                    },
                    "A,02,024,0000": {
                        "name": "林業サービス業",
                        "childDataDict": {}
                    },
                    "A,02,029,0000": {
                        "name": "その他の林業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "B,00,000,0000": {
        "name": "漁業",
        "childDataDict": {
            "B,03,000,0000": {
                "name": "漁業（水産養殖業を除く）",
                "childDataDict": {
                    "B,03,030,0000": {
                        "name": "管理，補助的経済活動を行う事業所（03漁業）",
                        "childDataDict": {}
                    },
                    "B,03,031,0000": {
                        "name": "海面漁業",
                        "childDataDict": {}
                    },
                    "B,03,032,0000": {
                        "name": "内水面漁業",
                        "childDataDict": {}
                    }
                }
            },
            "B,04,000,0000": {
                "name": "水産養殖業",
                "childDataDict": {
                    "B,04,040,0000": {
                        "name": "管理，補助的経済活動を行う事業所（04水産養殖業）",
                        "childDataDict": {}
                    },
                    "B,04,041,0000": {
                        "name": "海面養殖業",
                        "childDataDict": {}
                    },
                    "B,04,042,0000": {
                        "name": "内水面養殖業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "C,00,000,0000": {
        "name": "鉱業，採石業，砂利採取業",
        "childDataDict": {
            "C,05,000,0000": {
                "name": "鉱業，採石業，砂利採取業",
                "childDataDict": {
                    "C,05,050,0000": {
                        "name": "管理，補助的経済活動を行う事業所（05鉱業，採石業，砂利採取業）",
                        "childDataDict": {}
                    },
                    "C,05,051,0000": {
                        "name": "金属鉱業",
                        "childDataDict": {}
                    },
                    "C,05,052,0000": {
                        "name": "石炭・亜炭鉱業",
                        "childDataDict": {}
                    },
                    "C,05,053,0000": {
                        "name": "原油・天然ガス鉱業",
                        "childDataDict": {}
                    },
                    "C,05,054,0000": {
                        "name": "採石業，砂・砂利・玉石採取業",
                        "childDataDict": {}
                    },
                    "C,05,055,0000": {
                        "name": "窯業原料用鉱物鉱業（耐火物・陶磁器・ガラス・セメント原料用に限る）",
                        "childDataDict": {}
                    },
                    "C,05,059,0000": {
                        "name": "その他の鉱業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "D,00,000,0000": {
        "name": "建設業",
        "childDataDict": {
            "D,06,000,0000": {
                "name": "総合工事業",
                "childDataDict": {
                    "D,06,060,0000": {
                        "name": "管理，補助的経済活動を行う事業所（06総合工事業）",
                        "childDataDict": {}
                    },
                    "D,06,061,0000": {
                        "name": "一般土木建築工事業",
                        "childDataDict": {}
                    },
                    "D,06,062,0000": {
                        "name": "土木工事業（舗装工事業を除く）",
                        "childDataDict": {}
                    },
                    "D,06,063,0000": {
                        "name": "舗装工事業",
                        "childDataDict": {}
                    },
                    "D,06,064,0000": {
                        "name": "建築工事業(木造建築工事業を除く)",
                        "childDataDict": {}
                    },
                    "D,06,065,0000": {
                        "name": "木造建築工事業",
                        "childDataDict": {}
                    },
                    "D,06,066,0000": {
                        "name": "建築リフォーム工事業",
                        "childDataDict": {}
                    }
                }
            },
            "D,07,000,0000": {
                "name": "職別工事業(設備工事業を除く)",
                "childDataDict": {
                    "D,07,070,0000": {
                        "name": "管理，補助的経済活動を行う事業所（07職別工事業）",
                        "childDataDict": {}
                    },
                    "D,07,071,0000": {
                        "name": "大工工事業",
                        "childDataDict": {}
                    },
                    "D,07,072,0000": {
                        "name": "とび・土工・コンクリート工事業",
                        "childDataDict": {}
                    },
                    "D,07,073,0000": {
                        "name": "鉄骨・鉄筋工事業",
                        "childDataDict": {}
                    },
                    "D,07,074,0000": {
                        "name": "石工・れんが・タイル・ブロック工事業",
                        "childDataDict": {}
                    },
                    "D,07,075,0000": {
                        "name": "左官工事業",
                        "childDataDict": {}
                    },
                    "D,07,076,0000": {
                        "name": "板金・金物工事業",
                        "childDataDict": {}
                    },
                    "D,07,077,0000": {
                        "name": "塗装工事業",
                        "childDataDict": {}
                    },
                    "D,07,078,0000": {
                        "name": "床・内装工事業",
                        "childDataDict": {}
                    },
                    "D,07,079,0000": {
                        "name": "その他の職別工事業",
                        "childDataDict": {}
                    }
                }
            },
            "D,08,000,0000": {
                "name": "設備工事業",
                "childDataDict": {
                    "D,08,080,0000": {
                        "name": "管理，補助的経済活動を行う事業所（08設備工事業）",
                        "childDataDict": {}
                    },
                    "D,08,081,0000": {
                        "name": "電気工事業",
                        "childDataDict": {}
                    },
                    "D,08,082,0000": {
                        "name": "電気通信・信号装置工事業",
                        "childDataDict": {}
                    },
                    "D,08,083,0000": {
                        "name": "管工事業（さく井工事業を除く）",
                        "childDataDict": {}
                    },
                    "D,08,084,0000": {
                        "name": "機械器具設置工事業",
                        "childDataDict": {}
                    },
                    "D,08,089,0000": {
                        "name": "その他の設備工事業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "E,00,000,0000": {
        "name": "製造業",
        "childDataDict": {
            "E,09,000,0000": {
                "name": "食料品製造業",
                "childDataDict": {
                    "E,09,090,0000": {
                        "name": "管理，補助的経済活動を行う事業所（09食料品製造業）",
                        "childDataDict": {}
                    },
                    "E,09,091,0000": {
                        "name": "畜産食料品製造業",
                        "childDataDict": {}
                    },
                    "E,09,092,0000": {
                        "name": "水産食料品製造業",
                        "childDataDict": {}
                    },
                    "E,09,093,0000": {
                        "name": "野菜缶詰・果実缶詰・農産保存食料品製造業",
                        "childDataDict": {}
                    },
                    "E,09,094,0000": {
                        "name": "調味料製造業",
                        "childDataDict": {}
                    },
                    "E,09,095,0000": {
                        "name": "糖類製造業",
                        "childDataDict": {}
                    },
                    "E,09,096,0000": {
                        "name": "精穀・製粉業",
                        "childDataDict": {}
                    },
                    "E,09,097,0000": {
                        "name": "パン・菓子製造業",
                        "childDataDict": {}
                    },
                    "E,09,098,0000": {
                        "name": "動植物油脂製造業",
                        "childDataDict": {}
                    },
                    "E,09,099,0000": {
                        "name": "その他の食料品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,10,000,0000": {
                "name": "飲料・たばこ・飼料製造業",
                "childDataDict": {
                    "E,10,100,0000": {
                        "name": "管理，補助的経済活動を行う事業所（10飲料・たばこ・飼料製造業）",
                        "childDataDict": {}
                    },
                    "E,10,101,0000": {
                        "name": "清涼飲料製造業",
                        "childDataDict": {}
                    },
                    "E,10,102,0000": {
                        "name": "酒類製造業",
                        "childDataDict": {}
                    },
                    "E,10,103,0000": {
                        "name": "茶・コーヒー製造業（清涼飲料を除く）",
                        "childDataDict": {}
                    },
                    "E,10,104,0000": {
                        "name": "製氷業",
                        "childDataDict": {}
                    },
                    "E,10,105,0000": {
                        "name": "たばこ製造業",
                        "childDataDict": {}
                    },
                    "E,10,106,0000": {
                        "name": "飼料・有機質肥料製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,11,000,0000": {
                "name": "繊維工業",
                "childDataDict": {
                    "E,11,110,0000": {
                        "name": "管理，補助的経済活動を行う事業所（11繊維工業）",
                        "childDataDict": {}
                    },
                    "E,11,111,0000": {
                        "name": "製糸業，紡績業，化学繊維・ねん糸等製造業",
                        "childDataDict": {}
                    },
                    "E,11,112,0000": {
                        "name": "織物業",
                        "childDataDict": {}
                    },
                    "E,11,113,0000": {
                        "name": "ニット生地製造業",
                        "childDataDict": {}
                    },
                    "E,11,114,0000": {
                        "name": "染色整理業",
                        "childDataDict": {}
                    },
                    "E,11,115,0000": {
                        "name": "綱・網・レース・繊維粗製品製造業",
                        "childDataDict": {}
                    },
                    "E,11,116,0000": {
                        "name": "外衣・シャツ製造業（和式を除く）",
                        "childDataDict": {}
                    },
                    "E,11,117,0000": {
                        "name": "下着類製造業",
                        "childDataDict": {}
                    },
                    "E,11,118,0000": {
                        "name": "和装製品・その他の衣服・繊維製身の回り品製造業",
                        "childDataDict": {}
                    },
                    "E,11,119,0000": {
                        "name": "その他の繊維製品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,12,000,0000": {
                "name": "木材・木製品製造業（家具を除く）",
                "childDataDict": {
                    "E,12,120,0000": {
                        "name": "管理，補助的経済活動を行う事業所（12木材・木製品製造業）",
                        "childDataDict": {}
                    },
                    "E,12,121,0000": {
                        "name": "製材業，木製品製造業",
                        "childDataDict": {}
                    },
                    "E,12,122,0000": {
                        "name": "造作材・合板・建築用組立材料製造業",
                        "childDataDict": {}
                    },
                    "E,12,123,0000": {
                        "name": "木製容器製造業（竹，とうを含む）",
                        "childDataDict": {}
                    },
                    "E,12,129,0000": {
                        "name": "その他の木製品製造業(竹，とうを含む)",
                        "childDataDict": {}
                    }
                }
            },
            "E,13,000,0000": {
                "name": "家具・装備品製造業",
                "childDataDict": {
                    "E,13,130,0000": {
                        "name": "管理，補助的経済活動を行う事業所（13家具・装備品製造業）",
                        "childDataDict": {}
                    },
                    "E,13,131,0000": {
                        "name": "家具製造業",
                        "childDataDict": {}
                    },
                    "E,13,132,0000": {
                        "name": "宗教用具製造業",
                        "childDataDict": {}
                    },
                    "E,13,133,0000": {
                        "name": "建具製造業",
                        "childDataDict": {}
                    },
                    "E,13,139,0000": {
                        "name": "その他の家具・装備品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,14,000,0000": {
                "name": "パルプ・紙・紙加工品製造業",
                "childDataDict": {
                    "E,14,140,0000": {
                        "name": "管理，補助的経済活動を行う事業所（14パルプ・紙・紙加工品製造業）",
                        "childDataDict": {}
                    },
                    "E,14,141,0000": {
                        "name": "パルプ製造業",
                        "childDataDict": {}
                    },
                    "E,14,142,0000": {
                        "name": "紙製造業",
                        "childDataDict": {}
                    },
                    "E,14,143,0000": {
                        "name": "加工紙製造業",
                        "childDataDict": {}
                    },
                    "E,14,144,0000": {
                        "name": "紙製品製造業",
                        "childDataDict": {}
                    },
                    "E,14,145,0000": {
                        "name": "紙製容器製造業",
                        "childDataDict": {}
                    },
                    "E,14,149,0000": {
                        "name": "その他のパルプ・紙・紙加工品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,15,000,0000": {
                "name": "印刷・同関連業",
                "childDataDict": {
                    "E,15,150,0000": {
                        "name": "管理，補助的経済活動を行う事業所（15印刷・同関連業）",
                        "childDataDict": {}
                    },
                    "E,15,151,0000": {
                        "name": "印刷業",
                        "childDataDict": {}
                    },
                    "E,15,152,0000": {
                        "name": "製版業",
                        "childDataDict": {}
                    },
                    "E,15,153,0000": {
                        "name": "製本業，印刷物加工業",
                        "childDataDict": {}
                    },
                    "E,15,159,0000": {
                        "name": "印刷関連サービス業",
                        "childDataDict": {}
                    }
                }
            },
            "E,16,000,0000": {
                "name": "化学工業",
                "childDataDict": {
                    "E,16,160,0000": {
                        "name": "管理，補助的経済活動を行う事業所（16化学工業）",
                        "childDataDict": {}
                    },
                    "E,16,161,0000": {
                        "name": "化学肥料製造業",
                        "childDataDict": {}
                    },
                    "E,16,162,0000": {
                        "name": "無機化学工業製品製造業",
                        "childDataDict": {}
                    },
                    "E,16,163,0000": {
                        "name": "有機化学工業製品製造業",
                        "childDataDict": {}
                    },
                    "E,16,164,0000": {
                        "name": "油脂加工製品・石けん・合成洗剤・界面活性剤・塗料製造業",
                        "childDataDict": {}
                    },
                    "E,16,165,0000": {
                        "name": "医薬品製造業",
                        "childDataDict": {}
                    },
                    "E,16,166,0000": {
                        "name": "化粧品・歯磨・その他の化粧用調整品製造業",
                        "childDataDict": {}
                    },
                    "E,16,169,0000": {
                        "name": "その他の化学工業",
                        "childDataDict": {}
                    }
                }
            },
            "E,17,000,0000": {
                "name": "石油製品・石炭製品製造業",
                "childDataDict": {
                    "E,17,170,0000": {
                        "name": "管理，補助的経済活動を行う事業所（17石油製品・石炭製品製造業）",
                        "childDataDict": {}
                    },
                    "E,17,171,0000": {
                        "name": "石油精製業",
                        "childDataDict": {}
                    },
                    "E,17,172,0000": {
                        "name": "潤滑油・グリース製造業（石油精製業によらないもの）",
                        "childDataDict": {}
                    },
                    "E,17,173,0000": {
                        "name": "コークス製造業",
                        "childDataDict": {}
                    },
                    "E,17,174,0000": {
                        "name": "舗装材料製造業",
                        "childDataDict": {}
                    },
                    "E,17,179,0000": {
                        "name": "その他の石油製品・石炭製品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,18,000,0000": {
                "name": "プラスチック製品製造業（別掲を除く）",
                "childDataDict": {
                    "E,18,180,0000": {
                        "name": "管理，補助的経済活動を行う事業所（18プラスチック製品製造業）",
                        "childDataDict": {}
                    },
                    "E,18,181,0000": {
                        "name": "プラスチック板・棒・管・継手・異形押出製品製造業",
                        "childDataDict": {}
                    },
                    "E,18,182,0000": {
                        "name": "プラスチックフィルム・シート・床材・合成皮革製造業",
                        "childDataDict": {}
                    },
                    "E,18,183,0000": {
                        "name": "工業用プラスチック製品製造業",
                        "childDataDict": {}
                    },
                    "E,18,184,0000": {
                        "name": "発泡・強化プラスチック製品製造業",
                        "childDataDict": {}
                    },
                    "E,18,185,0000": {
                        "name": "プラスチック成形材料製造業（廃プラスチックを含む）",
                        "childDataDict": {}
                    },
                    "E,18,189,0000": {
                        "name": "その他のプラスチック製品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,19,000,0000": {
                "name": "ゴム製品製造業",
                "childDataDict": {
                    "E,19,190,0000": {
                        "name": "管理，補助的経済活動を行う事業所（19ゴム製品製造業）",
                        "childDataDict": {}
                    },
                    "E,19,191,0000": {
                        "name": "タイヤ・チューブ製造業",
                        "childDataDict": {}
                    },
                    "E,19,192,0000": {
                        "name": "ゴム製・プラスチック製履物・同附属品製造業",
                        "childDataDict": {}
                    },
                    "E,19,193,0000": {
                        "name": "ゴムベルト・ゴムホース・工業用ゴム製品製造業",
                        "childDataDict": {}
                    },
                    "E,19,199,0000": {
                        "name": "その他のゴム製品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,20,000,0000": {
                "name": "なめし革・同製品・毛皮製造業",
                "childDataDict": {
                    "E,20,200,0000": {
                        "name": "管理，補助的経済活動を行う事業所（20なめし革・同製品・毛皮製造業）",
                        "childDataDict": {}
                    },
                    "E,20,201,0000": {
                        "name": "なめし革製造業",
                        "childDataDict": {}
                    },
                    "E,20,202,0000": {
                        "name": "工業用革製品製造業（手袋を除く）",
                        "childDataDict": {}
                    },
                    "E,20,203,0000": {
                        "name": "革製履物用材料・同附属品製造業",
                        "childDataDict": {}
                    },
                    "E,20,204,0000": {
                        "name": "革製履物製造業",
                        "childDataDict": {}
                    },
                    "E,20,205,0000": {
                        "name": "革製手袋製造業",
                        "childDataDict": {}
                    },
                    "E,20,206,0000": {
                        "name": "かばん製造業",
                        "childDataDict": {}
                    },
                    "E,20,207,0000": {
                        "name": "袋物製造業",
                        "childDataDict": {}
                    },
                    "E,20,208,0000": {
                        "name": "毛皮製造業",
                        "childDataDict": {}
                    },
                    "E,20,209,0000": {
                        "name": "その他のなめし革製品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,21,000,0000": {
                "name": "窯業・土石製品製造業",
                "childDataDict": {
                    "E,21,210,0000": {
                        "name": "管理，補助的経済活動を行う事業所（21窯業・土石製品製造業）",
                        "childDataDict": {}
                    },
                    "E,21,211,0000": {
                        "name": "ガラス・同製品製造業",
                        "childDataDict": {}
                    },
                    "E,21,212,0000": {
                        "name": "セメント・同製品製造業",
                        "childDataDict": {}
                    },
                    "E,21,213,0000": {
                        "name": "建設用粘土製品製造業（陶磁器製を除く)",
                        "childDataDict": {}
                    },
                    "E,21,214,0000": {
                        "name": "陶磁器・同関連製品製造業",
                        "childDataDict": {}
                    },
                    "E,21,215,0000": {
                        "name": "耐火物製造業",
                        "childDataDict": {}
                    },
                    "E,21,216,0000": {
                        "name": "炭素・黒鉛製品製造業",
                        "childDataDict": {}
                    },
                    "E,21,217,0000": {
                        "name": "研磨材・同製品製造業",
                        "childDataDict": {}
                    },
                    "E,21,218,0000": {
                        "name": "骨材・石工品等製造業",
                        "childDataDict": {}
                    },
                    "E,21,219,0000": {
                        "name": "その他の窯業・土石製品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,22,000,0000": {
                "name": "鉄鋼業",
                "childDataDict": {
                    "E,22,220,0000": {
                        "name": "管理，補助的経済活動を行う事業所（22鉄鋼業）",
                        "childDataDict": {}
                    },
                    "E,22,221,0000": {
                        "name": "製鉄業",
                        "childDataDict": {}
                    },
                    "E,22,222,0000": {
                        "name": "製鋼・製鋼圧延業",
                        "childDataDict": {}
                    },
                    "E,22,223,0000": {
                        "name": "製鋼を行わない鋼材製造業（表面処理鋼材を除く）",
                        "childDataDict": {}
                    },
                    "E,22,224,0000": {
                        "name": "表面処理鋼材製造業",
                        "childDataDict": {}
                    },
                    "E,22,225,0000": {
                        "name": "鉄素形材製造業",
                        "childDataDict": {}
                    },
                    "E,22,229,0000": {
                        "name": "その他の鉄鋼業",
                        "childDataDict": {}
                    }
                }
            },
            "E,23,000,0000": {
                "name": "非鉄金属製造業",
                "childDataDict": {
                    "E,23,230,0000": {
                        "name": "管理，補助的経済活動を行う事業所（23非鉄金属製造業）",
                        "childDataDict": {}
                    },
                    "E,23,231,0000": {
                        "name": "非鉄金属第1次製錬・精製業",
                        "childDataDict": {}
                    },
                    "E,23,232,0000": {
                        "name": "非鉄金属第2次製錬・精製業（非鉄金属合金製造業を含む）",
                        "childDataDict": {}
                    },
                    "E,23,233,0000": {
                        "name": "非鉄金属・同合金圧延業（抽伸，押出しを含む）",
                        "childDataDict": {}
                    },
                    "E,23,234,0000": {
                        "name": "電線・ケーブル製造業",
                        "childDataDict": {}
                    },
                    "E,23,235,0000": {
                        "name": "非鉄金属素形材製造業",
                        "childDataDict": {}
                    },
                    "E,23,239,0000": {
                        "name": "その他の非鉄金属製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,24,000,0000": {
                "name": "金属製品製造業",
                "childDataDict": {
                    "E,24,240,0000": {
                        "name": "管理，補助的経済活動を行う事業所（24金属製品製造業）",
                        "childDataDict": {}
                    },
                    "E,24,241,0000": {
                        "name": "ブリキ缶・その他のめっき板等製品製造業",
                        "childDataDict": {}
                    },
                    "E,24,242,0000": {
                        "name": "洋食器・刃物・手道具・金物類製造業",
                        "childDataDict": {}
                    },
                    "E,24,243,0000": {
                        "name": "\"暖房・調理等装置",
                        "childDataDict": {}
                    },
                    "E,24,244,0000": {
                        "name": "建設用・建築用金属製品製造業（製缶板金業を含む)",
                        "childDataDict": {}
                    },
                    "E,24,245,0000": {
                        "name": "金属素形材製品製造業",
                        "childDataDict": {}
                    },
                    "E,24,246,0000": {
                        "name": "金属被覆・彫刻業，熱処理業（ほうろう鉄器を除く）",
                        "childDataDict": {}
                    },
                    "E,24,247,0000": {
                        "name": "金属線製品製造業（ねじ類を除く)",
                        "childDataDict": {}
                    },
                    "E,24,248,0000": {
                        "name": "ボルト・ナット・リベット・小ねじ・木ねじ等製造業",
                        "childDataDict": {}
                    },
                    "E,24,249,0000": {
                        "name": "その他の金属製品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,25,000,0000": {
                "name": "はん用機械器具製造業",
                "childDataDict": {
                    "E,25,250,0000": {
                        "name": "管理，補助的経済活動を行う事業所（25はん用機械器具製造業）",
                        "childDataDict": {}
                    },
                    "E,25,251,0000": {
                        "name": "ボイラ・原動機製造業",
                        "childDataDict": {}
                    },
                    "E,25,252,0000": {
                        "name": "ポンプ・圧縮機器製造業",
                        "childDataDict": {}
                    },
                    "E,25,253,0000": {
                        "name": "一般産業用機械・装置製造業",
                        "childDataDict": {}
                    },
                    "E,25,259,0000": {
                        "name": "その他のはん用機械・同部分品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,26,000,0000": {
                "name": "生産用機械器具製造業",
                "childDataDict": {
                    "E,26,260,0000": {
                        "name": "管理，補助的経済活動を行う事業所（26生産用機械器具製造業）",
                        "childDataDict": {}
                    },
                    "E,26,261,0000": {
                        "name": "農業用機械製造業（農業用器具を除く）",
                        "childDataDict": {}
                    },
                    "E,26,262,0000": {
                        "name": "建設機械・鉱山機械製造業",
                        "childDataDict": {}
                    },
                    "E,26,263,0000": {
                        "name": "繊維機械製造業",
                        "childDataDict": {}
                    },
                    "E,26,264,0000": {
                        "name": "生活関連産業用機械製造業",
                        "childDataDict": {}
                    },
                    "E,26,265,0000": {
                        "name": "基礎素材産業用機械製造業",
                        "childDataDict": {}
                    },
                    "E,26,266,0000": {
                        "name": "金属加工機械製造業",
                        "childDataDict": {}
                    },
                    "E,26,267,0000": {
                        "name": "半導体・フラットパネルディスプレイ製造装置製造業",
                        "childDataDict": {}
                    },
                    "E,26,269,0000": {
                        "name": "その他の生産用機械・同部分品製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,27,000,0000": {
                "name": "業務用機械器具製造業",
                "childDataDict": {
                    "E,27,270,0000": {
                        "name": "管理，補助的経済活動を行う事業所（27業務用機械器具製造業）",
                        "childDataDict": {}
                    },
                    "E,27,271,0000": {
                        "name": "事務用機械器具製造業",
                        "childDataDict": {}
                    },
                    "E,27,272,0000": {
                        "name": "サービス用・娯楽用機械器具製造業",
                        "childDataDict": {}
                    },
                    "E,27,273,0000": {
                        "name": "計量器・測定器・分析機器・試験機・測量機械器具・理化学機械器具製造業",
                        "childDataDict": {}
                    },
                    "E,27,274,0000": {
                        "name": "医療用機械器具・医療用品製造業",
                        "childDataDict": {}
                    },
                    "E,27,275,0000": {
                        "name": "光学機械器具・レンズ製造業",
                        "childDataDict": {}
                    },
                    "E,27,276,0000": {
                        "name": "武器製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,28,000,0000": {
                "name": "電子部品・デバイス・電子回路製造業",
                "childDataDict": {
                    "E,28,280,0000": {
                        "name": "管理，補助的経済活動を行う事業所（28電子部品・デバイス・電子回路製造業）",
                        "childDataDict": {}
                    },
                    "E,28,281,0000": {
                        "name": "電子デバイス製造業",
                        "childDataDict": {}
                    },
                    "E,28,282,0000": {
                        "name": "電子部品製造業",
                        "childDataDict": {}
                    },
                    "E,28,283,0000": {
                        "name": "記録メディア製造業",
                        "childDataDict": {}
                    },
                    "E,28,284,0000": {
                        "name": "電子回路製造業",
                        "childDataDict": {}
                    },
                    "E,28,285,0000": {
                        "name": "ユニット部品製造業",
                        "childDataDict": {}
                    },
                    "E,28,289,0000": {
                        "name": "その他の電子部品・デバイス・電子回路製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,29,000,0000": {
                "name": "電気機械器具製造業",
                "childDataDict": {
                    "E,29,290,0000": {
                        "name": "管理，補助的経済活動を行う事業所（29電気機械器具製造業）",
                        "childDataDict": {}
                    },
                    "E,29,291,0000": {
                        "name": "発電用・送電用・配電用電気機械器具製造業",
                        "childDataDict": {}
                    },
                    "E,29,292,0000": {
                        "name": "産業用電気機械器具製造業",
                        "childDataDict": {}
                    },
                    "E,29,293,0000": {
                        "name": "民生用電気機械器具製造業",
                        "childDataDict": {}
                    },
                    "E,29,294,0000": {
                        "name": "電球・電気照明器具製造業",
                        "childDataDict": {}
                    },
                    "E,29,295,0000": {
                        "name": "電池製造業",
                        "childDataDict": {}
                    },
                    "E,29,296,0000": {
                        "name": "電子応用装置製造業",
                        "childDataDict": {}
                    },
                    "E,29,297,0000": {
                        "name": "電気計測器製造業",
                        "childDataDict": {}
                    },
                    "E,29,299,0000": {
                        "name": "その他の電気機械器具製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,30,000,0000": {
                "name": "情報通信機械器具製造業",
                "childDataDict": {
                    "E,30,300,0000": {
                        "name": "管理，補助的経済活動を行う事業所（30情報通信機械器具製造業）",
                        "childDataDict": {}
                    },
                    "E,30,301,0000": {
                        "name": "通信機械器具・同関連機械器具製造業",
                        "childDataDict": {}
                    },
                    "E,30,302,0000": {
                        "name": "映像・音響機械器具製造業",
                        "childDataDict": {}
                    },
                    "E,30,303,0000": {
                        "name": "電子計算機・同附属装置製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,31,000,0000": {
                "name": "輸送用機械器具製造業",
                "childDataDict": {
                    "E,31,310,0000": {
                        "name": "管理，補助的経済活動を行う事業所（31輸送用機械器具製造業）",
                        "childDataDict": {}
                    },
                    "E,31,311,0000": {
                        "name": "自動車・同附属品製造業",
                        "childDataDict": {}
                    },
                    "E,31,312,0000": {
                        "name": "鉄道車両・同部分品製造業",
                        "childDataDict": {}
                    },
                    "E,31,313,0000": {
                        "name": "船舶製造・修理業，舶用機関製造業",
                        "childDataDict": {}
                    },
                    "E,31,314,0000": {
                        "name": "航空機・同附属品製造業",
                        "childDataDict": {}
                    },
                    "E,31,315,0000": {
                        "name": "産業用運搬車両・同部分品・附属品製造業",
                        "childDataDict": {}
                    },
                    "E,31,319,0000": {
                        "name": "その他の輸送用機械器具製造業",
                        "childDataDict": {}
                    }
                }
            },
            "E,32,000,0000": {
                "name": "その他の製造業",
                "childDataDict": {
                    "E,32,320,0000": {
                        "name": "管理，補助的経済活動を行う事業所（32その他の製造業）",
                        "childDataDict": {}
                    },
                    "E,32,321,0000": {
                        "name": "貴金属・宝石製品製造業",
                        "childDataDict": {}
                    },
                    "E,32,322,0000": {
                        "name": "装身具・装飾品・ボタン・同関連品製造業（貴金属・宝石製を除く）",
                        "childDataDict": {}
                    },
                    "E,32,323,0000": {
                        "name": "時計・同部分品製造業",
                        "childDataDict": {}
                    },
                    "E,32,324,0000": {
                        "name": "楽器製造業",
                        "childDataDict": {}
                    },
                    "E,32,325,0000": {
                        "name": "がん具・運動用具製造業",
                        "childDataDict": {}
                    },
                    "E,32,326,0000": {
                        "name": "ペン・鉛筆・絵画用品・その他の事務用品製造業",
                        "childDataDict": {}
                    },
                    "E,32,327,0000": {
                        "name": "漆器製造業",
                        "childDataDict": {}
                    },
                    "E,32,328,0000": {
                        "name": "畳等生活雑貨製品製造業",
                        "childDataDict": {}
                    },
                    "E,32,329,0000": {
                        "name": "他に分類されない製造業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "F,00,000,0000": {
        "name": "電気・ガス・熱供給・水道業",
        "childDataDict": {
            "F,33,000,0000": {
                "name": "電気業",
                "childDataDict": {
                    "F,33,330,0000": {
                        "name": "管理，補助的経済活動を行う事業所（33電気業）",
                        "childDataDict": {}
                    },
                    "F,33,331,0000": {
                        "name": "電気業",
                        "childDataDict": {}
                    }
                }
            },
            "F,34,000,0000": {
                "name": "ガス業",
                "childDataDict": {
                    "F,34,340,0000": {
                        "name": "管理，補助的経済活動を行う事業所（34ガス業）",
                        "childDataDict": {}
                    },
                    "F,34,341,0000": {
                        "name": "ガス業",
                        "childDataDict": {}
                    }
                }
            },
            "F,35,000,0000": {
                "name": "熱供給業",
                "childDataDict": {
                    "F,35,350,0000": {
                        "name": "管理，補助的経済活動を行う事業所（35熱供給業）",
                        "childDataDict": {}
                    },
                    "F,35,351,0000": {
                        "name": "熱供給業",
                        "childDataDict": {}
                    }
                }
            },
            "F,36,000,0000": {
                "name": "水道業",
                "childDataDict": {
                    "F,36,360,0000": {
                        "name": "管理，補助的経済活動を行う事業所（36水道業）",
                        "childDataDict": {}
                    },
                    "F,36,361,0000": {
                        "name": "上水道業",
                        "childDataDict": {}
                    },
                    "F,36,362,0000": {
                        "name": "工業用水道業",
                        "childDataDict": {}
                    },
                    "F,36,363,0000": {
                        "name": "下水道業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "G,00,000,0000": {
        "name": "情報通信業",
        "childDataDict": {
            "G,37,000,0000": {
                "name": "通信業",
                "childDataDict": {
                    "G,37,370,0000": {
                        "name": "管理，補助的経済活動を行う事業所（37通信業）",
                        "childDataDict": {}
                    },
                    "G,37,371,0000": {
                        "name": "固定電気通信業",
                        "childDataDict": {}
                    },
                    "G,37,372,0000": {
                        "name": "移動電気通信業",
                        "childDataDict": {}
                    },
                    "G,37,373,0000": {
                        "name": "電気通信に附帯するサービス業",
                        "childDataDict": {}
                    }
                }
            },
            "G,38,000,0000": {
                "name": "放送業",
                "childDataDict": {
                    "G,38,380,0000": {
                        "name": "管理，補助的経済活動を行う事業所（38放送業）",
                        "childDataDict": {}
                    },
                    "G,38,381,0000": {
                        "name": "公共放送業（有線放送業を除く）",
                        "childDataDict": {}
                    },
                    "G,38,382,0000": {
                        "name": "民間放送業（有線放送業を除く）",
                        "childDataDict": {}
                    },
                    "G,38,383,0000": {
                        "name": "有線放送業",
                        "childDataDict": {}
                    }
                }
            },
            "G,39,000,0000": {
                "name": "情報サービス業",
                "childDataDict": {
                    "G,39,390,0000": {
                        "name": "管理，補助的経済活動を行う事業所（39情報サービス業）",
                        "childDataDict": {}
                    },
                    "G,39,391,0000": {
                        "name": "ソフトウェア業",
                        "childDataDict": {}
                    },
                    "G,39,392,0000": {
                        "name": "情報処理・提供サービス業",
                        "childDataDict": {}
                    }
                }
            },
            "G,40,000,0000": {
                "name": "インターネット附随サービス業",
                "childDataDict": {
                    "G,40,400,0000": {
                        "name": "管理，補助的経済活動を行う事業所（40インターネット附随サービス業）",
                        "childDataDict": {}
                    },
                    "G,40,401,0000": {
                        "name": "インターネット附随サービス業",
                        "childDataDict": {}
                    }
                }
            },
            "G,41,000,0000": {
                "name": "映像・音声・文字情報制作業",
                "childDataDict": {
                    "G,41,410,0000": {
                        "name": "管理，補助的経済活動を行う事業所（41映像・音声・文字情報制作業）",
                        "childDataDict": {}
                    },
                    "G,41,411,0000": {
                        "name": "映像情報制作・配給業",
                        "childDataDict": {}
                    },
                    "G,41,412,0000": {
                        "name": "音声情報制作業",
                        "childDataDict": {}
                    },
                    "G,41,413,0000": {
                        "name": "新聞業",
                        "childDataDict": {}
                    },
                    "G,41,414,0000": {
                        "name": "出版業",
                        "childDataDict": {}
                    },
                    "G,41,415,0000": {
                        "name": "広告制作業",
                        "childDataDict": {}
                    },
                    "G,41,416,0000": {
                        "name": "映像・音声・文字情報制作に附帯するサービス業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "H,00,000,0000": {
        "name": "運輸業，郵便業",
        "childDataDict": {
            "H,42,000,0000": {
                "name": "鉄道業",
                "childDataDict": {
                    "H,42,420,0000": {
                        "name": "管理，補助的経済活動を行う事業所（42鉄道業）",
                        "childDataDict": {}
                    },
                    "H,42,421,0000": {
                        "name": "鉄道業",
                        "childDataDict": {}
                    }
                }
            },
            "H,43,000,0000": {
                "name": "道路旅客運送業",
                "childDataDict": {
                    "H,43,430,0000": {
                        "name": "管理，補助的経済活動を行う事業所（43道路旅客運送業）",
                        "childDataDict": {}
                    },
                    "H,43,431,0000": {
                        "name": "一般乗合旅客自動車運送業",
                        "childDataDict": {}
                    },
                    "H,43,432,0000": {
                        "name": "一般乗用旅客自動車運送業",
                        "childDataDict": {}
                    },
                    "H,43,433,0000": {
                        "name": "一般貸切旅客自動車運送業",
                        "childDataDict": {}
                    },
                    "H,43,439,0000": {
                        "name": "その他の道路旅客運送業",
                        "childDataDict": {}
                    }
                }
            },
            "H,44,000,0000": {
                "name": "道路貨物運送業",
                "childDataDict": {
                    "H,44,440,0000": {
                        "name": "管理，補助的経済活動を行う事業所（44道路貨物運送業）",
                        "childDataDict": {}
                    },
                    "H,44,441,0000": {
                        "name": "一般貨物自動車運送業",
                        "childDataDict": {}
                    },
                    "H,44,442,0000": {
                        "name": "特定貨物自動車運送業",
                        "childDataDict": {}
                    },
                    "H,44,443,0000": {
                        "name": "貨物軽自動車運送業",
                        "childDataDict": {}
                    },
                    "H,44,444,0000": {
                        "name": "集配利用運送業",
                        "childDataDict": {}
                    },
                    "H,44,449,0000": {
                        "name": "その他の道路貨物運送業",
                        "childDataDict": {}
                    }
                }
            },
            "H,45,000,0000": {
                "name": "水運業",
                "childDataDict": {
                    "H,45,450,0000": {
                        "name": "管理，補助的経済活動を行う事業所（45水運業）",
                        "childDataDict": {}
                    },
                    "H,45,451,0000": {
                        "name": "外航海運業",
                        "childDataDict": {}
                    },
                    "H,45,452,0000": {
                        "name": "沿海海運業",
                        "childDataDict": {}
                    },
                    "H,45,453,0000": {
                        "name": "内陸水運業",
                        "childDataDict": {}
                    },
                    "H,45,454,0000": {
                        "name": "船舶貸渡業",
                        "childDataDict": {}
                    }
                }
            },
            "H,46,000,0000": {
                "name": "航空運輸業",
                "childDataDict": {
                    "H,46,460,0000": {
                        "name": "管理，補助的経済活動を行う事業所（46航空運輸業）",
                        "childDataDict": {}
                    },
                    "H,46,461,0000": {
                        "name": "航空運送業",
                        "childDataDict": {}
                    },
                    "H,46,462,0000": {
                        "name": "航空機使用業（航空運送業を除く）",
                        "childDataDict": {}
                    }
                }
            },
            "H,47,000,0000": {
                "name": "倉庫業",
                "childDataDict": {
                    "H,47,470,0000": {
                        "name": "管理，補助的経済活動を行う事業所（47倉庫業）",
                        "childDataDict": {}
                    },
                    "H,47,471,0000": {
                        "name": "倉庫業（冷蔵倉庫業を除く）",
                        "childDataDict": {}
                    },
                    "H,47,472,0000": {
                        "name": "冷蔵倉庫業",
                        "childDataDict": {}
                    }
                }
            },
            "H,48,000,0000": {
                "name": "運輸に附帯するサービス業",
                "childDataDict": {
                    "H,48,480,0000": {
                        "name": "管理，補助的経済活動を行う事業所（48運輸に附帯するサービス業）",
                        "childDataDict": {}
                    },
                    "H,48,481,0000": {
                        "name": "港湾運送業",
                        "childDataDict": {}
                    },
                    "H,48,482,0000": {
                        "name": "貨物運送取扱業（集配利用運送業を除く）",
                        "childDataDict": {}
                    },
                    "H,48,483,0000": {
                        "name": "運送代理店",
                        "childDataDict": {}
                    },
                    "H,48,484,0000": {
                        "name": "こん包業",
                        "childDataDict": {}
                    },
                    "H,48,485,0000": {
                        "name": "運輸施設提供業",
                        "childDataDict": {}
                    },
                    "H,48,489,0000": {
                        "name": "その他の運輸に附帯するサービス業",
                        "childDataDict": {}
                    }
                }
            },
            "H,49,000,0000": {
                "name": "郵便業（信書便事業を含む）",
                "childDataDict": {
                    "H,49,490,0000": {
                        "name": "管理，補助的経済活動を行う事業所（49郵便業）",
                        "childDataDict": {}
                    },
                    "H,49,491,0000": {
                        "name": "郵便業（信書便事業を含む）",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "I,00,000,0000": {
        "name": "卸売業，小売業",
        "childDataDict": {
            "I,50,000,0000": {
                "name": "各種商品卸売業",
                "childDataDict": {
                    "I,50,500,0000": {
                        "name": "管理，補助的経済活動を行う事業所（50各種商品卸売業）",
                        "childDataDict": {}
                    },
                    "I,50,501,0000": {
                        "name": "各種商品卸売業",
                        "childDataDict": {}
                    }
                }
            },
            "I,51,000,0000": {
                "name": "繊維・衣服等卸売業",
                "childDataDict": {
                    "I,51,510,0000": {
                        "name": "管理，補助的経済活動を行う事業所（51繊維・衣服等卸売業）",
                        "childDataDict": {}
                    },
                    "I,51,511,0000": {
                        "name": "繊維品卸売業（衣服，身の回り品を除く）",
                        "childDataDict": {}
                    },
                    "I,51,512,0000": {
                        "name": "衣服卸売業",
                        "childDataDict": {}
                    },
                    "I,51,513,0000": {
                        "name": "身の回り品卸売業",
                        "childDataDict": {}
                    }
                }
            },
            "I,52,000,0000": {
                "name": "飲食料品卸売業",
                "childDataDict": {
                    "I,52,520,0000": {
                        "name": "管理，補助的経済活動を行う事業所（52飲食料品卸売業）",
                        "childDataDict": {}
                    },
                    "I,52,521,0000": {
                        "name": "農畜産物・水産物卸売業",
                        "childDataDict": {}
                    },
                    "I,52,522,0000": {
                        "name": "食料・飲料卸売業",
                        "childDataDict": {}
                    }
                }
            },
            "I,53,000,0000": {
                "name": "建築材料，鉱物・金属材料等卸売業",
                "childDataDict": {
                    "I,53,530,0000": {
                        "name": "管理，補助的経済活動を行う事業所（53建築材料，鉱物・金属材料等卸売業）",
                        "childDataDict": {}
                    },
                    "I,53,531,0000": {
                        "name": "建築材料卸売業",
                        "childDataDict": {}
                    },
                    "I,53,532,0000": {
                        "name": "化学製品卸売業",
                        "childDataDict": {}
                    },
                    "I,53,533,0000": {
                        "name": "石油・鉱物卸売業",
                        "childDataDict": {}
                    },
                    "I,53,534,0000": {
                        "name": "鉄鋼製品卸売業",
                        "childDataDict": {}
                    },
                    "I,53,535,0000": {
                        "name": "非鉄金属卸売業",
                        "childDataDict": {}
                    },
                    "I,53,536,0000": {
                        "name": "再生資源卸売業",
                        "childDataDict": {}
                    }
                }
            },
            "I,54,000,0000": {
                "name": "機械器具卸売業",
                "childDataDict": {
                    "I,54,540,0000": {
                        "name": "管理，補助的経済活動を行う事業所（54機械器具卸売業）",
                        "childDataDict": {}
                    },
                    "I,54,541,0000": {
                        "name": "産業機械器具卸売業",
                        "childDataDict": {}
                    },
                    "I,54,542,0000": {
                        "name": "自動車卸売業",
                        "childDataDict": {}
                    },
                    "I,54,543,0000": {
                        "name": "電気機械器具卸売業",
                        "childDataDict": {}
                    },
                    "I,54,549,0000": {
                        "name": "その他の機械器具卸売業",
                        "childDataDict": {}
                    }
                }
            },
            "I,55,000,0000": {
                "name": "その他の卸売業",
                "childDataDict": {
                    "I,55,550,0000": {
                        "name": "管理，補助的経済活動を行う事業所（55その他の卸売業）",
                        "childDataDict": {}
                    },
                    "I,55,551,0000": {
                        "name": "家具・建具・じゅう器等卸売業",
                        "childDataDict": {}
                    },
                    "I,55,552,0000": {
                        "name": "医薬品・化粧品等卸売業",
                        "childDataDict": {}
                    },
                    "I,55,553,0000": {
                        "name": "紙・紙製品卸売業",
                        "childDataDict": {}
                    },
                    "I,55,559,0000": {
                        "name": "他に分類されない卸売業",
                        "childDataDict": {}
                    }
                }
            },
            "I,56,000,0000": {
                "name": "各種商品小売業",
                "childDataDict": {
                    "I,56,560,0000": {
                        "name": "管理，補助的経済活動を行う事業所（56各種商品小売業）",
                        "childDataDict": {}
                    },
                    "I,56,561,0000": {
                        "name": "百貨店，総合スーパー",
                        "childDataDict": {}
                    },
                    "I,56,569,0000": {
                        "name": "その他の各種商品小売業（従業者が常時50人未満のもの）",
                        "childDataDict": {}
                    }
                }
            },
            "I,57,000,0000": {
                "name": "織物・衣服・身の回り品小売業",
                "childDataDict": {
                    "I,57,570,0000": {
                        "name": "管理，補助的経済活動を行う事業所（57織物・衣服・身の回り品小売業）",
                        "childDataDict": {}
                    },
                    "I,57,571,0000": {
                        "name": "呉服・服地・寝具小売業",
                        "childDataDict": {}
                    },
                    "I,57,572,0000": {
                        "name": "男子服小売業",
                        "childDataDict": {}
                    },
                    "I,57,573,0000": {
                        "name": "婦人・子供服小売業",
                        "childDataDict": {}
                    },
                    "I,57,574,0000": {
                        "name": "靴・履物小売業",
                        "childDataDict": {}
                    },
                    "I,57,579,0000": {
                        "name": "その他の織物・衣服・身の回り品小売業",
                        "childDataDict": {}
                    }
                }
            },
            "I,58,000,0000": {
                "name": "飲食料品小売業",
                "childDataDict": {
                    "I,58,580,0000": {
                        "name": "管理，補助的経済活動を行う事業所（58飲食料品小売業）",
                        "childDataDict": {}
                    },
                    "I,58,581,0000": {
                        "name": "各種食料品小売業",
                        "childDataDict": {}
                    },
                    "I,58,582,0000": {
                        "name": "野菜・果実小売業",
                        "childDataDict": {}
                    },
                    "I,58,583,0000": {
                        "name": "食肉小売業",
                        "childDataDict": {}
                    },
                    "I,58,584,0000": {
                        "name": "鮮魚小売業",
                        "childDataDict": {}
                    },
                    "I,58,585,0000": {
                        "name": "酒小売業",
                        "childDataDict": {}
                    },
                    "I,58,586,0000": {
                        "name": "菓子・パン小売業",
                        "childDataDict": {}
                    },
                    "I,58,589,0000": {
                        "name": "その他の飲食料品小売業",
                        "childDataDict": {}
                    }
                }
            },
            "I,59,000,0000": {
                "name": "機械器具小売業",
                "childDataDict": {
                    "I,59,590,0000": {
                        "name": "管理，補助的経済活動を行う事業所（59機械器具小売業）",
                        "childDataDict": {}
                    },
                    "I,59,591,0000": {
                        "name": "自動車小売業",
                        "childDataDict": {}
                    },
                    "I,59,592,0000": {
                        "name": "自転車小売業",
                        "childDataDict": {}
                    },
                    "I,59,593,0000": {
                        "name": "機械器具小売業（自動車，自転車を除く）",
                        "childDataDict": {}
                    }
                }
            },
            "I,60,000,0000": {
                "name": "その他の小売業",
                "childDataDict": {
                    "I,60,600,0000": {
                        "name": "管理，補助的経済活動を行う事業所（60その他の小売業）",
                        "childDataDict": {}
                    },
                    "I,60,601,0000": {
                        "name": "家具・建具・畳小売業",
                        "childDataDict": {}
                    },
                    "I,60,602,0000": {
                        "name": "じゅう器小売業",
                        "childDataDict": {}
                    },
                    "I,60,603,0000": {
                        "name": "医薬品・化粧品小売業",
                        "childDataDict": {}
                    },
                    "I,60,604,0000": {
                        "name": "農耕用品小売業",
                        "childDataDict": {}
                    },
                    "I,60,605,0000": {
                        "name": "燃料小売業",
                        "childDataDict": {}
                    },
                    "I,60,606,0000": {
                        "name": "書籍・文房具小売業",
                        "childDataDict": {}
                    },
                    "I,60,607,0000": {
                        "name": "スポーツ用品・がん具・娯楽用品・楽器小売業",
                        "childDataDict": {}
                    },
                    "I,60,608,0000": {
                        "name": "写真機・時計・眼鏡小売業",
                        "childDataDict": {}
                    },
                    "I,60,609,0000": {
                        "name": "他に分類されない小売業",
                        "childDataDict": {}
                    }
                }
            },
            "I,61,000,0000": {
                "name": "無店舗小売業",
                "childDataDict": {
                    "I,61,610,0000": {
                        "name": "管理，補助的経済活動を行う事業所（61無店舗小売業）",
                        "childDataDict": {}
                    },
                    "I,61,611,0000": {
                        "name": "通信販売・訪問販売小売業",
                        "childDataDict": {}
                    },
                    "I,61,612,0000": {
                        "name": "自動販売機による小売業",
                        "childDataDict": {}
                    },
                    "I,61,619,0000": {
                        "name": "その他の無店舗小売業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "J,00,000,0000": {
        "name": "金融業，保険業",
        "childDataDict": {
            "J,62,000,0000": {
                "name": "銀行業",
                "childDataDict": {
                    "J,62,620,0000": {
                        "name": "管理，補助的経済活動を行う事業所（62銀行業）",
                        "childDataDict": {}
                    },
                    "J,62,621,0000": {
                        "name": "中央銀行",
                        "childDataDict": {}
                    },
                    "J,62,622,0000": {
                        "name": "銀行（中央銀行を除く）",
                        "childDataDict": {}
                    }
                }
            },
            "J,63,000,0000": {
                "name": "協同組織金融業",
                "childDataDict": {
                    "J,63,630,0000": {
                        "name": "管理，補助的経済活動を行う事業所（63協同組織金融業）",
                        "childDataDict": {}
                    },
                    "J,63,631,0000": {
                        "name": "中小企業等金融業",
                        "childDataDict": {}
                    },
                    "J,63,632,0000": {
                        "name": "農林水産金融業",
                        "childDataDict": {}
                    }
                }
            },
            "J,64,000,0000": {
                "name": "貸金業，クレジットカード業等非預金信用機関",
                "childDataDict": {
                    "J,64,640,0000": {
                        "name": "管理，補助的経済活動を行う事業所（64貸金業，クレジットカード業等非預金信用機関）",
                        "childDataDict": {}
                    },
                    "J,64,641,0000": {
                        "name": "貸金業",
                        "childDataDict": {}
                    },
                    "J,64,642,0000": {
                        "name": "質屋",
                        "childDataDict": {}
                    },
                    "J,64,643,0000": {
                        "name": "クレジットカード業，割賦金融業",
                        "childDataDict": {}
                    },
                    "J,64,649,0000": {
                        "name": "その他の非預金信用機関",
                        "childDataDict": {}
                    }
                }
            },
            "J,65,000,0000": {
                "name": "金融商品取引業，商品先物取引業",
                "childDataDict": {
                    "J,65,650,0000": {
                        "name": "管理，補助的経済活動を行う事業所（65金融商品取引業，商品先物取引業）",
                        "childDataDict": {}
                    },
                    "J,65,651,0000": {
                        "name": "金融商品取引業",
                        "childDataDict": {}
                    },
                    "J,65,652,0000": {
                        "name": "商品先物取引業，商品投資顧問業",
                        "childDataDict": {}
                    }
                }
            },
            "J,66,000,0000": {
                "name": "補助的金融業等",
                "childDataDict": {
                    "J,66,660,0000": {
                        "name": "管理，補助的経済活動を行う事業所（66補助的金融業等）",
                        "childDataDict": {}
                    },
                    "J,66,661,0000": {
                        "name": "補助的金融業，金融附帯業",
                        "childDataDict": {}
                    },
                    "J,66,662,0000": {
                        "name": "信託業",
                        "childDataDict": {}
                    },
                    "J,66,663,0000": {
                        "name": "金融代理業",
                        "childDataDict": {}
                    }
                }
            },
            "J,67,000,0000": {
                "name": "保険業（保険媒介代理業，保険サービス業を含む）",
                "childDataDict": {
                    "J,67,670,0000": {
                        "name": "管理，補助的経済活動を行う事業所（67保険業）",
                        "childDataDict": {}
                    },
                    "J,67,671,0000": {
                        "name": "生命保険業",
                        "childDataDict": {}
                    },
                    "J,67,672,0000": {
                        "name": "損害保険業",
                        "childDataDict": {}
                    },
                    "J,67,673,0000": {
                        "name": "共済事業，少額短期保険業",
                        "childDataDict": {}
                    },
                    "J,67,674,0000": {
                        "name": "保険媒介代理業",
                        "childDataDict": {}
                    },
                    "J,67,675,0000": {
                        "name": "保険サービス業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "K,00,000,0000": {
        "name": "不動産業，物品賃貸業",
        "childDataDict": {
            "K,68,000,0000": {
                "name": "不動産取引業",
                "childDataDict": {
                    "K,68,680,0000": {
                        "name": "管理，補助的経済活動を行う事業所（68不動産取引業）",
                        "childDataDict": {}
                    },
                    "K,68,681,0000": {
                        "name": "建物売買業，土地売買業",
                        "childDataDict": {}
                    },
                    "K,68,682,0000": {
                        "name": "不動産代理業・仲介業",
                        "childDataDict": {}
                    }
                }
            },
            "K,69,000,0000": {
                "name": "不動産賃貸業・管理業",
                "childDataDict": {
                    "K,69,690,0000": {
                        "name": "管理，補助的経済活動を行う事業所（69不動産賃貸業・管理業）",
                        "childDataDict": {}
                    },
                    "K,69,691,0000": {
                        "name": "不動産賃貸業（貸家業，貸間業を除く）",
                        "childDataDict": {}
                    },
                    "K,69,692,0000": {
                        "name": "貸家業，貸間業",
                        "childDataDict": {}
                    },
                    "K,69,693,0000": {
                        "name": "駐車場業",
                        "childDataDict": {}
                    },
                    "K,69,694,0000": {
                        "name": "不動産管理業",
                        "childDataDict": {}
                    }
                }
            },
            "K,70,000,0000": {
                "name": "物品賃貸業",
                "childDataDict": {
                    "K,70,700,0000": {
                        "name": "管理，補助的経済活動を行う事業所（70物品賃貸業）",
                        "childDataDict": {}
                    },
                    "K,70,701,0000": {
                        "name": "各種物品賃貸業",
                        "childDataDict": {}
                    },
                    "K,70,702,0000": {
                        "name": "産業用機械器具賃貸業",
                        "childDataDict": {}
                    },
                    "K,70,703,0000": {
                        "name": "事務用機械器具賃貸業",
                        "childDataDict": {}
                    },
                    "K,70,704,0000": {
                        "name": "自動車賃貸業",
                        "childDataDict": {}
                    },
                    "K,70,705,0000": {
                        "name": "スポーツ・娯楽用品賃貸業",
                        "childDataDict": {}
                    },
                    "K,70,709,0000": {
                        "name": "その他の物品賃貸業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "L,00,000,0000": {
        "name": "学術研究，専門・技術サービス業",
        "childDataDict": {
            "L,71,000,0000": {
                "name": "学術・開発研究機関",
                "childDataDict": {
                    "L,71,710,0000": {
                        "name": "管理，補助的経済活動を行う事業所（71学術・開発研究機関）",
                        "childDataDict": {}
                    },
                    "L,71,711,0000": {
                        "name": "自然科学研究所",
                        "childDataDict": {}
                    },
                    "L,71,712,0000": {
                        "name": "人文・社会科学研究所",
                        "childDataDict": {}
                    }
                }
            },
            "L,72,000,0000": {
                "name": "専門サービス業（他に分類されないもの）",
                "childDataDict": {
                    "L,72,720,0000": {
                        "name": "管理，補助的経済活動を行う事業所（72専門サービス業）",
                        "childDataDict": {}
                    },
                    "L,72,721,0000": {
                        "name": "法律事務所，特許事務所",
                        "childDataDict": {}
                    },
                    "L,72,722,0000": {
                        "name": "公証人役場，司法書士事務所，土地家屋調査士事務所",
                        "childDataDict": {}
                    },
                    "L,72,723,0000": {
                        "name": "行政書士事務所",
                        "childDataDict": {}
                    },
                    "L,72,724,0000": {
                        "name": "公認会計士事務所，税理士事務所",
                        "childDataDict": {}
                    },
                    "L,72,725,0000": {
                        "name": "社会保険労務士事務所",
                        "childDataDict": {}
                    },
                    "L,72,726,0000": {
                        "name": "デザイン業",
                        "childDataDict": {}
                    },
                    "L,72,727,0000": {
                        "name": "著述・芸術家業",
                        "childDataDict": {}
                    },
                    "L,72,728,0000": {
                        "name": "経営コンサルタント業，純粋持株会社",
                        "childDataDict": {}
                    },
                    "L,72,729,0000": {
                        "name": "その他の専門サービス業",
                        "childDataDict": {}
                    }
                }
            },
            "L,73,000,0000": {
                "name": "広告業",
                "childDataDict": {
                    "L,73,730,0000": {
                        "name": "管理，補助的経済活動を行う事業所（73広告業）",
                        "childDataDict": {}
                    },
                    "L,73,731,0000": {
                        "name": "広告業",
                        "childDataDict": {}
                    }
                }
            },
            "L,74,000,0000": {
                "name": "技術サービス業（他に分類されないもの）",
                "childDataDict": {
                    "L,74,740,0000": {
                        "name": "管理，補助的経済活動を行う事業所（74技術サービス業）",
                        "childDataDict": {}
                    },
                    "L,74,741,0000": {
                        "name": "獣医業",
                        "childDataDict": {}
                    },
                    "L,74,742,0000": {
                        "name": "土木建築サービス業",
                        "childDataDict": {}
                    },
                    "L,74,743,0000": {
                        "name": "機械設計業",
                        "childDataDict": {}
                    },
                    "L,74,744,0000": {
                        "name": "商品・非破壊検査業",
                        "childDataDict": {}
                    },
                    "L,74,745,0000": {
                        "name": "計量証明業",
                        "childDataDict": {}
                    },
                    "L,74,746,0000": {
                        "name": "写真業",
                        "childDataDict": {}
                    },
                    "L,74,749,0000": {
                        "name": "その他の技術サービス業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "M,00,000,0000": {
        "name": "宿泊業，飲食サービス業",
        "childDataDict": {
            "M,75,000,0000": {
                "name": "宿泊業",
                "childDataDict": {
                    "M,75,750,0000": {
                        "name": "管理，補助的経済活動を行う事業所（75宿泊業）",
                        "childDataDict": {}
                    },
                    "M,75,751,0000": {
                        "name": "旅館，ホテル",
                        "childDataDict": {}
                    },
                    "M,75,752,0000": {
                        "name": "簡易宿所",
                        "childDataDict": {}
                    },
                    "M,75,753,0000": {
                        "name": "下宿業",
                        "childDataDict": {}
                    },
                    "M,75,759,0000": {
                        "name": "その他の宿泊業",
                        "childDataDict": {}
                    }
                }
            },
            "M,76,000,0000": {
                "name": "飲食店",
                "childDataDict": {
                    "M,76,760,0000": {
                        "name": "管理，補助的経済活動を行う事業所（76飲食店）",
                        "childDataDict": {}
                    },
                    "M,76,761,0000": {
                        "name": "食堂，レストラン（専門料理店を除く）",
                        "childDataDict": {}
                    },
                    "M,76,762,0000": {
                        "name": "専門料理店",
                        "childDataDict": {}
                    },
                    "M,76,763,0000": {
                        "name": "そば・うどん店",
                        "childDataDict": {}
                    },
                    "M,76,764,0000": {
                        "name": "すし店",
                        "childDataDict": {}
                    },
                    "M,76,765,0000": {
                        "name": "酒場，ビヤホール",
                        "childDataDict": {}
                    },
                    "M,76,766,0000": {
                        "name": "バー，キャバレー，ナイトクラブ",
                        "childDataDict": {}
                    },
                    "M,76,767,0000": {
                        "name": "喫茶店",
                        "childDataDict": {}
                    },
                    "M,76,769,0000": {
                        "name": "その他の飲食店",
                        "childDataDict": {}
                    }
                }
            },
            "M,77,000,0000": {
                "name": "持ち帰り・配達飲食サービス業",
                "childDataDict": {
                    "M,77,770,0000": {
                        "name": "管理，補助的経済活動を行う事業所（77持ち帰り・配達飲食サービス業）",
                        "childDataDict": {}
                    },
                    "M,77,771,0000": {
                        "name": "持ち帰り飲食サービス業",
                        "childDataDict": {}
                    },
                    "M,77,772,0000": {
                        "name": "配達飲食サービス業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "N,00,000,0000": {
        "name": "生活関連サービス業，娯楽業",
        "childDataDict": {
            "N,78,000,0000": {
                "name": "洗濯・理容・美容・浴場業",
                "childDataDict": {
                    "N,78,780,0000": {
                        "name": "管理，補助的経済活動を行う事業所（78洗濯・理容・美容・浴場業）",
                        "childDataDict": {}
                    },
                    "N,78,781,0000": {
                        "name": "洗濯業",
                        "childDataDict": {}
                    },
                    "N,78,782,0000": {
                        "name": "理容業",
                        "childDataDict": {}
                    },
                    "N,78,783,0000": {
                        "name": "美容業",
                        "childDataDict": {}
                    },
                    "N,78,784,0000": {
                        "name": "一般公衆浴場業",
                        "childDataDict": {}
                    },
                    "N,78,785,0000": {
                        "name": "その他の公衆浴場業",
                        "childDataDict": {}
                    },
                    "N,78,789,0000": {
                        "name": "その他の洗濯・理容・美容・浴場業",
                        "childDataDict": {}
                    }
                }
            },
            "N,79,000,0000": {
                "name": "その他の生活関連サービス業",
                "childDataDict": {
                    "N,79,790,0000": {
                        "name": "管理，補助的経済活動を行う事業所（79その他の生活関連サービス業）",
                        "childDataDict": {}
                    },
                    "N,79,791,0000": {
                        "name": "旅行業",
                        "childDataDict": {}
                    },
                    "N,79,792,0000": {
                        "name": "家事サービス業",
                        "childDataDict": {}
                    },
                    "N,79,793,0000": {
                        "name": "衣服裁縫修理業",
                        "childDataDict": {}
                    },
                    "N,79,794,0000": {
                        "name": "物品預り業",
                        "childDataDict": {}
                    },
                    "N,79,795,0000": {
                        "name": "火葬・墓地管理業",
                        "childDataDict": {}
                    },
                    "N,79,796,0000": {
                        "name": "冠婚葬祭業",
                        "childDataDict": {}
                    },
                    "N,79,799,0000": {
                        "name": "他に分類されない生活関連サービス業",
                        "childDataDict": {}
                    }
                }
            },
            "N,80,000,0000": {
                "name": "娯楽業",
                "childDataDict": {
                    "N,80,800,0000": {
                        "name": "管理，補助的経済活動を行う事業所（80娯楽業）",
                        "childDataDict": {}
                    },
                    "N,80,801,0000": {
                        "name": "映画館",
                        "childDataDict": {}
                    },
                    "N,80,802,0000": {
                        "name": "興行場（別掲を除く），興行団",
                        "childDataDict": {}
                    },
                    "N,80,803,0000": {
                        "name": "競輪・競馬等の競走場，競技団",
                        "childDataDict": {}
                    },
                    "N,80,804,0000": {
                        "name": "スポーツ施設提供業",
                        "childDataDict": {}
                    },
                    "N,80,805,0000": {
                        "name": "公園，遊園地",
                        "childDataDict": {}
                    },
                    "N,80,806,0000": {
                        "name": "遊戯場",
                        "childDataDict": {}
                    },
                    "N,80,809,0000": {
                        "name": "その他の娯楽業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "O,00,000,0000": {
        "name": "教育，学習支援業",
        "childDataDict": {
            "O,81,000,0000": {
                "name": "学校教育",
                "childDataDict": {
                    "O,81,810,0000": {
                        "name": "管理，補助的経済活動を行う事業所（81学校教育）",
                        "childDataDict": {}
                    },
                    "O,81,811,0000": {
                        "name": "幼稚園",
                        "childDataDict": {}
                    },
                    "O,81,812,0000": {
                        "name": "小学校",
                        "childDataDict": {}
                    },
                    "O,81,813,0000": {
                        "name": "中学校",
                        "childDataDict": {}
                    },
                    "O,81,814,0000": {
                        "name": "高等学校，中等教育学校",
                        "childDataDict": {}
                    },
                    "O,81,815,0000": {
                        "name": "特別支援学校",
                        "childDataDict": {}
                    },
                    "O,81,816,0000": {
                        "name": "高等教育機関",
                        "childDataDict": {}
                    },
                    "O,81,817,0000": {
                        "name": "専修学校，各種学校",
                        "childDataDict": {}
                    },
                    "O,81,818,0000": {
                        "name": "学校教育支援機関",
                        "childDataDict": {}
                    },
                    "O,81,819,0000": {
                        "name": "幼保連携型認定こども園",
                        "childDataDict": {}
                    }
                }
            },
            "O,82,000,0000": {
                "name": "その他の教育，学習支援業",
                "childDataDict": {
                    "O,82,820,0000": {
                        "name": "管理，補助的経済活動を行う事業所（82その他の教育，学習支援業）",
                        "childDataDict": {}
                    },
                    "O,82,821,0000": {
                        "name": "社会教育",
                        "childDataDict": {}
                    },
                    "O,82,822,0000": {
                        "name": "職業・教育支援施設",
                        "childDataDict": {}
                    },
                    "O,82,823,0000": {
                        "name": "学習塾",
                        "childDataDict": {}
                    },
                    "O,82,824,0000": {
                        "name": "教養・技能教授業",
                        "childDataDict": {}
                    },
                    "O,82,829,0000": {
                        "name": "他に分類されない教育，学習支援業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "P,00,000,0000": {
        "name": "医療，福祉",
        "childDataDict": {
            "P,83,000,0000": {
                "name": "医療業",
                "childDataDict": {
                    "P,83,830,0000": {
                        "name": "管理，補助的経済活動を行う事業所（83医療業）",
                        "childDataDict": {}
                    },
                    "P,83,831,0000": {
                        "name": "病院",
                        "childDataDict": {}
                    },
                    "P,83,832,0000": {
                        "name": "一般診療所",
                        "childDataDict": {}
                    },
                    "P,83,833,0000": {
                        "name": "歯科診療所",
                        "childDataDict": {}
                    },
                    "P,83,834,0000": {
                        "name": "助産・看護業",
                        "childDataDict": {}
                    },
                    "P,83,835,0000": {
                        "name": "療術業",
                        "childDataDict": {}
                    },
                    "P,83,836,0000": {
                        "name": "医療に附帯するサービス業",
                        "childDataDict": {}
                    }
                }
            },
            "P,84,000,0000": {
                "name": "保健衛生",
                "childDataDict": {
                    "P,84,840,0000": {
                        "name": "管理，補助的経済活動を行う事業所（84保健衛生）",
                        "childDataDict": {}
                    },
                    "P,84,841,0000": {
                        "name": "保健所",
                        "childDataDict": {}
                    },
                    "P,84,842,0000": {
                        "name": "健康相談施設",
                        "childDataDict": {}
                    },
                    "P,84,849,0000": {
                        "name": "その他の保健衛生",
                        "childDataDict": {}
                    }
                }
            },
            "P,85,000,0000": {
                "name": "社会保険・社会福祉・介護事業",
                "childDataDict": {
                    "P,85,850,0000": {
                        "name": "管理，補助的経済活動を行う事業所（85社会保険・社会福祉・介護事業）",
                        "childDataDict": {}
                    },
                    "P,85,851,0000": {
                        "name": "社会保険事業団体",
                        "childDataDict": {}
                    },
                    "P,85,852,0000": {
                        "name": "福祉事務所",
                        "childDataDict": {}
                    },
                    "P,85,853,0000": {
                        "name": "児童福祉事業",
                        "childDataDict": {}
                    },
                    "P,85,854,0000": {
                        "name": "老人福祉・介護事業",
                        "childDataDict": {}
                    },
                    "P,85,855,0000": {
                        "name": "障害者福祉事業",
                        "childDataDict": {}
                    },
                    "P,85,859,0000": {
                        "name": "その他の社会保険・社会福祉・介護事業",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "Q,00,000,0000": {
        "name": "複合サービス事業",
        "childDataDict": {
            "Q,86,000,0000": {
                "name": "郵便局",
                "childDataDict": {
                    "Q,86,860,0000": {
                        "name": "管理，補助的経済活動を行う事業所（86郵便局）",
                        "childDataDict": {}
                    },
                    "Q,86,861,0000": {
                        "name": "郵便局",
                        "childDataDict": {}
                    },
                    "Q,86,862,0000": {
                        "name": "郵便局受託業",
                        "childDataDict": {}
                    }
                }
            },
            "Q,87,000,0000": {
                "name": "協同組合（他に分類されないもの）",
                "childDataDict": {
                    "Q,87,870,0000": {
                        "name": "管理，補助的経済活動を行う事業所（87協同組合）",
                        "childDataDict": {}
                    },
                    "Q,87,871,0000": {
                        "name": "農林水産業協同組合（他に分類されないもの）",
                        "childDataDict": {}
                    },
                    "Q,87,872,0000": {
                        "name": "事業協同組合（他に分類されないもの）",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "R,00,000,0000": {
        "name": "サービス業（他に分類されないもの）",
        "childDataDict": {
            "R,88,000,0000": {
                "name": "廃棄物処理業",
                "childDataDict": {
                    "R,88,880,0000": {
                        "name": "管理，補助的経済活動を行う事業所（88廃棄物処理業）",
                        "childDataDict": {}
                    },
                    "R,88,881,0000": {
                        "name": "一般廃棄物処理業",
                        "childDataDict": {}
                    },
                    "R,88,882,0000": {
                        "name": "産業廃棄物処理業",
                        "childDataDict": {}
                    },
                    "R,88,889,0000": {
                        "name": "その他の廃棄物処理業",
                        "childDataDict": {}
                    }
                }
            },
            "R,89,000,0000": {
                "name": "自動車整備業",
                "childDataDict": {
                    "R,89,890,0000": {
                        "name": "管理，補助的経済活動を行う事業所（89自動車整備業）",
                        "childDataDict": {}
                    },
                    "R,89,891,0000": {
                        "name": "自動車整備業",
                        "childDataDict": {}
                    }
                }
            },
            "R,90,000,0000": {
                "name": "機械等修理業（別掲を除く）",
                "childDataDict": {
                    "R,90,900,0000": {
                        "name": "管理，補助的経済活動を行う事業所（90機械等修理業）",
                        "childDataDict": {}
                    },
                    "R,90,901,0000": {
                        "name": "機械修理業（電気機械器具を除く）",
                        "childDataDict": {}
                    },
                    "R,90,902,0000": {
                        "name": "電気機械器具修理業",
                        "childDataDict": {}
                    },
                    "R,90,903,0000": {
                        "name": "表具業",
                        "childDataDict": {}
                    },
                    "R,90,909,0000": {
                        "name": "その他の修理業",
                        "childDataDict": {}
                    }
                }
            },
            "R,91,000,0000": {
                "name": "職業紹介・労働者派遣業",
                "childDataDict": {
                    "R,91,910,0000": {
                        "name": "管理，補助的経済活動を行う事業所（91職業紹介・労働者派遣業）",
                        "childDataDict": {}
                    },
                    "R,91,911,0000": {
                        "name": "職業紹介業",
                        "childDataDict": {}
                    },
                    "R,91,912,0000": {
                        "name": "労働者派遣業",
                        "childDataDict": {}
                    }
                }
            },
            "R,92,000,0000": {
                "name": "その他の事業サービス業",
                "childDataDict": {
                    "R,92,920,0000": {
                        "name": "管理，補助的経済活動を行う事業所（92その他の事業サービス業）",
                        "childDataDict": {}
                    },
                    "R,92,921,0000": {
                        "name": "速記・ワープロ入力・複写業",
                        "childDataDict": {}
                    },
                    "R,92,922,0000": {
                        "name": "建物サービス業",
                        "childDataDict": {}
                    },
                    "R,92,923,0000": {
                        "name": "警備業",
                        "childDataDict": {}
                    },
                    "R,92,929,0000": {
                        "name": "他に分類されない事業サービス業",
                        "childDataDict": {}
                    }
                }
            },
            "R,93,000,0000": {
                "name": "政治・経済・文化団体",
                "childDataDict": {
                    "R,93,931,0000": {
                        "name": "経済団体",
                        "childDataDict": {}
                    },
                    "R,93,932,0000": {
                        "name": "労働団体",
                        "childDataDict": {}
                    },
                    "R,93,933,0000": {
                        "name": "学術・文化団体",
                        "childDataDict": {}
                    },
                    "R,93,934,0000": {
                        "name": "政治団体",
                        "childDataDict": {}
                    },
                    "R,93,939,0000": {
                        "name": "他に分類されない非営利的団体",
                        "childDataDict": {}
                    }
                }
            },
            "R,94,000,0000": {
                "name": "宗教",
                "childDataDict": {
                    "R,94,941,0000": {
                        "name": "神道系宗教",
                        "childDataDict": {}
                    },
                    "R,94,942,0000": {
                        "name": "仏教系宗教",
                        "childDataDict": {}
                    },
                    "R,94,943,0000": {
                        "name": "キリスト教系宗教",
                        "childDataDict": {}
                    },
                    "R,94,949,0000": {
                        "name": "その他の宗教",
                        "childDataDict": {}
                    }
                }
            },
            "R,95,000,0000": {
                "name": "その他のサービス業",
                "childDataDict": {
                    "R,95,950,0000": {
                        "name": "管理，補助的経済活動を行う事業所（95その他のサービス業）",
                        "childDataDict": {}
                    },
                    "R,95,951,0000": {
                        "name": "集会場",
                        "childDataDict": {}
                    },
                    "R,95,952,0000": {
                        "name": "と畜場",
                        "childDataDict": {}
                    },
                    "R,95,959,0000": {
                        "name": "他に分類されないサービス業",
                        "childDataDict": {}
                    }
                }
            },
            "R,96,000,0000": {
                "name": "外国公務",
                "childDataDict": {
                    "R,96,961,0000": {
                        "name": "外国公館",
                        "childDataDict": {}
                    },
                    "R,96,969,0000": {
                        "name": "その他の外国公務",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "S,00,000,0000": {
        "name": "公務（他に分類されるものを除く）",
        "childDataDict": {
            "S,97,000,0000": {
                "name": "国家公務",
                "childDataDict": {
                    "S,97,971,0000": {
                        "name": "立法機関",
                        "childDataDict": {}
                    },
                    "S,97,972,0000": {
                        "name": "司法機関",
                        "childDataDict": {}
                    },
                    "S,97,973,0000": {
                        "name": "行政機関",
                        "childDataDict": {}
                    }
                }
            },
            "S,98,000,0000": {
                "name": "地方公務",
                "childDataDict": {
                    "S,98,981,0000": {
                        "name": "都道府県機関",
                        "childDataDict": {}
                    },
                    "S,98,982,0000": {
                        "name": "市町村機関",
                        "childDataDict": {}
                    }
                }
            }
        }
    },
    "T,00,000,0000": {
        "name": "分類不能の産業",
        "childDataDict": {
            "T,99,000,0000": {
                "name": "分類不能の産業",
                "childDataDict": {
                    "T,99,999,0000": {
                        "name": "分類不能の産業",
                        "childDataDict": {}
                    }
                }
            }
        }
    }
}