import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, View, Text, Button, CheckboxField, Link, useTheme, ScrollView, Heading} from '@aws-amplify/ui-react';
import { Helmet } from "react-helmet-async";
import { RxExternalLink } from "react-icons/rx";
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useTokens } from './AuthPage'; // AuthPageからuseTokensをインポート
import Header from '../commons/Header';
import Footer from '../commons/Footer';
import '../css/ChangePlan.css';
import '../css/agreement.css';

export default function ChangePlan() {
  // ヘッダ読み込み時処理完了フラグ
  const [isLoadedHeader, setIsLoadedHeader] = React.useState(false);
  const {tokens} = useTheme();
  const { getTokens } = useTokens(); // useTokensフックを使用
  const navigate = useNavigate();
  const [consentChecked, setConsentChecked] = useState(false);
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConsentChecked(e.target.checked);
  };  
  
  // 画面読み込み時
  React.useEffect(() => {
    const handleLoadHeader = async () => {
      // 最新のトークンを取得する
      try {
        // 最新のトークンを取得する
        const { decodedAccessTokenInfo, idToken } = await getTokens(); // トークンを取得
        setTokenInfo(decodedAccessTokenInfo); // 取得したdecodedAccessTokenInfoをセット
        setIdToken(idToken); // 取得したidTokenをセット
  
        // 画面内容の初期化（トークン取得後に行いたい処理をここに記述）
      } catch (error) {
        console.error("トークン取得エラー:", error);
      }
    }
    
    if (isLoadedHeader) {
      handleLoadHeader();
      setIsLoadedHeader(false);
    }
    
  },[isLoadedHeader]);
  
  
  
  // ボタン押下時の処理
  const planChangeButtonClick = () => {
    const userConfirmed = window.confirm('プラン変更画面に別ウインドウで遷移します。プラン変更後は本画面に戻り、一度ログアウトしてから再ログインしてください。よろしいですか？');
    if (userConfirmed) {
      // 画面遷移処理 (別タブでG-Horizon会員プラン変更画面を起動)
      const myaccountUrl: string = process.env.REACT_APP_MYACCOUNT_URL || '';
      const appId: string = process.env.REACT_APP_APPID || '';
      const changePlanUrl = `${myaccountUrl}/applications/${appId}/settings`;
      window.open(changePlanUrl, '_blank');
    } else {
    }
  };
  const unsubscribeButtonClick = () => {
    const userConfirmed = window.confirm('登録解除は当月末日付けとなります。そのため、当月中は企業INDEXナビをご利用いただけます。\r\nジー・サーチアカウントの画面に切り替わりますので、プラン変更→解約の順に進んでください。');
    if (userConfirmed) {
      // 画面遷移処理 (同タブ内でG-Horizon登録解除画面を起動)
      const myaccountUrl: string = process.env.REACT_APP_MYACCOUNT_URL || '';
      const appId: string = process.env.REACT_APP_APPID || '';
      const unsubscriptionUrl = `${myaccountUrl}/applications/${appId}/settings`;
      window.location.href = unsubscriptionUrl;
    } 
  };

  const isPlanChangeButtonDisabled = !consentChecked || tokenInfo?.role !== 'ADMIN' || tokenInfo?.plan === 'basic';
  
  //G-Searchデータベースサービスリンク押下時
  // エラーメッセージ
  const ERROR_MESSAGE_PROCESSING_ERROR = "処理中にエラーが発生しました。もう一度お試し下さい。";
  // プラン変更画面へ遷移
  const handleNavigatePlanChange = () => {
    navigate("/planchange");
  };
  // G-Searchデータベースサービスへ遷移(別タブ)
  const handleOpenGSearchDBS = () => {
    window.open("https://www.g-search.or.jp/", "_blank", "noreferrer");
  };

 return (
    <>
      <Helmet>
        <title>プラン変更 | 企業INDEXナビ</title>
      </Helmet>
      <View className="appContainer">
      <Header setIsLoadedHeader={setIsLoadedHeader}/>
      <Flex className="mainArea" backgroundColor={tokens.colors.blue[10]} direction="column" gap="0">
        <Flex padding="1.5rem" backgroundColor={tokens.colors.blue[20]} width="100%" justifyContent="center" alignItems="center" >
          <Text color={tokens.colors.blue[90]} height="30px" fontSize={tokens.fontSizes.xxl} fontWeight="bold" width={{base:"100%", large:"calc(100% - 32px)"}} maxWidth="1200px" padding={{base: "0 clamp(2rem, 1.92rem + 0.4vw, 2.4rem)",large:"0"}} >
            プラン変更
          </Text>
        </Flex>
        <View as="div" margin="4.8rem auto" width={{base: "100%", large:"calc(100% - 32px)"}} maxWidth="1200px" padding={{base: "0 clamp(2rem, 1.92rem + 0.4vw, 2.4rem)",large:"0"}} >
          <Text backgroundColor={tokens.colors.blue[60]} fontSize="2xl" fontWeight="bold" color={tokens.colors.white} alignSelf="center" padding="10px 0 10px 20px">
            企業INDEXナビ プラン変更
          </Text>
          <View padding="20px 25px 20px 25px" backgroundColor={tokens.colors.white}  alignSelf="center">
            <Text color={tokens.colors.black}>
              企業INDEXナビからG-Searchデータベースサービスの企業情報・新聞記事コンテンツのご利用や、<br />
              企業INDEXナビの詳細検索及び、詳細表示・ダウンロード件数100件以上のご利用には、有料プランへの変更が必要です。<br />
              以下の「プラン変更する」ボタンの押下後に、プランを選択いただけます。<br />
              ※プランのダウングレード（ベーシック→フリーPlusまたはフリー、フリーPlus→フリー）は選択できません。予めご了承ください。
            </Text>
          </View>
        </View>
  
      <View as="div" className="price_list pc" maxWidth="1200px" display={{base:"none",large:"block"}}>
          <View as="table" className="changeplan_table">
            <View as="thead" className="changeplan_thead">
              <View as="tr" className="changeplan_tr" ><View as="th" className="changeplan_th" ></View>
              <View as="th" className="changeplan_th plan plus">フリーPlus</View>
              <View as="th" className="changeplan_th plan basic">ベーシック</View>
            </View></View>
            <View as="tbody" className="changeplan_tbody" >
              <View as="tr" className="changeplan_tr plan_price">
                <View as="th" className="changeplan_th">月額料金</View>
                <View as="td" className="changeplan_td plus" >495円<View as="span">（税込）</View></View>
                <View as="td" className="changeplan_td basic" >22,000円<View as="span">（税込）</View></View>
              </View>
              <View as="tr" className="changeplan_tr" >
                <View as="th" className="changeplan_th" >従量課金<View as="span">※G-Search利用料</View></View>
                <View as="td" className="changeplan_td plus" >G-Searchデータベースサービス利用不可のため対象外です</View>
                <View as="td" className="changeplan_td basic" >G-Searchデータベースサービス利用不可のため対象外です</View>
              </View>
              <View as="tr" className="changeplan_tr" >
                <View as="th" className="changeplan_th" >企業リスト件数</View>
                <View as="td" className="changeplan_td plus" >
                  <View as="ul">
                    <View as="li">月100件まで</View>
                  </View>
                </View>
                <View as="td" className="changeplan_td basic" >
                  <View as="ul" className="List">
                    <View as="li">月3,000件まで</View>
                    <View as="li">ダウンロード件数の追加購入可能</View>
                  </View>
                </View>
              </View>
              <View as="tr" className="changeplan_tr" >
                <View as="th" className="changeplan_th" >企業データの表示情報</View>
                <View as="td" className="changeplan_td plus" >
                  <View as="ul">
                    <View as="li">全ての項目が閲覧可能</View>
                  </View>
                </View>
                <View as="td" className="changeplan_td basic" >
                  <View as="ul">
                    <View as="li">全ての項目が閲覧可能</View>
                  </View>
                </View>
              </View>
              <View as="tr" className="changeplan_tr price_search">
                <View as="th" className="changeplan_th" >検索項目</View>
                <View as="td" className="changeplan_td plus" >
                  <View as="dl" className="changeplan_dl List">
                    <View as="dt" className="changeplan_dt">基本条件</View>
                    <View as="dd" className="changeplan_dd">業種</View>
                    <View as="dd" className="changeplan_dd">地域</View>
                    <View as="dd" className="changeplan_dd">企業名</View>
                  </View>
                </View>
                <View as="td" className="changeplan_td basic" >
                  <View as="dl" className="changeplan_dl List">
                    <View as="dt" className="changeplan_dt">基本条件</View>
                    <View as="dd" className="changeplan_dd">業種</View>
                    <View as="dd" className="changeplan_dd">地域</View>
                    <View as="dd" className="changeplan_dd">企業名</View>
                  </View>
                  <View as="dl" className="changeplan_dl List">
                    <View as="dt" className="changeplan_dt">詳細条件</View>
                    <View as="dd" className="changeplan_dd">事業内容</View>
                    <View as="dd" className="changeplan_dd">企業売上</View>
                    <View as="dd" className="changeplan_dd">従業員数</View>
                    <View as="dd" className="changeplan_dd">設立年</View>
                    <View as="dd" className="changeplan_dd">資本金</View>
                    <View as="dd" className="changeplan_dd">サイト有無</View>
                    <View as="dd" className="changeplan_dd">お問合せURL有無</View>
                    <View as="dd" className="changeplan_dd">上場非上場</View>
                    <View as="dd" className="changeplan_dd">閉鎖企業有無</View>
                    <View as="dd" className="changeplan_dd">出力済み企業有無</View>
                  </View>
                </View>
              </View>
              <View as="tr" className="changeplan_tr" >
                <View as="th" className="changeplan_th" >企業情報の深掘り<View as="span">※G-Searchが利用できます</View></View>
                <View as="td" className="changeplan_td plus">
                  <a tabIndex={0} onClick={handleOpenGSearchDBS} className="underline pointer-cursor"
                      onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                      e.preventDefault();
                                      handleOpenGSearchDBS();
                                    }
                                  }}>G-Searchデータベースサービス
                    <View as="span" className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                        open_in_new
                    </View>
                  </a>との連携で、リストアップした企業の深掘りデータの利用が可能
                </View>
                <View as="td" className="changeplan_td basic">
                  <a tabIndex={0} onClick={handleOpenGSearchDBS} className="underline pointer-cursor"
                      onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                      e.preventDefault();
                                      handleOpenGSearchDBS();
                                    }
                                  }}>G-Searchデータベースサービス
                    <View as="span" className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                        open_in_new
                    </View>
                  </a>との連携で、リストアップした企業の深掘りデータの利用が可能
                </View>
              </View>
            </View>
          </View>
        </View>
        
        <View as="div" className="price_list price_list_col sp" display={{base:"flex",large:"none"}}>
          <View as="div" className="price_list_col_inner">
            <Heading level={3} className="plan plus">フリーPlus</Heading>
            <View as="table" className="changeplan_table price_list_plus">
              <View as="tbody" className="changeplan_tbody" >
                <View as="tr" className="changeplan_tr plan_price">
                  <View as="th" className="changeplan_th" >月額料金</View>
                  <View as="td" className="changeplan_td plus" >495円<View as="span">（税込）</View></View>
                </View>
                <View as="tr" className="changeplan_tr" >
                  <View as="th" className="changeplan_th" >従量課金<View as="span">※G-Search利用料</View></View>
                  <View as="td" className="changeplan_td">G-Searchデータベースサービス利用不可のため対象外です</View>
                </View>
                <View as="tr" className="changeplan_tr" >
                  <View as="th" className="changeplan_th" >企業リスト件数</View>
                  <View as="td" className="changeplan_td">
                    <View as="ul">
                      <View as="li">月100件まで</View>
                    </View>
                  </View>
                </View>
                <View as="tr" className="changeplan_tr" >
                  <View as="th" className="changeplan_th" >企業データの表示情報</View>
                  <View as="td" className="changeplan_td">
                    <View as="ul">
                      <View as="li">全ての項目が閲覧可能</View>
                    </View>
                  </View>
                </View>
                <View as="tr" className="changeplan_tr price_search">
                  <View as="th" className="changeplan_th" >検索項目</View>
                  <View as="td" className="changeplan_td">
                    <View as="dl" className="changeplan_dl List">
                      <View as="dt" className="changeplan_dt">基本条件</View>
                      <View as="dd" className="changeplan_dd">業種</View>
                      <View as="dd" className="changeplan_dd">地域</View>
                      <View as="dd" className="changeplan_dd">企業名</View>
                    </View>
                  </View>
                </View>
                <View as="tr" className="changeplan_tr" >
                  <View as="th" className="changeplan_th" >企業情報の深掘り<View as="span">※G-Searchが利用できます</View></View>
                  <View as="td" className="changeplan_td"><a tabIndex={0} href="#" className="underline">G-Searchデータベースサービス<View as="span" className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                        open_in_new
                      </View></a>との連携で、リストアップした企業の深掘りデータの利用が可能</View>
                </View>
              </View>
            </View>
          </View>
          <View as="div" className="price_list_col_inner">
            <Heading level={3} className="plan plus">ベーシック</Heading>
            <View as="table" className="changeplan_table price_list_basic">
              <View as="tbody" className="changeplan_tbody" >
                <View as="tr" className="changeplan_tr plan_price">
                  <View as="th" className="changeplan_th" >月額料金</View>
                  <View as="td" className="changeplan_td plus">22,000円<View as="span">（税込）</View></View>
                </View>
                <View as="tr" className="changeplan_tr" >
                  <View as="th" className="changeplan_th" >従量課金<View as="span">※G-Search利用料</View></View>
                  <View as="td" className="changeplan_td">G-Searchデータベースサービス利用不可のため対象外です</View>
                </View>
                <View as="tr" className="changeplan_tr" >
                  <View as="th" className="changeplan_th" >企業リスト件数</View>
                  <View as="td" className="changeplan_td">
                    <View as="ul" className="List">
                      <View as="li">月3,000件まで</View>
                      <View as="li">ダウンロード件数の追加購入可能</View>
                    </View>
                  </View>
                </View>
                <View as="tr" className="changeplan_tr" >
                  <View as="th" className="changeplan_th" >企業データの表示情報</View>
                  <View as="td" className="changeplan_td">
                    <View as="ul">
                      <View as="li">全ての項目が閲覧可能</View>
                    </View>
                  </View>
                </View>
                <View as="tr" className="changeplan_tr price_search">
                  <View as="th" className="changeplan_th" >検索項目</View>
                  <View as="td" className="changeplan_td">
                    <View as="dl" className="changeplan_dl List">
                      <View as="dt" className="changeplan_dt">基本条件</View>
                      <View as="dd" className="changeplan_dd">業種</View>
                      <View as="dd" className="changeplan_dd">地域</View>
                      <View as="dd" className="changeplan_dd">企業名</View>
                    </View>
                    <View as="dl" className="changeplan_dl List">
                      <View as="dt" className="changeplan_dt">詳細条件</View>
                      <View as="dd" className="changeplan_dd">事業内容</View>
                      <View as="dd" className="changeplan_dd">企業売上</View>
                      <View as="dd" className="changeplan_dd">従業員数</View>
                      <View as="dd" className="changeplan_dd">設立年</View>
                      <View as="dd" className="changeplan_dd">資本金</View>
                      <View as="dd" className="changeplan_dd">サイト有無</View>
                      <View as="dd" className="changeplan_dd">お問合せURL有無</View>
                      <View as="dd" className="changeplan_dd">上場非上場</View>
                      <View as="dd" className="changeplan_dd">閉鎖企業有無</View>
                      <View as="dd" className="changeplan_dd">出力済み企業有無</View>
                    </View>
                  </View>
                </View>
                <View as="tr" className="changeplan_tr" >
                  <View as="th" className="changeplan_th" >企業情報の深掘り<View as="span">※G-Searchが利用できます</View></View>
                  <View as="td" className="changeplan_td"><a  tabIndex={0} href="#" className="underline">G-Searchデータベースサービス<View as="span" className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                        open_in_new
                      </View></a>との連携で、リストアップした企業の深掘りデータの利用が可能</View>
                </View>
              </View>
            </View>
          </View>
        </View>
        
        <Flex alignItems="center" justifyContent="center" width="100%" margin="4.8rem 0 0 0" padding={{base: "0 clamp(2rem, 1.92rem + 0.4vw, 2.4rem)",large:"0"}} >
          <ScrollView height="300px" backgroundColor={tokens.colors.white} padding="clamp(2rem, 1.76rem + 1.2vw, 3.2rem)" width={{base:"100%", large:"calc(100% - 32px)"}} maxWidth="1200px" borderRadius="10px" alignSelf="center">
             <div className="changeplan-agreement">
               <h4>G-Search 企業INDEXナビ会員規約</h4>
                <h4>第1章　総則</h4>
                <h5>第1条（会員規約の適用）</h5>
                <p>株式会社ジー・サーチ（以下「当社」という）が提供するサービスは、日本国内に所在の個人および法人のお客様を対象としております。ただし、国内在住であっても、以下（1）～（3）に該当する場合は、サービス提供の対象となりません。</p>
                <ol className="brackets">
                  <li>
                    <span>(1)</span>
                    <p>申込者、および利用者が外国法人等や外国政府等との間で雇用契約等の契約を締結し、当該外国法人等や外国政府等の指揮命令に服する又はそれらによる善管注意義務を負っているとき</p>
                  </li>
                  <li>
                    <span>(2)</span>
                    <p>申込者、および利用者が外国政府等から多額の金銭その他の重大な利益を得ている又は得ることを約しているとき</p>
                  </li>
                  <li>
                    <span>(3)</span>
                    <p>申込者、および利用者が行動に関し外国政府等の指示又は依頼を受けているとき</p>
                  </li>
                </ol>
                <p>当社は、この会員規約（以下「G-Search 企業INDEXナビ会員規約」という）に基づき、G-Search 企業INDEXナビ（以下「本サービス」という）を提供します。</p>
                <ol start={2}>
                  <li>本サービスの利用にあたっては、G-Search企業INDEXナビの会員登録および当社が提供するG-Searchデータベースサービスの会員登録が必要です。会員は、G-Search 企業INDEXナビ会員規約およびG-Searchデータベースサービス会員規約に従い、本サービスを利用するものとします。</li>
                  <li>本サービスの利用に際して、G-Search 企業INDEXナビ会員規約の規定とG-Searchデータベースサービス会員規約の規定と矛盾又は抵触する場合には、G-Search企業INDEXナビ会員規約の規定が優先して適用されるものとします。</li>
                </ol>

                <h5>第2条（会員規約の変更）</h5>
                <p>当社は、会員の承諾を得ることなく、G-Search 企業INDEXナビ会員規約を変更することがあります。この場合には、本サービスの料金その他の販売条件などは、変更後のG-Search 企業INDEXナビ会員規約によるものとします。</p>
                <ol start={2}>
                  <li>前項における会員規約の変更は、オンラインまたは当社が別途定める方法で、随時会員に通知します。</li>
                </ol>

                <h5>第3条（用語の定義）</h5>
                <p>G-Search 企業INDEXナビ会員規約においては、次の用語はそれぞれ次の意味で使用します。</p>
                <ol className="brackets">
                  <li>
                    <span>(1)</span>
                    <h6>本サービス</h6>
                    <p>当社が提供する別表記載のサービス（G-Search 企業INDEXナビ）</p>
                  </li>
                  <li>
                    <span>(2)</span>
                    <h6>会員</h6>
                    <p>当社に対して会員登録の申し込みを行い、当社が承諾した者および会員登録の承諾を受けた者が、別途定める手続きで利用者登録を行った者</p>
                  </li>
                  <li>
                    <span>(3)</span>
                    <h6>個人情報</h6>
                    <p>会員に関する情報であって、当該情報に含まれる個人の氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができるものを含む）</p>
                  </li>
                </ol>

                <h4>第2章　会員</h4>

                <h5>第4条（会員登録）</h5>
                <p>本サービスの入会希望者は、当社が定める手続きに従って会員登録の申し込みを行うものとします。</p>
                <ol start={2}>
                  <li>
                    <p>会員登録手続きは、前項の申し込みに対する当社の承諾をもって完了するものとします。ただし、次のいずれかに該当する場合には、当社は会員登録申し込みを承諾しないか、あるいは承諾後であっても承諾の取消を行うことがあります。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>会員登録の申し込みの際、申込者が虚偽の事実を申告したとき</li>
                      <li><span>(2)</span>会員登録の申し込みの際、申告事項に誤記または記入漏れがあったとき</li>
                      <li><span>(3)</span>申込者が本サービスの利用料金等の支払いを怠るおそれがあると当社が判断したとき</li>
                      <li><span>(4)</span>申込者がG-Search 企業INDEXナビ会員規約違反等で本サービスの利用停止処分中または過去に強制退会処分を受けていたことが判明したとき</li>
                      <li><span>(5)</span>会員登録の申し込みの際、料金の支払方法として指定したクレジットカードが、クレジットカード会社よりクレジット利用契約の解除、脱会その他の理由により利用を認められていないとき</li>
                      <li><span>(6)</span>会員登録の申し込みの際、料金の支払い方法として指定したクレジットカードの名義人と申込者が異なっていたとき</li>
                      <li><span>(7)</span>申込者が未成年の方で、会員登録の申し込みの際、親権者の同意を得ていないとき、または親権者の同意を得ていないことが判明したとき</li>
                      <li><span>(8)</span>その他、当社が会員として不適当であると判明したとき</li>
                    </ol>
                  </li>
                  <li>会員は、会員登録の申し込みの際に選択した本サービスのプランを利用できるものとします。入会後に、利用するプランを変更する場合には、当社が別途定める手続きに従うものとします。</li>
                  <li>本サービスのプランの利用は自動継続とし、第20条および第22条で規定する利用停止や強制退会にあたる事象がない限り更新されるものとします。</li>
                  <li>会員が本サービスを利用する為の会員登録、登録情報の変更ならびに認証は当社が提供するアカウント管理サービス「ジー・サーチ　アカウント」を利用するものとします。</li>
                </ol>
                <p>「ジー・サーチ　アカウント」の利用にあたっては、会員は、本項のほか、G-Searchデータベースサービス会員規約およびG-Search 企業INDEXナビ会員規約で定める各条項を遵守するものとします。</p>

                <h5>第5条（権利譲渡の禁止）</h5>
                <p>会員は、本サービスを利用する権利を第三者に譲渡しないものとします。</p>

                <h5>第6条（会員の地位の承継等）</h5>
                <p>相続または法人の合併により会員の地位の承継があったときは、地位を承継した者は、承継した日から1か月以内に当社所定のフォーマットにて当社に通知するものとし、当社は当該通知に従って登録内容を変更するものとします。</p>
                <ol start={2}>
                  <li>
                    <p>当社は会員について次の変更があったときは、その会員またはその会員の業務の同一性および継続性が認められる場合に限り、前項の会員の地位の継承があったものとみなして前項の規定を適用します。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>会員である法人の営業の分割による新たな法人への変更</li>
                      <li><span>(2)</span>会員である法人の営業の譲渡による別法人への変更</li>
                      <li><span>(3)</span>会員である法人格を有しない社団または財団の代表者の変更</li>
                      <li><span>(4)</span>その他上記各号に類する変更</li>
                    </ol>
                  </li>
                </ol>

                <h5>第7条（会員登録情報変更）</h5>
                <p>会員は、その申込書記載事項について変更があったときは、すみやかに当社所定のフォーマットにて当社に通知するものとします。</p>
                <ol start={2}>
                  <li>前項において、通知があった場合は、当社は、当該通知に従って登録内容を変更するものとします。</li>
                  <li>変更の届出がなかったことで、会員が不利益を被ったとしても、当社は一切その責任を負いません。</li>
                </ol>

                <h4>第3章　会員の義務</h4>

                <h5>第8条（会員設備等の設置）</h5>
                <p>会員は、本サービスを利用するにあたって、自らの費用で、コンピュータその他の機器およびソフトウェア（以下「会員設備等」という）を設置するものとします。</p>

                <h5>第9条（会員設備等の維持責任）</h5>
                <p>会員は、本サービスの利用に支障をきたさないよう、会員設備等を正常に稼働させるよう維持するものとします。</p>

                <h5>第10条（IDおよびパスワードの管理責任）</h5>
                <p>会員は、当社より付与されたIDおよびパスワードを責任を持って管理、使用するものとし、当社に損害を生じさせないものとします。</p>
                <ol start={2}>
                  <li>会員は、本サービスを利用するために当社より付与されたIDおよびパスワードを第三者に譲渡もしくは利用させたり、売買、名義変更、質入れ等をしないものとします。</li>
                  <li>IDおよびパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任は、会員が負うものとし、当社は一切の責任を負いません。</li>
                </ol>

                <h5>第11条（利用範囲）</h5>
                <p>会員は、自ら使用する目的の範囲内でのみ本サービスを利用することができるものとします。なお、自ら使用するとは、会員が個人の場合は、会員自身のための使用をいい、法人の場合は、同一法人の同一事業所内での使用を指すものとします。</p>
                <ol start={2}>
                  <li>前項における本サービスで検索結果のデータに関する会員の使用範囲は、ダウンロードの許可されているものを除き、会員設備等のディスプレイ上の表示またはプリンタによる印字に限られるものとします。ダウンロードの許可されているものについては、磁気媒体による保存ができるものとします。</li>
                  <li>第1項における本サービスで検索したデータ等につき、会員は、複製ならびにFAXあるいはE-mailによる配信等はできないものとします。また、本サービスを第三者に利用させたり、アウトプットの全部または一部を第三者に公表または、利用させることはできないものとします。</li>
                  <li>会員は、本サービスで提供される情報をAI ツール（人工知能の技術を用いたＲＰＡ・ロボット・プログラム・ソフトウェア等を含むがこれに限らない）に入力し、学習・テスト・分析に利用すること、および出力を生成することはできないものとします。また、AI ツールの開発にも使用してはならないものとします。</li>
                </ol>

                <h4>第4章　料金等</h4>

                <h5>第12条（料金）</h5>
                <p>本サービスの料金は、別表のとおりとします。そのうち、各「月会費」は、会員が月毎に支払う料金とし、「オプション料金」は会員が利用した月のみ支払う料金とします。また、本サービスと連携するG-Searchデータベースサービスの料金は、会員が利用月毎にG-Searchデータベースサービスを利用した量に応じて算出される料金とします。</p>

                <h5>第13条（消費税等相当額の算定）</h5>
                <p>消費税および地方消費税（以下総称して「消費税等」という）相当額は、前条に基づく本サービスの料金それぞれに対して算定されるものとします。</p>
                <ol start={2}>
                  <li>消費税等相当額算定の際の税率は、当該算定時に法律上有効な税率とします。</li>
                </ol>

                <h5>第14条（料金の支払方法）</h5>
                <p>会員は、第12条に定める本サービスの料金を次の方法により当社に支払うものとします。</p>
                <ol className="brackets">
                  <li><span>(1)</span>当社が承諾するクレジットカード会社が発行するクレジットカードにより、当該クレジット会社の規約に基づいて支払う方法（原則としてクレジット会員・クレカ会員に限ります） 但し、この場合カードの名義人と申込者が同一であることを条件とします。</li>
                </ol>
                <ol start={2}>
                  <li>本サービスの料金及びG-Searchデータベースサービス料金の支払時期は、別表記載の通りとします。</li>
                  <li>当社は、会員より支払われた料金については理由の如何に拘わらず返還しないものとします。</li>
                </ol>

                <h5>第15条（遅延利息）</h5>
                <p>会員は、本サービスの料金その他の債務（遅延利息を除きます）について支払期日を経過してもなお支払いがなされない場合には、支払期日の翌日から支払日の前日までの日数について年14.6％の割合で算出した額を、遅延利息として当社が指定する期日までに支払うものとします。</p>

                <h4>第5章　責任</h4>

                <h5>第16条（損害賠償）</h5>
                <p>会員がG-Search 企業INDEXナビ会員規約に違反する行為または不正もしくは違法な行為によって当社に損害を与えた場合には、当社は当該会員に対して当社の被った損害の賠償を請求することができるものとします。</p>

                <h5>第17条（免責）</h5>
                <p>当社は本サービスの提供する情報もしくは物品について、その完全性、正確性、適用性、有用性などいかなる保証も行いません。</p>
                <ol start={2}>
                  <li>本サービスの中断、本サービス中の事故、本サービスに基づき検索したデータの誤り等によって、直接または間接的に生じた会員またはそれ以外の第三者の損害については、当社は、その内容、方法の如何にかかわらず賠償の責任を負わないものとします。当社に対する情報提供者や当社の代理店等も同様とします。</li>
                </ol>

                <h5>第18条（個人情報）</h5>
                <p>当社は、個人情報を、当社の基本姿勢とその取り扱い基準を明確化した「個人情報保護ポリシー」に基づき管理するものとします。</p>
                <ol start={2}>
                  <li>
                    <p>当社は、個人情報を、以下の利用目的の範囲内で取り扱います。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>本サービスを提供すること。</li>
                      <li><span>(2)</span>本サービスのサービスレベル維持向上を図るため、アンケート調査、及び分析を行うこと。</li>
                      <li><span>(3)</span>個々の会員に有益と思われる当社のサービス（本サービスに限りません。）又は提携先の商品、サービス等の情報を、会員がアクセスした当社のWebページその他会員の端末装置上に表示し、もしくはメール、郵便等により送付し、又は電話すること。なお、会員は、当社が別途定める方法で届け出ることにより、これらの取扱いを中止させたり、再開させたりすることができます。</li>
                      <li><span>(4)</span>会員から個人情報の取扱いに関する同意を求めるために、電子メール、郵便等を送付し、又は電話すること。</li>
                      <li><span>(5)</span>クレジットカード取引における不正利用防止のため、不正検知処理を行う第三者に個人情報を提供すること。</li>
                      <li><span>(6)</span>会員の解約日より1年間を限度として、前四号に定める利用目的の範囲内において個人情報を取り扱うこと。</li>
                    </ol>
                  </li>
                  <li>当社は本条2項の利用目的の実施に必要な範囲内で、個人情報の取り扱いに関して当社が選定した協力会社へ委託することがあります。委託先との間には秘密保持契約等を締結し個人情報が適切に取扱われるよう管理いたします。</li>
                  <li>当社は、個人情報の提供先とその利用目的を通知し承諾を得ること（画面上それらを明示し、会員が拒絶する機会を設けることを含みます）を行わない限り、第三者に個人情報を開示、提供しないものとします。</li>
                  <li>
                    <p>当社は、会員からあらかじめ前項の承諾を得た上で、第2項第5号におけるクレジットカード不正検知処理のため、カード決済会社である㈱DGフィナンシャルテクノロジーとの契約に基づき、同社を経由する方法により、決済の都度、 不正検知サービスを提供するアメリカ合衆国の第三者（ACI Worldwide Inc.）に対して個人情報を提供いたします。提供する個人情報の項目は以下のとおりです。</p>
                    <p>クレジット情報（トランザクションＩＤ、クレジットカード名義、カード番号の一部及び有効期限 ）、ＩＰアドレス、新規顧客フラグ、アカウントが作成されてからの日数、ユーザＩＤ、プロフィール情報（氏名、電話番号、メールアドレス、部署名、役職、職種）、請求先情報（会社名、住所、組織名、部署名、担当者名、メールアドレス）、言語、居住国、決済合計額、デバイス識別子</p>
                    <p>アメリカ合衆国の個人情報保護に関する制度については以下をご参照ください。</p>
                    <p><a href="https://www.ppc.go.jp/files/pdf/USA_report.pdf" target="_blank">https://www.ppc.go.jp/files/pdf/USA_report.pdf</a></p>
                    <p>また、当該事業者はOECDプライバシーガイドライン8原則に対応する処置をすべて講じております 。</p>
                    <p>その他、お客様の同意を得ずに、個人情報を他の第三者へ提供することは一切ございません。</p>
                  </li>
                  <li>本条第4項にかかわらず、会員による本サービス又は提携サービスの利用に係わる債権・債務の特定、支払い及び回収に必要と認めた場合には、当社は、必要な範囲でクレジットカード会社等の金融機関又は提携先等に個人情報を開示、提供することがあります。</li>
                  <li>
                    <p>本条第4項にかかわらず、当社は、以下の各号により個人情報を開示、提供することがあります。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>刑事訴訟法など、法令に基づき必要な範囲で開示、提供することがあります。</li>
                      <li><span>(2)</span>生命、身体又は財産の保護のために必要があると当社が判断した場合には、当該保護のために必要な範囲で開示、提供することがあります。</li>
                    </ol>
                  </li>
                  <li>当社は、個人情報の委託、開示、提供にあたっては、機密が保たれた経路を使用した伝送、暗号化を用いた通信、暗号化を施した記録媒体類の使用、配達記録を用いた運送などにより、不正アクセス、紛失、改ざん、漏洩等の危険防止のため、適切かつ合理的な保護措置を実施するよう努めます。</li>
                  <li>個人情報に関して、開示のご請求や、開示の結果、内容が事実ではないと判明し、訂正、追加、削除が必要になった場合や、個人情報の利用の停止、消去または第三者への提供の停止が必要になった場合は、別途オンライン上に掲示する連絡先までご連絡下さい。</li>
                  <li>個人情報の提供は任意ですが、ご提供いただけなかった項目の内容次第では、本サービスを利用することができない場合がございます。</li>
                  <li>当社は、会員の個人情報、本サービスを利用する上で当社が取得可能なアクセスログや経路情報など各種情報の属性の集計、分析を行い、個人が識別・特定できないように加工したもの（以下「統計資料」といいます）を作成し、新規サービスの開発等の業務の遂行のために利用、処理することがあります。また、統計資料を第三者に提供することがあります。</li>
                </ol>

                <h4>第6章　利用停止および退会</h4>

                <h5>第19条（退会）</h5>
                <p>会員は、本サービスを退会しようとするときは、当社ウェブサイト上にあるオンライン解約申請を行うことにより、当月末付で、退会できるものとします。</p>
                <ol start={2}>
                  <li>
                    <p>会員は、以下の条件をいずれも満たしている場合、当社所定の手続を行うことで「ジー・サーチ　アカウント」の登録を抹消できます。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>他に会員が利用しているサービスがないこと</li>
                      <li><span>(2)</span>利用料その他、当社への支払債務が残っていないこと</li>
                    </ol>
                  </li>
                  <li>前項の登録抹消によって、本サービスならびに「ジー・サーチ　アカウント」にログインが出来なくなり、これまでの利用履歴・利用明細、本サービスで取得、検索した情報の一切が閲覧できなくなります。</li>
                  <li>本サービスを利用停止にした状態で30日間以上経過し、第2項の条件をいずれも満たしている場合、セキュリティ保持の観点から当社が自動で登録抹消処理をする場合がございます。</li>
                </ol>

                <h5>第20条（利用の停止）</h5>
                <p>当社は、会員がG-Search データベース会員規約またはG-Search 企業INDEXナビ会員規約の何れかに違反したとき、会員に対し当社が任意に定める期間、本サービスの利用を停止することができます。</p>
                <ol start={2}>
                  <li>会員は、当社所定の手続きを行うことにより本サービスを会員自らの意思で利用停止とすることができます。また、本サービスの利用停止の状態でも「ジー・サーチ　アカウント」にはログインできるものとします。</li>
                  <li>会員保有のクレジットカードがクレジットカード会社より利用契約の解除、脱会、その他の理由により利用が認められなくなった場合、当社は本サービスの利用を停止することができます。また、本サービスの利用停止の状態でも会員は「ジー・サーチ　アカウント」にはログインできるものとします。</li>
                </ol>

                <h5>第21条（一次的な中断および提供停止）</h5>
                <p>以下の各号に定める場合において、当社は「ジー・サーチ　アカウント」の機能の全部または一部を停止することができるものとします。</p>
                <ol className="brackets">
                  <li><span>(1)</span>本サービスならびに「ジー・サーチ　アカウント」を提供するためのシステム、ソフトウェア、サーバー等の保守・更新等を行う場合</li>
                  <li><span>(2)</span>本サービスならびに「ジー・サーチ　アカウント」を提供するためのシステムに過大な負荷がかかり、または不正アクセス等によりセキュリティ上の問題があると当社が判断した場合</li>
                  <li><span>(3)</span>火災、停電、地震その他天災により本サービスならびに「ジー・サーチ　アカウント」の提供が困難となった場合</li>
                  <li><span>(4)</span>その他、運用上・技術上、本サービスならびに「ジー・サーチ　アカウント」の提供の中止または一時的な中断が必要と判断した場合</li>
                </ol>

                <h5>第22条（強制退会）</h5>
                <p>当社は、第20条第1項の規定により本サービスの利用を停止された会員が同項に定める期間中にその違反事由を解消しない場合は、その会員を退会させることができます。</p>
                <ol start={2}>
                  <li>
                    <p>当社は、会員が次のいずれかに該当する場合は、第20条および前項の規定にかかわらず利用停止の措置を経由しないで退会させることができます。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>当社に対して虚偽の事実を申告したとき</li>
                      <li><span>(2)</span>本サービスの料金等について、その支払いを遅延したとき</li>
                      <li><span>(3)</span>当社が承認したクレジットカード会社の発行する会員保有のクレジットカードが、クレジットカード会社より利用契約の解除、脱会、その他の理由により利用が認められなくなったとき</li>
                      <li><span>(4)</span>第10条または第11条の規定に違反したとき</li>
                      <li><span>(5)</span>本サービスを違法な目的、または公序良俗に反する目的に利用したとき</li>
                      <li><span>(6)</span>本サービスの運営を妨げたとき</li>
                      <li><span>(7)</span>自ら振り出しまたは引き受けた手形もしくは小切手を不渡りとしたとき、または支払いを停止しもしくは支払不能となったとき</li>
                      <li><span>(8)</span>差押え、競売、破産、民事再生、会社更生、特別清算の申し立てがなされたとき、または合併によらず解散したとき</li>
                      <li><span>(9)</span>その他、当社が会員として不適当であると判断したとき</li>
                    </ol>
                  </li>
                </ol>

                <h5>第23条（退会後の会員の義務）</h5>
                <p>会員が本サービスを退会した場合においても、すでに会員に生じた金銭債務、および、第10条、第11条に定める義務は消滅しないものとします。</p>

                <h4>第7章　反社会勢力等の排除</h4>

                <h5>第24条（反社条項）</h5>
                <p>会員は、自らが、次の各号に記載する者（以下「反社会的勢力等」という）に該当せず今後も該当しないこと、また、反社会的勢力等との関係を持っておらず今後も持たないことを確約します。</p>
                <ol className="brackets">
                  <li><span>(1)</span>警察庁「組織犯罪対策要綱」記載の「暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団等」その他これらに準ずる者</li>
                  <li><span>(2)</span>資金や便宜を供与したり、不正の利益を図る目的で利用したりするなど、前号に記載する者と人的・資本的・経済的に深い関係にある者</li>
                </ol>
                <ol start={2}>
                    <li>
                      <p>会員は、 自らまたは第三者を利用して、次の各号に記載する行為を行わないことを相手方に対して確約します。</p>
                      <ol className="brackets">
                        <li><span>(1)</span>詐術、暴力的行為または脅迫的言辞を用いる行為</li>
                        <li><span>(2)</span>違法行為または不当要求行為</li>
                        <li><span>(3)</span>業務を妨害する行為</li>
                        <li><span>(4)</span>名誉や信用等を毀損する行為</li>
                        <li><span>(5)</span>前各号に準ずる行為</li>
                      </ol>
                    </li>
                  </ol>

                  <h4>第8章　管轄裁判所</h4>

                  <h5>第25条（合意管轄）</h5>
                  <p>G-Search 企業INDEXナビ会員規約に基づきまたは関連して生じる一切の紛争については、当社の本店所在地を管轄する裁判所を第一審の専属的合意管轄裁判所とします。</p>
                  <p>付則　この会員規約は、2024年11月1日から実施します。</p>

                  <h4>《別表》</h4>

                  <h5>A．本サービスの料金</h5>
                  <ol>
                    <li>
                      <h6>G-Search 企業INDEXナビ 月会費</h6>
                      <ol className="brackets">
                        <li><span>(1)</span>フリープラン会員　月会費：無料</li>
                        <li><span>(2)</span>フリーPlus会員　月会費：450円（税込 495円）</li>
                        <li><span>(3)</span>ベーシック会員　月会費：20,000円（税込 22,000円）</li>
                      </ol>
                    </li>
                    <li>
                      <h6>G-Search 企業INDEXナビ オプション料金</h6>
                      <p>ダウンロード件数追加(1,000件)　5,000円/月（税込5,500円）</p>
                      <p>※ベーシック会員がオプションを申し込んだ場合のみ適用</p>
                    </li>
                    <li>
                      <h6>G-Searchデータベースサービスコンテンツ利用料金（従量料金）</h6>
                      <p>G-Searchデータベースサービスの利用にあたっては、G-Searchデータベースサービスの従量料金が発生します。</p>
                      <p>従量料金については、G-Searchのオンライン上での表示または印刷物など当社が提供する手段を通じて随時会員に通知します。</p>
                      <p>※フリーPlus会員、ベーシック会員に適用</p>
                    </li>
                    <li>
                      <h6>プラン別の利用範囲</h6>
                      <ol className="brackets">
                        <li><span>(1)</span>
                          <p>フリープラン会員</p>
                          <ul>
                            <li>企業リスト件数：100件/月</li>
                          </ul>
                        </li>
                        <li><span>(2)</span>
                          <p>フリーPlus会員</p>
                          <ul>
                            <li>企業リスト件数：100件/月</li>
                            <li>G-Searchデータベースサービス：利用可能</li>
                          </ul>
                        </li>
                        <li><span>(3)</span>
                          <p>ベーシック会員</p>
                          <ul>
                            <li>企業リスト件数：3,000件/月</li>
                            <li>検索項目：詳細条件の設定</li>
                            <li>G-Searchデータベースサービス：利用可能</li>
                          </ul>
                        </li>
                        <li>
                          <h6>支払時期</h6>
                          <ol className="brackets">
                            <li><span>(1)</span>
                              <p>クレジットカードによる支払いの場合</p>
                              <p>毎月発生した本サービスの料金を、毎月末日を締め日とし、当該クレジットカード発行会社が定める規約に従い支払うものとします。</p>
                            </li>
                            <li><span>(2)</span>月会費は登録月に対して発生し、月の途中から登録あるいは利用停止した場合でも日割りでの計算は行いません。</li>
                          </ol>
                        </li>
                      </ol>
                    </li>
                  </ol>

                  <h5>B．本サービスの時間</h5>
                  <p>本サービスのサービス時間は２４時間を原則とします。ただし、コンピュータまたは回線の障害その他やむを得ない事情により、サービス時間を短縮、または提供が不可能もしくは中断となる場合があります。</p>

                  <p>－ 以上 －</p>
              </div>
          </ScrollView>
        </Flex>
        <Flex alignItems="center" justifyContent="center" width="100%" margin="3rem 0 0 0" padding={{base: "0 clamp(2rem, 1.92rem + 0.4vw, 2.4rem)",large:"0"}} >
          <ScrollView height="300px" backgroundColor={tokens.colors.white} padding="clamp(2rem, 1.76rem + 1.2vw, 3.2rem)" width={{base:"100%", large:"calc(100% - 32px)"}} maxWidth="1200px" borderRadius="10px" alignSelf="center">
           <div className="changeplan-agreement">
               <h4>G-Searchデータベースサービス会員規約</h4>
                <h4>第1章　総則</h4>

                <h5>第1条（会員規約の適用）</h5>
                <p>株式会社ジー・サーチ（以下「当社」という）が提供するサービスは、日本国内に所在の個人および法人のお客様を対象としております。ただし、国内在住であっても、以下（1）～（3）に該当する場合は、サービス提供の対象となりません。</p>
                <ol className="brackets">
                  <li>
                    <span>(1)</span>
                    <p>申込者、および利用者が外国法人等や外国政府等との間で雇用契約等の契約を締結し、当該外国法人等や外国政府等の指揮命令に服する又はそれらによる善管注意義務を負っているとき</p>
                  </li>
                  <li>
                    <span>(2)</span>
                    <p>申込者、および利用者が外国政府等から多額の金銭その他の重大な利益を得ている又は得ることを約しているとき</p>
                  </li>
                  <li>
                    <span>(3)</span>
                    <p>申込者、および利用者が行動に関し外国政府等の指示又は依頼を受けているとき</p>
                  </li>
                </ol>
                <p>当社は、この会員規約（以下「G-Searchデータベースサービス会員規約」という）に基づき、G-Searchデータベースサービス（以下「G-Searchサービス」という）を提供します。</p>

                <ol start={2}>
                  <li>会員は、G-Searchデータベースサービス会員規約に従い、G-Searchサービスを利用するものとします。また、G-Searchサービス以外のサービスを利用する場合は、そのサービスの利用条件（以下「個別規約」といいます。）を遵守するものとします。</li>
                  <li>G-Searchサービス以外のサービスの利用には、G-Searchデータベースサービス会員規約に加えて個別規約が適用されます。個別規約の規定が、G-Searchデータベースサービス会員規約の規定と矛盾又は抵触する場合には、個別規約の規定が優先して適用されるものとします。
                  </li>
                </ol>

                <h5>第2条（会員規約の変更）</h5>
                <p>当社は、会員の承諾を得ることなく、G-Searchデータベースサービス会員規約を変更することがあります。この場合には、G-Searchサービス料金その他の販売条件などは、変更後のG-Searchデータベースサービス会員規約によるものとします。</p>
                <ol start={2}>
                  <li>前項における会員規約の変更は、オンラインまたは当社が別途定める方法で、随時会員に通知します。</li>
                </ol>

                <h5>第3条（用語の定義）</h5>
                <p>G-Searchデータベースサービス会員規約においては、次の用語はそれぞれ次の意味で使用します。</p>
                <ol className="brackets">
                  <li>
                    <span>(1)</span>
                    <h6>G-Searchサービス</h6>
                    <p>当社が提供する別表記載のサービス</p>
                  </li>
                  <li>
                    <span>(2)</span>
                    <h6>会員</h6>
                    <p>当社に対して会員登録の申し込みを行い、当社が承諾した者および会員登録の承諾を受けた者が、別途定める手続きで利用者登録を行った者</p>
                  </li>
                  <li>
                    <span>(3)</span>
                    <h6>個人情報</h6>
                    <p>会員に関する情報であって、当該情報に含まれる個人の氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができるものを含む）</p>
                  </li>
                </ol>

                <h4>第2章　会員</h4>

                <h5>第4条（会員登録）</h5>
                <p>G-Searchサービスの入会希望者は、当社が定める手続きに従って会員登録の申し込みを行うものとします。</p>
                <ol start={2}>
                  <li>
                    <p>会員登録手続きは、前項の申し込みに対する当社の承諾をもって完了するものとします。ただし、次のいずれかに該当する場合には、当社は会員登録申し込みを承諾しないか、あるいは承諾後であっても承諾の取消を行うことがあります。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>会員登録の申し込みの際、申込者が虚偽の事実を申告したとき</li>
                      <li><span>(2)</span>会員登録の申し込みの際、申告事項に誤記または記入漏れがあったとき</li>
                      <li><span>(3)</span>申込者がG-Searchサービスの利用料金等の支払いを怠るおそれがあると当社が判断したとき</li>
                      <li><span>(4)</span>申込者がG-Searchデータベースサービス会員規約違反等でG-Searchサービスの利用停止処分中または過去に強制退会処分を受けていたことが判明したとき</li>
                      <li><span>(5)</span>会員登録の申し込みの際、料金の支払方法として指定したクレジットカードが、クレジットカード会社よりクレジット利用契約の解除、脱会その他の理由により利用を認められていないとき</li>
                      <li><span>(6)</span>会員登録の申し込みの際、料金の支払い方法として指定したクレジットカードの名義人と申込者が異なっていたとき</li>
                      <li><span>(7)</span>申込者が未成年の方で、会員登録の申し込みの際、親権者の同意を得ていないとき、または親権者の同意を得ていないことが判明したとき</li>
                      <li><span>(8)</span>その他、当社が会員として不適当であると判明したとき</li>
                    </ol>
                  </li>
                  <li>会員は、会員登録の申し込みの際記入した申込書に記載されているG-Searchサービスを利用できるものとします。入会後に、利用するサービスを変更する場合には、当社が別途定める手続きに従うものとします。</li>
                </ol>

                <h5>第5条（権利譲渡の禁止）</h5>
                <p>会員は、G-Searchサービスを利用する権利を第三者に譲渡しないものとします。</p>

                <h5>第6条（会員の地位の承諾等）</h5>
                <p>相続または法人の合併により会員の地位の承継があったときは、地位を承継した者は、承継した日から1か月以内に当社所定のフォーマットにて当社に通知するものとし、当社は当該通知に従って登録内容を変更するものとします。</p>
                <ol start={2}>
                  <li>
                    <p>当社は会員について次の変更があったときは、その会員またはその会員の業務の同一性および継続性が認められる場合に限り、前項の会員の地位の継承があったものとみなして前項の規定を適用します。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>会員である法人の営業の分割による新たな法人への変更</li>
                      <li><span>(2)</span>会員である法人の営業の譲渡による別法人への変更</li>
                      <li><span>(3)</span>会員である法人格を有しない社団または財団の代表者の変更</li>
                      <li><span>(4)</span>その他上記各号に類する変更</li>
                    </ol>
                  </li>
                </ol>

                <h5>第7条（会員登録情報変更）</h5>
                <p>会員は、その申込書記載事項について変更があったときは、すみやかに当社所定のフォーマットにて当社に通知するものとします。</p>
                <ol start={2}>
                  <li>前項において、通知があった場合は、当社は、当該通知に従って登録内容を変更するものとします。</li>
                  <li>変更の届出がなかったことで、会員が不利益を被ったとしても、当社は一切その責任を負いません。</li>
                </ol>

                 <h4>第3章　会員の義務</h4>

                <h5>第8条（会員設備等の設置）</h5>
                <p>会員は、G-Searchサービスを利用するにあたって、自らの費用で、コンピュータその他の機器およびソフトウェア（以下「会員設備等」という）を設置するものとします。</p>

                <h5>第9条（会員設備等の維持責任）</h5>
                <p>会員は、G-Searchサービスの利用に支障をきたさないよう、会員設備等を正常に稼働させるよう維持するものとします。</p>

                <h5>第10条（IDおよびパスワードの管理責任）</h5>
                <p>会員は、当社より付与されたIDおよびパスワードを責任を持って管理、使用するものとし、当社に損害を生じさせないものとします。</p>
                <ol start={2}>
                  <li>会員は、G-Searchサービスを利用するために当社より付与されたIDおよびパスワードを第三者に譲渡もしくは利用させたり、売買、名義変更、質入れ等をしないものとします。</li>
                  <li>IDおよびパスワードの管理不十分、使用上の過誤、第三者の使用等による損害の責任は、会員が負うものとし、当社は一切の責任を負いません。</li>
                </ol>

                <h5>第11条（利用範囲）</h5>
                <p>会員は、自ら使用する目的の範囲内でのみG-Searchサービスを利用することができるものとします。なお、自ら使用するとは、会員が個人の場合は、会員自身のための使用をいい、法人の場合は、同一法人の同一事業所内での使用を指すものとします。</p>
                <ol start={2}>
                  <li>前項におけるG-Searchサービスで検索結果のデータに関する会員の使用範囲は、ダウンロードの許可されているものを除き、会員設備等のディスプレイ上の表示またはプリンタによる印字に限られるものとします。ダウンロードの許可されているデータベースについては、磁気媒体による保存ができるものとします。なお、ダウンロードの許可されているデータベースについては、G-Searchサービスのオンラインまたは別途当社が定める方法でお知らせします。</li>
                  <li>第1項におけるG-Searchサービスで検索したデータ等につき、会員は、複製ならびにFAXあるいはE-mailによる配信等はできないものとします。また、G-Searchサービスを第三者に利用させたり、アウトプットの全部または一部を第三者に公表または、利用させることはできないものとします。</li>
                  <li>会員は、G-Searchサービスのうち、データベース毎に定められている使用条件等がある場合には、それに従うものとします。</li>
                </ol>

                <h4>第4章　料金等</h4>

                <h5>第12条（料金）</h5>
                <p>G-Searchサービス料金は、別表のとおりとします。そのうち、「年会費」、「月会費」は、指定の期日に会員が支払う料金とし、「月間基本料金」、「月間最低料金（ミニマムチャージ）」、「月間固定料金」は、会員が月毎にG-Searchサービスの利用の有無にかかわらず支払う料金とし、「従量料金」は、会員が利用月毎にG-Searchサービスを利用した量に応じて算出される料金とします。</p>

                <h5>第13条（消費税等相当額の算定）</h5>
                <p>消費税および地方消費税（以下総称して「消費税等」という）相当額は、前条に基づくG-Searchサービス料金それぞれに対して算定されるものとします。</p>
                <ol start={2}>
                  <li>消費税等相当額算定の際の税率は、当該算定時に法律上有効な税率とします。</li>
                </ol>

                <h5>第14条（料金の支払方法）</h5>
                <p>会員は、第12条に定めるG-Searchサービス料金を会員登録時に会員が選択する次のいずれかの方法により当社または代理店に支払うものとします。</p>
                <ol className="brackets">
                  <li><span>(1)</span>指定の金融機関に振り込む方法（法人会員に限ります）</li>
                  <li><span>(2)</span>当社が承諾するクレジットカード会社が発行するクレジットカードにより、当該クレジット会社の規約に基づいて支払う方法（原則としてクレジット会員・クレカ会員に限ります） 但し、この場合カードの名義人と申込者が同一であることを条件とします。</li>
                  <li><span>(3)</span>金融機関の預金口座振替による方法（原則として法人会員に限ります）但し、この場合預金口座の名義人と会員登録上の名義が同一であることを条件とします。</li>
                </ol>
                <ol start={2}>
                  <li>G-Searchサービス料金の支払時期は、別表記載の通りとします。</li>
                  <li>当社は、会員より支払われた料金については理由の如何に拘わらず返還しないものとします。</li>
                </ol>

                <h5>第15条（遅延利息）</h5>
                <p>会員は、G-Searchサービス料金その他の債務（遅延利息を除きます）について支払期日を経過してもなお支払いがなされない場合には、支払期日の翌日から支払日の前日までの日数について年14.6％の割合で算出した額を、遅延利息として当社が指定する期日までに支払うものとします。</p>

                <h4>第5章　責任</h4>

                <h5>第16条（損害賠償）</h5>
                <p>会員がG-Searchデータベースサービス会員規約もしくは個別規約に違反する行為または不正もしくは違法な行為によって当社に損害を与えた場合には、当社は当該会員に対して当社の被った損害の賠償を請求することができるものとします。</p>

                <h5>第17条（免責）</h5>
                <p>当社はG-Searchサービスの提供する情報もしくは物品について、その完全性、正確性、適用性、有用性などいかなる保証も行いません。</p>
                <ol start={2}>
                  <li>G-Searchサービスの中断、G-Searchサービス中の事故、G-Searchサービスに基づき検索したデータの誤り等によって、直接または間接的に生じた会員またはそれ以外の第三者の損害については、当社は、その内容、方法の如何にかかわらず賠償の責任を負わないものとします。当社に対する情報提供者や当社の代理店等も同様とします。</li>
                </ol>

                <h5>第18条（個人情報）</h5>
                <p>当社は、個人情報を、当社の基本姿勢とその取り扱い基準を明確化した「個人情報保護ポリシー」に基づき管理するものとします。</p>
                <ol start={2}>
                  <li>
                    <p>当社は、個人情報を、以下の利用目的の範囲内で取り扱います。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>G-Searchサービスを提供すること。</li>
                      <li><span>(2)</span>G-Searchサービスのサービスレベル維持向上を図るため、アンケート調査、及び分析を行うこと。</li>
                      <li><span>(3)</span>個々の会員に有益と思われる当社のサービス（G-Searchサービスに限りません。）又は提携先の商品、サービス等の情報を、会員がアクセスした当社のWebページその他会員の端末装置上に表示し、もしくはメール、郵便等により送付し、又は電話すること。なお、会員は、当社が別途定める方法で届け出ることにより、これらの取扱いを中止させたり、再開させたりすることができます。</li>
                      <li><span>(4)</span>会員から個人情報の取扱いに関する同意を求めるために、電子メール、郵便等を送付し、又は電話すること。</li>
                      <li><span>(5)</span>会員の解約日より1年間を限度として、前四号に定める利用目的の範囲内において個人情報を取り扱うこと。</li>
                    </ol>
                  </li>
                  <li>当社は本条2項の利用目的の実施に必要な範囲内で、個人情報の取り扱いに関して当社が選定した協力会社へ委託することがあります。委託先との間には秘密保持契約等を締結し個人情報が適切に取扱われるよう管理いたします。</li>
                  <li>当社は、個人情報の提供先とその利用目的を通知し承諾を得ること（画面上それらを明示し、会員が拒絶する機会を設けることを含みます）を行わない限り、第三者に個人情報を開示、提供しないものとします。</li>
                  <li>本条第4項にかかわらず、会員によるG-Searchサービス又は提携サービスの利用に係わる債権・債務の特定、支払い及び回収に必要と認めた場合には、当社は、必要な範囲でクレジットカード会社等の金融機関又は提携先等に個人情報を開示、提供することがあります。</li>
                  <li>
                    <p>本条第4項にかかわらず、当社は、以下の各号により個人情報を開示、提供することがあります。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>刑事訴訟法など、法令に基づき必要な範囲で開示、提供することがあります。</li>
                      <li><span>(2)</span>生命、身体又は財産の保護のために必要があると当社が判断した場合には、当該保護のために必要な範囲で開示、提供することがあります。</li>
                    </ol>
                  </li>
                  <li>当社は、個人情報の委託、開示、提供にあたっては、機密が保たれた経路を使用した伝送、暗号化を用いた通信、暗号化を施した記録媒体類の使用、配達記録を用いた運送などにより、不正アクセス、紛失、改ざん、漏洩等の危険防止のため、適切かつ合理的な保護措置を実施するよう努めます。</li>
                  <li>個人情報に関して、開示のご請求や、開示の結果、内容が事実ではないと判明し、訂正、追加、削除が必要になった場合や、個人情報の利用の停止、消去または第三者への提供の停止が必要になった場合は、別途オンライン上に掲示する連絡先までご連絡下さい。</li>
                  <li>個人情報の提供は任意ですが、ご提供いただけなかった項目の内容次第では、G-Searchサービスを利用することができない場合がございます。</li>
                  <li>当社は、会員の個人情報、G-Searchサービスを利用する上で当社が取得可能なアクセスログや経路情報など各種情報の属性の集計、分析を行い、個人が識別・特定できないように加工したもの（以下「統計資料」といいます）を作成し、新規サービスの開発等の業務の遂行のために利用、処理することがあります。また、統計資料を第三者に提供することがあります。</li>
                </ol>

                <h4>第6章　利用停止および退会</h4>

                <h5>第19条（退会）</h5>
                <p>会員は、G-Searchサービスを退会しようとするときは、退会日等当社の指定する事項を退会日の1か月前までに当社所定のフォーマットにて当社に通知することにより、いつでも退会できるものとします。なお、クレカ会員に限っては、当社ウェブサイト上にあるオンライン解約申請を行うことにより、当月末付で、退会できるものとします。</p>

                <h5>第20条（利用の停止）</h5>
                <p>当社は、会員がG-Searchデータベースサービス会員規約または個別規約の何れかに違反したとき、会員に対し当社が任意に定める期間、G-Searchサービスの利用を停止することができます。</p>

                <h5>第21条（強制退会）</h5>
                <p>当社は、前条の規定によりG-Searchサービスの利用を停止された会員が前条の期間中にその違反事由を解消しない場合は、その会員を退会させることができます。</p>
                <ol start={2}>
                  <li>
                    <p>当社は、会員が次のいずれかに該当する場合は、前条および前項の規定にかかわらず利用停止の措置を経由しないで退会させることができます。</p>
                    <ol className="brackets">
                      <li><span>(1)</span>当社に対して虚偽の事実を申告したとき</li>
                      <li><span>(2)</span>G-Searchサービス料金等について、その支払いを遅延したとき</li>
                      <li><span>(3)</span>当社が承認したクレジットカード会社の発行する会員保有のクレジットカードが、クレジットカード会社より利用契約の解除、脱会、その他の理由により利用が認められなくなったとき</li>
                      <li><span>(4)</span>第10条または第11条の規定に違反したとき</li>
                      <li><span>(5)</span>G-Searchサービスを違法な目的、または公序良俗に反する目的に利用したとき</li>
                      <li><span>(6)</span>G-Searchサービスの運営を妨げたとき</li>
                      <li><span>(7)</span>自ら振り出しまたは引き受けた手形もしくは小切手を不渡りとしたとき、または支払いを停止しもしくは支払不能となったとき</li>
                      <li><span>(8)</span>差押え、競売、破産、民事再生、会社更生、特別清算の申し立てがなされたとき、または合併によらず解散したとき</li>
                      <li><span>(9)</span>その他、当社が会員として不適当であると判断したとき</li>
                    </ol>
                  </li>
                </ol>

                <h5>第22条（退会後の会員の義務）</h5>
                <p>会員がG-Searchサービスを退会した場合においても、すでに会員に生じた金銭債務、および、第10条、第11条に定める義務は消滅しないものとします。</p>

                <h4>第7章　反社会勢力等の排除</h4>

                <h5>第23条（反社条項）</h5>
                <p>会員は、自らが、次の各号に記載する者（以下「反社会的勢力等」という）に該当せず今後も該当しないこと、また、反社会的勢力等との関係を持っておらず今後も持たないことを確約します。 </p>

                <ol className="brackets">
                  <li><span>(1)</span>警察庁「組織犯罪対策要綱」記載の「暴力団、暴力団員、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団等」その他これらに準ずる者</li>
                  <li><span>(2)</span>資金や便宜を供与したり、不正の利益を図る目的で利用したりするなど、前号に記載する者と人的・資本的・経済的に深い関係にある者</li>
                </ol>

                <ol start={2}>
                    <li>
                      <p>会員は、 自らまたは第三者を利用して、次の各号に記載する行為を行わないことを相手方に対して確約します。</p>
                      <ol className="brackets">
                        <li><span>(1)</span>詐術、暴力的行為または脅迫的言辞を用いる行為</li>
                        <li><span>(2)</span>違法行為または不当要求行為</li>
                        <li><span>(3)</span>業務を妨害する行為</li>
                        <li><span>(4)</span>名誉や信用等を毀損する行為</li>
                        <li><span>(5)</span>前各号に準ずる行為</li>
                      </ol>
                    </li>
                  </ol>

                <h4>第8章　管轄裁判所</h4>

                <h5>第24条（合意管轄）</h5>
                <p>G-Searchデータベースサービス会員規約に基づきまたは関連して生じる一切の紛争については、当社の本店所在地を管轄する裁判所を第一審の専属的合意管轄裁判所とします。</p>
                <p>付則　この会員規約は、2024年11月1日から改訂実施します。</p>

                <h4>《別表》</h4>

                <h5>A．G-Searchサービスの内容</h5>
                <p>会員が利用申込書により申し込んだ商用データベースに関する以下のサービスをいいます。ただし、データベースによっては提供されないサービスもあります。</p>
                <ol className="brackets">
                  <li>
                    <span>(1)</span>
                    <h6>オンライン検索サービス</h6>
                    <p>インターネットを利用して、会員設備等を操作することにより、データベースの指定、検索方法、検索条件、検索結果の出力等を指示すると、その指示に従ってデータベースの検索、検索システムによる当該データの抽出を行い、検索結果の回答、およびデータの内容などを会員設備等に送信するサービス。</p>
                  </li>
                  <li>
                    <span>(2)</span>
                    <h6>オプションサービス</h6>
                    <p>G-Searchサービスには月間基本料金・月間最低利用料金（ミニマムチャージ）・月間固定料金が発生するサービス、またはサービスの利用に前提条件があるサービス（これらをオプションサービスと呼びます）があります。オプションサービスの利用には別途申込手続きが必要です。</p>
                  </li>
                </ol>

                <h5>B．G-Searchサービスの時間</h5>
                <p>本サービスのサービス時間は２４時間を原則とし、データベース毎に異なる場合はオンライン上での表示または印刷物など当社が提供する手段を通じて随時会員に通知します。ただし、コンピュータまたは回線の障害その他やむを得ない事情により、サービス時間を短縮、または提供が不可能もしくは中断となる場合があります。</p>

                <h5>C．G-Searchサービスの料金</h5>
                <ol>
                  <li>
                    <h6>年会費・月会費</h6>
                    <ol className="brackets">
                      <li><span>(1)</span>クレジット会員　年会費：1,000円（税込 1,100円）／人</li>
                      <li><span>(2)</span>クレカ会員　月会費：450円（税込 495円）／人</li>
                      <li><span>(3)</span>法人会員　年会費：6,000円（税込 6,600円）／社 ※1,000IDまで<br />※請求先が同一の場合に適用されます。</li>
                    </ol>
                  </li>
                  <li>
                      <h6>月間基本料金、月間最低料金（ミニマムチャージ）、月間固定料金</h6>
                      <p>G-Searchのオンライン上での表示または印刷物など当社が提供する手段を通じて随時会員に通知します。</p>
                  </li>
                  <li>
                    <h6>従量料金</h6>
                    <p>G-Searchのオンライン上での表示または印刷物など当社が提供する手段を通じて随時会員に通知します。</p>
                  </li>
                  <li>
                    <h6>支払時期</h6>
                    <ol className="brackets">
                      <li>
                        <span>(1)</span>
                        <h6>当社指定の金融機関への振り込みの場合</h6>
                        <p>毎月発生したG-Searchサービス料金を、毎月末日を締め日として、原則として翌月末日（金融機関休業日の場合は前営業日）までに振り込むものとします。但し、本規定と異なる支払い期日が請求書に記載されている場合は、その期日までに振り込むものとします。</p>
                      </li>
                      <li>
                        <span>(2)</span>
                        <h6>クレジットカードによる支払いの場合</h6>
                        <p>毎月発生したG-Searchサービス料金を、毎月末日を締め日とし、当該クレジットカード発行会社が定める規約に従い支払うものとします。</p>
                      </li>
                      <li>
                        <span>(3)</span>
                        <h6>金融機関の預金口座振替の場合</h6>
                        <p>毎月発生したG-Searchサービス料金を、毎月末日を締め日とし、翌々月1日（金融機関休業日の場合は翌営業日）に振り替えるものとします。</p>
                      </li>
                    </ol>
                  </li>
                </ol>

                  <p>－ 以上 －</p>
                </div>
          </ScrollView>
        </Flex>
        <Flex alignItems="center" justifyContent="center" width="100%" margin="3.6rem 0 0 0">
         <Text marginRight="1.5rem" marginTop="0.3rem">規約に同意する</Text>
         <CheckboxField size="large" label="" name="consent" value="yes" onChange={handleCheckboxChange} />
        </Flex>
        <Flex direction="column" alignItems="center" justifyContent="center" width="100%" margin="2rem 0 0 0">
          <Button className="orangeHoverButton" variation="menu" display="inline-flex" justifyContent="center"  backgroundColor={tokens.colors.red[60]} marginTop="1rem" width="25rem" fontSize={tokens.fontSizes.xxl} isDisabled={isPlanChangeButtonDisabled} onClick={planChangeButtonClick} borderRadius="30px">
            <Flex display="inline-flex" alignItems="center">
             <Text color="inherit">プラン変更する</Text>
             <View paddingTop="0.5rem" color="inherit"><RxExternalLink size="2rem"/></View>
            </Flex>
          </Button>
          <Text marginRight="1.5rem" marginTop="0.3rem" color={tokens.colors.red[60]}>プラン変更後は、一度ログアウトしてから再ログインしてください</Text>
        </Flex>

        <View as="div" margin="7.2rem auto 2.4rem auto" width={{base: "100%", large:"calc(100% - 32px)"}} maxWidth="1200px" padding={{base: "0 clamp(2rem, 1.92rem + 0.4vw, 2.4rem)",large:"0"}} >
          <Text backgroundColor={tokens.colors.blue[60]} fontSize="2xl" fontWeight="bold" color={tokens.colors.white} alignSelf="center" padding="10px 0 10px 20px">
            企業INDEXナビ サービス登録解除
          </Text>
          <View padding="20px 25px 20px 25px" backgroundColor={tokens.colors.white}  alignSelf="center">
            <Text color={tokens.colors.black}>
              こちらから企業INDEXナビの登録解除を行うことができます。<br/>
              登録解除は当月末日付けとなります。そのため、当月中は企業INDEXナビをご利用いただけます。<br/>
              <br/>
              「登録解除する」をクリックすると、ジー・サーチアカウントの画面に切り替わりますので、プラン変更→解約の順に進んでください。<br/>
              なお、ジー・サーチアカウント自体の登録を削除するには、ジー・サーチアカウント画面の「組織設定」→組織設定画面下部の退会の順に進んでください。
            </Text>
          </View>
        </View>
        <Flex alignItems="center" justifyContent="center" width="100%" marginBottom="5rem">
          <Button className="whiteHoverButton" variation="primary" backgroundColor={tokens.colors.white} color={tokens.colors.blue[90]} marginTop="1rem" width="15rem" onClick={unsubscribeButtonClick} borderRadius="20px" borderColor={tokens.colors.blue[90]}>
           <Flex display="inline-flex" alignItems="center">
             <Text color="inherit" fontSize={tokens.fontSizes.large}>登録解除する</Text>
             <View paddingTop="0.5rem" color="inherit"><RxExternalLink size="1.5rem"/></View>
           </Flex>
          </Button>
        </Flex>
      </Flex>
      <Footer />
      </View>
    </>  
  );
};
