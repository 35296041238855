import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, Label, View, Flex, Divider, Button, Link, Image, Icon, Accordion, useTheme } from '@aws-amplify/ui-react';
import '../css/Header.css'
import '../js/common.js'
import IcoLogo from '../image/logo.svg';

export default function HeaderOpenVersion() {
  // 企業INDEXナビとは？を同タブで開く
  const handleOpenGuidePage = () => {
    window.location.href = `${process.env.REACT_APP_OPENSITE_BASE_URL}/about.html`;
  };
  
  // 料金を同タブで開く
  const handleOpenPrice = () => {
    window.location.href = `${process.env.REACT_APP_OPENSITE_BASE_URL}/price.html`;
  };
  
  // 資料ダウンロードを別タブで開く
  const handleOpenFdownload = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/download/guide/index.html`, "_blank", "noreferrer");
  };
  
  //お問い合わせを別タブで開く
  const handleOpenFmail = () => {
    window.open(`${process.env.REACT_APP_OPENSITE_BASE_URL}/inquiry/index.html`, "_blank", "noreferrer");
  };
  
  //無料会員登録を別タブで開く
  const handleOpenSignup = () => {
    window.open(`${process.env.REACT_APP_MYACCOUNT_URL}/signup/start?client_id=${process.env.REACT_APP_OIDC_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_API_BASE_URL}/`, "_blank", "noreferrer");
  };
  
  //ログインを別タブで開く
  const handleOpenLogin = () => {
    window.open(`${process.env.REACT_APP_API_BASE_URL}/`, "_blank", "noreferrer");
  };
  
  return (
    <header>
    	<div className="header_inner">
    		<div className="logo">
    			<a href="/">
    				<img src={IcoLogo} alt="企業INDEXナビ" width="180" height="50" />
    			</a>
    		</div>
    		<div className="user_btn">
    			<div className="btn conversion">
    				<a onClick={handleOpenSignup} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenSignup();
                }
              }}>無料会員登録</a>
    			</div>
    			<div className="btn function f_login">
    				<a onClick={handleOpenLogin} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenSignup();
                }
              }}>ログイン</a>
    			</div>
    			<button type="button" className="sp_menu_btn" aria-label="メニューボタン"><span className="sp_menu_btn_line"></span></button>
    		</div>
    	</div>
    
    	<div className="sp_conversion">
    		<div className="btn function f_download">
    			<a onClick={handleOpenFdownload} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenFdownload();
                }
              }}>資料ダウンロード</a>
    		</div>
    		<div className="btn conversion">
    			<a onClick={handleOpenSignup} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenSignup();
                }
              }}>無料会員登録</a>
    		</div>
    	</div>
    
    	<div className="sp_wrap">
    		<div className="header_nav mainmenu">
    			<nav aria-label="メインメニュー">
    				<ul className="mainmenu_wrap">
    					<li><a onClick={handleOpenGuidePage} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenGuidePage();
                }
              }}>企業INDEXナビとは？</a></li>
    					<li><a onClick={handleOpenPrice} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenPrice();
                }
              }}>料金</a></li>
    				</ul>
    				<div className="nav_btn">
    					<div className="btn function f_download">
    						<a onClick={handleOpenFdownload} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenFdownload();
                }
              }}>資料ダウンロード</a>
    					</div>
    					<div className="btn function f_mail">
    						<a onClick={handleOpenFmail} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenFmail();
                }
              }}>お問い合わせ</a>
    					</div>
    				</div>
    			</nav>
    		</div>
    	</div>
    </header>
  );
};

