// 必要なユーティリティ関数を定義

export const MESSAGE_ENV_NOT_SET = 'Environment variable setting incorrect.';

// 値が設定されているかどうかを確認する関数
export const assert = (value: any, msg = MESSAGE_ENV_NOT_SET) => {
  if (value == null) throw new Error(msg);
  return value;
};


