import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';
import { HelmetProvider } from "react-helmet-async";
import { oidcConfig } from './oidcConfig';  // OIDC設定ファイルのインポート

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* headをページ別に変更するプロバイダーでラップ */}
    <HelmetProvider>
    {/* OIDC認証プロバイダーでラップ */}
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
