import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { CheckboxField, Flex,Text,View } from '@aws-amplify/ui-react';

export interface DataNode {
    name: string;
    childDataDict: { [key: string]: DataNode };
}

interface CheckboxItemDataDict {
    [key: string]: DataNode;
}

interface ForManageCheckboxState {
    [key: string]: boolean;
}

interface ForManageSearchRequirement {
    [key: string]: string;
}

/*
    dataDict: チェックボックスリスト作成用の辞書型データ
    checkedItems: チェックされたチェックボックスの状態管理用辞書型データ
    setCheckedItems: checkedItemsのsetter
    forNotationItems: チェックされたdataDictのコードを「>」で連結して状態管理する用の辞書型データ
    setForNotationItems: forNotationItemsのsetter
    sortingList: 辞書型データは並び順が保証されていないため、並び順を固定したい場合に配列にデータのコードを表示したい順番で挿入する
*/
interface SearchFilterCheckboxInputProps {
    dataDict: CheckboxItemDataDict;
    checkedItems: ForManageCheckboxState;
    setCheckedItems: (checkedItems: ForManageCheckboxState) => void;
    forNotationItems: ForManageSearchRequirement;
    setForNotationItems: (checkedItems:ForManageSearchRequirement) => void;
    sortingList: string[];
    upperLevelItemCodeConcatString: string | undefined;
    upperLevelItemNameConcatString: string | undefined;
    eachItemNum: {[key: string]: number};
    isTopLevelComponent: boolean;
    isRegionClassification: boolean;
};

export const SearchFilterCheckboxInput:React.FC<SearchFilterCheckboxInputProps> = ({dataDict,checkedItems,setCheckedItems,forNotationItems,setForNotationItems,sortingList,upperLevelItemCodeConcatString,upperLevelItemNameConcatString,eachItemNum,isTopLevelComponent,isRegionClassification}) => {
    // 地域分類かつ親要素が東京都でないかつ区が最後につくデータである場合trueにする
    const isWardData:boolean = isRegionClassification && Object.keys(dataDict)[0].substring(0,2) !== "13" && dataDict[Object.keys(dataDict)[0]]["name"].charAt(dataDict[Object.keys(dataDict)[0]]["name"].length - 1) === "区";
    
    const handleCheck:(itemId:string) => void = (itemId) => {
        // 処理終了前にcheckedItemsにセットするための更新後データを入れる辞書型データ
        const newCheckedItems:ForManageCheckboxState = {...checkedItems};
        const newForNotationItems:ForManageSearchRequirement = {...forNotationItems};
        
        
        // チェックが外された際の処理
        // checkedItemsから下位階層のコードがキーであるを削除することによって、チェックを外す
        const unCheckChildren:(checkboxItemKey:string,checkboxItem:DataNode,isChecked:boolean,itemCodeConcatString:string) => void = (checkboxItemKey,checkboxItem,isChecked,itemCodeConcatString) => {
            delete newCheckedItems[checkboxItemKey];
            delete newForNotationItems[itemCodeConcatString];
            // 再帰させ一番下位の階層まで処理を行う
            if (Object.keys(checkboxItem.childDataDict).length > 0) {
                Object.keys(checkboxItem.childDataDict).forEach(key => {
                    unCheckChildren(key,checkboxItem.childDataDict[key],isChecked,itemCodeConcatString+">"+key);
                })
            }
            
        }
        
        const itemCodeConcatString = upperLevelItemCodeConcatString ? upperLevelItemCodeConcatString + ">" + itemId : itemId;
        const itemNameConcatString = upperLevelItemNameConcatString ? upperLevelItemNameConcatString + ">" + dataDict[itemId].name : dataDict[itemId].name;
        
        // チェックボックスのチェックをつけた場合はtrue,外した場合はfalse
        const isChecked = !checkedItems[itemId];
        
        if (isChecked) {
            newCheckedItems[itemId] = isChecked;
            newForNotationItems[itemCodeConcatString] = itemNameConcatString;      
            if (upperLevelItemCodeConcatString !== undefined && newForNotationItems[upperLevelItemCodeConcatString]) {
                delete newForNotationItems[upperLevelItemCodeConcatString]
            }
        } else {
            unCheckChildren(itemId,dataDict[itemId],isChecked,itemCodeConcatString);
            if (upperLevelItemCodeConcatString !== undefined && upperLevelItemNameConcatString !== undefined) {
                if(Object.keys(newForNotationItems).filter(key => key.includes(upperLevelItemCodeConcatString)).length === 0 || Object.keys(newForNotationItems).length === 0){
                    newForNotationItems[upperLevelItemCodeConcatString] = upperLevelItemNameConcatString;                    
                }
            }
        }
        
        // 更新後データをcheckedItemsにセットする
        setCheckedItems(newCheckedItems);
        setForNotationItems(newForNotationItems);
    }
    
    return (
          <Flex direction="row" wrap="wrap" 
            marginLeft={{base:isTopLevelComponent ? "20px" : isWardData ? "0" :"30px", large:isTopLevelComponent ? "40px" : "80px"}} gap="0" 
            backgroundColor={isWardData ? "white" : "transparent"}
            paddingLeft={isWardData ? "20px" : "0"}
            paddingBottom={isWardData ? "20px" : "0"}
            paddingRight={isWardData ? "20px" : "0"}
            marginTop={isWardData ? "15px" : "0"}
            width={{ base:isWardData ? "calc(100% - 50px)":"auto",large:isWardData ? "calc(100% - 200px)":"auto"}}
          >
            {/*sortingListが空でない場合、チェックボックス一覧表示時の並び替え順がsortingListの先頭から順になる*/}    
            {!sortingList.length ?
                (
                    <>
                        {Object.entries(dataDict).map(([key,value]) => 
                            <View marginTop="20px"
                                width={{base:(isWardData ? 'auto' : '100%'),
                                        large:(dataDict[key]["childDataDict"] && Object.keys(dataDict[key]["childDataDict"]).length > 0) ? '100%' : 'auto'}} 
                                minWidth={{base:"150px",large:"200px"}}
                                backgroundColor="transparent" >
                                        <CheckboxField 
                                            size="large"
                                            label={dataDict[key].name+"("+(eachItemNum[key] ? eachItemNum[key] : "-")+")"} 
                                            name={key} 
                                            value={key} 
                                            checked={checkedItems[key] !== undefined && checkedItems[key]} 
                                            onChange={() => handleCheck(key)} 
                                            gap="15px"
                                            paddingRight={{base:"0" ,large:"50px"}}
                                            marginRight={{base:isWardData ? "10px" : "0",large:"0"}}
                                        />
                                        {checkedItems[key] && dataDict[key]["childDataDict"] && Object.keys(dataDict[key]["childDataDict"]).length > 0 &&(
                                            <SearchFilterCheckboxInput 
                                                dataDict={dataDict[key]["childDataDict"]} 
                                                checkedItems={checkedItems} 
                                                setCheckedItems={setCheckedItems} 
                                                forNotationItems={forNotationItems} 
                                                setForNotationItems={setForNotationItems} 
                                                sortingList={[]} 
                                                upperLevelItemCodeConcatString = {upperLevelItemCodeConcatString ? upperLevelItemCodeConcatString + ">" + key : key}
                                                upperLevelItemNameConcatString = {upperLevelItemNameConcatString ? upperLevelItemNameConcatString + ">" + dataDict[key].name : dataDict[key].name}
                                                eachItemNum={eachItemNum}
                                                isTopLevelComponent={false}
                                                isRegionClassification={isRegionClassification}
                                            />
                                        )}
                            </View>)
                        }
                    </>
                )
                :
                (
                    <>
                        {sortingList.map((sortingItem:string,index:number) =>
                                <View marginTop="20px" 
                                    width={{base:(isWardData ? 'auto' : '100%'), large:(dataDict[sortingItem]["childDataDict"] && Object.keys(dataDict[sortingItem]["childDataDict"]).length > 0) ? '100%' : '25%'}}  >
                                    <CheckboxField gap="15px" size="large" label={dataDict[sortingItem].name+"("+(eachItemNum[sortingItem] ? eachItemNum[sortingItem] : "-")+")"} name={sortingItem} value={sortingItem} checked={checkedItems[sortingItem] !== undefined && checkedItems[sortingItem]} onChange={() => handleCheck(sortingItem)} />
                                    {checkedItems[sortingItem] && dataDict[sortingItem]["childDataDict"] && Object.keys(dataDict[sortingItem]["childDataDict"]).length > 0 &&(
                                        <SearchFilterCheckboxInput
                                            dataDict={dataDict[sortingItem]["childDataDict"]} 
                                            checkedItems={checkedItems}
                                            setCheckedItems={setCheckedItems} 
                                            forNotationItems={forNotationItems} 
                                            setForNotationItems={setForNotationItems} 
                                            sortingList={[]}
                                            upperLevelItemCodeConcatString = {upperLevelItemCodeConcatString ? upperLevelItemCodeConcatString + ">" + sortingItem : sortingItem}
                                            upperLevelItemNameConcatString = {upperLevelItemNameConcatString ? upperLevelItemNameConcatString + ">" + dataDict[sortingItem].name : dataDict[sortingItem].name}
                                            eachItemNum={eachItemNum}
                                            isTopLevelComponent={false}
                                            isRegionClassification={isRegionClassification}
                                        />
                                    )}
                                </View>
                            )
                        }
                    </>
                )
            }
          </Flex>    
    )
}