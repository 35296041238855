import React,{ ReactNode, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate, Outlet } from "react-router-dom";

interface ErrorCheckerProps {
  children?: ReactNode;
}

const AuthErrorChecker = ({ children }: ErrorCheckerProps) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    if (params.has("error")) {
      console.log("AuthErrorChecker Params(location)の内容：",location);
      navigate(`/autherrorscreen${location.search}`);
    }
  }, [location.search, navigate]);

  useEffect(() => {
    if (auth.error) {
      console.log("AuthErrorChecker auth.errorの内容：",auth.error);
      navigate("/autherrorscreen");
    }
  }, [auth.error, navigate]);

  return <>{children ? children : <Outlet />}</>;
};

export default AuthErrorChecker;
