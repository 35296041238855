import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Text, Label, View, Flex, Divider, Button, Link, Image, Icon, Accordion, useTheme } from '@aws-amplify/ui-react';
import '../css/Footer.css'
import '../js/common.js'

export default function FooterPropsOpenVersion() {
  const { tokens } = useTheme();
  // サイトのご利用についてを同タブで開く
  const handleOpenSitePolicy = () => {
    window.location.href = `${process.env.REACT_APP_OPENSITE_BASE_URL}/sitepolicy.html`;
  };
  // 情報の修正依頼を同タブで開く
  const handleOpenChangeRequest = () => {
    window.location.href = `${process.env.REACT_APP_OPENSITE_BASE_URL}/inquiry/change_request.html`;
  };
  // 会員規約を同タブで開く
  const handleOpenAgreement = () => {
    window.location.href = `${process.env.REACT_APP_OPENSITE_BASE_URL}/agreement.html`;
  };
  
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  useEffect(() => {
    const year = new Date().getFullYear();
    setCurrentYear(year);
  }, []);
  
   // スクロールボタンのフッターより上かどうか
  const [isAboveFooter, setIsAboveFooter] = useState<boolean>(false);
  // スクロールボタンの高さの位置
  const [buttonBottom, setButtonBottom] = useState<number>(0);
  // スクロールボタンの表示/非表示
  const [isButtonDisplay,setIsButtonDisplay] = useState<boolean>(false);
  // フッターの要素
  const footerElment = useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    scrollWindow()
    window.addEventListener('scroll', scrollWindow)
    return () => {
      window.removeEventListener('scroll', scrollWindow)
    }
  }, [])

  // スクロール時の高さ別のステータス変更
  const scrollWindow = () => {

    const top = 50;  //ボタンを表示させたい位置
    let scroll = 0;
    scroll = window.scrollY; // 現在のスクロール位置
    
    // スクロールが最上部が判定
    if (scroll < top) {
      setButtonBottom(0);
      setIsButtonDisplay(false);
      return
    } else {
      if(footerElment.current === null) return
      setIsButtonDisplay(true);
      const bounding = footerElment.current.getBoundingClientRect()
      
      if (window.innerHeight > bounding.top) {
        setButtonBottom(bounding.height);
        setIsAboveFooter(true)
      } else {
        setButtonBottom(0);
        setIsAboveFooter(false)
      }
    }   
  }

  // 最上部へのスクロール
  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  
  const styleTop = {
    position: isAboveFooter ? 'absolute' : 'fixed',
    bottom: buttonBottom,
    right: 0,
    zIndex: 3,
    display: isButtonDisplay ? 'block' : 'none',
  } as React.CSSProperties
  
  return (
      <footer ref={footerElment}>
        <div style={{backgroundColor:`${tokens.colors.blue[90]}` ,...styleTop}}>
          <Button backgroundColor={tokens.colors.blue[90]} color={tokens.colors.white} fontSize="1.4rem"fontWeight="bold" 
        borderRadius="0" borderStyle="none" padding="0.8rem 1.6rem" onClick={returnTop}>▲</Button>
        </div>
        <div className="footer_inner">
          <div className="footer_main">
            <h2>ジー・サーチが提供するビジネス情報サービス</h2>
            <ul>
              <li><a href="https://www.g-search.or.jp/" target="_blank">G-Searchデータベースサービス<span
                    className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                    open_in_new
                  </span></a></li>
              <li><a href="https://www.g-search.or.jp/contents/compliance/gchecker/" target="_blank">コンプライアンスチェックサービス<span
                    className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                    open_in_new
                  </span></a></li>
              <li><a href="https://db.g-search.or.jp/compliance/overseas-check/" target="_blank">海外コンプライアンスチェック<span
                    className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                    open_in_new
                  </span></a></li>
              <li><a href="https://db.g-search.or.jp/map/WZFS.html" target="_blank">ゼンリン住宅地図情報サービス<span
                    className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                    open_in_new
                  </span></a></li>
            </ul>
          </div>
          <div className="footer_sub">
            <ul>
              <li><a href="https://www.g-search.jp/" target="_blank">運営会社（株式会社ジー・サーチについて）<span
                    className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                    open_in_new
                  </span></a></li>
              <li><a href="https://www.g-search.jp/info/privacy.html" target="_blank">個人情報保護ポリシー<span
                    className="material-symbols-outlined" role="img" aria-label="新規タブで開く">
                    open_in_new
                  </span></a></li>
              <li><a onClick={handleOpenSitePolicy} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenSitePolicy();
                }
              }}>サイトのご利用について</a></li>
              <li><a onClick={handleOpenChangeRequest} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenChangeRequest();
                }
              }}>情報の修正依頼</a></li>
              <li><a onClick={handleOpenAgreement} tabIndex={0} className="pointer-cursor"
    					  onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOpenAgreement();
                }
              }}>会員規約</a></li>
            </ul>
            <p className="copy">
              &copy; 2023-{currentYear} G-Search Limited
            </p>
          </div>
        </div>
      
      </footer>
  );
};

